import { Component } from '@angular/core';

import { WorkerConnectionService } from './worker-connection.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    worker: Worker | undefined;

    constructor(
        public readonly workerConnectionService: WorkerConnectionService,
    ) {}
}
