import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureCoreServicesModule } from '@orthocore-web-mono/feature-core-services';
import { FeatureLandmarksModule } from '@orthocore-web-mono/feature-landmarks';
import {
    StyledButtonComponent,
    StyledSliderComponent,
    StyledToggleComponent,
    ToggleGroupComponent,
} from '@orthocore-web-mono/shared-ui';

import { ViewOptionsContainerComponent } from './view-options-container/view-options-container.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,

        FeatureLandmarksModule,
        FeatureCoreServicesModule,

        //UI Elements
        StyledButtonComponent,
        StyledToggleComponent,
        StyledSliderComponent,
        ToggleGroupComponent,
    ],
    declarations: [ViewOptionsContainerComponent],
    exports: [ViewOptionsContainerComponent],
})
export class SharedEditorModule {}
