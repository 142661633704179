export const rad2deg = 180 / Math.PI;
export const deg2rad = Math.PI / 180;
export const inch2cm = 2.54;
export const mm2cm = 0.1;
export const cm2mm = 10;
export const value2percent = 0.01;

export const mmUnit = ' mm';
export const degreeUnit = '°';
export const percentUnit = '%';
