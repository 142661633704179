import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    FeatureCoreServicesModule,
    Stage,
    StageGuard,
} from '@orthocore-web-mono/feature-core-services';
import {
    FeatureFileLoaderModule,
    FilePanelComponent,
} from '@orthocore-web-mono/feature-file-loader';
import { FeatureImageImportModule } from '@orthocore-web-mono/feature-image-import';
import {
    FeatureLandmarksModule,
    LANDMARKS_TOKEN,
    LandmarksComponent,
} from '@orthocore-web-mono/feature-landmarks';
import {
    FeatureScanEditModule,
    ScanSettingsComponent,
} from '@orthocore-web-mono/feature-scan-edit';
import { FeatureUndoRedoResetModule } from '@orthocore-web-mono/feature-undo-redo-reset';
import { DESIGN_EDIT_COMPONENT_PROVIDER } from '@orthocore-web-mono/shared-types';
import {
    AxisSlidersComponent,
    CollapsingGroupComponent,
    CollapsingSectionComponent,
    HeaderComponent,
    HorizontalSeparatorComponent,
    PoweredByComponent,
    StyledButtonComponent,
    StyledDropdownComponent,
    StyledSliderComponent,
    StyledSliderWithInputComponent,
    StyledTextInputMultilineComponent,
    StyledToggleComponent,
    ToggleGroupComponent,
} from '@orthocore-web-mono/shared-ui';
import { SharedEditorModule } from '@orthocore-web-mono/shared/feature-editor';
import { SharedFeatureSceneModule } from '@orthocore-web-mono/shared/feature-scene';
import {
    FeatureDesignModule,
    SpinalDesignComponent,
} from '@orthocore-web-mono/spinal-feature-design';
import { SpinalEngineModule } from '@orthocore-web-mono/spinal-feature-engine';
import {
    FeatureSpinalMeshEditModule,
    SpinalMeshEditComponent,
} from '@orthocore-web-mono/spinal-feature-mesh-edit';

import { CutoutComponent } from './editor/component-settings/cutout/cutout.component';
import { DifferentThicknessComponent } from './editor/component-settings/different-thickness/different-thickness.component';
import { EdgeThicknessComponent } from './editor/component-settings/edge-thickness/edge-thickness.component';
import { HoleComponent } from './editor/component-settings/hole/hole.component';
import { LoopComponent } from './editor/component-settings/loop/loop.component';
import { OutlineComponent } from './editor/component-settings/outline/outline.component';
import { PatternComponent } from './editor/component-settings/pattern/pattern.component';
import { ReinforcementComponent } from './editor/component-settings/reinforcement/reinforcement.component';
import { SpinalEditDesignComponentProvider } from './editor/component-settings/spinal-edit-design-component-provider';
import { StrutComponent } from './editor/component-settings/strut/strut.component';
import { EditorComponent } from './editor/editor.component';
import { spinalLandmarksInOrder } from './landmarks';

const routes: Routes = [
    { path: Stage.FileImport, component: FilePanelComponent },
    {
        path: Stage.ScanSettings,
        component: ScanSettingsComponent,
        canActivate: [StageGuard],
    },
    {
        path: Stage.Landmarks,
        component: LandmarksComponent,
        canActivate: [StageGuard],
    },
    {
        path: Stage.MeshEdit,
        component: SpinalMeshEditComponent,
        canActivate: [StageGuard],
    },
    {
        path: Stage.Design,
        component: SpinalDesignComponent,
        canActivate: [StageGuard],
    },
    { path: '**', redirectTo: Stage.FileImport },
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,

        SpinalEngineModule,
        SharedEditorModule,
        SharedFeatureSceneModule,
        FeatureFileLoaderModule,
        FeatureScanEditModule,
        FeatureLandmarksModule,
        FeatureUndoRedoResetModule,
        FeatureCoreServicesModule,
        FeatureSpinalMeshEditModule,
        FeatureImageImportModule,
        FeatureDesignModule,

        //UI Elements
        HeaderComponent,
        PoweredByComponent,
        HorizontalSeparatorComponent,
        StyledButtonComponent,
        CollapsingGroupComponent,
        CollapsingSectionComponent,
        StyledToggleComponent,
        StyledSliderComponent,
        ToggleGroupComponent,
        StyledDropdownComponent,
        StyledSliderWithInputComponent,
        AxisSlidersComponent,
        StyledTextInputMultilineComponent,
    ],
    declarations: [
        EditorComponent,
        OutlineComponent,
        CutoutComponent,
        DifferentThicknessComponent,
        EdgeThicknessComponent,
        HoleComponent,
        LoopComponent,
        PatternComponent,
        ReinforcementComponent,
        StrutComponent,
    ],
    exports: [EditorComponent],
    providers: [
        provideRouter(routes),
        {
            provide: DESIGN_EDIT_COMPONENT_PROVIDER,
            useClass: SpinalEditDesignComponentProvider,
        },
        { provide: LANDMARKS_TOKEN, useValue: spinalLandmarksInOrder },
    ],
})
export class SpinalEditorModule {}
