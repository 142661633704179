import { createAction, props } from '@ngrx/store';
import {
    FittingPlane,
    MeasurementMode,
    ScanCleanSelectionMode,
} from '@orthocore-web-mono/shared-types';
import { ScanSettingsScaleUnit } from '@orthocore-web-mono/shared/utils';

// import { EditorEntity } from './editor.models';

const ACTION_PREFIX = '[Editor]';

export const loadEngineSuccess = createAction(
    `${ACTION_PREFIX} Load Engine Success`,
);

export const loadEditorFailure = createAction(
    `${ACTION_PREFIX} Load Editor Failure`,
    props<{ error: string | null }>(),
);

export const loadedModel = createAction(`${ACTION_PREFIX} Loaded model`);

export const changeShowGroundPlane = createAction(
    `${ACTION_PREFIX} Change Show Ground Place`,
    props<{ value: boolean }>(),
);

export const changeShowLandmarks = createAction(
    `${ACTION_PREFIX} Change Show Landmarks`,
    props<{ value: boolean }>(),
);

export const changeIsOrthographicsView = createAction(
    `${ACTION_PREFIX} Change Orthographics View`,
    props<{ value: boolean }>(),
);

export const changeShowMeasurementsPanel = createAction(
    `${ACTION_PREFIX} Change Show Measurements Panel`,
    props<{ value: boolean }>(),
);

export const setMeasurementMode = createAction(
    `${ACTION_PREFIX} Set Measurement Mode`,
    props<{ mode: MeasurementMode }>(),
);

export const clearMeasurementPoints = createAction(
    `${ACTION_PREFIX} Clear Measurement Points`,
);

export const setMeasuredDistance = createAction(
    `${ACTION_PREFIX} Set Measured Distance`,
    props<{ value?: number }>(),
);

export const setMeasuredGirth = createAction(
    `${ACTION_PREFIX} Set Measured Girth`,
    props<{ value?: number }>(),
);

export const setScale = createAction(
    `${ACTION_PREFIX} Set Scale`,
    props<{ value: ScanSettingsScaleUnit }>(),
);

export const setOpacity = createAction(
    `${ACTION_PREFIX} Set Opacity`,
    props<{ value: number }>(),
);

export const setScanTransformMode = createAction(
    `${ACTION_PREFIX} Set Scan Transfrom Mode`,
    props<{ mode?: 'translate' | 'rotate' }>(),
);

// Sliders or the gizmo changed the settings
export const scanTransformRotateSettingsChanged = createAction(
    `${ACTION_PREFIX} Scan Transform Rotate Settings Changed`,
    props<{ x: number; y: number; z: number }>(),
);

export const scanTransformRotateFinished = createAction(
    `${ACTION_PREFIX} Scan Transform Rotate Finished`,
);

export const finalRotationValuesCalculated = createAction(
    `${ACTION_PREFIX} Final Rotation Values Calculated`,
);

export const changeScanClearSelectionMode = createAction(
    `${ACTION_PREFIX} Change Scan Clear Selection Mode`,
    props<{ mode: ScanCleanSelectionMode }>(),
);

// Mesh edit / Import image
export const meshEditImageLoaded = createAction(
    `${ACTION_PREFIX} Image import loaded`,
    props<{ loaded: boolean }>(),
);

export const changeMeshEditImageFittingPlane = createAction(
    `${ACTION_PREFIX} Change Mesh Edit Image Fitting Plane`,
    props<{ plane: FittingPlane }>(),
);

export const changeMeshEditImageImportShow = createAction(
    `${ACTION_PREFIX} Change Mesh Edit Image Import Show`,
    props<{ show: boolean }>(),
);

export const changeMeshEditImageImportOpacity = createAction(
    `${ACTION_PREFIX} Change Mesh Edit Image Import Opacity`,
    props<{ opacity: number }>(),
);

export const applyImageToMesh = createAction(
    `${ACTION_PREFIX} Apply Image To Mesh`,
);
