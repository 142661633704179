import * as THREE from 'three';

export const selectionMaterial = new THREE.ShaderMaterial({
    side: THREE.DoubleSide,
    transparent: false,
    blending: THREE.NormalBlending,
    uniforms: {
        opacity: { value: 1.0 },
    },
    vertexShader: `
varying vec3 vViewNormal;
varying vec4 vWorldPosition;
varying float vSelection;

attribute float selection;

void main()
{
    vViewNormal = normalize(normalMatrix * normal);
    vWorldPosition = modelMatrix * vec4(position, 1);
    vSelection = selection;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
}
`,
    fragmentShader: `
uniform float opacity;

varying vec3 vViewNormal;
varying vec4 vWorldPosition;
varying float vSelection;

void main()
{
    float zCoord = abs(vViewNormal.z);
    float brightness = smoothstep(-0.4, 1.3, zCoord);

    const vec3 defaultColor = vec3(1);
    const vec3 selectionColor = vec3(0, 0.5, 1);

    vec3 baseColor = defaultColor * vec3(brightness);
    vec3 color = mix(baseColor, selectionColor, vSelection * 0.6);

    gl_FragColor = vec4(color, opacity);
}
`,
});
