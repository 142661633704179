import { Inject, Injectable } from '@angular/core';
import { NavigationService } from '@orthocore-web-mono/feature-core-services';
import { PressureReliefOperation } from '@orthocore-web-mono/feature-mesh-edit';
import { UndoRedoService } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    Float3JS,
    GeometryUpdateFlags,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    PressureReliefSamplePointsData,
    PressureReliefShapeData,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import {
    SceneService,
    SplineObject,
    SplineObjectParams,
} from '@orthocore-web-mono/shared/feature-scene';
import { Vector3ToFloat3 } from '@orthocore-web-mono/shared/utils';
import {
    Subject,
    distinctUntilChanged,
    exhaustMap,
    filter,
    map,
    takeUntil,
} from 'rxjs';
import * as THREE from 'three';

const lineThickness = 0.3;
const splineDisplayOffset = lineThickness * 0.5;
const controlPointRadius = 0.3;

const editMouseButton = 0; // Left
let draggedControlPoint: SplineControlPoint | null = null;
let lastDraggedControlPoint: SplineControlPoint | null = null; // Separate value, used for height slider
const tmpVec3 = new THREE.Vector3();

interface SplineControlPoint {
    isInner: boolean;
    index: number;
}

interface SplineInfo {
    isInner: boolean;
    controlPointHeightScales: number[];
    splineObject: SplineObject;
}

interface SplineUpdateData {
    shape?: PressureReliefShapeData | null;
    sampled: PressureReliefSamplePointsData;
}

const splineObjectParams: SplineObjectParams = {
    lineWidth: lineThickness,
    lineWidthOrthographic: 0.01,
    lineWidthIsWorldUnits: true,
    controlPointRadius,
};

// let positionsBeforeDrag: {
//     innerPoints: THREE.Vector3[];
//     outerPoints: THREE.Vector3[];
// } = {
//     innerPoints: [],
//     outerPoints: [],
// };

@Injectable()
export class PressureReliefService {
    public splineInfos: SplineInfo[] = [];
    public shapeInfos!: { shapeNames: string[] };

    public shapeData?: SplineUpdateData;

    public init$ = new Subject<void>();

    private lastDraggedControlPoint$ = new Subject<SplineControlPoint | null>();
    public selectedInnerPointChanged$ = this.lastDraggedControlPoint$.pipe(
        map(point => {
            if (point === null || !point.isInner) return {};
            return {
                index: point.index,
                height: this.splineInfos[0].controlPointHeightScales[
                    point.index
                ],
            };
        }),
    );

    private isOnSection$ = this.nav.selectedSection$.pipe(
        map(section => section === 'pressure-relief'),
        distinctUntilChanged(),
    );

    public activate$ = this.isOnSection$.pipe(filter(v => v));
    public deactivate$ = this.isOnSection$.pipe(filter(v => !v));

    private updatePressureReliefInternal$ = new Subject<void>();

    constructor(
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: IScanService,
        private readonly sceneService: SceneService,
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        private readonly undoRedoService: UndoRedoService,
        private readonly nav: NavigationService,
    ) {
        this.init().catch(e => console.error(e));

        this.activate$.subscribe(() => this.subscribeToMouseEvents());
        this.deactivate$.subscribe(() => this.finish(true));

        this.updatePressureReliefInternal$
            .pipe(
                exhaustMap(
                    async () => await this.updatePressureReliefInternal(),
                ),
            )
            .subscribe();
    }

    public async StartPressureRelief(shapeIndex: number) {
        this.shapeData = await this.engine.startPressureRelief(shapeIndex);
        this.updateSplineVisuals(this.shapeData);
        await this.scanService.syncScanMeshFromEngine(
            GeometryUpdateFlags.Vertices | GeometryUpdateFlags.Normals,
        );
        // MeshEditDisableUndoRedo();
    }

    public async setDepth(value: number) {
        if (!this.shapeData?.shape) return;
        this.shapeData.shape.depth = value;
        this.updatePressureReliefInternal$.next();
    }

    public async setHeight(value: number) {
        if (
            lastDraggedControlPoint === null ||
            !lastDraggedControlPoint.isInner
        ) {
            return;
        }
        this.splineInfos[0].controlPointHeightScales[
            lastDraggedControlPoint.index
        ] = value;
        this.updatePressureReliefInternal$.next();
    }

    public finish(isCancel: boolean) {
        if (!this.shapeData?.shape) return;
        if (isCancel) {
            this.engine.finishPressureRelief(true);
            this.scanService.syncScanMeshFromEngine(
                GeometryUpdateFlags.Vertices | GeometryUpdateFlags.Normals,
            );
        } else {
            this.undoRedoService.doOperation(
                new PressureReliefOperation(this.engine),
            );
        }

        lastDraggedControlPoint = null;

        this.splineInfos.forEach(spline => {
            spline.splineObject.visible = false;
        });

        // MeshEditEnableUndoRedo();

        this.sceneService.requestSceneRender();
    }

    private async init() {
        this.shapeInfos = await this.engine.getPressureReliefShapeInfos();
        this.splineInfos.push(
            {
                isInner: true,
                splineObject: new SplineObject(
                    this.sceneService,
                    splineObjectParams,
                ),
                controlPointHeightScales: [],
            },
            {
                isInner: false,
                splineObject: new SplineObject(
                    this.sceneService,
                    splineObjectParams,
                ),
                controlPointHeightScales: [],
            },
        );

        // Render inner spline with higher render order, because it might go inside the foot scan mesh
        this.splineInfos[0].splineObject.splineMaterial.depthTest = false;
        this.splineInfos[0].splineObject.controlPointMaterials[0].depthTest =
            false;
        this.splineInfos[0].splineObject.splineObject.renderOrder = 1;
        this.splineInfos[0].splineObject.controlPointContainer.renderOrder = 2;

        this.splineInfos.forEach(spline => {
            this.sceneService.scene.add(spline.splineObject);
            spline.splineObject.visible = false;
        });

        this.init$.next();
        this.init$.complete();
    }

    private subscribeToMouseEvents() {
        this.sceneService.mouseDown$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.mouseDown(event));

        this.sceneService.mouseUp$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.mouseUp(event));

        this.sceneService.mouseMove$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.mouseMove(event));
    }

    private async mouseDown(ev: MouseEvent) {
        if (ev.button !== editMouseButton) {
            return;
        }

        const ray = this.scanService.getMouseRay(ev);
        const hit = await this.engine.scanRaycast(ray.origin, ray.direction);
        if (hit === null) {
            return;
        }

        const hitPoint = tmpVec3.set(hit.x, hit.y, hit.z);

        draggedControlPoint = null;
        let minDistanceSqr = controlPointRadius * controlPointRadius;

        // Find closest control point to raycast hit point
        for (const spline of this.splineInfos) {
            const { controlPointObjects } = spline.splineObject;
            for (let i = 0; i < controlPointObjects.length; ++i) {
                const distanceSqr = hitPoint.distanceToSquared(
                    controlPointObjects[i].mesh.position,
                );
                if (distanceSqr < minDistanceSqr) {
                    minDistanceSqr = distanceSqr;
                    draggedControlPoint = {
                        index: i,
                        isInner: spline.isInner,
                    };
                }
            }
        }

        if (draggedControlPoint !== null) {
            lastDraggedControlPoint = draggedControlPoint;
        }

        this.lastDraggedControlPoint$.next(lastDraggedControlPoint);
    }

    private mouseUp(ev: MouseEvent) {
        if (ev.button !== editMouseButton) {
            return;
        }

        draggedControlPoint = null;
    }

    private async mouseMove(ev: MouseEvent) {
        const controlPoint = draggedControlPoint;
        if (controlPoint === null) return;

        const ray = this.scanService.getMouseRay(ev);
        const hit = await this.engine.scanRaycast(ray.origin, ray.direction);
        if (hit === null) return;

        const hitPoint = tmpVec3.set(hit.x, hit.y, hit.z);

        const spline =
            this.splineInfos[controlPoint.isInner ? 0 : 1].splineObject;
        const point = spline.controlPointObjects[controlPoint.index];

        point.mesh.position.copy(hitPoint);

        // if (ev.ctrlKey) {
        //     const diff = new THREE.Vector3().subVectors(
        //         hitPoint,
        //         positionsBeforeDrag[
        //             controlPoint.isInner ? 'innerPoints' : 'outerPoints'
        //         ][controlPoint.index],
        //     );

        //     const getClosestPositionPromise = (pos: THREE.Vector3) => {
        //         const newPosition = pos.clone().add(diff);
        //         return this.engine.FootScanGetClosestPoint(newPosition);
        //     };

        //     const newOuterPositionsPromise =
        //         positionsBeforeDrag.outerPoints.map(getClosestPositionPromise);
        //     const newInnerPositionsPromise =
        //         positionsBeforeDrag.innerPoints.map(getClosestPositionPromise);
        //     await Promise.all([
        //         ...newInnerPositionsPromise,
        //         ...newOuterPositionsPromise,
        //     ]);

        //     // Already awaited, so this won't wait again
        //     const newInnerPositions = (
        //         await Promise.all(newInnerPositionsPromise)
        //     ).map(data => data.closestPoint);
        //     const newOuterPositions = (
        //         await Promise.all(newOuterPositionsPromise)
        //     ).map(data => data.closestPoint);

        //     const updatePositions = (
        //         positions: Float3JS[],
        //         controlPoints: ControlPointData[],
        //         isOuter: boolean,
        //     ) => {
        //         for (let i = 0; i < controlPoints.length; ++i) {
        //             if (
        //                 controlPoint.isInner === !isOuter &&
        //                 i === controlPoint.index
        //             ) {
        //                 continue;
        //             }

        //             const p = positions[i];
        //             controlPoints[i].mesh.position.copy(
        //                 tmpVec3.set(p.x, p.y, p.z),
        //             );
        //         }
        //     };

        //     updatePositions(
        //         newInnerPositions,
        //         splineInfos[0].splineObject.controlPointObjects,
        //         false,
        //     );
        //     updatePositions(
        //         newOuterPositions,
        //         splineInfos[1].splineObject.controlPointObjects,
        //         true,
        //     );
        // }

        this.updatePressureReliefInternal$.next();
    }

    private updateSplineVisuals(shapeData: SplineUpdateData) {
        const updateSpline = (isInner: boolean) => {
            const splineInfo = this.splineInfos[isInner ? 0 : 1];
            const spline = splineInfo.splineObject;

            let controlPoints: Float3JS[] | null = null;
            if (shapeData.shape !== null && shapeData.shape !== undefined) {
                const pressureReliefPoints =
                    shapeData.shape[isInner ? 'inner' : 'outer'];
                controlPoints = pressureReliefPoints.map(
                    shapePoint => shapePoint.position,
                );

                splineInfo.controlPointHeightScales = pressureReliefPoints.map(
                    point => point.heightScale,
                );
            }

            const samplePoints =
                shapeData.sampled[isInner ? 'innerPoints' : 'outerPoints'];
            const sampleNormals =
                shapeData.sampled[isInner ? 'innerNormals' : 'outerNormals'];
            for (let i = 0; i < samplePoints.length; ++i) {
                samplePoints[i] += sampleNormals[i] * splineDisplayOffset;
            }

            spline.updateGeometry(controlPoints, samplePoints);
            spline.visible = true;

            return true;
        };

        if (updateSpline(true) && updateSpline(false)) {
            this.sceneService.requestSceneRender();
        }
    }

    private async updatePressureReliefInternal() {
        const GetPressureReliefPoints = (isInner: boolean) => {
            const info = this.splineInfos[isInner ? 0 : 1];
            return info.splineObject.controlPointObjects.map(
                (controlPointObject, index) => {
                    return {
                        heightScale: info.controlPointHeightScales[index],
                        position: Vector3ToFloat3(
                            controlPointObject.mesh.position,
                        ),
                    };
                },
            );
        };

        this.sceneService.requestSceneRender();

        const updatedShapeData = await this.engine.updatePressureRelief({
            depth: this.shapeData?.shape?.depth ?? 0,
            inner: GetPressureReliefPoints(true),
            outer: GetPressureReliefPoints(false),
        });

        await this.afterUpdatePressureRelief(updatedShapeData);
    }

    private async afterUpdatePressureRelief(shapeData: SplineUpdateData) {
        this.updateSplineVisuals(shapeData);
        await this.scanService.syncScanMeshFromEngine(
            GeometryUpdateFlags.Vertices | GeometryUpdateFlags.Normals,
        );
    }
}
