import * as THREE from 'three';

export const loopTransformMaterial = new THREE.ShaderMaterial({
    side: THREE.DoubleSide,
    transparent: false,
    blending: THREE.NormalBlending,
    uniforms: {
        opacity: { value: 1.0 },
        plane0: { value: new THREE.Vector4(0, 1, 0, -15) },
        plane1: { value: new THREE.Vector4(0, 1, 0, -20) },
        plane2: { value: new THREE.Vector4(0, 1, 0, -10) },
        plane3: { value: new THREE.Vector4(0, 1, 0, -30) },
    },
    vertexShader: `
varying vec3 vViewNormal;
varying vec4 vWorldPosition;

void main()
{
    vViewNormal = normalize(normalMatrix * normal);
    vWorldPosition = modelMatrix * vec4(position, 1);

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
}
`,
    fragmentShader: `
uniform float opacity;
uniform vec4 plane0;
uniform vec4 plane1;
uniform vec4 plane2;
uniform vec4 plane3;

varying vec3 vViewNormal;
varying vec4 vWorldPosition;

float getPlaneFactor(vec4 plane)
{
    const float radius = 0.1;

    float dist = abs(dot(plane, vWorldPosition));
    return smoothstep(0.0, 0.5, (radius - dist) / radius);
}

void main()
{
    float zCoord = abs(vViewNormal.z);
    float brightness = smoothstep(-0.2, 1.4, zCoord);

    const vec3 defaultColor = vec3(1);
    const vec3 mainLoopColor = vec3(1);
    const vec3 secondaryLoopColor = vec3(0, 0.5, 1);

    vec3 baseColor = defaultColor * vec3(brightness);
    vec3 color = baseColor;
    color = mix(color, mainLoopColor, getPlaneFactor(plane0));
    color = mix(color, secondaryLoopColor, getPlaneFactor(plane1));
    color = mix(color, secondaryLoopColor, getPlaneFactor(plane2));
    color = mix(color, mainLoopColor, getPlaneFactor(plane3));

    gl_FragColor = vec4(color, opacity);
}
`,
});
