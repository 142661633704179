import { ScanPreparationParamsJS } from './native-types';

// Fixed parameter values
const smoothFactor = 1;
const smoothIterations = 1;
const remeshResolution = 100;
const brushSize = 0.15;

export const defaultScanPreparationParams: ScanPreparationParamsJS = {
    applyManualDel: false,
    applyHoleFill: false,
    applyGlobalSmooth: false,
    applySmallDel: false,
    applyVoxelRemesh: false,

    manualDelMode: 'Lasso',
    manualDelIsSelClearOnSel: true,
    cameraViewProjMat: {
        c0: { x: 1, y: 0, z: 0, w: 0 },
        c1: { x: 0, y: 1, z: 0, w: 0 },
        c2: { x: 0, y: 0, z: 1, w: 0 },
        c3: { x: 0, y: 0, z: 0, w: 1 },
    },
    screenAspect: 1,
    vertexSelInfoGuideRect: { x: 0, y: 0, z: 0, w: 0 },
    brushSize,

    globalSmoothAmount: smoothFactor,
    globalSmoothNumIterations: smoothIterations,

    smallDelFactor: 'VertexCount',
    smallDelKeepCnt: 1,

    voxelRemeshGridResolution: remeshResolution,
};
