import {
    EditorStageStatusService,
    NavigationService,
} from '@orthocore-web-mono/feature-core-services';
import { LandmarksService } from '@orthocore-web-mono/feature-landmarks';
import { UndoRedoService } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    GeometryUpdateFlags,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    ToolLoopOutJS,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import {
    Observable,
    Subject,
    distinctUntilChanged,
    exhaustMap,
    filter,
    map,
    takeUntil,
} from 'rxjs';

import { LoopTool } from './loop-tool';
import { LoopToopOperation } from './loop-tool-operation';
import { LoopToolsMaterialService } from './loop-tools-material.service';
import { MeasurementBO } from './measurements/measurement.bo';

export abstract class LoopToolBaseService<T extends unknown> {
    protected abstract sectionName: LoopTool;
    protected isOnSection$!: Observable<boolean>;

    public measurements?: MeasurementBO;
    public measurements$ = new Subject<MeasurementBO>();

    protected activate$!: Observable<boolean>;
    protected deactivate$!: Observable<boolean>;

    private update$ = new Subject<void>();

    public params: T;

    constructor(
        protected readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        protected readonly scanService: IScanService,
        protected readonly sceneService: SceneService,
        protected readonly undoRedoService: UndoRedoService,
        protected readonly nav: NavigationService,
        protected readonly loopToolsMaterialService: LoopToolsMaterialService,
        protected readonly status: EditorStageStatusService,
        private readonly landmarks: LandmarksService,
        private readonly depaultParamsThatChangeOnReset: Partial<T>,
        private readonly depaultParamsThatStayOnReset: Partial<T>,
    ) {
        this.params = {
            ...this.depaultParamsThatChangeOnReset,
            ...this.depaultParamsThatStayOnReset,
        } as T;

        this.init();
    }

    protected abstract sendStartToEngine(): Promise<void>;
    protected abstract sendUpdateToEngine(params: T): Promise<ToolLoopOutJS>;
    protected abstract sendFinishToEngine(isCancel: boolean): Promise<void>;

    protected async init() {
        this.isOnSection$ = this.nav.selectedSection$.pipe(
            map(s => s === this.sectionName),
            distinctUntilChanged(),
        );

        this.isOnSection$.subscribe(onSection => {
            this.loopToolsMaterialService.activateMaterial(onSection);
        });

        this.activate$ = this.isOnSection$.pipe(filter(v => v));
        this.deactivate$ = this.isOnSection$.pipe(filter(v => !v));

        this.activate$.subscribe(async () => {
            this.undoRedoService.start$
                .pipe(takeUntil(this.deactivate$))
                .subscribe(() => {
                    this.sendFinishToEngine(true);
                });

            this.undoRedoService.finish$
                .pipe(takeUntil(this.deactivate$))
                .subscribe(() => {
                    this.sendStartToEngine();
                });

            this.startTool();
        });

        this.deactivate$.subscribe(async () => {
            await this.onFinishLoopTool(true);
        });

        this.update$
            .pipe(exhaustMap(async () => await this.sendToEngineAndUpdate()))
            .subscribe();

        this.measurements$.subscribe(measurements => {
            this.loopToolsMaterialService.hideAllPlanes();
            this.setPlanesOnMaterial(measurements);
            this.sceneService.requestSceneRender();
        });
    }

    public async startTool() {
        await this.status.landmarksApplied;
        await this.sendStartToEngine();
        await this.sendToEngineAndUpdate();
    }

    public update() {
        this.update$.next();
    }

    protected async sendToEngineAndUpdate() {
        const [result, _] = await Promise.all([
            this.sendUpdateToEngine(this.params),
            this.scanService.syncScanMeshFromEngine(
                GeometryUpdateFlags.Vertices | GeometryUpdateFlags.Normals,
            ),
        ]);

        this.measurements = new MeasurementBO(result);
        this.measurements$.next(this.measurements);
    }

    protected abstract setPlanesOnMaterial(measurements: MeasurementBO): void;

    public async onFinishLoopTool(isCancel: boolean) {
        if (!this.sendFinishToEngine) return;

        // this.engine.transformToMainAxis();

        Object.assign(this.params as any, this.depaultParamsThatChangeOnReset);

        if (!isCancel) {
            await this.undoRedoService.doOperation(
                new LoopToopOperation(this.engine, () =>
                    this.sendFinishToEngine(false),
                ),
            );

            await this.startTool();
        }

        if (isCancel) {
            await this.sendFinishToEngine(true);
            await this.scanService.syncScanMeshFromEngine(
                GeometryUpdateFlags.Vertices | GeometryUpdateFlags.Normals,
            );
        }
    }
}
