import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureCoreServicesModule } from '@orthocore-web-mono/feature-core-services';
import {
    AxisSlidersComponent,
    CollapsingGroupComponent,
    CollapsingSectionComponent,
    HeaderComponent,
    HorizontalSeparatorComponent,
    PoweredByComponent,
    StyledButtonComponent,
    StyledDropdownComponent,
    StyledSliderComponent,
    StyledSliderWithInputComponent,
    StyledTextInputMultilineComponent,
    StyledToggleComponent,
    ToggleGroupComponent,
} from '@orthocore-web-mono/shared-ui';
import { SharedFeatureSceneModule } from '@orthocore-web-mono/shared/feature-scene';

import { SpinalDesignComponent } from './design-controls/design.component';
import { SpinalDesignService } from './design.service';
import { EmbosserService } from './embosser-service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,

        FeatureCoreServicesModule,
        SharedFeatureSceneModule,

        HeaderComponent,
        PoweredByComponent,
        HorizontalSeparatorComponent,
        StyledButtonComponent,
        CollapsingGroupComponent,
        CollapsingSectionComponent,
        StyledToggleComponent,
        StyledSliderComponent,
        ToggleGroupComponent,
        StyledDropdownComponent,
        StyledSliderWithInputComponent,
        AxisSlidersComponent,
        StyledTextInputMultilineComponent,
    ],
    declarations: [SpinalDesignComponent],
    providers: [SpinalDesignService, EmbosserService],
    exports: [SpinalDesignComponent],
})
export class FeatureDesignModule {}
