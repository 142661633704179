import { Component } from '@angular/core';
import { mmUnit } from '@orthocore-web-mono/shared/utils';

@Component({
    selector: 'leo-different-thickness',
    templateUrl: './different-thickness.component.html',
    styleUrl: './different-thickness.component.scss',
})
export class DifferentThicknessComponent {
    mmUnit = mmUnit;
}
