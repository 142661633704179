import { Inject, Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    NavigationService,
} from '@orthocore-web-mono/feature-core-services';
import { LandmarksService } from '@orthocore-web-mono/feature-landmarks';
import { UndoRedoService } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    ENVIRONMENT,
    Environment,
    Float3JS,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import { ScanSettingsScaleUnit } from '@orthocore-web-mono/shared/utils';
import { EditorFacade } from '@orthocore-web-mono/state';
import {
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    takeUntil,
} from 'rxjs';
import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';

import {
    ScanSettingSections,
    ScanTransformMode,
    transformSectionName,
} from './constants';
import {
    ApplyTransformOperation,
    ScaleUnitChangeOperation,
    TransformMoveOperation,
    TransformRotateOperation,
} from './transform';

const tmpEuler = new THREE.Euler(0, 0, 0, 'YXZ');
const maxMoveDistance = 150;

@Injectable()
export class ScanSettingsService {
    public scale: ScanSettingsScaleUnit = ScanSettingsScaleUnit.CM;

    public rotation: Float3JS = {
        x: 0,
        y: 0,
        z: 0,
    };

    gizmoSettings$ = combineLatest([
        this.nav.selectedSection$,
        this.facade.scanTransformMode$,
    ]);

    public rotationChanged$ = new Subject<Float3JS>();

    public transformGizmo: TransformControls;

    private isOnTransformSection$ = this.nav.selectedSection$.pipe(
        map(section => section === ScanSettingSections.TransformSectionName),
        distinctUntilChanged(),
    );

    private activate$ = this.isOnTransformSection$.pipe(filter(v => v));
    private deactivate$ = this.isOnTransformSection$.pipe(filter(v => !v));

    constructor(
        @Inject(ENVIRONMENT) private readonly environment: Environment,
        @Inject(SCAN_SERVICE_TOKEN) private readonly scanService: IScanService,
        private readonly sceneService: SceneService,
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        private readonly undoRedoService: UndoRedoService,
        private readonly nav: NavigationService,
        private readonly facade: EditorFacade,
        private readonly status: EditorStageStatusService,
        private readonly landmarks: LandmarksService,
    ) {
        this.applyTransformInDev().catch(e => console.error(e));

        this.gizmoSettings$.subscribe(([section, mode]) => {
            this.updateGizmoSettings(section, mode as ScanTransformMode);
        });

        this.setScale(ScanSettingsScaleUnit.MM).catch(e => console.error(e));

        this.activate$.subscribe(() => {
            this.subscribeToMouseEvents();
        });

        this.transformGizmo = new TransformControls(
            this.sceneService.activeCamera,
            this.sceneService.renderer.domElement,
        );
        this.sceneService.scene.add(this.transformGizmo);
        this.transformGizmo.enabled = false;
    }

    public async apply() {
        await this.status.modelLoaded;

        await this.undoRedoService.doOperation(
            new ApplyTransformOperation(
                this.scanService.scan,
                this.scanService.scan.position,
                this.scanService.scan.quaternion,
                this.scanService.scan.scale.x,
                this.engine,
            ),
        );

        await this.undoRedoService.clearUndoRedo();
        this.status.transformInitialised$.next(true);
    }

    public async setScale(value: ScanSettingsScaleUnit) {
        this.scale = value;
        await this.undoRedoService.doOperation(
            new ScaleUnitChangeOperation(this.scanService, this.scale),
        );
        this.sceneService.requestSceneRender();
    }

    public updateRotationFromFootscan() {
        this.rotation = new THREE.Euler(0, 0, 0, 'YXZ').setFromQuaternion(
            this.scanService.scan.quaternion,
        );
        this.rotationChanged$.next(this.rotation);
    }

    public updateRotationFromSliders(event: Float3JS) {
        this.rotation = event;

        tmpEuler.set(event.x, event.y, event.z);
        this.scanService.scan.quaternion.setFromEuler(tmpEuler);
        this.sceneService.requestSceneRender();
    }

    public async sliderFinished() {
        await this.undoRedoService.doOperation(
            new TransformRotateOperation(
                this,
                this.scanService,
                this.scanService.scan.quaternion,
            ),
        );
    }

    private subscribeToMouseEvents() {
        this.sceneService.mouseMove$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(() => this.sceneService.requestSceneRender());
    }

    private updateGizmoSettings(section?: string, mode?: ScanTransformMode) {
        if (section === transformSectionName && mode !== undefined) {
            this.enableGizmo(mode);
        } else {
            this.disableGizmo();
        }
    }

    private enableGizmo(mode: ScanTransformMode) {
        const gizmo = this.transformGizmo;

        gizmo.attach(this.scanService.scan);
        gizmo.enabled = true;
        gizmo.setMode(mode);
        gizmo.addEventListener('rotationAngle-changed', () =>
            this.updateRotationFromFootscan(),
        );
        // gizmo.addEventListener('dragging-changed', ScanSettingChanged);
        gizmo.addEventListener('mouseUp', async ev => {
            if (ev.mode === ScanTransformMode.Translate) {
                this.scanService.scan.position.clampScalar(
                    -maxMoveDistance,
                    maxMoveDistance,
                );
                await this.undoRedoService.doOperation(
                    new TransformMoveOperation(
                        this.scanService,
                        this.scanService.scan.position,
                    ),
                );
            } else if (ev.mode === ScanTransformMode.Rotate) {
                await this.undoRedoService.doOperation(
                    new TransformRotateOperation(
                        this,
                        this.scanService,
                        this.scanService.scan.quaternion,
                    ),
                );
            }
        });

        this.sceneService.requestSceneRender();
    }

    private disableGizmo() {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!this.transformGizmo?.enabled) return;
        this.transformGizmo.detach();
        this.transformGizmo.enabled = false;
        this.sceneService.requestSceneRender();
    }

    private async applyTransformInDev() {
        if (!this.environment.autoTransform) return;
        await this.apply();
    }
}
