import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export interface StyledSliderValueChange {
    /** Slider percent. */
    percent: number;
    /** Value based on displayed units. This is what is shown on FE. */
    display: number;
    /** Value after unit multipliyer is applied. This is how value is stored in engine. */
    value: number;
}

@Component({
    selector: 'leo-styled-slider',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    templateUrl: './styled-slider.component.html',
    styleUrl: './styled-slider.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyledSliderComponent implements OnInit {
    @Input() label = '';
    @Input() min = 0;
    @Input() max = 1;
    @Input() default = 0;
    @Input() step?: number | 'any'; //TODO: step on html and calculated step are different
    @Input() color?: string;
    @Input() hoverColor?: string;
    @Input() multiplier = 1;

    @Input() disabled: boolean | null = false;

    @Output() valueChange = new EventEmitter<StyledSliderValueChange>();
    @Output() valueChangeFinished = new EventEmitter<StyledSliderValueChange>();

    public _value?: number;

    public get value(): number | undefined {
        return this._value;
    }

    @Input()
    public set value(value: number | undefined) {
        if (value === undefined) return;
        value /= this.multiplier;
        this._value = value;
        this._sliderPercent = this.calculatePercentFromValue(value);
    }

    private _sliderPercent = this.default;
    public get sliderPercent(): number {
        return this._sliderPercent;
    }

    onSliderValueChange(event: Event) {
        this._sliderPercent = +(event.target as HTMLInputElement).value;
        this._value = this.calculateValueFromPercent(this.sliderPercent);
        this.valueChange.emit({
            percent: +this.sliderPercent,
            display: this.value ?? this.default,
            value: (this.value ?? this.default) * this.multiplier,
        });
    }

    onSliderValueChangeFinished(event: Event) {
        this._sliderPercent = +(event.target as HTMLInputElement).value;
        this._value = this.calculateValueFromPercent(this.sliderPercent);
        this.valueChangeFinished.emit({
            percent: this.sliderPercent,
            display: this.value ?? this.default,
            value: (this.value ?? this.default) * this.multiplier,
        });
    }

    ngOnInit(): void {
        this.color ??= 'var(--primary-color)';
        this.hoverColor ??= 'var(--primary-hover-color)';
        this.value ??= this.default;
    }

    private calculatePercentFromValue(value: number): number {
        return ((value - this.min) / (this.max - this.min)) * 100;
    }

    private calculateValueFromPercent(percent: number): number {
        return (this.max - this.min) * (percent / 100) + this.min;
    }
}
