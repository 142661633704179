/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { IDesignEditComponentProvider } from '@orthocore-web-mono/shared-types';

import { CutoutComponent } from './cutout/cutout.component';
import { DifferentThicknessComponent } from './different-thickness/different-thickness.component';
import { EdgeThicknessComponent } from './edge-thickness/edge-thickness.component';
import { HoleComponent } from './hole/hole.component';
import { LoopComponent } from './loop/loop.component';
import { OutlineComponent } from './outline/outline.component';
import { ReinforcementComponent } from './reinforcement/reinforcement.component';
import { StrutComponent } from './strut/strut.component';

@Injectable()
export class SpinalEditDesignComponentProvider
    implements IDesignEditComponentProvider
{
    getComponentLabels(infos: any[][]): string[] {
        return infos
            .filter(item => item[0].type != 'Embosser')
            .map(info => this.mapInfoToLabel(info));
    }

    getEditComponent(info: any[]): any {
        const firstMesh = info[0];
        switch (firstMesh.type) {
            case 'Outline':
                return OutlineComponent;
            case 'Hole':
                return HoleComponent;
            case 'Inner':
                // return firstMesh.patternEnabled
                //     ? 'DESIGN.PATTERN.TITLE'
                //     : 'DESIGN.THICKNESS_OFFSET';
                return DifferentThicknessComponent;
            case 'Cutout':
                return CutoutComponent;
            case 'Reinforcement':
                return ReinforcementComponent;
            case 'LoopComponent':
                return LoopComponent;
            case 'SplineGradient':
                return EdgeThicknessComponent;
            case 'SplineGradientGrid':
                return 'SplineGradientGrid TODO';
            case 'SplineGradientArea':
                return 'SplineGradientArea TODO';
            case 'StrutComponent':
                return StrutComponent;
            case 'Embosser':
            default:
                throw Error(`Cannot map title to type: ${firstMesh.type}`);
        }
    }

    private mapInfoToLabel(info: any[]): string {
        const firstMesh = info[0];
        switch (firstMesh.type) {
            case 'Outline':
                return 'DESIGN.OUTLINE';
            case 'Hole':
                return 'DESIGN.HOLE';
            case 'Inner':
                return firstMesh.patternEnabled
                    ? 'DESIGN.PATTERN.TITLE'
                    : 'DESIGN.THICKNESS_OFFSET';
            case 'Cutout':
                return 'DESIGN.CUTOUT';
            case 'Reinforcement':
                return 'DESIGN.REINFORCEMENT';
            case 'Embosser':
                return 'DESIGN.EMBOSSER';
            case 'LoopComponent':
                return 'DESIGN.LOOP';
            case 'SplineGradient':
                return 'DESIGN.EDGE_THICKNESS.TITLE';
            case 'SplineGradientGrid':
                return 'SplineGradientGrid TODO';
            case 'SplineGradientArea':
                return 'SplineGradientArea TODO';
            case 'StrutComponent':
                return 'DESIGN.STRUT.TITLE';
            default:
                throw Error(`Cannot map title to type: ${firstMesh.type}`);
        }
    }
}
