import { Inject, Injectable } from '@angular/core';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    Float3JS,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import {
    RoundToDecimals,
    WorldRayToLocal,
} from '@orthocore-web-mono/shared/utils';
import { EditorFacade } from '@orthocore-web-mono/state';
import {
    Subject,
    distinctUntilChanged,
    exhaustMap,
    filter,
    map,
    takeUntil,
} from 'rxjs';
import * as THREE from 'three';

import { SplineObject } from '../spline-object';
import { SceneService } from './scene-data.service';

const requiredPointCount = 2;
const controlPointRadius = 0.3;
const tmpVec3 = new THREE.Vector3();

@Injectable()
export class DistanceMeasurementService {
    private draggedControlPointIndex: number | null = null;
    private measurementPoints: {
        localPosition: Float3JS;
        worldPosition: Float3JS;
    }[] = [];

    private splineObject: SplineObject;

    private handlerCanvasClicks$ = this.facade.measurementMode$.pipe(
        map(mode => mode === 'distance'),
        distinctUntilChanged(),
    );

    private activate$ = this.handlerCanvasClicks$.pipe(filter(v => v));
    private deactivate$ = this.handlerCanvasClicks$.pipe(filter(v => !v));

    private updateMeasurement$ = new Subject<void>();

    constructor(
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: IScanService,
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        private readonly sceneService: SceneService,
        private readonly facade: EditorFacade,
    ) {
        this.splineObject = new SplineObject(this.sceneService, {
            controlPointColors: [0x0086ff],
            lineColor: 0x0086ff,
            controlPointRadius,
        });

        this.sceneService.scene.add(this.splineObject);
        this.splineObject.visible = false;

        this.activate$.subscribe(() => this.subscribeToMouseEvents());
        this.deactivate$.subscribe(() => this.clearPoints());

        this.updateMeasurement$
            .pipe(exhaustMap(() => this.updateMeasurement()))
            .subscribe();
    }

    public clearPoints() {
        this.splineObject.clearSplineData();
        this.splineObject.visible = false;
        this.measurementPoints.length = 0;
        this.draggedControlPointIndex = null;
        this.facade.changeMeasuredDistance();
        this.sceneService.requestSceneRender();
    }

    private subscribeToMouseEvents() {
        this.sceneService.mouseDown$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.handleMouseDown(event));

        this.sceneService.mouseUp$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.handleMouseUp(event));

        this.sceneService.mouseMove$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.handleMouseMove(event));
    }

    private async handleMouseDown(event: MouseEvent) {
        if (event.button !== 0) {
            return;
        }

        const hitInfo = await this.raycastFootscanWithTransform(event);
        if (!hitInfo) {
            return;
        }

        if (this.draggedControlPointIndex !== null) {
            return;
        }

        if (this.measurementPoints.length < requiredPointCount) {
            // Add new control point
            this.draggedControlPointIndex = this.measurementPoints.length;
            this.measurementPoints.push(hitInfo);
        } else {
            // Select closest control point
            this.draggedControlPointIndex = null;

            let minDistanceSqr = Infinity;

            // Find closest control point to raycast hit point
            for (let i = 0; i < this.splineObject.getNumControlPoints(); ++i) {
                const data = this.splineObject.getControlPointLocalPosition(i);
                const distanceSqr = data.distanceToSquared(
                    hitInfo.worldPosition,
                );
                if (distanceSqr < minDistanceSqr) {
                    minDistanceSqr = distanceSqr;
                    this.draggedControlPointIndex = i;
                }
            }

            if (this.draggedControlPointIndex !== null) {
                this.measurementPoints[this.draggedControlPointIndex] = hitInfo;
            }
        }

        this.splineObject.visible = true;
        this.splineObject.updateGeometry(
            this.measurementPoints.map(({ worldPosition }) => worldPosition),
            null,
        );

        this.updateMeasurement$.next();
        this.sceneService.requestSceneRender();
    }

    private async handleMouseMove(event: MouseEvent) {
        if (this.draggedControlPointIndex === null) return;

        const hitInfo = await this.raycastFootscanWithTransform(event);
        if (!hitInfo) {
            return;
        }

        this.splineObject
            .getControlPointLocalPosition(this.draggedControlPointIndex)
            .copy(hitInfo.worldPosition);
        this.measurementPoints[this.draggedControlPointIndex] = hitInfo;
        this.sceneService.requestSceneRender();

        this.updateMeasurement$.next();
    }

    private handleMouseUp(event: MouseEvent) {
        if (event.button !== 0) {
            return;
        }

        this.draggedControlPointIndex = null;
    }

    private async raycastFootscanWithTransform(ev: MouseEvent) {
        const ray = this.scanService.getMouseRay(ev);
        WorldRayToLocal(ray, this.scanService.scan, ray);
        const hit = await this.engine.scanRaycast(ray.origin, ray.direction);
        if (hit === null) {
            return null;
        }

        const worldPosition = new THREE.Vector3(hit.x, hit.y, hit.z);
        worldPosition.applyMatrix4(this.scanService.scan.matrix);

        return {
            localPosition: hit,
            worldPosition,
            worldRay: ray,
        };
    }

    private async updateMeasurement() {
        if (this.measurementPoints.length !== requiredPointCount) {
            return;
        }

        const baseSampleNormalOffset = 0.06; // 2x line thickness

        const result = await this.engine.measureScan(
            this.measurementPoints.map(({ localPosition }) => localPosition),
            true,
            baseSampleNormalOffset / this.scanService.scan.scale.x,
        );

        if (result !== null) {
            // Transform to world space
            for (let i = 0; i < result.samplePoints.length; i += 3) {
                tmpVec3.set(
                    result.samplePoints[i],
                    result.samplePoints[i + 1],
                    result.samplePoints[i + 2],
                );
                tmpVec3.applyMatrix4(this.scanService.scan.matrix);
                result.samplePoints[i] = tmpVec3.x;
                result.samplePoints[i + 1] = tmpVec3.y;
                result.samplePoints[i + 2] = tmpVec3.z;
            }

            // Also adjust measured scale
            result.measuredValue *= this.scanService.scan.scale.x;
        }

        const roundedResult = result?.measuredValue
            ? RoundToDecimals(result.measuredValue * 10, 1)
            : undefined;
        this.facade.changeMeasuredDistance(roundedResult);
        this.splineObject.updateGeometry(null, result?.samplePoints ?? []);
        this.sceneService.requestSceneRender();
    }
}
