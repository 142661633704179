import { Injectable } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { BehaviorSubject, filter, firstValueFrom, map } from 'rxjs';

import { Stage } from './stage';

const guardTimeout = 1_000;

type optBool = boolean | undefined;
type StageBehaviorSubject = BehaviorSubject<optBool>;

@Injectable()
export class EditorStageStatusService {
    public engineReady$ = new BehaviorSubject<optBool>(undefined);
    public modelLoaded$ = new BehaviorSubject<optBool>(undefined);
    public transformInitialised$ = new BehaviorSubject<boolean | undefined>(
        undefined,
    );
    public landmarksApplied$ = new BehaviorSubject<boolean | undefined>(
        undefined,
    );

    public engineReady = firstValueFrom(
        this.engineReady$.pipe(
            filter(v => v === true),
            map(() => true),
        ),
    );
    public modelLoaded = firstValueFrom(
        this.modelLoaded$.pipe(
            filter(v => v === true),
            map(() => true),
        ),
    );
    public transformInitialised = firstValueFrom(
        this.transformInitialised$.pipe(
            filter(v => v === true),
            map(() => true),
        ),
    );
    public landmarksApplied = firstValueFrom(
        this.landmarksApplied$.pipe(
            filter(v => v === true),
            map(() => true),
        ),
    );

    public async canActivate(url: UrlSegment[]) {
        const stage = url[0].path as Stage;
        switch (stage) {
            case Stage.FileImport:
                return true;
            case Stage.ScanSettings:
                return this.waitOrTimeout(this.modelLoaded$, this.modelLoaded);
            case Stage.Landmarks:
                return this.waitOrTimeout(
                    this.transformInitialised$,
                    this.transformInitialised,
                );
            case Stage.MeshEdit:
                return this.waitOrTimeout(
                    this.landmarksApplied$,
                    this.landmarksApplied,
                );
            case Stage.Design:
                return this.waitOrTimeout(
                    this.landmarksApplied$,
                    this.landmarksApplied,
                );
        }
    }

    private async waitOrTimeout(
        subject: StageBehaviorSubject,
        promise: Promise<boolean>,
    ): Promise<boolean> {
        if (subject.value !== undefined) return subject.value;

        const waitPromise = new Promise<boolean>(async r => {
            await promise;
            r(true);
        });

        const timeoutPromise = new Promise<boolean>(r =>
            setTimeout(() => r(false), guardTimeout),
        );

        return Promise.race([waitPromise, timeoutPromise]);
    }
}
