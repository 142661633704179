import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IframeCommunicationsService } from './iframe-communications.service';

@NgModule({
    imports: [CommonModule],
    providers: [IframeCommunicationsService],
})
export class FeatureIframeIntegratonModule {}
