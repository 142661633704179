import { Environment } from '@orthocore-web-mono/shared-types';
import * as THREE from 'three';

export const environment1: Environment = {
    production: false,
};

export const environment: Environment = {
    production: false,
    autoLoadModel: '/assets/wasm/sample-spinal-dev.stl',
    autoTransform: true,
    devLandmarks: [
        {
            type: 0,
            vector: new THREE.Vector3(
                -1.7844185829162598,
                0.05304718017578125,
                13.425575256347656,
            ),
        },
        {
            type: 1,
            vector: new THREE.Vector3(
                15.016044616699219,
                0.1376533508300781,
                1.1099984645843506,
            ),
        },
        {
            type: 2,
            vector: new THREE.Vector3(
                -18.612144470214844,
                0.13733196258544922,
                3.6612091064453125,
            ),
        },
        {
            type: 3,
            vector: new THREE.Vector3(
                13.790214538574219,
                8.159849166870117,
                2.079159736633301,
            ),
        },
        {
            type: 4,
            vector: new THREE.Vector3(
                -16.727333068847656,
                7.318490982055664,
                3.516223907470703,
            ),
        },
        {
            type: 5,
            vector: new THREE.Vector3(
                -1.148576021194458,
                28.643394470214844,
                9.134830474853516,
            ),
        },
        {
            type: 6,
            vector: new THREE.Vector3(
                -0.08088958263397217,
                43.40530014038086,
                1.8691825866699219,
            ),
        },
        {
            type: 7,
            vector: new THREE.Vector3(
                19.33071517944336,
                45.89105987548828,
                -4.277974605560303,
            ),
        },
        {
            type: 8,
            vector: new THREE.Vector3(
                -18.82172393798828,
                45.80409240722656,
                -3.768847942352295,
            ),
        },
        {
            type: 9,
            vector: new THREE.Vector3(
                -0.1756575107574463,
                46.112247467041016,
                -12.844829559326172,
            ),
        },
        {
            type: 10,
            vector: new THREE.Vector3(
                10.517802238464355,
                38.34581756591797,
                -13.145034790039,
            ),
        },
        {
            type: 11,
            vector: new THREE.Vector3(
                -10.754157066345215,
                38.21215057373047,
                -13.456729888916016,
            ),
        },
    ],
    autoApplyLandmarks: true,
    skipEngineActivation: true,
};
