import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    GuardResult,
    Router,
} from '@angular/router';

import { EditorStageStatusService } from './editor-stage-status.service';
import { Stage } from './stage';

@Injectable()
export class StageGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly status: EditorStageStatusService,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<GuardResult> {
        const canActivate = await this.status.canActivate(route.url);
        if (!canActivate) {
            this.router.navigate([Stage.FileImport]);
        }

        return canActivate;
    }
}
