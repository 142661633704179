import { Component } from '@angular/core';
import { mm2cm, mmUnit } from '@orthocore-web-mono/shared/utils';
import { SpinalDesignService } from '@orthocore-web-mono/spinal-feature-design';

@Component({
    selector: 'leo-outline',
    templateUrl: './outline.component.html',
    styleUrl: './outline.component.scss',
})
export class OutlineComponent {
    public mmUnit = mmUnit;
    public mm2cm = mm2cm;

    constructor(public readonly service: SpinalDesignService) {}

    update() {
        this.service.updateGeneralOffsetAndThickness();
    }
}
