import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import * as fabric from 'fabric';
import { Subject, combineLatest, takeUntil } from 'rxjs';

import { ImageImportService } from '../image-import.service';

const loadedImageScaleFactor = 0.7;

@Component({
    selector: 'leo-image-import-layer',
    templateUrl: './image-import-layer.component.html',
    styleUrl: './image-import-layer.component.scss',
})
export class ImageImportLayerComponent implements OnInit, OnDestroy {
    private canvas!: fabric.Canvas;
    private image?: fabric.FabricImage;
    private opacity = 1;

    public isOnImportImageSection$ = this.service.editImage$;

    private destroyed$ = new Subject<void>();

    constructor(private readonly service: ImageImportService) {}

    ngOnInit(): void {
        this.canvas = new fabric.Canvas('c');
        this.service.loadedImageFromImport$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(async dataUrl => {
                await this.loadImage(dataUrl);
            });

        this.service.opacity$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(opacity => {
                if (!this.image) return;
                this.opacity = opacity;
                this.changeOpacity(this.opacity);
            });

        this.service.applyImageToMesh$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.getDataAndSendToServiceForTexture());

        this.service.addImageToScene$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.getDataAndSendToServiceForImage());

        this.service.cancel$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            if (!this.image) return;
            this.canvas.remove(this.image);
            this.image = undefined;
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private changeOpacity(value: number) {
        if (this.image) {
            this.image.set('opacity', value);
            this.canvas.renderAll();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.resizeCanvas();
        this.canvas.renderAll();
    }

    private async loadImage(src: string) {
        this.resizeCanvas();
        if (this.image) this.canvas.remove(this.image);
        this.image = await fabric.FabricImage.fromURL(src);

        const scaledWidth = this.image.width * loadedImageScaleFactor;
        const scaledHeight = this.image.height * loadedImageScaleFactor;

        this.image.set({
            scaleX: loadedImageScaleFactor,
            scaleY: loadedImageScaleFactor,
            left: (this.canvas.width - scaledWidth) / 2,
            top: (this.canvas.height - scaledHeight) / 2,
            originX: 'left',
            originY: 'top',
        });

        this.canvas.add(this.image);
    }

    private resizeCanvas() {
        this.canvas.setHeight(window.innerHeight);
        this.canvas.setWidth(window.innerWidth);
        this.canvas.renderAll();
    }

    private getDataAndSendToServiceForTexture() {
        if (!this.image) return;
        this.changeOpacity(1);

        setTimeout(() => {
            const data = this.canvas.toDataURL();
            this.service.addDataUrlForTexture(data);
            this.changeOpacity(this.opacity);
        }, 1);
    }

    private getDataAndSendToServiceForImage() {
        if (!this.image) return;
        this.changeOpacity(1);

        setTimeout(() => {
            const data = this.canvas.toDataURL();
            this.service.addDataUrlForImage(data);
            this.changeOpacity(this.opacity);
        }, 1);
    }

    // getThreeJsCoordinates(canvasX: number, canvasY: number) {
    //     const rect = renderer.domElement.getBoundingClientRect();
    //     const x = (canvasX / rect.width) * 2 - 1;
    //     const y = -(canvasY / rect.height) * 2 + 1;

    //     const vector = new THREE.Vector3(x, y, 0.5).unproject(camera);
    //     const dir = vector.sub(camera.position).normalize();
    //     const distance = -camera.position.z / dir.z;
    //     const pos = camera.position.clone().add(dir.multiplyScalar(distance));

    //     return pos;
    //   }

    //   // Function to add the image to the scene as a plane
    //   addImageToScene() {
    //     if (!this.image) return;
    //     const imgRect = this.image.getBoundingRect();
    //     const imgCenterX = imgRect.left + imgRect.width / 2;
    //     const imgCenterY = imgRect.top + imgRect.height / 2;

    //     const pos = this.getThreeJsCoordinates(imgCenterX, imgCenterY);

    //     const planeGeometry = new THREE.PlaneGeometry(1, 1);
    //     const planeMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
    //     const plane = new THREE.Mesh(planeGeometry, planeMaterial);

    //     plane.position.copy(pos);
    //     scene.add(plane);
    //   }
}
