import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'leo-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.scss',
})
export class ConfirmationDialogComponent {
    @Input()
    public confirmMessage?: string;
    @Input()
    public buttonMessage?: string;

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}
}
