import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EditorState } from './editor.models';
import { EDITOR_FEATURE_KEY } from './editor.reducer';

// Lookup the 'Editor' feature state managed by NgRx
export const selectEditorState =
    createFeatureSelector<EditorState>(EDITOR_FEATURE_KEY);

export const selectEngineLoaded = createSelector(
    selectEditorState,
    (state: EditorState) => state.loadedEngine,
);

export const selectEditorError = createSelector(
    selectEditorState,
    (state: EditorState) => state.error,
);

export const selectLoadedModel = createSelector(
    selectEditorState,
    (state: EditorState) => state.loadedModel,
);

export const selectActiveHandler = createSelector(
    selectEditorState,
    (state: EditorState) => state.activeHandler,
);

export const selectShowGroundPlane = createSelector(
    selectEditorState,
    (state: EditorState) => state.showGroundPlane,
);

export const selectShowLandmarks = createSelector(
    selectEditorState,
    (state: EditorState) => state.showLandMarks,
);

export const selectIsOrthographicView = createSelector(
    selectEditorState,
    (state: EditorState) => state.isOrthographicsView,
);

export const selectShowMeasurementsPanel = createSelector(
    selectEditorState,
    (state: EditorState) => state.showMeasurementsPanel,
);

export const selectMeasurementMode = createSelector(
    selectEditorState,
    (state: EditorState) => state.measurementMode,
);

export const selectMeasuredDistance = createSelector(
    selectEditorState,
    (state: EditorState) => state.measuredDistance,
);

export const selectMeasuredGirth = createSelector(
    selectEditorState,
    (state: EditorState) => state.measuredGirth,
);

export const selectOpacity = createSelector(
    selectEditorState,
    (state: EditorState) => state.opacity,
);

export const selectScanTransformMode = createSelector(
    selectEditorState,
    (state: EditorState) => state.scanTransformSettings.mode,
);

export const selectScanClearSelectionMode = createSelector(
    selectEditorState,
    (state: EditorState) => state.scanCrearSettings.selectionMode,
);

// Mesh edit / Import image
export const selectMeshEditImageLoaded = createSelector(
    selectEditorState,
    (state: EditorState) => state.importImage.imageLoaded,
);

export const selectMeshEditImageFittingPlane = createSelector(
    selectEditorState,
    (state: EditorState) => state.importImage.fittingPlane,
);

export const selectMeshEditImageImportShow = createSelector(
    selectEditorState,
    (state: EditorState) => state.importImage.showImage,
);

export const selectMeshEditImageImportOpacity = createSelector(
    selectEditorState,
    (state: EditorState) => state.importImage.opacity,
);
