import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'leo-horizontal-separator',
    standalone: true,
    imports: [CommonModule],
    template: '',
    styleUrl: './horizontal-separator.component.scss',
})
export class HorizontalSeparatorComponent {}
