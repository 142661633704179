import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    EditorStageStatusService,
    Stage,
} from '@orthocore-web-mono/feature-core-services';
import { FileLoaderService } from '@orthocore-web-mono/feature-file-loader';
import { ScanSettingsService } from '@orthocore-web-mono/feature-scan-edit';
import { DialogService } from '@orthocore-web-mono/shared/feature-dialogs';
import { EditorFacade } from '@orthocore-web-mono/state';
import { map, of } from 'rxjs';

@Component({
    selector: 'leo-spinal-editor',
    templateUrl: './editor.component.html',
    styleUrl: './editor.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorComponent implements OnInit {
    public editorSteps = [
        {
            key: Stage.FileImport,
            name: 'MAIN_MENU.FILE',
            disabled: of(false),
        },
        {
            key: Stage.ScanSettings,
            name: 'MAIN_MENU.SCAN_SETTINGS',
            disabled: this.status.modelLoaded$.pipe(map(c => c !== true)),
        },
        {
            key: Stage.Landmarks,
            name: 'MAIN_MENU.LANDMARKS',
            disabled: this.status.transformInitialised$.pipe(
                map(c => c !== true),
            ),
        },
        {
            key: Stage.MeshEdit,
            name: 'MAIN_MENU.MESH_EDIT',
            disabled: this.status.landmarksApplied$.pipe(map(c => c !== true)),
        },
        {
            key: Stage.Design,
            name: 'MAIN_MENU.DESIGN',
            disabled: this.status.landmarksApplied$.pipe(map(c => c !== true)),
        },
    ];

    constructor(
        private readonly dialog: DialogService,
        private readonly facade: EditorFacade,
        private readonly status: EditorStageStatusService,
        private readonly fileLoaderService: FileLoaderService,
        private readonly scanSettingsService: ScanSettingsService,
    ) {}

    ngOnInit(): void {
        this.openLoadingDialogUntilEngineIsLoaded();
    }

    private openLoadingDialogUntilEngineIsLoaded() {
        const ref = this.dialog.openLoadingDialog();
        const sub = this.facade.engineLoaded$.subscribe(res => {
            if (!res) return;
            ref.close();
            sub.unsubscribe();
        });
    }
}
