<div class="title">{{ 'MAIN_MENU.FILE' | translate }}</div>
<leo-horizontal-separator></leo-horizontal-separator>
<leo-styled-button (click)="onImportButtonClicked()" [leoStyle]="'gray'">
    {{ 'FILE.IMPORT' | translate }}
</leo-styled-button>

<leo-collapsing-section
    [title]="'FILE.EXPORT' | translate"
    [disabled]="(exportDisabled$ | async) ?? true">
    <leo-styled-dropdown
        [title]="'FILE.FORMAT' | translate"
        [options]="exportOptions"
        [(selected)]="selectedExportOption"></leo-styled-dropdown>
    <leo-styled-button (click)="export()">{{
        'FILE.EXPORT' | translate
    }}</leo-styled-button>
</leo-collapsing-section>

<input
    #importInput
    type="file"
    accept=".obj,.stl,.ply,.drc"
    (input)="onInputChanged($event)" />

<a #exportLink style="display: none"></a>
