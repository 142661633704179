<leo-styled-dropdown
    [title]="'MESH_EDIT.LOOP_TOOLS.MODE' | translate"
    [options]="modes"
    [selected]="selectedModeIndex"
    [localise]="true"
    (selectedChange)="onModeChanged($event)"></leo-styled-dropdown>

<leo-styled-slider-with-input
    *ngIf="showTopPositionInput"
    [title]="'MESH_EDIT.LOOP_TOOLS.TOP_POSITION' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.scaleDistTop"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    *ngIf="showBottomPositionInput"
    [title]="'MESH_EDIT.LOOP_TOOLS.BOTTOM_POSITION' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.scaleDistBottom"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-dropdown
    [title]="'MESH_EDIT.LOOP_TOOLS.SCALE' | translate"
    [options]="directions"
    [selected]="selectedDirectionIndex"
    [localise]="true"
    (selectedChange)="onDirectionChange($event)"></leo-styled-dropdown>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.AMOUNT' | translate"
    [min]="-100"
    [max]="200"
    [(value)]="params.scaleAmount"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.POSITION' | translate"
    [min]="0"
    [max]="100"
    [(value)]="params.scalePos"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    *ngIf="showDirectionInput"
    [title]="'MESH_EDIT.LOOP_TOOLS.DIRECTION' | translate"
    [min]="-180"
    [max]="180"
    [(value)]="params.scaleAngle"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="degreeUnit"
    [multiplier]="deg2rad"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    *ngIf="params.scaleMode === Mode.Middle"
    [title]="'MESH_EDIT.LOOP_TOOLS.EFFECT_RANGE' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.scaleMiddleSize"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-measurements-loop-tool
    *ngIf="measurements$ | async as measurements"
    [measurements]="measurements"></leo-measurements-loop-tool>

<leo-styled-button
    (click)="onApplyLoopTool()"
    [disabled]="params.scaleAmount === 0">
    {{ 'APPLY' | translate }}
</leo-styled-button>
