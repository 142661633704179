<leo-styled-slider-with-input
    [title]="'X'"
    [min]="min"
    [max]="max"
    [step]="step"
    [unit]="unit"
    [multiplier]="multiplier"
    [value]="value.x"
    [color]="colors.xColor"
    [hoverColor]="colors.xHover"
    [disabled]="disabled"
    (valueChange)="onValueChange(axis.x, $event)"
    (valueChangeFinished)="onValueFinish()">
</leo-styled-slider-with-input>
<leo-styled-slider-with-input
    [title]="'Y'"
    [min]="min"
    [max]="max"
    [step]="step"
    [unit]="unit"
    [multiplier]="multiplier"
    [value]="value.y"
    [color]="colors.yColor"
    [hoverColor]="colors.yHovel"
    [disabled]="disabled"
    (valueChange)="onValueChange(axis.y, $event)"
    (valueChangeFinished)="onValueFinish()">
</leo-styled-slider-with-input>
<leo-styled-slider-with-input
    [title]="'Z'"
    [min]="min"
    [max]="max"
    [step]="step"
    [unit]="unit"
    [multiplier]="multiplier"
    [value]="value.z"
    [color]="colors.zColor"
    [hoverColor]="colors.zHover"
    [disabled]="disabled"
    (valueChange)="onValueChange(axis.z, $event)"
    (valueChangeFinished)="onValueFinish()">
</leo-styled-slider-with-input>
