import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
    mm2cm,
    mmUnit,
    percentUnit,
    value2percent,
} from '@orthocore-web-mono/shared/utils';
import { Subject, takeUntil } from 'rxjs';

import { PressureReliefService } from '../pressure-relief.service';

enum PressureReliefStep {
    'Creating',
    'Editing',
}

const defaultdDepth = 0;
const defaultdHeight = 5;

@Component({
    selector: 'leo-pressure-relief',
    templateUrl: './pressure-relief.component.html',
    styleUrl: './pressure-relief.component.scss',
})
export class PressureReliefComponent implements OnInit, OnDestroy {
    public PressureReliefStep = PressureReliefStep;
    public mm2cm = mm2cm;
    public mmUnit = mmUnit;
    public value2percent = value2percent;
    public percentUnit = percentUnit;

    public pressureReliefShapeNames: string[] =
        this.service.shapeInfos?.shapeNames;
    private destroyed$ = new Subject<void>();

    public selected = 0;

    public activeProcess?: PressureReliefStep = PressureReliefStep.Creating;

    public depth = defaultdDepth;
    public height = defaultdHeight;
    public selectedInnerPointIndex?: number;

    constructor(
        private readonly change: ChangeDetectorRef,
        private readonly service: PressureReliefService,
    ) {}

    public async onAddShapeClick() {
        this.activeProcess = undefined;
        await this.service.StartPressureRelief(this.selected);
        this.activeProcess = PressureReliefStep.Editing;
        this.depth = this.service.shapeData?.shape?.depth ?? defaultdDepth;
        this.change.markForCheck();
    }

    public onDepthChange(value: number) {
        this.service.setDepth(value);
    }

    public onHeightChange(value: number) {
        this.service.setHeight(value);
    }

    public onFinish() {
        this.service.finish(false);
        this.resetForm();
    }

    public onCancel() {
        this.service.finish(true);
        this.resetForm();
    }

    ngOnInit(): void {
        if (this.pressureReliefShapeNames) {
            return;
        }
        this.service.init$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.pressureReliefShapeNames = this.service.shapeInfos.shapeNames;
        });

        this.service.selectedInnerPointChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(({ index, height }) => {
                this.selectedInnerPointIndex = index;
                this.height = height ?? 0;
                this.change.markForCheck();
            });

        this.service.deactivate$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.resetForm();
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private resetForm() {
        this.activeProcess = PressureReliefStep.Creating;
        this.selected = 0;
        this.selectedInnerPointIndex = undefined;
        this.depth = defaultdDepth;
        this.height = defaultdHeight;
    }
}
