import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
    StyledButtonComponent,
    StyledDropdownComponent,
    StyledSliderWithInputComponent,
} from '@orthocore-web-mono/shared-ui';

import { PressureReliefComponent } from './control/pressure-relief.component';
import { PressureReliefService } from './pressure-relief.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        StyledDropdownComponent,
        StyledButtonComponent,
        StyledSliderWithInputComponent,
    ],
    declarations: [PressureReliefComponent],
    providers: [PressureReliefService],
    exports: [PressureReliefComponent],
})
export class FeaturePressureReliefModule {}
