import { Component } from '@angular/core';
import { ToggleGroupselectedChangeEvent } from '@orthocore-web-mono/shared-ui';
import {
    mm2cm,
    mmUnit,
    percentUnit,
    value2percent,
} from '@orthocore-web-mono/shared/utils';

import { SculptingService } from '../services/sculpting.service';

@Component({
    selector: 'leo-sculpting',
    templateUrl: './sculpting.component.html',
    styleUrl: './sculpting.component.scss',
})
export class SculptingComponent {
    mmUnit = mmUnit;
    mm2cm = mm2cm;
    percentUnit = percentUnit;
    value2percent = value2percent;

    public selectedSculptingMode = this.service.selectedModeIndex;

    public readonly sculptingModes = [
        'MESH_EDIT.SCULPTING.SMOOTH',
        'MESH_EDIT.SCULPTING.FLATTEN',
    ];

    constructor(public readonly service: SculptingService) {}

    onSelectedSculptingModeChanged(event: ToggleGroupselectedChangeEvent) {
        this.service.changeSelectedModeIndex(event.index);
    }
}
