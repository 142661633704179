import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'leo-prompt-dialog',
    templateUrl: './prompt-dialog.component.html',
    styleUrl: './prompt-dialog.component.scss',
})
export class PromptDialogComponent {
    @Input()
    public confirmMessage?: string;
    @Input()
    public buttonMessage?: string;

    constructor(public dialogRef: MatDialogRef<PromptDialogComponent>) {}
}
