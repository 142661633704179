import {
    CreateNativeStruct,
    NativeArrayDescriptor,
    NativeEnumDescriptor,
    NativeType,
    createJSEnumFromNativeEnum,
} from './native-interop';

export type Ptr = number;
export type LeoBool = number;

export interface FileSystem {
    createPath(
        parent: string,
        path: string,
        canRead?: boolean,
        canWrite?: boolean,
    ): void;
    writeFile(
        path: string,
        data: string | Uint8Array,
        opts?: { flags?: number },
    ): void;
}

export interface Module {
    HEAP8: Int8Array;
    HEAPU8: Uint8Array;
    HEAP16: Int16Array;
    HEAPU16: Uint16Array;
    HEAP32: Int32Array;
    HEAPU32: Uint32Array;
    HEAPF32: Float32Array;
    HEAPF64: Float64Array;
    FS: FileSystem;
    _malloc(size: number): Ptr;
    _free(ptr: Ptr): void;
    UTF8ToString(ptr: Ptr, maxBytesToRead?: number): string;
    stringToUTF8(str: string, dst: Ptr, maxBytesToWrite: number): number;
    lengthBytesUTF8(str: string): number;
}

export class HeapData {
    private mod: Module;
    public ptr: Ptr;

    constructor(mod: Module, ptr: Ptr) {
        this.mod = mod;
        this.ptr = ptr;
    }

    public free() {
        if (this.ptr === 0) {
            return;
        }

        this.mod._free(this.ptr);
        this.ptr = 0;
    }
}

export function MallocWrapper(mod: Module, size: number) {
    return new HeapData(mod, size === 0 ? 0 : mod._malloc(size));
}

export function StringToNative(mod: Module, str: string) {
    const lengthInBytes = mod.lengthBytesUTF8(str) + 1; // +1 for null terminator
    const ptr = mod._malloc(lengthInBytes);
    const bytesCopied = mod.stringToUTF8(str, ptr, lengthInBytes); // adds null terminator
    console.assert(bytesCopied === lengthInBytes - 1);
    return new HeapData(mod, ptr);
}

export function StringFromNative(
    mod: Module,
    ptr: Ptr,
    maxBytesToRead?: number,
) {
    return mod.UTF8ToString(ptr, maxBytesToRead);
}

export function BytesToNative(
    mod: Module,
    bytes: Uint8Array,
    sizeOverride?: number,
) {
    const size = sizeOverride ?? bytes.length;
    console.assert(size >= bytes.length);

    const data = MallocWrapper(mod, size);
    mod.HEAPU8.set(bytes, data.ptr);
    return data;
}

export function BytesFromNative(mod: Module, ptr: Ptr, size: number) {
    return mod.HEAPU8.subarray(ptr, ptr + size);
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const Float2Descriptor = {
    x: NativeType.Float,
    y: NativeType.Float,
} as const;

export const Float3Descriptor = {
    x: NativeType.Float,
    y: NativeType.Float,
    z: NativeType.Float,
} as const;

export const Float4Descriptor = {
    x: NativeType.Float,
    y: NativeType.Float,
    z: NativeType.Float,
    w: NativeType.Float,
} as const;

export const Matrix3x3Descriptor = {
    c0: Float3Descriptor,
    c1: Float3Descriptor,
    c2: Float3Descriptor,
} as const;

export const Matrix4x4Descriptor = {
    c0: Float4Descriptor,
    c1: Float4Descriptor,
    c2: Float4Descriptor,
    c3: Float4Descriptor,
} as const;

export const Int3Descriptor = {
    x: NativeType.Int,
    y: NativeType.Int,
    z: NativeType.Int,
} as const;

export const PoseDescriptor = {
    position: Float3Descriptor,
    rotation: Float4Descriptor,
} as const;

export const Float2 = CreateNativeStruct(Float2Descriptor);
export const Float3 = CreateNativeStruct(Float3Descriptor);
export const Float4 = CreateNativeStruct(Float4Descriptor);
export const Matrix3x3 = CreateNativeStruct(Matrix3x3Descriptor);
export const Matrix4x4 = CreateNativeStruct(Matrix4x4Descriptor);
export const Pose = CreateNativeStruct(PoseDescriptor);

export const ScanPreparationManualDelModeDescriptor = {
    Lasso: 0,
    Rect: 1,
    Brush: 2,
    SelectionInvert: 3,
    SelectionClear: 4,
    Delete: 5,
} as const;

export const ScanPreparationSmallDelFactorDescriptor = {
    VertexCount: 0,
    Volume: 1,
    BBoxSize: 2,
    Smart: 3,
} as const;

export const ScanPreparationParamsDescriptor = {
    applyManualDel: NativeType.LeoBool,
    applyHoleFill: NativeType.LeoBool,
    applyGlobalSmooth: NativeType.LeoBool,
    applySmallDel: NativeType.LeoBool,
    applyVoxelRemesh: NativeType.LeoBool,

    manualDelMode: new NativeEnumDescriptor(
        ScanPreparationManualDelModeDescriptor,
    ),
    manualDelIsSelClearOnSel: NativeType.LeoBool,
    cameraViewProjMat: Matrix4x4Descriptor,
    screenAspect: NativeType.Float,
    vertexSelInfoGuideRect: Float4Descriptor,
    brushSize: NativeType.Float,

    globalSmoothAmount: NativeType.Float,
    globalSmoothNumIterations: NativeType.Int,

    smallDelFactor: new NativeEnumDescriptor(
        ScanPreparationSmallDelFactorDescriptor,
    ),
    smallDelKeepCnt: NativeType.Int,

    voxelRemeshGridResolution: NativeType.Int,
} as const;

export const ScanPreparationParams = CreateNativeStruct(
    ScanPreparationParamsDescriptor,
);

export type ScanPreparationParamsJS = typeof ScanPreparationParams.typeHolder;

export const enum AxisNative {
    X = 0,
    Y = 1,
    Z = 2,
    NegX = 3,
    NegY = 4,
    NegZ = 5,
}

export const enum HistoryOperation {
    Reset,
    Undo,
    Redo,
}

export type Matrix3JS = typeof Matrix3x3.typeHolder;
export type Float2JS = typeof Float2.typeHolder;
export type Float3JS = typeof Float3.typeHolder;
export type Float4JS = typeof Float4.typeHolder;
export type PoseJS = typeof Pose.typeHolder;
export type Matrix4x4JS = typeof Matrix4x4.typeHolder;

export const FalloffModeDescriptor = {
    Linear: 0,
    SmoothStep: 1,
    CircleEaseIn: 2,
    CircleEaseOut: 3,
    TrigEaseIn: 4,
    TrigEaseOut: 5,
    TrigEaseInOut: 6,
    SmoothAnyStep: 7,
    EaseInPolynomial: 8,
    EaseOutPolynomial: 9,
    SquareRoot: 10,
};

export const ScanPreparationMeshChangeTypeDescriptor = {
    NoChange: 0,
    MeshChanged: 1,
    SelectionChanged: 2,
    MeshAndSelectionChanged: 3,
} as const;

export const ScanPreparationMeshChangeType = new NativeEnumDescriptor(
    ScanPreparationMeshChangeTypeDescriptor,
);

export const measurementModes = ['distance', 'girth'] as const;

export type MeasurementMode = ArrayElement<typeof measurementModes>;

export const ContourSplineTypeDescriptor = {
    Outline: 0,
    Hole: 1,
    Inner: 2,
    Cutout: 3,
    Reinforcement: 4,
    Embosser: 5,
    LoopComponent: 6,
    SplineGradient: 7,
    SplineGradientGrid: 8,
    SplineGradientArea: 9,
    StrutComponent: 10,
} as const;

const OrthoContourSplineGradientType = {
    Offset: 0,
    Thickness: 1,
} as const;

export const ContourSplineInfoDescriptor = {
    type: new NativeEnumDescriptor(ContourSplineTypeDescriptor),

    closed: NativeType.LeoBool,

    splineGradientType: new NativeEnumDescriptor(
        OrthoContourSplineGradientType,
    ),

    thickness: NativeType.Float,
    offset: NativeType.Float,

    transitionLength: NativeType.Float,

    rotation: NativeType.Float,
    size: Float2Descriptor,
    rounding: NativeType.Float,

    patternEnabled: NativeType.LeoBool,
    patternElementDistance: NativeType.Float,
    patternElementRadius: NativeType.Float,

    strutComponentStartOffset: NativeType.Float,
    strutComponentEndOffset: NativeType.Float,
    strutComponentNumRods: NativeType.Int,

    numControlPoints: NativeType.Int,
    numSamplePoints: NativeType.Int,
} as const;

export const ContourSplineInfo = CreateNativeStruct(
    ContourSplineInfoDescriptor,
);

export type ContourSplineInfoJS = typeof ContourSplineInfo.typeHolder;

export const PressureReliefShapeDescriptor = {
    depth: NativeType.Float,
    numInnerPoints: NativeType.Int,
    numOuterPoints: NativeType.Int,
} as const;

export const PressureReliefPointDescriptor = {
    position: Float3Descriptor,
    heightScale: NativeType.Float,
} as const;

export const PressureReliefShapeParams = CreateNativeStruct(
    PressureReliefShapeDescriptor,
);
export const PressureReliefPointParams = CreateNativeStruct(
    PressureReliefPointDescriptor,
);
export type PressureReliefShapeJS = typeof PressureReliefShapeParams.typeHolder;
export type PressureReliefPointJS = typeof PressureReliefPointParams.typeHolder;

export const SculptMode = {
    Smooth: 0,
    Flatten: 1,
};

export const SculptDescriptor = {
    cursorRadius: NativeType.Float,
    cursorInnerRadius: NativeType.Float,
    strength: NativeType.Float,

    falloffMode: new NativeEnumDescriptor(FalloffModeDescriptor),
    falloffParam: NativeType.Float,

    mode: new NativeEnumDescriptor(SculptMode),
    smoothIterations: NativeType.Int,
    flattenAngleThreshold: NativeType.Float,
} as const;

export const SculptParameters = CreateNativeStruct(SculptDescriptor);
export type SculptParametersJS = typeof SculptParameters.typeHolder;

export const LoopTransformParamsDescriptor = {
    scanCenterSplineT: NativeType.Float,
    effectRange0: NativeType.Float,
    effectRange1: NativeType.Float,
    planeExtraRotationMainAxis: NativeType.Float,
    planeExtraRotationSecondaryAxis: NativeType.Float,
    localTranslation: Float3Descriptor,
    rotation: Float3Descriptor,
    scale: Float3Descriptor,
} as const;
export const LoopTransformParams = CreateNativeStruct(
    LoopTransformParamsDescriptor,
);
export type LoopTransformParamsJS = typeof LoopTransformParams.typeHolder;

export const ToolLoopBendParamsDescriptor = {
    isBendTop: NativeType.LeoBool,
    isBendBottom: NativeType.LeoBool,
    isBendModeMirror: NativeType.LeoBool,
    bendPos: NativeType.Float,
    bendDistTop: NativeType.Float,
    bendDistBottom: NativeType.Float,
    bendAmount: NativeType.Float,
    bendAngle: NativeType.Float,
} as const;

export const ToolLoopBendParameters = CreateNativeStruct(
    ToolLoopBendParamsDescriptor,
);
export type ToolLoopBendParametersJS = typeof ToolLoopBendParameters.typeHolder;

export const ToolLoopTwistModeModeDescriptor = {
    None: 0,
    Top: 1,
    Bottom: 2,
    Middle: 3,
} as const;

export const ToolLoopTwistModeJS = createJSEnumFromNativeEnum(
    ToolLoopTwistModeModeDescriptor,
);

export const ToolLoopTwistParamsDescriptor = {
    twistMode: new NativeEnumDescriptor(ToolLoopTwistModeModeDescriptor),
    twistPos: NativeType.Float,
    twistDistTop: NativeType.Float,
    twistDistBottom: NativeType.Float,
    twistAmount: NativeType.Float,
} as const;

export const ToolLoopTwistParameters = CreateNativeStruct(
    ToolLoopTwistParamsDescriptor,
);
export type ToolLoopTwistParametersJS =
    typeof ToolLoopTwistParameters.typeHolder;

export const ToolLoopStretchModeDescriptor = {
    None: 0,
    Double: 1,
    Triple: 2,
} as const;

export const ToolLoopStretchModeJS = createJSEnumFromNativeEnum(
    ToolLoopStretchModeDescriptor,
);

export const ToolLoopStretchParamsDescriptor = {
    stretchMode: new NativeEnumDescriptor(ToolLoopStretchModeDescriptor),
    isStretchModeBottom: NativeType.LeoBool,
    stretchPos: NativeType.Float,
    stretchDistTop: NativeType.Float,
    stretchDistBottom: NativeType.Float,
    stretchAmount: NativeType.Float,
    stretchMiddleSize: NativeType.Float,
} as const;

export const ToolLoopStretchParameters = CreateNativeStruct(
    ToolLoopStretchParamsDescriptor,
);
export type ToolLoopStretchParametersJS =
    typeof ToolLoopStretchParameters.typeHolder;

export const ToolLoopScaleModeDescriptor = {
    None: 0,
    Top: 1,
    Bottom: 2,
    Middle: 3,
    All: 4,
} as const;

export const ToolLoopScaleModeJS = createJSEnumFromNativeEnum(
    ToolLoopScaleModeDescriptor,
);

export const ToolLoopScaleDirectionDescriptor = {
    None: 0,
    Single: 1,
    Double: 2,
    Full: 3,
} as const;

export const ToolLoopScaleDirectionJS = createJSEnumFromNativeEnum(
    ToolLoopScaleDirectionDescriptor,
);

export const ToolLoopScaleParamsDescriptor = {
    scaleMode: new NativeEnumDescriptor(ToolLoopScaleModeDescriptor),
    scaleDir: new NativeEnumDescriptor(ToolLoopScaleDirectionDescriptor),
    scalePos: NativeType.Float,
    scaleDistTop: NativeType.Float,
    scaleDistBottom: NativeType.Float,
    scaleAmount: NativeType.Float,
    scaleAngle: NativeType.Float,
    scaleMiddleSize: NativeType.Float,
} as const;

export const ToolLoopScaleParameters = CreateNativeStruct(
    ToolLoopScaleParamsDescriptor,
);
export type ToolLoopScaleParametersJS =
    typeof ToolLoopScaleParameters.typeHolder;

export const ToolLoopOutDescriptor = {
    /**
     * 0: top
     * 1: middle (top part)
     * 2: bottom
     * 3: middle (bottom part)
     * 4: middle (center) <no show>
     * 5: middle (center cross) <no show>
     */
    planePos: new NativeArrayDescriptor(Float3Descriptor, 6),
    planeNormal: new NativeArrayDescriptor(Float3Descriptor, 6),
    measureTopHeight: NativeType.Float,
    measureTopPerimeter: NativeType.Float,
    measureBottomHeight: NativeType.Float,
    measureBottomPerimeter: NativeType.Float,
} as const;

export const ToolLoopOutParameters = CreateNativeStruct(ToolLoopOutDescriptor);
export type ToolLoopOutJS = typeof ToolLoopOutParameters.typeHolder;

export const DesignInfoDescriptor = {
    name: new NativeArrayDescriptor(NativeType.Char, 32),
    hasJointComponent: NativeType.LeoBool,
    hasDoubleShell: NativeType.LeoBool,
} as const;

export const DesignInfo = CreateNativeStruct(DesignInfoDescriptor);
export type DesignInfoJS = typeof DesignInfo.typeHolder;
