/* eslint-disable max-params-no-constructor/max-params-no-constructor */
import * as THREE from 'three';

const tmpVec3 = new THREE.Vector3();
const tmpMat3 = new THREE.Matrix3();
const tmpMat4 = new THREE.Matrix4();

export function TransformRay(
    ray: THREE.Ray,
    matrix: THREE.Matrix4,
    target: THREE.Ray,
) {
    target.copy(ray);
    target.origin.applyMatrix4(matrix);
    target.direction.applyMatrix3(tmpMat3.setFromMatrix4(matrix)).normalize();
    return target;
}

export function WorldRayToLocal(
    ray: THREE.Ray,
    obj: THREE.Object3D,
    target: THREE.Ray,
) {
    const matrixWorldInverse = tmpMat4.copy(obj.matrix).invert();
    return TransformRay(ray, matrixWorldInverse, target);
}

export function IntersectRaySphere(
    sphereCenter: THREE.Vector3,
    sphereRadius: number,
    ray: THREE.Ray,
) {
    const dir = tmpVec3.subVectors(ray.origin, sphereCenter);
    const x = ray.direction.dot(dir);
    const c = dir.lengthSq() - sphereRadius * sphereRadius;

    const d = x * x - c;

    if (d < 0) {
        return null;
    }

    const sqrtD = Math.sqrt(d);

    // const t1 = -x + sqrtD;
    const t2 = -x - sqrtD;

    return t2;
}

export function GetClosestPointToLine(
    linePoint: THREE.Vector3,
    lineDirection: THREE.Vector3,
    point: THREE.Vector3,
    target: THREE.Vector3,
) {
    return target
        .copy(lineDirection)
        .multiplyScalar(tmpVec3.subVectors(point, linePoint).dot(lineDirection))
        .add(linePoint);
}
