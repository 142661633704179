import { SplineObjectParams } from '@orthocore-web-mono/shared/feature-scene';

export const embosserColor = 0x7ceb36;

export const splineLineWidth = 0.1;
export const controlPointRadius = 0.2;
export const tangentColor = 0x278a27;

export const defaultLineColor = 0xff0000;
export const contrastLineColor = 0x00ffff;

export const splineDisplayOffset = splineLineWidth * 0.5;

export const deafultSplineObjectParams: SplineObjectParams = {
    lineWidth: splineLineWidth,
    lineColor: defaultLineColor,
    lineWidthIsWorldUnits: true,
    controlPointRadius,
    controlPointColors: [0x0086ff, tangentColor],
};

export const contrastSplineObjectParams: SplineObjectParams = {
    lineWidth: splineLineWidth * 0.75,
    lineColor: contrastLineColor,
    lineWidthIsWorldUnits: true,
    controlPointRadius,
    controlPointColors: [0x0086ff, tangentColor],
};
