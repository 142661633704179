import {
    Component,
    Inject,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {
    DESIGN_EDIT_COMPONENT_PROVIDER,
    IDesignEditComponentProvider,
} from '@orthocore-web-mono/shared-types';
import { ToggleGroupselectedChangeEvent } from '@orthocore-web-mono/shared-ui';
import {
    degreeUnit,
    mmUnit,
    percentUnit,
} from '@orthocore-web-mono/shared/utils';
import { Subject, startWith, takeUntil, tap } from 'rxjs';

import { SpinalDesignService } from '../design.service';
import { EmbosserService } from '../embosser-service';

@Component({
    selector: 'leo-spinal-design',
    templateUrl: './design.component.html',
    styleUrl: './design.component.scss',
})
export class SpinalDesignComponent implements OnDestroy {
    mmUnit = mmUnit;
    degreeUnit = degreeUnit;
    percentUnit = percentUnit;

    @ViewChild('editDesignComponentContainer', {
        read: ViewContainerRef,
        static: true,
    })
    editContainerRef!: ViewContainerRef;

    public designs$ = this.service.designs$.pipe(
        startWith(this.service.designs),
    );

    public editComponentsModes: string[] = [];

    public selectedEditComponentIndex = 0;

    private destroyed$ = new Subject<void>();

    constructor(
        public readonly service: SpinalDesignService,
        @Inject(DESIGN_EDIT_COMPONENT_PROVIDER)
        private readonly componentProvider: IDesignEditComponentProvider,
        private readonly embosserService: EmbosserService,
    ) {
        this.service.splineInfosChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(splines => {
                this.selectedEditComponentIndex = 0;
                this.editComponentsModes =
                    this.componentProvider.getComponentLabels(splines);
                this.injectEditComponent();
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public onDesignChange(index: number) {
        this.service.changeDesign(index);
    }

    public onselectedEditComponentChange(
        event: ToggleGroupselectedChangeEvent,
    ) {
        this.selectedEditComponentIndex = event.index;
        this.service.changeSelectedComponent(event.index);
        this.injectEditComponent();
    }

    public async onGenerate() {
        await this.service.generate();
    }

    private injectEditComponent() {
        this.editContainerRef.clear();
        const info =
            this.service.currentSplineInfos[this.selectedEditComponentIndex];

        const componentClass = this.componentProvider.getEditComponent(info);
        this.editContainerRef.createComponent(componentClass);
    }
}
