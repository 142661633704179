import { Injectable } from '@angular/core';
import { ISceneService } from '@orthocore-web-mono/shared-types';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import * as THREE from 'three';

@Injectable()
export class SceneService implements ISceneService {
    public scene = new THREE.Scene();
    public perspectiveCamera = new THREE.PerspectiveCamera(60);
    public orthographicCamera = new THREE.OrthographicCamera();
    public activeCamera: THREE.Camera = this.perspectiveCamera;
    public renderer = new THREE.WebGLRenderer({ antialias: true });

    public mouseDown$ = fromEvent<MouseEvent>(
        this.renderer.domElement,
        'mousedown',
    );
    public mouseUp$ = fromEvent<MouseEvent>(window, 'mouseup');
    public mouseMove$ = fromEvent<MouseEvent>(
        this.renderer.domElement,
        'mousemove',
    );

    public requestSceneRender$ = new Subject<void>();
    public cameraModeChanged$ = new Subject<boolean>();
    public lockCamera$ = new BehaviorSubject<boolean>(false);

    constructor() {
        this.orthographicCamera.position.copy(this.perspectiveCamera.position);
    }

    requestSceneRender() {
        this.requestSceneRender$.next();
    }

    public addObjectToScene(obj: THREE.Object3D) {
        obj.frustumCulled = false;
        this.scene.add(obj);
    }

    public setCameraMode(isOrthographicsView: boolean) {
        this.cameraModeChanged$.next(isOrthographicsView);
    }

    public lockCamera(lock: boolean) {
        this.lockCamera$.next(lock);
    }
}
