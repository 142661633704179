import {
    Float2JS,
    GeometryUpdateFlags,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    ScanPreparationParamsJS,
    defaultScanPreparationParams,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import { UpdateGeometryAttribute } from '@orthocore-web-mono/shared/utils';

import { MeshOperationBase } from './mesh-operation-base';

export function VisualizeSelectionOnScan(
    selectedVertices: Float32Array | null,
    scanService: IScanService,
    sceneService: SceneService,
) {
    UpdateGeometryAttribute(
        scanService.scan.geometry,
        'selection',
        1,
        selectedVertices,
        false,
    );
    sceneService.requestSceneRender();
}

export abstract class ScanCleanOperation extends MeshOperationBase<IEngineWorkerServiceDataBase> {
    public result: any = null;

    constructor(
        engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        private readonly scanService: IScanService,
        private readonly sceneService: SceneService,
        private readonly params: ScanPreparationParamsJS,
        private readonly selectionPoints: Float2JS[] | null,
    ) {
        super(engine);
    }

    public scanMeshChangeFlags() {
        return GeometryUpdateFlags.All;
    }

    public needsLandmarkSync() {
        return false;
    }

    protected async doEngineOperation() {
        this.result = await this.engine.updateScanPreparation(
            this.params,
            this.selectionPoints,
        );
    }

    private async clearSelection() {
        await this.engine.updateScanPreparation(
            {
                ...defaultScanPreparationParams,
                applyManualDel: true,
                manualDelMode: 'SelectionClear',
            },
            null,
        );
    }

    public override async undo() {
        await Promise.all([super.undo(), this.clearSelection()]);

        VisualizeSelectionOnScan(null, this.scanService, this.sceneService);
    }

    public override async redo() {
        await Promise.all([super.redo(), this.clearSelection()]);

        VisualizeSelectionOnScan(null, this.scanService, this.sceneService);
    }
}

export class MeshEraseOperation extends ScanCleanOperation {}

export class GlobalSmoothOperation extends ScanCleanOperation {}

export class FillHolesOperation extends ScanCleanOperation {}

export class RemeshOperation extends ScanCleanOperation {}
