import { Component } from '@angular/core';
import { SpinalDesignService } from '@orthocore-web-mono/spinal-feature-design';

import { ComponentSettingsBaseComponent } from '../component-settings-base.component';

@Component({
    selector: 'leo-hole',
    templateUrl: './hole.component.html',
    styleUrl: './hole.component.scss',
})
export class HoleComponent extends ComponentSettingsBaseComponent {
    public isEnabled: boolean;

    constructor(public readonly service: SpinalDesignService) {
        super();
        const spline = this.service.currentSplineInfo;
        this.isEnabled = spline.isEnabled;
    }
}
