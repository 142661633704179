import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { EditorEffects } from './state/editor.effects';
import { EditorFacade } from './state/editor.facade';
import * as fromEditor from './state/editor.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot(
            {},
            {
                metaReducers: [],
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true,
                },
            },
        ),
        EffectsModule.forRoot([EditorEffects]),
        StoreModule.forFeature(
            fromEditor.EDITOR_FEATURE_KEY,
            fromEditor.editorReducer,
        ),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
        }),
    ],
    providers: [EditorFacade],
})
export class StateModule {}
