import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, tap } from 'rxjs';

@Injectable()
export class NavigationService {
    public selectedStep = '';
    public selectedSection = '';

    public selectedStep$ = this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        map(e => <NavigationEnd>e),
        map(e => e.url.split('#')[0].split('/')[1]),
        distinctUntilChanged(),
        tap(v => (this.selectedStep = v)),
    );

    public selectedSection$ = this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        map(e => <NavigationEnd>e),
        map(e => e.url.split('#')[1]),
        distinctUntilChanged(),
        tap(v => (this.selectedSection = v)),
    );

    constructor(private readonly router: Router) {
        setTimeout(() => {
            const { url } = this.router;
            this.selectedStep = url.split('#')[0].split('/')[1];
            this.selectedSection = url.split('#')[1];
        });
    }
}
