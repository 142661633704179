import { CommonModule } from '@angular/common';
import { Component, Input, input, output } from '@angular/core';

@Component({
    selector: 'leo-raw-undo-redo-reset',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './raw-undo-redo-reset.component.html',
    styleUrl: './raw-undo-redo-reset.component.scss',
})
export class RawUndoRedoResetComponent {
    hideUndo = input<boolean>(false);
    hideRedo = input<boolean>(false);
    hideReset = input<boolean>(false);

    disableUndo = input<boolean>(false);
    disableRedo = input<boolean>(false);
    disableReset = input<boolean>(false);

    onUndoClick = output<void>();
    onRedoClick = output<void>();
    onResetClick = output<void>();
}
