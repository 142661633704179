/* eslint-disable max-params-no-constructor/max-params-no-constructor */
import {
    Float3JS,
    Float4JS,
    GeometryUpdateFlags,
    MeshDataOptional,
} from '@orthocore-web-mono/shared-types';
import { isArray } from 'lodash';
import * as THREE from 'three';

export function UpdateGeometryAttribute(
    geometry: THREE.BufferGeometry,
    name: string,
    itemSize: number,
    newData: Float32Array | null,
    normalized: boolean,
) {
    let attribute = geometry.getAttribute(name) as
        | THREE.BufferAttribute
        | undefined;

    if (newData === null) {
        geometry.deleteAttribute(name);
    } else if (!attribute || attribute.count < newData.length / itemSize) {
        attribute = new THREE.BufferAttribute(newData, itemSize, normalized);
        geometry.setAttribute(name, attribute);
    } else {
        const array = attribute.array as Float32Array;
        array.set(newData);
        attribute.needsUpdate = true;
    }
}

export function UpdateGeometryFromMeshData(
    geometry: THREE.BufferGeometry,
    meshData: MeshDataOptional,
    updateFlags: GeometryUpdateFlags = GeometryUpdateFlags.All,
) {
    if (updateFlags & GeometryUpdateFlags.Vertices && meshData.vertices) {
        UpdateGeometryAttribute(
            geometry,
            'position',
            3,
            meshData.vertices,
            false,
        );
    }

    if (updateFlags & GeometryUpdateFlags.Normals && meshData.normals) {
        UpdateGeometryAttribute(geometry, 'normal', 3, meshData.normals, true);
    }

    if (updateFlags & GeometryUpdateFlags.Triangles && meshData.triangles) {
        const index = geometry.getIndex();
        if (!index || index.count < meshData.triangles.length) {
            geometry.setIndex(
                new THREE.BufferAttribute(meshData.triangles, 1, false),
            );
            geometry.setDrawRange(0, Infinity);
        } else {
            const array = index.array as Uint32Array;
            array.set(meshData.triangles);
            index.needsUpdate = true;
            geometry.setDrawRange(0, meshData.triangles.length);
        }
    }
}

export function UpdateOpacity(
    targetObject: THREE.Object3D,
    materials: THREE.Material | THREE.Material[],
    opacity: number,
    keepTransparent = false,
) {
    if (!isArray(materials)) {
        materials = [materials];
    }

    const visible = opacity > 0;
    const transparent = keepTransparent || opacity < 1;
    targetObject.visible = visible;

    for (const material of materials) {
        material.depthWrite = !transparent;
        material.transparent = transparent;

        if (visible) {
            material.opacity = opacity;

            if (material instanceof THREE.ShaderMaterial) {
                material.uniforms['opacity'].value = opacity;
            }
        }

        material.needsUpdate = true;
    }
}

export function Float3ToVector3(v: Float3JS): THREE.Vector3 {
    return new THREE.Vector3(v.x, v.y, v.z);
}

export function Vector3ToFloat3(v: THREE.Vector3): Float3JS {
    return { x: v.x, y: v.y, z: v.z };
}

export function Float4ToVector4(v: Float4JS): THREE.Vector4 {
    return new THREE.Vector4(v.x, v.y, v.z, v.w);
}

export function Vector4ToFloat4(v: THREE.Vector4): Float4JS {
    return { x: v.x, y: v.y, z: v.z, w: v.w };
}

export function Float4ToQuaternion(v: Float4JS): THREE.Quaternion {
    return new THREE.Quaternion(v.x, v.y, v.z, v.w);
}

export function QuaternionToFloat4(v: THREE.Quaternion): Float4JS {
    return { x: v.x, y: v.y, z: v.z, w: v.w };
}

export function RoundToDecimals(value: number, decimals: number) {
    const multiplier = 10 ** decimals;
    value *= multiplier;
    value = Math.round(value);
    value /= multiplier;
    return value;
}

type Optional<T> = T | null | undefined;
type OptionalNumber = Optional<number>;
type OptionalBoolean = Optional<boolean>;
type OptionalString = Optional<string>;

export function IsNullish(value: unknown): value is null | undefined {
    return value === null || value === undefined;
}

export function IsNotNullish<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

export function EnsureNumberOrNullish(value: unknown): OptionalNumber {
    if (typeof value === 'number') {
        return value;
    }

    if (IsNullish(value)) {
        return value;
    }

    return null;
}

export function EnsureBooleanOrNullish(value: unknown): OptionalBoolean {
    if (typeof value === 'boolean') {
        return value;
    }

    if (IsNullish(value)) {
        return value;
    }

    return null;
}

export function EnsureStringOrNullish(value: unknown): OptionalString {
    if (typeof value === 'string') {
        return value;
    }

    if (IsNullish(value)) {
        return value;
    }

    return null;
}

export function IsIframe() {
    return window.parent !== window;
}

export function IsObject(obj: unknown): obj is object {
    return obj !== null && typeof obj === 'object';
}
