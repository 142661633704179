<div class="title">
    {{ 'MAIN_MENU.MESH_EDIT' | translate }}
</div>

<leo-horizontal-separator></leo-horizontal-separator>

<leo-collapsing-group>
    <!-- Loop tools -->
    <leo-collapsing-section
        [title]="'MESH_EDIT.LOOP_TOOLS.BEND' | translate"
        [openOnFragment]="LoopTool.Bend">
        <leo-bend-loop-tool></leo-bend-loop-tool>
    </leo-collapsing-section>
    <leo-collapsing-section
        [title]="'MESH_EDIT.LOOP_TOOLS.TWIST' | translate"
        [openOnFragment]="LoopTool.Twist">
        <leo-twist-loop-tool></leo-twist-loop-tool>
    </leo-collapsing-section>
    <leo-collapsing-section
        [title]="'MESH_EDIT.LOOP_TOOLS.STRETCH' | translate"
        [openOnFragment]="LoopTool.Stretch">
        <leo-stretch-loop-tool></leo-stretch-loop-tool>
    </leo-collapsing-section>
    <leo-collapsing-section
        [title]="'MESH_EDIT.LOOP_TOOLS.SCALE' | translate"
        [openOnFragment]="LoopTool.Scale">
        <leo-scale-loop-tool></leo-scale-loop-tool>
    </leo-collapsing-section>
    <!-- Pressure or relief -->
    <leo-collapsing-section
        [title]="'MESH_EDIT.PRESSURE_RELIEF.TITLE' | translate"
        [openOnFragment]="SharedMeshEditSecion.PressureRelief">
        <leo-pressure-relief></leo-pressure-relief>
    </leo-collapsing-section>
    <!-- Sculpting -->
    <leo-collapsing-section
        [title]="'MESH_EDIT.SCULPTING.TITLE' | translate"
        [openOnFragment]="SharedMeshEditSecion.Sculpting">
        <leo-sculpting></leo-sculpting>
    </leo-collapsing-section>
    <!-- Image import -->
    <leo-collapsing-section
        [title]="'MESH_EDIT.IMPORT_IMAGE.TITLE' | translate"
        [openOnFragment]="ImageImportSectionName">
        <leo-image-import></leo-image-import>
    </leo-collapsing-section>
</leo-collapsing-group>
<leo-undo-redo-reset></leo-undo-redo-reset>
