import { HistoryOperationData } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    GeometryUpdateFlags,
    HistoryOperation,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
} from '@orthocore-web-mono/shared-types';

export class LoopToopOperation extends HistoryOperationData {
    private previusEngineHistoryState: number | null = null;
    private engineHistoryState: number | null = null;

    constructor(
        private readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        protected finishFn: Function,
    ) {
        super();
    }

    public async do() {
        this.previusEngineHistoryState =
            await this.engine.getScanHistoryState();
        await this.finishFn();
        this.engineHistoryState = await this.engine.getScanHistoryState();
    }

    public async undo() {
        if (this.previusEngineHistoryState === null) {
            return;
        }

        await this.engine.setScanHistoryState(
            HistoryOperation.Undo,
            this.previusEngineHistoryState,
        );
    }

    public async redo() {
        if (this.engineHistoryState === null) {
            return;
        }

        await this.engine.setScanHistoryState(
            HistoryOperation.Redo,
            this.engineHistoryState,
        );
    }

    override scanMeshChangeFlags() {
        return GeometryUpdateFlags.All;
    }

    public override needsLandmarkSync(): boolean {
        return true;
    }
}
