import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureIframeIntegratonModule } from '@orthocore-web-mono/feature-iframe-integraton';
import {
    CollapsingSectionComponent,
    HorizontalSeparatorComponent,
    StyledButtonComponent,
    StyledDropdownComponent,
} from '@orthocore-web-mono/shared-ui';

import { FileLoaderService } from './file-loader.service';
import { FilePanelComponent } from './file-panel/file-panel.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        HorizontalSeparatorComponent,
        StyledButtonComponent,
        CollapsingSectionComponent,
        StyledDropdownComponent,
        FeatureIframeIntegratonModule,
    ],
    providers: [FileLoaderService],
    declarations: [FilePanelComponent],
})
export class FeatureFileLoaderModule {}
