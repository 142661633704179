import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Stage } from '@orthocore-web-mono/feature-core-services';
import { Subject, map, startWith, takeUntil, tap } from 'rxjs';

import { LandMarkInOrderData } from '../base-landmark-objects';
import { LandmarksService } from '../landmarks.service';

@Component({
    selector: 'leo-landmarks',
    templateUrl: './landmarks.component.html',
    styleUrl: './landmarks.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandmarksComponent implements OnInit, OnDestroy {
    public landmarks = this.service.landmarks;

    public selected: LandMarkInOrderData = this.landmarks[0];

    private destroyed$ = new Subject<void>();

    public applyDisabled$ = this.service.allLandmarksSet$.pipe(
        startWith(false),
        map(v => !v),
    );

    constructor(
        private readonly change: ChangeDetectorRef,
        private readonly service: LandmarksService,
        private readonly router: Router,
    ) {}

    ngOnInit(): void {
        this.selectLandmark(this.landmarks[0]);

        this.service.landmarksChange$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.change.markForCheck());
    }
    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public onLandmarkSelected(landmark: LandMarkInOrderData) {
        this.selectLandmark(landmark);
    }

    public async onApplyClick() {
        await this.service.applyLandmarks();
        await this.router.navigate([Stage.MeshEdit]);
    }

    public onReset() {
        this.service.resetLandmarks();
    }

    private selectLandmark(landmark: LandMarkInOrderData) {
        this.selected = landmark;
        this.service.selectedLandmarktType = landmark.type;
    }
}
