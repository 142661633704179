import { Inject, Injectable } from '@angular/core';
import { EditorStageStatusService } from '@orthocore-web-mono/feature-core-services';
import {
    AxisNative,
    ENGINE_WORKER_SERVICE_TOKEN,
    ENVIRONMENT,
    Environment,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    ImportAxes,
    MeshImportFileFormat,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import { EditorFacade } from '@orthocore-web-mono/state';

const defaultImportAxes: ImportAxes = {
    upAxis: AxisNative.NegZ,
    forwardAxis: AxisNative.Y,
    rightAxis: AxisNative.X,
};

@Injectable()
export class FileLoaderService {
    constructor(
        @Inject(ENVIRONMENT) private readonly environment: Environment,
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: IScanService,
        private readonly facade: EditorFacade,
        private readonly status: EditorStageStatusService,
    ) {
        if (!this.environment.production) {
            this.autoLoadTestModel().catch(e => console.error(e));
        }
    }

    public async LoadFromArrayBuffer(buffer: ArrayBuffer, fileName: string) {
        await this.status.engineReady;
        const fileNameLowercase = fileName.toLowerCase();

        let extension: MeshImportFileFormat;

        switch (true) {
            case fileNameLowercase.endsWith('.stl'): {
                extension = 'stl';
                break;
            }
            case fileNameLowercase.endsWith('.ply'): {
                extension = 'ply';
                break;
            }
            case fileNameLowercase.endsWith('.drc'): {
                extension = 'draco';
                break;
            }
            default: {
                extension = 'obj';
                break;
            }
        }

        const bytes = new Uint8Array(buffer);

        const importResult = await this.engine.importMesh(extension, bytes, {
            axes: defaultImportAxes,
            generateNormals: true,
            centerTo: 'ground',
        });
        if (importResult.meshData === null) {
            console.error('Cannot import foot scan');
            return;
        }
        await this.engine.setScan(importResult.ptr);
        await this.scanService.syncScanMeshFromEngine();
        this.facade.modelLoaded();
        await this.engine.destroyMesh(importResult.ptr);
    }

    private async autoLoadTestModel() {
        if (!this.environment.autoLoadModel) return;
        const file = this.environment.autoLoadModel.split('/').slice(-1)[0];
        const req = await fetch(this.environment.autoLoadModel);
        const buf = await req.arrayBuffer();
        await this.LoadFromArrayBuffer(buf, file);
    }
}
