import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
} from '@angular/core';
import { NavigationService } from '@orthocore-web-mono/feature-core-services';
import { LandmarksService } from '@orthocore-web-mono/feature-landmarks';
import {
    SCAN_SERVICE_TOKEN,
    measurementModes,
} from '@orthocore-web-mono/shared-types';
import {
    StyledSliderValueChange,
    ToggleGroupselectedChangeEvent,
} from '@orthocore-web-mono/shared-ui';
import {
    DistanceMeasurementService,
    GirthMeasurementService,
    ScanService,
    SceneService,
} from '@orthocore-web-mono/shared/feature-scene';
import { EditorFacade } from '@orthocore-web-mono/state';
import { combineLatest, map, startWith, tap } from 'rxjs';

@Component({
    selector: 'leo-view-options-container',
    templateUrl: './view-options-container.component.html',
    styleUrl: './view-options-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewOptionsContainerComponent {
    @Input()
    public scanOpacityIconUrl = '/assets/icons/scan-opacity.svg';

    @Input()
    public generatedOpacityIconUrl = '/assets/icons/generated-opacity.svg';

    public measurementModes = measurementModes.map(
        x => `MEASUREMENTS.${x.toLocaleUpperCase()}`,
    );
    public selectedMeasurementMode = 0;

    public showMeasurementsPanel = false;
    public showGridSizeIndicator$ = this.nav.selectedSection$.pipe(
        map(s => s === 'scale'),
    );

    public showGroundPlane$ = this.facade.showGroundPlane$;
    public showLandmarks$ = this.landmarks.atTheLandmarksStep$;

    public disableMeasurements$ = combineLatest([
        this.nav.selectedStep$,
        this.nav.selectedSection$,
    ]).pipe(
        map(([step, selection]) => {
            return !!(step === 'landmarks' || selection);
        }),
        tap(val => {
            //Close the Measurement tab if it became disabled
            if (val !== true) return;
            if (!this.showMeasurementsPanel) return;
            this.showMeasurementsPanel = false;
            this.changeShowMeasurementsPanel(false);
        }),
    );

    public isScanOpacityInputDisabled$ = this.facade.modelLoaded$.pipe(
        map(x => !x),
    );

    public isGeneratedOpacityInputDisabled$ =
        this.scanService.generationStateChangeSubject.pipe(
            map(v => !v),
            startWith(true),
        );

    public distance$ = this.facade.measuredDistance$.pipe(
        map(v => {
            if (!v) return;
            return `${v}mm`;
        }),
    );

    public girth$ = this.facade.measuredGirth$.pipe(
        map(v => {
            if (!v) return;
            return `${v}mm`;
        }),
    );

    public isOrthographicsCamera$ = this.sceneService.cameraModeChanged$;
    public lockCamera$ = this.sceneService.lockCamera$;

    constructor(
        private readonly facade: EditorFacade,
        private readonly landmarks: LandmarksService,
        private readonly distanceService: DistanceMeasurementService,
        private readonly girthService: GirthMeasurementService,
        private readonly nav: NavigationService,
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: ScanService,
        private readonly sceneService: SceneService,
    ) {}

    public onSelectedMeasurementModeChanged(
        event: ToggleGroupselectedChangeEvent,
    ) {
        if (this.selectedMeasurementMode === event.index) return;
        this.selectedMeasurementMode = event.index;
        const key = measurementModes[event.index];
        this.facade.changeMeasurementMode(key);
    }

    public onGroundPlaneVisibilityChanged(value: boolean) {
        this.facade.changeShowGroundPlane(value);
    }

    public onShowLandmarksChanged(value: boolean) {
        this.facade.changeShowLandmarksChanged(value);
    }

    public onIsOrthographicsViewChanged(value: boolean) {
        this.sceneService.setCameraMode(value);
    }

    public toggleMeasurementsPanel() {
        this.showMeasurementsPanel = !this.showMeasurementsPanel;
        this.changeShowMeasurementsPanel(this.showMeasurementsPanel);
    }

    public changeShowMeasurementsPanel(show: boolean) {
        this.facade.changeShowMeasurementsPanel(show);
    }

    public onScanOpacityChange(event: StyledSliderValueChange) {
        this.scanService.setScanOpacity(event.value);
    }

    public onGeneratedOpacityChange(event: StyledSliderValueChange) {
        this.scanService.setGeneratedOpacity(event.value);
    }

    public onClearPointsClicked() {
        this.distanceService.clearPoints();
        this.girthService.clearPoints();
    }
}
