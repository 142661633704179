import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EditorStageStatusService } from './editor-stage-status.service';
import { EngineWorkerConnectorService } from './engine-worker-connector.service';
import { NavigationService } from './navigation.service';
import { StageGuard } from './stage.guard';

@NgModule({
    imports: [CommonModule],
    providers: [
        EngineWorkerConnectorService,
        NavigationService,
        EditorStageStatusService,
        StageGuard,
    ],
})
export class FeatureCoreServicesModule {}
