import { Inject, Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    NavigationService,
} from '@orthocore-web-mono/feature-core-services';
import { LandmarksService } from '@orthocore-web-mono/feature-landmarks';
import { UndoRedoService } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    SCAN_SERVICE_TOKEN,
    ToolLoopTwistModeJS,
    ToolLoopTwistParametersJS,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';

import { LoopTool } from '../loop-tool';
import { LoopToolBaseService } from '../loop-tool-base.service';
import {
    LoopToolsMaterialService,
    LoopTransformPlanes,
} from '../loop-tools-material.service';
import { MeasurementBO } from '../measurements/measurement.bo';

const depaultParamsThatChangeOnReset = {
    twistAmount: 0,
};

const depaultParamsThatStayOnReset = {
    twistMode: ToolLoopTwistModeJS.Top,
    twistPos: 0.5,
    twistDistTop: 0.15,
    twistDistBottom: 0.15,
};

@Injectable()
export class TwistLoopToolService extends LoopToolBaseService<ToolLoopTwistParametersJS> {
    protected sectionName = LoopTool.Twist;

    constructor(
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        @Inject(SCAN_SERVICE_TOKEN)
        scanService: IScanService,
        sceneService: SceneService,
        undoRedoService: UndoRedoService,
        nav: NavigationService,
        loopToolsMaterialService: LoopToolsMaterialService,
        status: EditorStageStatusService,
        landmarks: LandmarksService,
    ) {
        super(
            engine,
            scanService,
            sceneService,
            undoRedoService,
            nav,
            loopToolsMaterialService,
            status,
            landmarks,
            depaultParamsThatChangeOnReset,
            depaultParamsThatStayOnReset,
        );
    }

    protected sendStartToEngine = async () =>
        await this.engine.startLoopTwist();
    protected sendUpdateToEngine = async (params: ToolLoopTwistParametersJS) =>
        await this.engine.updateLoopTwist(params);
    protected sendFinishToEngine = async (isCancel: boolean) =>
        await this.engine.finishLoopTwist(isCancel);

    protected setPlanesOnMaterial(measurements: MeasurementBO): void {
        this.loopToolsMaterialService.updatePlaneFromVector(
            LoopTransformPlanes.Middle,
            measurements.middleTopPlane,
        );

        if (
            this.params.twistMode === ToolLoopTwistModeJS.Top ||
            this.params.twistMode === ToolLoopTwistModeJS.Middle
        ) {
            this.loopToolsMaterialService.updatePlaneFromVector(
                LoopTransformPlanes.Top,
                measurements.topPlane,
            );
        }

        if (
            this.params.twistMode === ToolLoopTwistModeJS.Bottom ||
            this.params.twistMode === ToolLoopTwistModeJS.Middle
        ) {
            this.loopToolsMaterialService.updatePlaneFromVector(
                LoopTransformPlanes.Bottom,
                measurements.bottomPlane,
            );
        }
    }
}
