export const orthosisName = 'afo';
export const defaultExportFormat = 'obj';
export const defaultImportFormat = 'obj';

interface ScanImportData {
    url: string | null;
    importFormat: 'stl' | 'obj' | null;
}

export interface IframeInitializer {
    scanData: ScanImportData | null;
    exportFormat: 'stl' | 'obj' | null;
}

export interface IframeUploadData {
    meshFiles: {
        name: string; // Additional data will be added to create the final file name, so this name should be minimal
        bytes: Uint8Array;
    }[];
}

export interface IframeIntegrationConfig {
    initialize: () => Promise<IframeInitializer>;
    approveExport: () => Promise<true | string | null>;
    uploadResult: (exportedMesh: IframeUploadData) => Promise<boolean>;
    closeEditor: () => void;
}
