<leo-toggle-group
    [label]="'MESH_EDIT.SCULPTING.MODE' | translate"
    [options]="sculptingModes"
    [selected]="selectedSculptingMode"
    (selectionChanged)="
        onSelectedSculptingModeChanged($event)
    "></leo-toggle-group>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.SCULPTING.RADIUS' | translate"
    [min]="5"
    [max]="50"
    [(value)]="service.radius"
    [step]="0.1"
    [unit]="mmUnit"
    [multiplier]="mm2cm"></leo-styled-slider-with-input>
<leo-styled-slider-with-input
    [title]="'MESH_EDIT.SCULPTING.STRENGTH' | translate"
    [min]="1"
    [max]="100"
    [(value)]="service.strength"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>
