<button
    [class.hidden]="hideReset"
    [disabled]="disableReset$ | async"
    (click)="onResetClick()">
    <img
        src="assets/icons/reset.svg"
        style="height: 30px; transform: rotate(40deg)" />
</button>
<div class="undo-redo">
    <button
        [class.hidden]="hideUndo"
        [disabled]="disableUndo$ | async"
        (click)="onUndoClick()">
        <img src="assets/icons/arrow.svg" />
    </button>
    <button
        [class.hidden]="hideRedu"
        [disabled]="disableRedo$ | async"
        (click)="onReduClick()">
        <img src="assets/icons/arrow.svg" style="transform: rotate(180deg)" />
    </button>
</div>
