import { Float3JS, ToolLoopOutJS } from '@orthocore-web-mono/shared-types';
import * as THREE from 'three';

export enum ToolLoopOutPlaneIndex {
    Top = 0,
    Middle_top = 1,
    Bottom = 2,
    Middle_bottom = 3,
    Middle_center = 4,
    Middle_center_cross = 5,
}

export class MeasurementBO implements ToolLoopOutJS {
    planePos: Float3JS[];
    planeNormal: Float3JS[];
    measureTopHeight: number;
    measureTopPerimeter: number;
    measureBottomHeight: number;
    measureBottomPerimeter: number;

    constructor(measurement: ToolLoopOutJS) {
        this.planePos = measurement.planePos;
        this.planeNormal = measurement.planeNormal;
        this.measureTopHeight = measurement.measureTopHeight;
        this.measureTopPerimeter = measurement.measureTopPerimeter;
        this.measureBottomHeight = measurement.measureBottomHeight;
        this.measureBottomPerimeter = measurement.measureBottomPerimeter;
    }

    public getPosition(index: ToolLoopOutPlaneIndex) {
        return this.planePos[index];
    }

    public getNormal(index: ToolLoopOutPlaneIndex) {
        return this.planeNormal[index];
    }

    get topPlane() {
        return this.mapPlaneToVector(ToolLoopOutPlaneIndex.Top);
    }

    get middleTopPlane() {
        return this.mapPlaneToVector(ToolLoopOutPlaneIndex.Middle_top);
    }

    get bottomPlane() {
        return this.mapPlaneToVector(ToolLoopOutPlaneIndex.Bottom);
    }

    get middleBottomPlane() {
        return this.mapPlaneToVector(ToolLoopOutPlaneIndex.Middle_bottom);
    }

    private mapPlaneToVector(index: ToolLoopOutPlaneIndex) {
        const position = this.planePos[index];
        const normal = this.planeNormal[index];
        const plane = new THREE.Plane();
        const normalVector = new THREE.Vector3(normal.x, normal.y, normal.z);
        const positionVector = new THREE.Vector3(
            position.x,
            position.y,
            position.z,
        );
        plane.setFromNormalAndCoplanarPoint(normalVector, positionVector);
        return new THREE.Vector4(
            plane.normal.x,
            plane.normal.y,
            plane.normal.z,
            plane.constant,
        );
    }
}
