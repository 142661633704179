<leo-styled-dropdown
    [title]="'MESH_EDIT.LOOP_TOOLS.REGION' | translate"
    [options]="regions"
    [localise]="true"
    (selectedChange)="onRegionChanged($event)"></leo-styled-dropdown>

<leo-styled-slider-with-input
    *ngIf="
        selectedRegionIndex === Region.ALL || selectedRegionIndex === Region.TOP
    "
    [title]="'MESH_EDIT.LOOP_TOOLS.TOP_POSITION' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.bendDistTop"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    *ngIf="
        selectedRegionIndex === Region.ALL ||
        selectedRegionIndex === Region.BOTTOM
    "
    [title]="'MESH_EDIT.LOOP_TOOLS.BOTTOM_POSITION' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.bendDistBottom"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<span>{{ 'MESH_EDIT.LOOP_TOOLS.BEND' | translate }}</span>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.AMOUNT' | translate"
    [min]="-90"
    [max]="90"
    [(value)]="params.bendAmount"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="degreeUnit"
    [multiplier]="deg2rad"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.POSITION' | translate"
    [min]="0"
    [max]="100"
    [(value)]="params.bendPos"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.ROTATION_AXIS' | translate"
    [min]="-180"
    [max]="180"
    [(value)]="params.bendAngle"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="degreeUnit"
    [multiplier]="deg2rad"></leo-styled-slider-with-input>

<leo-styled-toggle
    *ngIf="selectedRegionIndex === Region.ALL"
    [label]="'MESH_EDIT.LOOP_TOOLS.MIRROR' | translate"
    [(checked)]="params.isBendModeMirror"
    (checkedChange)="onChange()"></leo-styled-toggle>

<leo-measurements-loop-tool
    *ngIf="measurements$ | async as measurements"
    [measurements]="measurements"></leo-measurements-loop-tool>

<leo-styled-button
    (click)="onApplyLoopTool()"
    [disabled]="params.bendAmount === 0">
    {{ 'APPLY' | translate }}
</leo-styled-button>
