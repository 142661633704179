import { Inject, Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    NavigationService,
} from '@orthocore-web-mono/feature-core-services';
import { LandmarksService } from '@orthocore-web-mono/feature-landmarks';
import { UndoRedoService } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    SCAN_SERVICE_TOKEN,
    ToolLoopScaleDirectionJS,
    ToolLoopScaleModeJS,
    ToolLoopScaleParametersJS,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import * as THREE from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import { LoopTool } from '../loop-tool';
import { LoopToolBaseService } from '../loop-tool-base.service';
import {
    LoopToolsMaterialService,
    LoopTransformPlanes,
} from '../loop-tools-material.service';
import {
    MeasurementBO,
    ToolLoopOutPlaneIndex,
} from '../measurements/measurement.bo';

const depaultParamsThatChangeOnReset = {
    scaleAmount: 0,
};

const depaultParamsThatStayOnReset = {
    scaleMode: ToolLoopScaleModeJS.Middle,
    scaleDir: ToolLoopScaleDirectionJS.Full,
    scalePos: 0.5,
    scaleDistTop: 0.225,
    scaleDistBottom: 0.225,
    scaleAngle: 0,
    scaleMiddleSize: 0,
};

const arrowColor = 0xff0000;

const geometry = new LineGeometry();
const stickMaterial = new LineMaterial({
    color: arrowColor,
    linewidth: 5,
});

const stickSizepadding = 1.1;

@Injectable()
export class ScaleLoopToolService extends LoopToolBaseService<ToolLoopScaleParametersJS> {
    protected sectionName = LoopTool.Scale;

    private arrowLine = new Line2(geometry, stickMaterial);
    private arrowHeadMain = new THREE.ArrowHelper(
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 0, 0),
        1,
        arrowColor,
    );
    private arrowHeadSecunder = new THREE.ArrowHelper(
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 0, 0),
        1,
        arrowColor,
    );

    private arrowGroup = new THREE.Group();

    constructor(
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        @Inject(SCAN_SERVICE_TOKEN)
        scanService: IScanService,
        sceneService: SceneService,
        undoRedoService: UndoRedoService,
        nav: NavigationService,
        loopToolsMaterialService: LoopToolsMaterialService,
        status: EditorStageStatusService,
        landmarks: LandmarksService,
    ) {
        super(
            engine,
            scanService,
            sceneService,
            undoRedoService,
            nav,
            loopToolsMaterialService,
            status,
            landmarks,
            depaultParamsThatChangeOnReset,
            depaultParamsThatStayOnReset,
        );

        this.setupArrows();

        this.arrowHeadMain.line.visible = false;
        this.arrowHeadSecunder.line.visible = false;

        this.arrowGroup.add(this.arrowLine);
        this.arrowGroup.add(this.arrowHeadMain);
        this.arrowGroup.add(this.arrowHeadSecunder);
        this.sceneService.scene.add(this.arrowGroup);

        this.isOnSection$.subscribe(
            onSection => (this.arrowGroup.visible = onSection),
        );

        this.measurements$.subscribe(measurements => {
            this.updateArrows(measurements);
        });
    }

    protected sendStartToEngine = async () =>
        await this.engine.startLoopScale();
    protected sendUpdateToEngine = async (params: ToolLoopScaleParametersJS) =>
        await this.engine.updateLoopScale(params);
    protected sendFinishToEngine = async (isCancel: boolean) =>
        await this.engine.finishLoopScale(isCancel);

    protected async setPlanesOnMaterial(
        measurements: MeasurementBO,
    ): Promise<void> {
        this.loopToolsMaterialService.updatePlaneFromVector(
            LoopTransformPlanes.Middle,
            measurements.middleTopPlane,
        );

        if (
            this.params.scaleMode === ToolLoopScaleModeJS.Middle ||
            this.params.scaleMode === ToolLoopScaleModeJS.Top
        ) {
            this.loopToolsMaterialService.updatePlaneFromVector(
                LoopTransformPlanes.Top,
                measurements.topPlane,
            );
        }

        if (
            this.params.scaleMode === ToolLoopScaleModeJS.Middle ||
            this.params.scaleMode === ToolLoopScaleModeJS.Bottom
        ) {
            this.loopToolsMaterialService.updatePlaneFromVector(
                LoopTransformPlanes.Bottom,
                measurements.bottomPlane,
            );
        }

        if (this.params.scaleMiddleSize !== 0) {
            this.loopToolsMaterialService.updatePlaneFromVector(
                LoopTransformPlanes.Middle2,
                measurements.middleBottomPlane,
            );
        }
    }

    private async setupArrows() {
        await this.status.landmarksApplied;
        const box = new THREE.Box3().setFromObject(this.scanService.scan);
        const size = box.getSize(new THREE.Vector3());
        const maxDim = Math.max(size.x, size.y, size.z);

        const length = maxDim * stickSizepadding;
        const center = new THREE.Vector3();
        box.getCenter(center);

        this.arrowHeadMain.position.copy(center);
        this.arrowHeadMain.setDirection(new THREE.Vector3(1, 0, 1));
        this.arrowHeadMain.setLength(length);
    }

    private updateArrows(measurements: MeasurementBO) {
        if (
            this.params.scaleDir === 'None' ||
            this.params.scaleDir === 'Full'
        ) {
            this.arrowGroup.visible = false;
            return;
        }

        this.arrowGroup.visible = true;
        this.arrowHeadSecunder.visible = false;

        const midPoint = new THREE.Vector3(
            ...Object.values(
                measurements.getPosition(ToolLoopOutPlaneIndex.Middle_top),
            ),
        );

        const direction = new THREE.Vector3(
            -Math.cos(-this.params.scaleAngle),
            0,
            Math.sin(-this.params.scaleAngle),
        );

        const box = new THREE.Box3().setFromObject(this.scanService.scan);
        const size = box.getSize(new THREE.Vector3());

        const maxDim = Math.max(size.x, size.y, size.z);

        const padding = 1.1;
        const length = maxDim * padding;
        const shaftLength = length - this.arrowHeadMain.cone.scale.y;

        const pointOne = midPoint
            .clone()
            .add(direction.multiplyScalar(shaftLength));
        let pointTwo = midPoint;

        this.arrowHeadMain.position.copy(midPoint);
        this.arrowHeadMain.setDirection(direction);
        this.arrowHeadMain.setLength(length);

        if (this.params.scaleDir === 'Double') {
            this.arrowHeadSecunder.visible = true;
            const reversedDirection = direction.clone().negate();

            this.arrowHeadSecunder.position.copy(midPoint);
            this.arrowHeadSecunder.setDirection(reversedDirection);
            this.arrowHeadSecunder.setLength(length);
            pointTwo = midPoint.clone().add(reversedDirection);
        }

        geometry.setPositions([
            ...Object.values(pointOne),
            ...Object.values(pointTwo),
        ]);
        stickMaterial.resolution.set(window.innerWidth, window.innerHeight);
        this.arrowLine.computeLineDistances();
    }
}
