import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Float3JS } from '@orthocore-web-mono/shared-types';

import { axisColors } from '../contants';
import { StyledSliderWithInputComponent } from '../styled-slider-with-input/styled-slider-with-input.component';

export enum Axis {
    x = 'x',
    y = 'y',
    z = 'z',
}

@Component({
    selector: 'leo-axis-sliders',
    standalone: true,
    imports: [CommonModule, StyledSliderWithInputComponent],
    templateUrl: './axis-sliders.component.html',
    styleUrl: './axis-sliders.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxisSlidersComponent {
    colors = axisColors;
    axis = Axis;

    @Input() min = 0;
    @Input() max = 1;
    @Input() step?: number | 'any';
    @Input() unit = '';
    @Input() multiplier = 1;
    @Input() disabled = false;

    private _value: Float3JS = { x: 0, y: 0, z: 0 };

    @Input() set value(value: Float3JS) {
        this._value = value;
        this.change.markForCheck();
    }

    public get value(): Float3JS {
        return this._value;
    }

    @Output() valueChange = new EventEmitter<Float3JS>();
    @Output() movingFinished = new EventEmitter<void>();

    constructor(private readonly change: ChangeDetectorRef) {}

    onValueChange(axis: keyof Float3JS, value: number) {
        this._value[axis] = value;
        this.valueChange.emit(this._value);
    }

    onValueFinish() {
        this.movingFinished.emit();
    }
}
