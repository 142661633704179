<div data-locale-key="main-menu/design" class="title">
    {{ 'MAIN_MENU.DESIGN' | translate }}
</div>
<leo-horizontal-separator></leo-horizontal-separator>

<leo-collapsing-group>
    <!-- Select design -->
    <leo-collapsing-section
        [title]="'DESIGN.SELECT_DESIGN' | translate"
        [openOnFragment]="'select-desing'">
        <div
            class="toggle-group-grid"
            style="
                grid-template-columns: repeat(2, minmax(0px, 1fr));
                grid-template-rows: repeat(2, minmax(0px, 1fr));
            ">
            <button
                *ngFor="let design of this.designs$ | async; let i = index"
                class="afo-design-selector-button"
                [class.selected]="i === this.service.selectedDesignIndex"
                (click)="onDesignChange(i)">
                <img src="assets/icons/designs/{{ design | lowercase }}.svg" />
                <div>{{ design }}</div>
            </button>
        </div>
    </leo-collapsing-section>

    <!-- Edit design -->
    <leo-collapsing-section
        [title]="'DESIGN.EDIT_DESIGN' | translate"
        [openOnFragment]="'edit-desing'">
        <leo-toggle-group
            [colums]="1"
            [options]="editComponentsModes"
            [selected]="selectedEditComponentIndex"
            (selectionChanged)="onselectedEditComponentChange($event)">
        </leo-toggle-group>
        <ng-template #editDesignComponentContainer></ng-template>
    </leo-collapsing-section>

    <!-- Rearfoot post
    <leo-collapsing-section
        [title]="'DESIGN.REARFOOT_TITLE' | translate"
        [openOnFragment]="'rear-foot-post'">
        <leo-rearfoot-post></leo-rearfoot-post>
    </leo-collapsing-section> -->

    <!-- Embossed text & logo -->
    <!-- <leo-collapsing-section
        [title]="'DESIGN.EMBOSSED_TEXT' | translate"
        [openOnFragment]="'embossed-text'">
        <leo-styled-text-input-multiline
            [label]="
                'DESIGN.EMBOSSED_TEXT' | translate
            "></leo-styled-text-input-multiline>

        <leo-styled-slider-with-input
            [title]="'DESIGN.EMBOSSED_ROTATION' | translate"
            [min]="-180"
            [max]="180"
            [step]="0.1"
            [unit]="degreeUnit"></leo-styled-slider-with-input>
        <leo-styled-slider-with-input
            [title]="'DESIGN.EMBOSSED_FONT_SIZE' | translate"
            [min]="0.1"
            [max]="5"
            [value]="1.2"
            [step]="0.1"></leo-styled-slider-with-input>
        <leo-styled-slider-with-input
            [title]="'DESIGN.EMBOSSED_LINE_HEIGHT' | translate"
            [min]="25"
            [max]="200"
            [value]="100"
            [step]="0.1"></leo-styled-slider-with-input>
        <leo-styled-slider-with-input
            [title]="'DESIGN.EMBOSSED_DEPTH' | translate"
            [min]="-1"
            [max]="3"
            [value]="0.5"
            [step]="0.1"
            [unit]="mmUnit"></leo-styled-slider-with-input>
        <leo-styled-toggle
            [label]="
                'DESIGN.EMBOSSER_ENABLE_LOGO' | translate
            "></leo-styled-toggle>

        <leo-styled-slider-with-input
            [title]="'DESIGN.EMBOSSER_LOGO_SCALE' | translate"
            [min]="0.2"
            [max]="10"
            [value]="4.6"
            [step]="0.1"></leo-styled-slider-with-input>
    </leo-collapsing-section> -->
</leo-collapsing-group>
<leo-styled-button (click)="onGenerate()">{{
    'DESIGN.GENERATE' | translate
}}</leo-styled-button>
