import { Inject, Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    NavigationService,
} from '@orthocore-web-mono/feature-core-services';
import {
    FittingPlane,
    IScanService,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import { UpdateOpacity } from '@orthocore-web-mono/shared/utils';
import { cloneDeep } from 'lodash';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    startWith,
} from 'rxjs';
import * as THREE from 'three';

import { ImageImportSectionName } from './constants';
import { shaderMaterialBuilder } from './shader.material';

export enum Stage {
    Nothing,
    ImageImported,
    ImageAddedToMesh,
    ImageAddedToScene,
}

@Injectable()
export class ImageImportService {
    public stage$ = new BehaviorSubject<Stage>(Stage.Nothing);

    public loadedImageFromImport$ = new Subject<string>();
    public addedImageToMesh$ = new Subject<void>();
    public applyImageToMesh$ = new Subject<void>();
    public addImageToScene$ = new Subject<void>();
    public cancel$ = new Subject<void>();

    private imagePlane?: THREE.Mesh;

    public isOnImportImageSection$ = combineLatest([
        this.nav.selectedStep$,
        this.nav.selectedSection$,
    ]).pipe(
        map(
            ([step, section]) =>
                step === 'mesh-edit' && section === ImageImportSectionName,
        ),
    );
    public opacity$ = new BehaviorSubject<number>(1);
    public fittingPlane$ = new BehaviorSubject<FittingPlane>(
        FittingPlane.Coronal,
    );

    public editImage$ = combineLatest([
        this.isOnImportImageSection$,
        this.stage$,
    ]).pipe(
        map(([onSection, stage]) => onSection && stage === Stage.ImageImported),
        distinctUntilChanged(),
    );
    private doneEditImage$ = this.editImage$.pipe(filter(v => !v));

    private editOrPlaneChange$ = combineLatest([
        this.editImage$,
        this.fittingPlane$,
    ]).pipe(filter(([editing]) => editing));

    private stageChange$ = this.stage$.pipe(
        startWith(null),
        pairwise(),
        map(([leaving, entering]) => ({ leaving, entering })),
    );
    private leavingAppliedMaterial$ = this.stageChange$.pipe(
        map(({ leaving }) => leaving === Stage.ImageAddedToMesh),
        distinctUntilChanged(),
        filter(v => !!v),
    );

    private isStoredCameraOrto?: boolean;

    constructor(
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: IScanService,
        private readonly sceneService: SceneService,
        private readonly nav: NavigationService,
        private readonly status: EditorStageStatusService,
    ) {
        this.editOrPlaneChange$.subscribe(async ([_, plane]) => {
            await this.status.landmarksApplied;
            this.isStoredCameraOrto =
                this.sceneService.activeCamera ===
                this.sceneService.orthographicCamera;
            this.sceneService.setCameraMode(true);
            await this.scanService.moveOrtoCameraToSeeFittingPlane(plane);
            this.sceneService.lockCamera(true);
        });

        this.doneEditImage$.subscribe(() => {
            if (this.isStoredCameraOrto !== undefined) {
                this.sceneService.setCameraMode(this.isStoredCameraOrto);
            }
            this.sceneService.lockCamera(false);
        });

        this.opacity$.subscribe(value => {
            if (this.imagePlane) {
                UpdateOpacity(
                    this.imagePlane,
                    this.imagePlane.material,
                    value,
                    true,
                );
                this.sceneService.requestSceneRender();
            }
        });

        this.leavingAppliedMaterial$.subscribe(() => {
            this.scanService.setBaseMaterial(undefined);
        });
    }

    public loadImageFromImportToCanvas(dataUrl: string) {
        this.loadedImageFromImport$.next(dataUrl);
        this.stage$.next(Stage.ImageImported);
    }

    public changeFittingPlane(plane: FittingPlane) {
        this.fittingPlane$.next(plane);
    }

    public setOpacity(opacity: number) {
        this.opacity$.next(opacity);
    }

    public startApplyImageToMesh() {
        this.applyImageToMesh$.next();
        setTimeout(() => {
            this.stage$.next(Stage.ImageAddedToMesh);
        }, 10);
    }

    public startAddImageToScene() {
        this.addImageToScene$.next();
        setTimeout(() => {
            this.stage$.next(Stage.ImageAddedToScene);
        }, 10);
    }

    public addDataUrlForTexture(dataUrl: string) {
        this.setImageTextureToMeshAtcurrentCamera(dataUrl);
        this.addedImageToMesh$.next();
    }

    public addDataUrlForImage(dataUrl: string) {
        this.addImageToScene(dataUrl);
        this.addedImageToMesh$.next();
    }

    public repositionImage() {
        if (this.imagePlane) {
            this.sceneService.scene.remove(this.imagePlane);
            this.imagePlane = undefined;
        }

        this.stage$.next(Stage.ImageImported);
    }

    public cancel() {
        this.cancel$.next();
        this.stage$.next(Stage.Nothing);
    }

    public addImageToScene(imageDataUrl: string) {
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(imageDataUrl, texture => {
            const box = new THREE.Box3().setFromObject(this.scanService.scan);
            const center = box.getCenter(new THREE.Vector3());

            const camera = <THREE.OrthographicCamera>(
                this.sceneService.activeCamera
            );

            const planeWidth = (camera.right - camera.left) / camera.zoom;
            const planeHeight = (camera.top - camera.bottom) / camera.zoom;

            const planeGeometry = new THREE.PlaneGeometry(
                planeWidth,
                planeHeight,
            );
            const planeMaterial = new THREE.MeshBasicMaterial({
                map: texture,
                side: THREE.DoubleSide,
            });
            this.imagePlane = new THREE.Mesh(planeGeometry, planeMaterial);

            this.imagePlane.position.copy(center);
            this.imagePlane.lookAt(camera.position);
            UpdateOpacity(
                this.imagePlane,
                this.imagePlane.material,
                this.opacity$.value,
                true,
            );
            this.sceneService.scene.add(this.imagePlane);
            this.sceneService.requestSceneRender();
        });
    }

    private setImageTextureToMeshAtcurrentCamera(data: string): void {
        const material = shaderMaterialBuilder(
            data,
            this.sceneService.activeCamera,
        );
        this.scanService.setBaseMaterial(material);
        setTimeout(() => {
            this.sceneService.requestSceneRender();
        });
    }
}
