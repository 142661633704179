import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureCoreServicesModule } from '@orthocore-web-mono/feature-core-services';
import { FeatureUndoRedoResetModule } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    AxisSlidersComponent,
    CollapsingGroupComponent,
    CollapsingSectionComponent,
    HeaderComponent,
    HorizontalSeparatorComponent,
    PoweredByComponent,
    StyledButtonComponent,
    StyledDropdownComponent,
    StyledSliderComponent,
    StyledSliderWithInputComponent,
    StyledTextInputMultilineComponent,
    StyledToggleComponent,
    ToggleGroupComponent,
} from '@orthocore-web-mono/shared-ui';

import { SculptingComponent } from './sculpting/sculpting.component';
import { SculptingService } from './services/sculpting.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        // RouterModule.forChild(routes),

        FeatureUndoRedoResetModule,
        FeatureCoreServicesModule,

        //UI Elements
        HeaderComponent,
        PoweredByComponent,
        HorizontalSeparatorComponent,
        StyledButtonComponent,
        CollapsingGroupComponent,
        CollapsingSectionComponent,
        StyledToggleComponent,
        StyledSliderComponent,
        ToggleGroupComponent,
        StyledDropdownComponent,
        StyledSliderWithInputComponent,
        AxisSlidersComponent,
        StyledTextInputMultilineComponent,
    ],
    declarations: [SculptingComponent],
    providers: [SculptingService],
    exports: [SculptingComponent],
})
export class MeshEditModule {}
