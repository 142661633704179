import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureImageImportModule } from '@orthocore-web-mono/feature-image-import';
import { FeatureLoopToolsModule } from '@orthocore-web-mono/feature-loop-tools';
import { MeshEditModule } from '@orthocore-web-mono/feature-mesh-edit';
import { FeaturePressureReliefModule } from '@orthocore-web-mono/feature-pressure-relief';
import { FeatureUndoRedoResetModule } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    CollapsingGroupComponent,
    CollapsingSectionComponent,
    HorizontalSeparatorComponent,
} from '@orthocore-web-mono/shared-ui';

import { SpinalMeshEditComponent } from './mesh-edit.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        HorizontalSeparatorComponent,
        CollapsingGroupComponent,
        CollapsingSectionComponent,

        FeatureUndoRedoResetModule,
        MeshEditModule,
        FeatureLoopToolsModule,
        FeaturePressureReliefModule,
        FeatureImageImportModule,
    ],
    declarations: [SpinalMeshEditComponent],
})
export class FeatureSpinalMeshEditModule {}
