import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureCoreServicesModule } from '@orthocore-web-mono/feature-core-services';
import { ENGINE_WORKER_SERVICE_TOKEN } from '@orthocore-web-mono/shared-types';

import { SpinalEngineWorkerService } from './spinal-engine-worker-service';

@NgModule({
    imports: [CommonModule, FeatureCoreServicesModule],
    providers: [
        {
            provide: ENGINE_WORKER_SERVICE_TOKEN,
            useClass: SpinalEngineWorkerService,
        },
    ],
})
export class SpinalEngineModule {}
