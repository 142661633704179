import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
} from '@angular/core';
import { Subject, map, takeUntil } from 'rxjs';

import { UndoRedoService } from '../undo-redo.service';

@Component({
    selector: 'leo-undo-redo-reset',
    templateUrl: './undo-redo-reset.component.html',
    styleUrl: './undo-redo-reset.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UndoRedoResetComponent implements AfterViewInit, OnDestroy {
    @Input() hideReset = false;

    @Input() hideUndo = false;
    @Input() hideRedu = false;

    private destroyed$ = new Subject<void>();

    public disableUndo$ = this.service.availability$.pipe(
        takeUntil(this.destroyed$),
        map(v => !v.canUndo),
    );

    public disableRedo$ = this.service.availability$.pipe(
        takeUntil(this.destroyed$),
        map(v => !v.canRedo),
    );

    public disableReset$ = this.service.availability$.pipe(
        takeUntil(this.destroyed$),
        map(v => !v.canUndo),
    );

    constructor(
        private change: ChangeDetectorRef,
        private readonly service: UndoRedoService,
    ) {}

    ngAfterViewInit(): void {
        this.service.updateUndoRedoAvailability();
        setTimeout(() => this.change.markForCheck());
    }
    async onResetClick() {
        await this.service.reset();
    }

    async onUndoClick() {
        await this.service.undo();
    }

    async onReduClick() {
        await this.service.redo();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
