import { Inject, Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    NavigationService,
} from '@orthocore-web-mono/feature-core-services';
import { LandmarksService } from '@orthocore-web-mono/feature-landmarks';
import { UndoRedoService } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    SCAN_SERVICE_TOKEN,
    ToolLoopBendParametersJS,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import * as THREE from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import { LoopTool } from '../loop-tool';
import { LoopToolBaseService } from '../loop-tool-base.service';
import {
    LoopToolsMaterialService,
    LoopTransformPlanes,
} from '../loop-tools-material.service';
import {
    MeasurementBO,
    ToolLoopOutPlaneIndex,
} from '../measurements/measurement.bo';

const depaultParamsThatChangeOnReset = {
    bendAmount: 0,
};

const depaultParamsThatStayOnReset = {
    isBendTop: true,
    isBendBottom: false,
    isBendModeMirror: true,
    bendPos: 0.5,
    bendDistTop: 0.25,
    bendDistBottom: 0.25,
    bendAngle: 0,
};

const stickColor = 0xff0000;

const geometry = new LineGeometry();
const stickMaterial = new LineMaterial({
    color: stickColor,
    linewidth: 5,
});

@Injectable()
export class BendLoopToolService extends LoopToolBaseService<ToolLoopBendParametersJS> {
    protected sectionName = LoopTool.Bend;

    private axisStick = new Line2(geometry, stickMaterial);

    constructor(
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        @Inject(SCAN_SERVICE_TOKEN)
        scanService: IScanService,
        sceneService: SceneService,
        undoRedoService: UndoRedoService,
        nav: NavigationService,
        loopToolsMaterialService: LoopToolsMaterialService,
        status: EditorStageStatusService,
        landmarks: LandmarksService,
    ) {
        super(
            engine,
            scanService,
            sceneService,
            undoRedoService,
            nav,
            loopToolsMaterialService,
            status,
            landmarks,
            depaultParamsThatChangeOnReset,
            depaultParamsThatStayOnReset,
        );

        this.sceneService.scene.add(this.axisStick);
        this.isOnSection$.subscribe(
            onSection => (this.axisStick.visible = onSection),
        );

        this.measurements$.subscribe(measurements => {
            this.updateStick(measurements);
        });
    }

    protected sendStartToEngine = async () => await this.engine.startLoopBend();
    protected sendUpdateToEngine = async (params: ToolLoopBendParametersJS) =>
        await this.engine.updateLoopBend(params);
    protected sendFinishToEngine = async (isCancel: boolean) =>
        await this.engine.finishLoopBend(isCancel);

    protected async setPlanesOnMaterial(
        measurements: MeasurementBO,
    ): Promise<void> {
        this.loopToolsMaterialService.updatePlaneFromVector(
            LoopTransformPlanes.Middle,
            measurements.middleTopPlane,
        );

        if (this.params.isBendTop) {
            this.loopToolsMaterialService.updatePlaneFromVector(
                LoopTransformPlanes.Top,
                measurements.topPlane,
            );
        }

        if (this.params.isBendBottom) {
            this.loopToolsMaterialService.updatePlaneFromVector(
                LoopTransformPlanes.Bottom,
                measurements.bottomPlane,
            );
        }
    }

    private updateStick(measurements: MeasurementBO) {
        const midPoint = new THREE.Vector3(
            ...Object.values(
                measurements.getPosition(ToolLoopOutPlaneIndex.Middle_top),
            ),
        );

        const direction = new THREE.Vector3(
            -Math.sin(-this.params.bendAngle),
            0,
            Math.cos(-this.params.bendAngle),
        );
        const reversedDirection = direction.clone().negate();

        const box = new THREE.Box3().setFromObject(this.scanService.scan);
        const size = box.getSize(new THREE.Vector3());

        const maxDim = Math.max(size.x, size.y, size.z);
        const padding = 1.1;
        const halfLength = maxDim * padding;

        const forwardPoint = midPoint
            .clone()
            .add(direction.multiplyScalar(halfLength));
        const backPoint = midPoint
            .clone()
            .add(reversedDirection.multiplyScalar(halfLength));

        geometry.setPositions([
            ...Object.values(backPoint),
            ...Object.values(forwardPoint),
        ]);
        stickMaterial.resolution.set(window.innerWidth, window.innerHeight);
        this.axisStick.computeLineDistances();
    }
}
