import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    IntersectRaySphere,
    RenderAxisGizmo,
    axisGizmoCamera,
    gizmoCircles,
} from '@orthocore-web-mono/shared/utils';
import { EditorFacade } from '@orthocore-web-mono/state';
import { Subject, takeUntil } from 'rxjs';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { CheckerboardPlane } from '../ground-plane/ground-plane';
import { SceneService } from '../services/scene-data.service';

const sceneBackgroundColor = 0xb0b0b0;
const defaultCameraDistance = 150;

const axisGeometry = new THREE.CylinderGeometry(0.1, 0.1, 100, 16, 1);
const axisFadeStart = 10;
const axisFadeEnd = 80;
const axisDefaultAlpha = 0.2;

@Component({
    selector: 'leo-canvas',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanvasComponent implements OnInit, OnDestroy {
    private canvasContainer: HTMLDivElement;
    private renderRequested = false;

    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    rendererCanvas: HTMLCanvasElement;

    canvasSize = new THREE.Vector2();
    tmpVec3 = new THREE.Vector3();
    tmpMatrix3 = new THREE.Matrix3();
    tmpMatrix4 = new THREE.Matrix4();

    light = new THREE.DirectionalLight(0xffffff, 1.2);

    groundPlane = new CheckerboardPlane(
        new THREE.Color(0x000000),
        0.04,
        new THREE.Color(0xffffff),
        0.0,
        1,
        100,
    );

    orbitControls!: OrbitControls;

    mousePos = new THREE.Vector2();
    raycaster = new THREE.Raycaster();

    private axisContainer = new THREE.Object3D();

    viewDirections: THREE.Vector3[] = [
        new THREE.Vector3(0, 1, 0),
        new THREE.Vector3(0, -1, 0),
        new THREE.Vector3(-1, 0, 0),
        new THREE.Vector3(1, 0, 0),
        new THREE.Vector3(0, 0, 1),
        new THREE.Vector3(0, 0, -1),
    ];

    private destroyed$ = new Subject<void>();

    constructor(
        elRef: ElementRef,
        private readonly sceneService: SceneService,
        private readonly facade: EditorFacade,
    ) {
        this.canvasContainer = elRef.nativeElement;
        this.scene = this.sceneService.scene;
        this.renderer = this.sceneService.renderer;
        this.rendererCanvas = this.renderer.domElement;
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    ngOnInit(): void {
        this.sceneService.requestSceneRender$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.requestRender());

        this.renderer.setClearColor(sceneBackgroundColor);
        this.canvasContainer.prepend(this.renderer.domElement);
        this.scene.add(new THREE.AmbientLight(0xffffff, 1));
        this.scene.add(this.light);
        this.scene.add(this.light.target);

        this.groundPlane.visible = false;
        this.scene.add(this.groundPlane);

        this.resizeCanvas();

        this.orbitControls = new OrbitControls(
            this.sceneService.activeCamera,
            this.renderer.domElement,
        );

        this.orbitControls.addEventListener('change', () =>
            this.requestRender(),
        );

        this.orbitControls.rotateSpeed = 0.5;
        this.orbitControls.mouseButtons.LEFT = undefined;
        this.orbitControls.mouseButtons.MIDDLE = THREE.MOUSE.PAN;
        this.orbitControls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;

        this.resetCameraPosition();

        let rightClickDraggingOnCanvas = false;
        let justReleasedRightClickOnCanvas = false;
        this.rendererCanvas.addEventListener(
            'pointerdown',
            (ev: PointerEvent) => {
                if (ev.button === 2) {
                    rightClickDraggingOnCanvas = true;
                }
            },
        );

        this.rendererCanvas.addEventListener(
            'pointerup',
            (ev: PointerEvent) => {
                if (ev.button === 2) {
                    rightClickDraggingOnCanvas = false;
                    justReleasedRightClickOnCanvas = true;

                    window.requestAnimationFrame(
                        () => (justReleasedRightClickOnCanvas = false),
                    );
                }
            },
        );

        window.addEventListener('contextmenu', (ev: Event) => {
            if (rightClickDraggingOnCanvas || justReleasedRightClickOnCanvas) {
                ev.preventDefault();
                return false;
            }
            return true;
        });

        this.scene.add(this.axisContainer);

        this.setupAxis(0, new THREE.Vector3(1, 0, 0));
        this.setupAxis(1, new THREE.Vector3(0, 1, 0));
        this.setupAxis(2, new THREE.Vector3(0, 0, 1));

        this.rendererCanvas.addEventListener(
            'mousedown',
            event => this.onGizmoInteraction(event),
            false,
        );

        this.facade.showGroundPlane$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(val => {
                this.groundPlane.visible = val;
                this.requestRender();
            });

        this.sceneService.cameraModeChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(val => {
                this.setCameraMode(val);
                this.requestRender();
            });
    }

    public getMouseRay(ev: MouseEvent) {
        this.mousePos.x =
            (ev.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
        this.mousePos.y =
            (ev.offsetY / this.renderer.domElement.clientHeight) * -2 + 1;

        this.raycaster.setFromCamera(
            this.mousePos,
            this.sceneService.activeCamera,
        );
        return this.raycaster.ray;
    }

    @HostListener('window:resize')
    onResize() {
        this.resizeCanvas();
    }

    private requestRender() {
        if (this.renderRequested) {
            return;
        }

        this.renderRequested = true;
        window.requestAnimationFrame(this.render.bind(this));
    }

    private render() {
        this.renderRequested = false;

        // Render main scene
        this.light.position.copy(this.sceneService.activeCamera.position);
        const cameraForward = this.sceneService.activeCamera.getWorldDirection(
            this.tmpVec3,
        );
        this.light.target.position.copy(this.light.position).add(cameraForward);
        this.renderer.setViewport(0, 0, this.canvasSize.x, this.canvasSize.y);
        this.renderer.render(this.scene, this.sceneService.activeCamera);

        // Render axis gizmo
        RenderAxisGizmo(
            this.sceneService.activeCamera.quaternion,
            this.renderer,
            this.canvasSize.x,
            this.canvasSize.y,
        );
    }

    private resizeCanvas() {
        this.canvasSize.set(
            this.canvasContainer.clientWidth,
            this.canvasContainer.clientHeight,
        );
        this.renderer.setSize(this.canvasSize.x, this.canvasSize.y);
        const canvasAspectRatio = this.canvasSize.x / this.canvasSize.y;

        this.sceneService.perspectiveCamera.aspect = canvasAspectRatio;
        this.sceneService.perspectiveCamera.updateProjectionMatrix();

        const frustumSize = 25;
        this.sceneService.orthographicCamera.left =
            (-frustumSize * canvasAspectRatio) / 2;
        this.sceneService.orthographicCamera.right =
            (frustumSize * canvasAspectRatio) / 2;
        this.sceneService.orthographicCamera.top = frustumSize / 2;
        this.sceneService.orthographicCamera.bottom = -frustumSize / 2;
        this.sceneService.orthographicCamera.updateProjectionMatrix();

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.requestRender();
    }

    private resetCameraPosition() {
        this.sceneService.activeCamera.position
            .set(-2, 2, 3)
            .normalize()
            .multiplyScalar(defaultCameraDistance);
        this.orbitControls.target.setScalar(0);
        this.orbitControls.update();
        this.sceneService.activeCamera.updateMatrixWorld();
    }

    private setCameraMode(orthographic: boolean) {
        this.sceneService.activeCamera = orthographic
            ? this.sceneService.orthographicCamera
            : this.sceneService.perspectiveCamera;
        this.orbitControls.object = this.sceneService.activeCamera;

        this.resetCameraPosition();
    }

    private setupAxis(axisIndex: number, color: THREE.Vector3) {
        const axisMaterial = new THREE.ShaderMaterial({
            transparent: true,
            depthWrite: false,
            blending: THREE.NormalBlending,
            vertexShader: `
                varying float vHeight;
                
                void main()
                {
                    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                
                    vHeight = position.y; // Object space coordinate
                }
                `,
            fragmentShader: `
                varying float vHeight;
                
                const vec4 uColor = vec4(${color.x}, ${color.y}, ${color.z}, 1);
                
                const float fadeStart = float(${axisFadeStart});
                const float fadeEnd = float(${axisFadeEnd});
                const float defaultAlpha = float(${axisDefaultAlpha});
                
                void main()
                {
                    gl_FragColor = uColor;
                    float height = abs(vHeight);
                    gl_FragColor.a = defaultAlpha * smoothstep(fadeEnd, fadeStart, height);
                }`,
        });

        const axis = new THREE.Mesh(axisGeometry, axisMaterial);
        switch (axisIndex) {
            case 0:
                // X axis, rotate around Z
                axis.quaternion.setFromAxisAngle(
                    new THREE.Vector3(0, 0, 1),
                    Math.PI / 2,
                );
                break;
            case 1:
                // Y axis, nothing to rotate
                break;
            case 2:
                // Z axis, rotate around X
                axis.quaternion.setFromAxisAngle(
                    new THREE.Vector3(1, 0, 0),
                    Math.PI / 2,
                );
                break;
        }

        this.axisContainer.add(axis);
    }

    private handleGizmoCircleClick(intersectedObject: THREE.Object3D) {
        if (intersectedObject instanceof THREE.Mesh) {
            const index = gizmoCircles.indexOf(intersectedObject);
            if (index !== -1) {
                const cameraDistance =
                    this.sceneService.activeCamera.position.distanceTo(
                        this.orbitControls.target,
                    );

                const dir = this.viewDirections[index];
                this.sceneService.activeCamera.position
                    .copy(dir)
                    .multiplyScalar(cameraDistance)
                    .add(this.orbitControls.target);

                this.sceneService.activeCamera.lookAt(
                    this.orbitControls.target,
                );
                this.sceneService.activeCamera.up.set(0, 1, 0);

                this.sceneService.activeCamera.updateMatrixWorld();
                this.orbitControls.update();

                this.requestRender();
            }
        }
    }

    private onGizmoInteraction(event: MouseEvent) {
        const sizeInPixels = 150;
        const margin = 20;

        const viewportStartX = this.canvasSize.x - sizeInPixels - margin;
        const viewportStartY = margin;

        // Check if the click is within the gizmo viewport
        if (
            event.clientX >= viewportStartX &&
            event.clientX <= viewportStartX + sizeInPixels &&
            event.clientY >= viewportStartY &&
            event.clientY <= viewportStartY + sizeInPixels
        ) {
            const gizmoMouseX = (event.clientX - viewportStartX) / sizeInPixels;
            const gizmoMouseY = (event.clientY - viewportStartY) / sizeInPixels;

            const normalizedMouseX = gizmoMouseX * 2 - 1;
            const normalizedMouseY = -(gizmoMouseY * 2 - 1);

            this.raycaster.setFromCamera(
                new THREE.Vector2(normalizedMouseX, normalizedMouseY),
                axisGizmoCamera,
            );

            const gizmoSphereHitRadius = 0.2;

            let closestHit = Infinity;
            let closestIndex: number | null = null;
            for (const [index, gizmoCircle] of gizmoCircles.entries()) {
                const intersection = IntersectRaySphere(
                    gizmoCircle.position,
                    gizmoSphereHitRadius,
                    this.raycaster.ray,
                );
                if (intersection !== null && intersection < closestHit) {
                    closestHit = intersection;
                    closestIndex = index;
                }
            }

            if (closestIndex !== null) {
                this.handleGizmoCircleClick(gizmoCircles[closestIndex]);
            }
        }
    }
}
