<button
    class="collapsing-title"
    [ngClass]="{ collapsed: collapsed }"
    (click)="onTitleClicked()"
    [disabled]="disabled">
    {{ title }}
</button>
<div *ngIf="!collapsed" class="collapsing-section-content">
    <ng-content></ng-content>
</div>
