import { MeshOperationBase } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    GeometryUpdateFlags,
    IEngineWorkerServiceDataBase,
} from '@orthocore-web-mono/shared-types';

export abstract class MeshEditOperation<
    E extends IEngineWorkerServiceDataBase,
> extends MeshOperationBase<E> {
    public scanMeshChangeFlags() {
        return GeometryUpdateFlags.Vertices | GeometryUpdateFlags.Normals;
    }

    override needsLandmarkSync() {
        return true;
    }
}

export class LoopTransformOperation extends MeshEditOperation<IEngineWorkerServiceDataBase> {
    protected async doEngineOperation() {
        await this.engine.loopTransformFinish(false);
    }
}

export class PressureReliefOperation extends MeshEditOperation<IEngineWorkerServiceDataBase> {
    protected async doEngineOperation() {
        await this.engine.finishPressureRelief(false);
    }
}

export class SculptOperation extends MeshEditOperation<IEngineWorkerServiceDataBase> {
    protected async doEngineOperation() {
        await this.engine.sculptFinish();
    }
}
