import { ChangeDetectorRef } from '@angular/core';
import {
    deg2rad,
    degreeUnit,
    percentUnit,
    value2percent,
} from '@orthocore-web-mono/shared/utils';

import { LoopToolBaseService } from './loop-tool-base.service';

export abstract class LoopToolBaseComponent<
    T extends LoopToolBaseService<any>,
> {
    public degreeUnit = degreeUnit;
    public deg2rad = deg2rad;
    public percentUnit = percentUnit;
    public value2percent = value2percent;

    public params = this.service.params;
    public measurements$ = this.service.measurements$;

    constructor(
        protected readonly change: ChangeDetectorRef,
        protected readonly service: T,
    ) {}

    public onChange() {
        this.service.update();
    }

    public async onApplyLoopTool() {
        this.service.onFinishLoopTool(false);
        this.change.markForCheck();
    }
}
