import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SCAN_SERVICE_TOKEN } from '@orthocore-web-mono/shared-types';

import { CanvasComponent } from './canvas/canvas.component';
import { DistanceMeasurementService } from './services/distance-measurement.service';
import { GirthMeasurementService } from './services/girth-measurement.service';
import { ScanService } from './services/scan.service';
import { SceneService } from './services/scene-data.service';

@NgModule({
    imports: [CommonModule],
    declarations: [CanvasComponent],
    exports: [CanvasComponent],
    providers: [
        ScanService,
        {
            provide: SCAN_SERVICE_TOKEN,
            useExisting: ScanService,
        },
        SceneService,
        DistanceMeasurementService,
        GirthMeasurementService,
    ],
})
export class SharedFeatureSceneModule {}
