import * as THREE from 'three';

export const sculptMaterial = new THREE.ShaderMaterial({
    side: THREE.DoubleSide,
    transparent: false,
    blending: THREE.NormalBlending,
    uniforms: {
        opacity: { value: 1.0 },
    },
    vertexShader: `
varying vec3 vViewNormal;

void main()
{
    vViewNormal = normalize(normalMatrix * normal);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
}
`,
    fragmentShader: `
uniform float opacity;
varying vec3 vViewNormal;

void main()
{
    float z = abs(vViewNormal.z);
    float col = pow(smoothstep(0.2, 1.1, z), 1.5);

    gl_FragColor = vec4(vec3(col), opacity);
}
`,
});
