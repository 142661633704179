<div *ngIf="label" class="toggle-group-title">{{ label }}</div>
<div
    class="toggle-group-container"
    [style.--toggle-group-columns]="gridColumnsSyle"
    [style.--toggle-group-rows]="gridRowsSyle">
    <leo-styled-button
        *ngFor="let option of options; index as i"
        (click)="onSelectionChanged(i)"
        [ngClass]="i === selected ? 'selected' : ''"
        [leoStyle]="i === selected ? 'default' : 'gray'"
        >{{ option | translate }}
    </leo-styled-button>
</div>
