import { Inject, Injectable } from '@angular/core';
import {
    Float3JS,
    Float4JS,
    IScanService,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import * as THREE from 'three';

import { loopTransformMaterial } from './loop.material';

export enum LoopTransformPlanes {
    Middle = 'plane0',
    Top = 'plane1',
    Bottom = 'plane2',
    Middle2 = 'plane3',
}

@Injectable()
export class LoopToolsMaterialService {
    constructor(
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: IScanService,
    ) {}

    public activateMaterial(activate = true) {
        this.scanService.setMaterial(
            activate ? loopTransformMaterial : undefined,
        );
    }

    public deactivateMaterial() {
        this.activateMaterial(false);
    }

    public hideAllPlanes() {
        Object.values(LoopTransformPlanes).forEach(l =>
            this.updatePlaneFromVector(l, null),
        );
    }

    public updatePlaneFromVector(
        loopName: LoopTransformPlanes,
        vector: Float4JS | null,
    ) {
        const loop = loopTransformMaterial.uniforms[loopName].value;

        if (!vector) {
            loop.set(null);
        } else {
            (loop as THREE.Vector4).set(vector.x, vector.y, vector.z, vector.w);
        }
    }

    public updateMainPlaneFromPontAndNormal(
        loop: LoopTransformPlanes,
        position: Float3JS,
        normal: Float3JS,
    ) {
        const plane = new THREE.Plane();
        const normalVector = new THREE.Vector3(normal.x, normal.y, normal.z);
        const positionVector = new THREE.Vector3(
            position.x,
            position.y,
            position.z,
        );
        plane.setFromNormalAndCoplanarPoint(normalVector, positionVector);
        const vector = new THREE.Vector4(
            plane.normal.x,
            plane.normal.y,
            plane.normal.z,
            plane.constant,
        );
        this.updatePlaneFromVector(loop, vector);
    }
}
