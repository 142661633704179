import { LandMarkInOrderData } from '@orthocore-web-mono/feature-landmarks';
import {
    CreateNativeStruct,
    Float3Descriptor,
} from '@orthocore-web-mono/shared-types';

export const enum SpinalLandmarkName {
    PubicBone,
    GreaterTrochanterFemurLeft,
    GreaterTrochanterFemurRight,
    AnteriorSuperiorIliacSpineLeft,
    AnteriorSuperiorIliacSpineRight,
    XiphoidProcessOfSternum,
    ManubriumOfSternum,
    AcromioclavicularLeft,
    AcromioclavicularRight,
    C7,
    ScapulaLeft,
    ScapulaRight,
}

// Note: this is the order that is displayed on the UI, so not in the same order as the native type
export const landmarkNames = [
    SpinalLandmarkName.PubicBone,
    SpinalLandmarkName.GreaterTrochanterFemurLeft,
    SpinalLandmarkName.GreaterTrochanterFemurRight,
    SpinalLandmarkName.AnteriorSuperiorIliacSpineLeft,
    SpinalLandmarkName.AnteriorSuperiorIliacSpineRight,
    SpinalLandmarkName.XiphoidProcessOfSternum,
    SpinalLandmarkName.ManubriumOfSternum,
    SpinalLandmarkName.AcromioclavicularLeft,
    SpinalLandmarkName.AcromioclavicularRight,
    SpinalLandmarkName.C7,
    SpinalLandmarkName.ScapulaLeft,
    SpinalLandmarkName.ScapulaRight,
] as const;

export const SpinalScanLandmarksDescriptor = {
    PubicBone: Float3Descriptor,
    GreaterTrochanterFemurLeft: Float3Descriptor,
    GreaterTrochanterFemurRight: Float3Descriptor,
    AnteriorSuperiorIliacSpineLeft: Float3Descriptor,
    AnteriorSuperiorIliacSpineRight: Float3Descriptor,
    XiphoidProcessOfSternum: Float3Descriptor,
    ManubriumOfSternum: Float3Descriptor,
    AcromioclavicularLeft: Float3Descriptor,
    AcromioclavicularRight: Float3Descriptor,
    C7: Float3Descriptor,
    ScapulaLeft: Float3Descriptor,
    ScapulaRight: Float3Descriptor,
} as const;

export const SpinalScanLandmarks = CreateNativeStruct(
    SpinalScanLandmarksDescriptor,
);

export type SpinalScanLandmarksJS = typeof SpinalScanLandmarks.typeHolder;

export const spinalLandmarksInOrder: LandMarkInOrderData[] = [
    {
        type: SpinalLandmarkName.PubicBone,
        shortName: 'pubic-bone',
        fullName: 'PubicBone',
        icon: 'PubicBone.png',
    },
    {
        type: SpinalLandmarkName.GreaterTrochanterFemurLeft,
        shortName: 'greater-trochanter-of-femur-left',
        fullName: 'GreaterTrochanterFemurLeft',
        icon: 'GreaterTrochanterOfFemurLeft.png',
    },
    {
        type: SpinalLandmarkName.GreaterTrochanterFemurRight,
        shortName: 'greater-trochanter-of-femur-right',
        fullName: 'GreaterTrochanterFemurRight',
        icon: 'GreaterTrochanterOfFemurRight.png',
    },
    {
        type: SpinalLandmarkName.AnteriorSuperiorIliacSpineLeft,
        shortName: 'anterior-superior-iliac-spine-left',
        fullName: 'AnteriorSuperiorIliacSpineLeft',
        icon: 'AnteriorSuperiorIliacSpineLeft.png',
    },
    {
        type: SpinalLandmarkName.AnteriorSuperiorIliacSpineRight,
        shortName: 'anterior-superior-iliac-spine-right',
        fullName: 'AnteriorSuperiorIliacSpineRight',
        icon: 'AnteriorSuperiorIliacSpineRight.png',
    },
    {
        type: SpinalLandmarkName.XiphoidProcessOfSternum,
        shortName: 'xiphoid-proces-sof-sternum',
        fullName: 'XiphoidProcessOfSternum',
        icon: 'XiphoidProcessOfSternum.png',
    },
    {
        type: SpinalLandmarkName.ManubriumOfSternum,
        shortName: 'manubrium-of-sternum',
        fullName: 'ManubriumOfSternum',
        icon: 'ManubriumOfSternum.png',
    },
    {
        type: SpinalLandmarkName.AcromioclavicularLeft,
        shortName: 'acromioclavicular-left',
        fullName: 'AcromioclavicularLeft',
        icon: 'AcromioclavicularLeft.png',
    },
    {
        type: SpinalLandmarkName.AcromioclavicularRight,
        shortName: 'acromioclavicular-right',
        fullName: 'AcromioclavicularRight',
        icon: 'AcromioclavicularRight.png',
    },
    {
        type: SpinalLandmarkName.C7,
        shortName: 'c7',
        fullName: 'C7',
        icon: 'C7.png',
    },
    {
        type: SpinalLandmarkName.ScapulaLeft,
        shortName: 'scapula-left',
        fullName: 'ScapulaLeft',
        icon: 'ScapulaLeft.png',
    },
    {
        type: SpinalLandmarkName.ScapulaRight,
        shortName: 'scapula-right',
        fullName: 'ScapulaRight',
        icon: 'ScapulaRight.png',
    },
];
