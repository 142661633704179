import { Float3JS } from '@orthocore-web-mono/shared-types';
import { Subject, takeUntil } from 'rxjs';
import * as THREE from 'three';

import { MeshLineGeometry } from './mesh-line/mesh-line-geometry';
import { MeshLineMaterial } from './mesh-line/mesh-line-material';
import { SceneService } from './services/scene-data.service';

export interface SplineObjectParams {
    lineWidth?: number;
    lineWidthOrthographic?: number;
    lineWidthIsWorldUnits?: boolean;
    lineColor?: THREE.ColorRepresentation;
    controlPointColors?: THREE.ColorRepresentation[];
    controlPointRadius?: number;
}

interface ControlPointData {
    mesh: THREE.Mesh;
    controlPointIndex: number;
}

const controlPointGeometry = new THREE.IcosahedronGeometry(1, 4);

const defaultLineWidth = 0.3;

export class SplineObject extends THREE.Group {
    private splineGeometry: MeshLineGeometry;
    public readonly splineMaterial: MeshLineMaterial;
    public readonly splineObject: THREE.Mesh;

    public readonly controlPointContainer: THREE.Group;
    public readonly controlPointObjects: ControlPointData[] = [];
    public readonly controlPointMaterials: THREE.MeshBasicMaterial[] = [];
    private controlPointRadius: number;

    private params?: SplineObjectParams;

    private destoyed$ = new Subject<void>();

    constructor(
        private readonly sceneService: SceneService,
        params?: SplineObjectParams,
    ) {
        super();
        this.params = params;

        this.splineGeometry = new MeshLineGeometry();
        this.splineMaterial = new MeshLineMaterial({
            resolution: new THREE.Vector2(1, 1),
            sizeAttenuation: params?.lineWidthIsWorldUnits ?? true ? 1 : 0,
            lineWidth: this.getLineWidth(),
            color: params?.lineColor ?? 0xff0000,
            opacity: 1,
        });

        this.splineObject = new THREE.Mesh(
            this.splineGeometry,
            this.splineMaterial,
        );
        this.add(this.splineObject);

        this.controlPointContainer = new THREE.Group();
        this.add(this.controlPointContainer);

        if (
            params?.controlPointColors !== undefined &&
            params.controlPointColors.length !== 0
        ) {
            for (const color of params.controlPointColors) {
                this.controlPointMaterials.push(
                    new THREE.MeshBasicMaterial({ color }),
                );
            }
        } else {
            this.controlPointMaterials.push(
                new THREE.MeshBasicMaterial({ color: 0x0086ff }),
            );
        }

        this.controlPointRadius = params?.controlPointRadius ?? 0.3;

        this.frustumCulled = false;

        window.addEventListener('resize', () => this.onScreenResize());
        this.sceneService.cameraModeChanged$
            .pipe(takeUntil(this.destoyed$))
            .subscribe(() => this.onScreenResize());
        this.onScreenResize();
    }

    public dispose() {
        this.removeFromParent();
        this.splineMaterial.dispose();
        this.splineGeometry.dispose();
        this.controlPointMaterials.forEach(material => material.dispose());

        window.removeEventListener('resize', this.onScreenResize);
        this.destoyed$.next();
        this.destoyed$.complete();
    }

    public updateGeometry(
        controlPoints: THREE.Vector3[] | Float3JS[] | Float32Array | null,
        samplePoints: THREE.Vector3[] | Float32Array | null,
    ) {
        if (controlPoints !== null) {
            if (controlPoints instanceof Float32Array) {
                const newControlPoints: Float3JS[] = [];

                for (let i = 0; i < controlPoints.length; i += 3) {
                    newControlPoints.push({
                        x: controlPoints[i],
                        y: controlPoints[i + 1],
                        z: controlPoints[i + 2],
                    });
                }

                controlPoints = newControlPoints;
            }

            while (this.controlPointObjects.length < controlPoints.length) {
                const obj = new THREE.Mesh(
                    controlPointGeometry,
                    this.controlPointMaterials[0],
                );
                obj.scale.setScalar(this.controlPointRadius);
                this.controlPointContainer.add(obj);

                this.controlPointObjects.push({
                    mesh: obj,
                    controlPointIndex: -1, // Updated later
                });
            }
            while (this.controlPointObjects.length > controlPoints.length) {
                const last = this.controlPointObjects.slice(-1)[0];
                last.mesh.removeFromParent();
                this.controlPointObjects.pop();
            }

            for (let i = 0; i < this.controlPointObjects.length; ++i) {
                const obj = this.controlPointObjects[i];
                obj.controlPointIndex = i;

                const point = controlPoints[i];

                if (point instanceof THREE.Vector3) {
                    obj.mesh.position.copy(point);
                } else {
                    obj.mesh.position.set(point.x, point.y, point.z);
                }
            }
        }

        if (samplePoints !== null) {
            this.splineGeometry.setPoints(samplePoints);
        }
    }

    public setSplineVisibility(visible: boolean) {
        this.splineObject.visible = visible;
    }

    public setSplineOpacity(opacity: number) {
        this.splineMaterial.opacity = opacity;
        this.splineMaterial.transparent = opacity !== 1;
        this.splineMaterial.depthWrite = opacity === 1;
    }

    public setControlPointVisibility(visible: boolean) {
        this.controlPointContainer.visible = visible;
    }

    public getControlPointVisibility() {
        return this.controlPointContainer.visible;
    }

    public setControlPointColorIndex(
        controlPointIndex: number,
        colorIndex: number,
    ) {
        this.controlPointObjects[controlPointIndex].mesh.material =
            this.controlPointMaterials[colorIndex];
    }

    public getNumControlPoints() {
        return this.controlPointObjects.length;
    }

    public getControlPointLocalPosition(index: number) {
        return this.getControlPointData(index).mesh.position;
    }

    public getControlPointData(index: number): ControlPointData {
        return this.controlPointObjects[index];
    }

    public clearSplineData() {
        this.updateGeometry([], []);
    }

    private getLineWidth() {
        if (
            this.sceneService.activeCamera instanceof THREE.OrthographicCamera
        ) {
            return (
                this.params?.lineWidthOrthographic ??
                (this.params?.lineWidth ?? defaultLineWidth) / 15
            );
        } else {
            return this.params?.lineWidth ?? defaultLineWidth;
        }
    }

    private onScreenResize = () => {
        this.splineMaterial.lineWidth = this.getLineWidth();
        this.sceneService.renderer.getSize(this.splineMaterial.resolution);
        this.splineMaterial.needsUpdate = true;
    };
}
