import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FittingPlane } from '@orthocore-web-mono/shared-types';
import { degreeUnit, value2percent } from '@orthocore-web-mono/shared/utils';
import { Subject, map, takeUntil } from 'rxjs';

import { ImageImportService, Stage } from '../image-import.service';

@Component({
    selector: 'leo-image-import',
    templateUrl: './image-import.component.html',
    styleUrl: './image-import.component.scss',
})
export class ImageImportComponent implements OnInit, OnDestroy {
    public degreeUnit = degreeUnit;
    public value2percent = value2percent;
    public Stage = Stage;

    public selectedPlaneIndex = 0;
    public readonly fittingPlaneLocales = [
        'MESH_EDIT.IMPORT_IMAGE.CORONAL',
        'MESH_EDIT.IMPORT_IMAGE.SAGGITAL',
        'MESH_EDIT.IMPORT_IMAGE.TRANSVERSE',
    ];

    @Output() public imageAdded = new EventEmitter<void>();

    private readonly planes = Object.values(FittingPlane);

    private destroyed$ = new Subject<void>();

    public stage$ = this.service.stage$;
    public finished$ = this.service.stage$.pipe(
        map(stage =>
            [Stage.ImageAddedToMesh, Stage.ImageAddedToScene].includes(stage),
        ),
    );

    public canSetOpacity$ = this.service.stage$.pipe(
        map(stage =>
            [Stage.ImageImported, Stage.ImageAddedToScene].includes(stage),
        ),
    );

    constructor(private readonly service: ImageImportService) {}
    ngOnInit(): void {
        this.service.addedImageToMesh$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.imageAdded.emit();
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public onInputChanged(event: Event) {
        const fileInput = <HTMLInputElement>event.target;
        if (!fileInput.files?.length) return;

        const [file] = fileInput.files;

        new Promise<void>(resolve => {
            const reader = new FileReader();
            reader.addEventListener(
                'loadend',
                (ev: ProgressEvent<FileReader>) => {
                    if (!ev.target?.result) return resolve();
                    this.service.loadImageFromImportToCanvas(
                        <string>ev.target.result,
                    );
                },
            );
            reader.readAsDataURL(file);
            fileInput.value = '';
        }).catch(e => console.error(e));
    }

    onSelectedFittingPlaneChange(index: number) {
        this.service.changeFittingPlane(this.planes[index]);
    }

    onOpacityChange(value: number) {
        this.service.setOpacity(value ?? 1);
    }

    applyImageToMesh() {
        this.service.startApplyImageToMesh();
    }

    addImageToScene() {
        this.service.startAddImageToScene();
    }

    repositionImage() {
        this.service.repositionImage();
    }

    cancel() {
        this.service.cancel();
    }
}
