import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
    MeasurementMode,
    ScanCleanSelectionMode,
} from '@orthocore-web-mono/shared-types';
import { Subject } from 'rxjs';

import * as EditorActions from './editor.actions';
import * as EditorSelectors from './editor.selectors';

@Injectable()
export class EditorFacade {
    private readonly store = inject(Store);

    engineLoaded$ = this.store.pipe(select(EditorSelectors.selectEngineLoaded));
    showGroundPlane$ = this.store.pipe(
        select(EditorSelectors.selectShowGroundPlane),
    );

    modelLoaded$ = this.store.pipe(select(EditorSelectors.selectLoadedModel));

    activeCanvasEventHandler$ = this.store.pipe(
        select(EditorSelectors.selectActiveHandler),
    );

    showLandmarks$ = this.store.pipe(
        select(EditorSelectors.selectShowLandmarks),
    );

    showMeasurementsPanel$ = this.store.pipe(
        select(EditorSelectors.selectShowMeasurementsPanel),
    );

    measurementMode$ = this.store.pipe(
        select(EditorSelectors.selectMeasurementMode),
    );

    clearMeasurentPoints$ = new Subject<void>();

    measuredDistance$ = this.store.pipe(
        select(EditorSelectors.selectMeasuredDistance),
    );

    measuredGirth$ = this.store.pipe(
        select(EditorSelectors.selectMeasuredGirth),
    );

    // scanSettingsScaleUnit$ = this.store.pipe(
    //     select(EditorSelectors.selectScanSettingsScaleUnit),
    // );

    // scale$ = this.store.pipe(select(EditorSelectors.selectScale));
    opacity$ = this.store.pipe(select(EditorSelectors.selectOpacity));

    scanTransformMode$ = this.store.pipe(
        select(EditorSelectors.selectScanTransformMode),
    );

    scanClearSelectionMode$ = this.store.pipe(
        select(EditorSelectors.selectScanClearSelectionMode),
    );

    public engineLoaded() {
        this.store.dispatch(EditorActions.loadEngineSuccess());
    }

    public modelLoaded() {
        this.store.dispatch(EditorActions.loadedModel());
    }

    public changeShowGroundPlane(value: boolean) {
        this.store.dispatch(EditorActions.changeShowGroundPlane({ value }));
    }

    public changeShowLandmarksChanged(value: boolean) {
        this.store.dispatch(EditorActions.changeShowLandmarks({ value }));
    }

    public changeShowMeasurementsPanel(value: boolean) {
        this.store.dispatch(
            EditorActions.changeShowMeasurementsPanel({ value }),
        );
    }

    public changeMeasurementMode(mode: MeasurementMode) {
        this.store.dispatch(EditorActions.setMeasurementMode({ mode }));
    }

    public clearMeasurementPoints() {
        this.store.dispatch(EditorActions.clearMeasurementPoints());
        this.clearMeasurentPoints$.next();
    }

    public changeMeasuredDistance(value?: number) {
        this.store.dispatch(EditorActions.setMeasuredDistance({ value }));
    }

    public changeMeasuredGirth(value?: number) {
        this.store.dispatch(EditorActions.setMeasuredGirth({ value }));
    }

    public setOpacity(value: number) {
        this.store.dispatch(EditorActions.setOpacity({ value }));
    }

    public changeScanTransformMode(mode?: 'translate' | 'rotate') {
        this.store.dispatch(EditorActions.setScanTransformMode({ mode }));
    }

    public scanTransformRotateFinished() {
        this.store.dispatch(EditorActions.scanTransformRotateFinished());
    }

    public changeScanClearSelectionMode(mode: ScanCleanSelectionMode) {
        this.store.dispatch(
            EditorActions.changeScanClearSelectionMode({ mode }),
        );
    }
}
