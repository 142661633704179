/* eslint-disable max-params-no-constructor/max-params-no-constructor */
import { Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    EngineWorkerConnectorService,
} from '@orthocore-web-mono/feature-core-services';
import {
    DestroyMeshMessage,
    EnablerServiceGetRandomValueMessage,
    EnablerServiceGetRandomValueResponse,
    EnablerServiceVerifyMessage,
    EnablerServiceVerifyMessageResponse,
    FinishLoopBendRequest,
    FinishLoopScaleRequest,
    FinishLoopStretchRequest,
    FinishLoopTwistRequest,
    Float2JS,
    Float3JS,
    Float4JS,
    GeometryUpdateFlags,
    HistoryOperation,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    ImportMeshMessage,
    LoopTransformFinishMessage,
    LoopTransformParamsJS,
    LoopTransformUpdateMessage,
    LoopTransformUpdateResult,
    MeasureFootScanMessage,
    MeasureFootScanResult,
    MeshCalculateBoundingBoxMessage,
    MeshCalculateBoundingBoxResult,
    MeshData,
    MeshDataOptional,
    MeshExportFileFormat,
    MeshImportFileFormat,
    MeshImportOptions,
    MeshImportResult,
    MeshTransformMessage,
    MeshTransformResult,
    PressureReliefFinish,
    PressureReliefGetShapeInfos,
    PressureReliefGetShapeInfosResult,
    PressureReliefGetShapePointsResult,
    PressureReliefShapeData,
    PressureReliefStart,
    PressureReliefUpdate,
    Ptr,
    RaycasterCreateMessage,
    RaycasterCreateResult,
    RaycasterDestroyMessage,
    RaycasterRaycastMessage,
    RaycasterRaycastResult,
    ScanPreparationParamsJS,
    ScanPreparationUpdateMessage,
    ScanPreparationUpdateResult,
    SculptFinishMessage,
    SculptParametersJS,
    SculptStartMessage,
    SculptUpdateMessage,
    SculptUpdateResponse,
    SetSculptParametersMessage,
    StartLoopBendRequest,
    StartLoopScaleRequest,
    StartLoopStretchRequest,
    StartLoopTwistRequest,
    ToolLoopBendParametersJS,
    ToolLoopOutJS,
    ToolLoopScaleParametersJS,
    ToolLoopStretchParametersJS,
    ToolLoopTwistParametersJS,
    UpdateLoopBendRequest,
    UpdateLoopBendResponse,
    UpdateLoopScaleRequest,
    UpdateLoopScaleResponse,
    UpdateLoopStretchRequest,
    UpdateLoopStretchResponse,
    UpdateLoopTwistRequest,
    UpdateLoopTwistResponse,
    WorkerRequest,
    WorkerResponse,
} from '@orthocore-web-mono/shared-types';

@Injectable()
export abstract class EngineWorkerServiceBase<
    K extends IEngineWorkerServiceDataBase,
> implements IEngineWorkerService<K>
{
    protected msgId = 0;

    protected waitingMessages = new Map<
        number,
        (response: WorkerResponse) => void
    >();

    constructor(
        private readonly connector: EngineWorkerConnectorService,
        private readonly status: EditorStageStatusService,
    ) {}
    abstract getLandmarks(): Promise<any>;
    abstract setScan(meshPtr: Ptr): Promise<void>;
    abstract scanExport(
        exportFlags: GeometryUpdateFlags,
    ): Promise<MeshDataOptional>;
    abstract enablerServiceBeginExport(): Promise<string>;
    abstract enablerServiceExport(): Promise<boolean>;
    abstract enablerServiceEndExport(
        response: string,
        format: MeshExportFileFormat,
    ): Promise<Uint8Array>;
    abstract getParameters(): Promise<unknown>;
    abstract getBuiltinDesigns(): Promise<unknown[]>;
    abstract selectBuiltinDesign(designIndex: number): Promise<void>;
    abstract getContourSplineInfo(
        info: unknown,
        isInnerShell: boolean,
    ): Promise<Map<number, unknown[]>>;
    abstract setContourSplineData(
        data: unknown,
        isInnerShell: boolean,
    ): Promise<void>;
    abstract scanRaycast(
        rayOrigin: Float3JS,
        rayDirection: Float3JS,
        includeForefootFull?: boolean,
    ): Promise<Float3JS | null>;
    abstract setScanLandmark(name: number, position: Float3JS): Promise<void>;
    abstract snapshotScanLandmarks(): Promise<void>;
    abstract applySnapshottedScanLandmarks(): Promise<void>;

    abstract transformToMainAxis(): Promise<void>;

    abstract getScanHistoryState(): Promise<number>;
    abstract setScanHistoryState(
        operation: HistoryOperation,
        targetState: number,
    ): Promise<boolean>;
    abstract scanTransform(
        translation?: Float3JS,
        rotation?: Float4JS,
        scale?: number,
    ): Promise<void>;
    abstract scanUndoRedo(isUndo: boolean): Promise<{
        success: boolean;
        stillCanUndo: boolean;
        stillCanRedo: boolean;
    }>;

    public onMessage = this.connector.onMessage.bind(this.connector);
    protected sendMessage = this.connector.sendMessage.bind(this.connector);
    protected sendSimpleMessage = this.connector.sendSimpleMessage.bind(
        this.connector,
    );

    async importMesh(
        format: MeshImportFileFormat,
        fileBytes: Uint8Array,
        options: MeshImportOptions,
    ) {
        const { meshData, ptr } = await this.sendSimpleMessage<
            ImportMeshMessage,
            MeshImportResult
        >('importMesh', {
            format: format,
            fileBytes,
            options,
        });
        return { meshData, ptr };
    }

    abstract exportMesh(arg0: any): any;
    // async exportMesh(format: MeshExportFileFormat, mesh: MeshData) {
    //     return (
    //         await this.sendSimpleMessage<ExportMeshMessage, MeshExportResult>(
    //             'exportMesh',
    //             { format, meshPtr: mesh.nativePtr },
    //         )
    //     ).fileBytes;
    // }

    async destroyMesh(meshPtr: Ptr) {
        await this.sendSimpleMessage<DestroyMeshMessage>('destroyMesh', {
            meshPtr,
        });
    }

    abstract generateMesh(): Promise<MeshData[]>;

    async setSculptParameters(parameters: SculptParametersJS) {
        await this.sendSimpleMessage<SetSculptParametersMessage>(
            'setSculptParameters',
            { parameters },
        );
    }

    async sculptStart() {
        await this.sendSimpleMessage<SculptStartMessage>('sculptStart', {});
    }

    async sculptUpdate(
        rayOrigin: Float3JS,
        rayDirection: Float3JS,
        raycastOnly: boolean,
    ) {
        return (
            await this.sendSimpleMessage<
                SculptUpdateMessage,
                SculptUpdateResponse
            >('sculptUpdate', {
                rayOrigin,
                rayDirection,
                raycastOnly,
            })
        ).hitPoint;
    }

    async sculptFinish() {
        await this.sendSimpleMessage<SculptFinishMessage>('sculptFinish', {});
    }

    async loopTransformUpdate(params: LoopTransformParamsJS) {
        const { plane0, plane1, plane2 } = await this.sendSimpleMessage<
            LoopTransformUpdateMessage,
            LoopTransformUpdateResult
        >('loopTransformUpdate', { params });

        return { plane0, plane1, plane2 };
    }

    async loopTransformFinish(isCancel: boolean) {
        await this.sendSimpleMessage<LoopTransformFinishMessage>(
            'loopTransformFinish',
            { isCancel },
        );
    }

    async getPressureReliefShapeInfos() {
        const { shapeNames } = await this.sendSimpleMessage<
            PressureReliefGetShapeInfos,
            PressureReliefGetShapeInfosResult
        >('pressureReliefGetShapeInfos', {});
        return { shapeNames };
    }

    async startPressureRelief(builtinShapeIndex: number) {
        const { shape, sampled } = await this.sendSimpleMessage<
            PressureReliefStart,
            PressureReliefGetShapePointsResult
        >('pressureReliefStartMessage', { builtinShapeIndex });

        return { shape, sampled };
    }

    async updatePressureRelief(shape: PressureReliefShapeData) {
        const { sampled } = await this.sendSimpleMessage<
            PressureReliefUpdate,
            PressureReliefGetShapePointsResult
        >('pressureReliefUpdateMessage', { shape });
        return { sampled };
    }

    async finishPressureRelief(isCancel: boolean) {
        await this.sendSimpleMessage<PressureReliefFinish>(
            'pressureReliefFinishMessage',
            { isCancel },
        );
    }

    async updateScanPreparation(
        params: ScanPreparationParamsJS,
        selectionPoints: Float2JS[] | null,
    ) {
        const { updatedMesh, selection } = await this.sendSimpleMessage<
            ScanPreparationUpdateMessage,
            ScanPreparationUpdateResult
        >('scanPreparationUpdateMessage', { params, selectionPoints });

        return { updatedMesh, selection };
    }

    async measureScan(
        points: Float3JS[],
        isDistanceMeasurement: boolean,
        sampleNormalOffset: number,
    ) {
        const { result } = await this.sendSimpleMessage<
            MeasureFootScanMessage,
            MeasureFootScanResult
        >('measureFootScan', {
            points,
            isDistanceMeasurement,
            sampleNormalOffset,
        });

        return result;
    }

    async meshCalculateBoundingBox(meshPtrs: Ptr[]) {
        const { minP, maxP } = await this.sendSimpleMessage<
            MeshCalculateBoundingBoxMessage,
            MeshCalculateBoundingBoxResult
        >('meshCalculateBoundingBox', { meshPtrs: meshPtrs });

        return { minP, maxP };
    }

    async meshTransform(
        meshPtr: Ptr,
        returnTransformedMeshData: boolean,
        translation?: Float3JS,
        rotation?: Float4JS,
        scale?: number,
    ) {
        const { transformedMeshData } = await this.sendSimpleMessage<
            MeshTransformMessage,
            MeshTransformResult
        >('meshTransform', {
            meshPtr,
            returnTransformedMeshData,
            translation,
            rotation,
            scale,
        });

        return transformedMeshData;
    }

    async raycasterCreate(meshPtr: Ptr, buildStrategy = 1) {
        return (
            await this.sendSimpleMessage<
                RaycasterCreateMessage,
                RaycasterCreateResult
            >('raycasterCreate', { meshPtr, buildStrategy })
        ).ptr;
    }

    async raycasterDestroy(ptr: Ptr) {
        await this.sendSimpleMessage<RaycasterDestroyMessage>(
            'raycasterDestroy',
            {
                ptr,
            },
        );
    }

    async raycasterRaycast(
        ptr: Ptr,
        rayOrigin: Float3JS,
        rayDirection: Float3JS,
    ) {
        return (
            await this.sendSimpleMessage<
                RaycasterRaycastMessage,
                RaycasterRaycastResult
            >('raycasterRaycast', { ptr, rayOrigin, rayDirection })
        ).tuv;
    }

    async enablerServiceGetRandomValue() {
        const { randomValue } = await this.sendSimpleMessage<
            EnablerServiceGetRandomValueMessage,
            EnablerServiceGetRandomValueResponse
        >('enablerServiceGetRandomValue', {});
        return randomValue;
    }

    async enablerServiceVerifyMessage(message: string) {
        const { success } = await this.sendSimpleMessage<
            EnablerServiceVerifyMessage,
            EnablerServiceVerifyMessageResponse
        >('enablerServiceVerify', { message });

        return success;
    }

    async startLoopBend(): Promise<void> {
        await this.sendSimpleMessage<StartLoopBendRequest>(
            'startLoopBendRequest',
            {},
        );
    }

    async updateLoopBend(
        params: ToolLoopBendParametersJS,
    ): Promise<ToolLoopOutJS> {
        const { out } = await this.sendSimpleMessage<
            UpdateLoopBendRequest,
            UpdateLoopBendResponse
        >('updateLoopBendRequest', { params });
        return out;
    }

    async finishLoopBend(isCancel: boolean): Promise<void> {
        await this.sendSimpleMessage<FinishLoopBendRequest>(
            'finishLoopBendRequest',
            { isCancel },
        );
    }

    async startLoopTwist(): Promise<void> {
        await this.sendSimpleMessage<StartLoopTwistRequest>(
            'startLoopTwistRequest',
            {},
        );
    }

    async updateLoopTwist(
        params: ToolLoopTwistParametersJS,
    ): Promise<ToolLoopOutJS> {
        const { out } = await this.sendSimpleMessage<
            UpdateLoopTwistRequest,
            UpdateLoopTwistResponse
        >('updateLoopTwistRequest', { params });
        return out;
    }

    async finishLoopTwist(isCancel: boolean): Promise<void> {
        await this.sendSimpleMessage<FinishLoopTwistRequest>(
            'finishLoopTwistRequest',
            { isCancel },
        );
    }

    async startLoopStretch(): Promise<void> {
        await this.sendSimpleMessage<StartLoopStretchRequest>(
            'startLoopStretchRequest',
            {},
        );
    }

    async updateLoopStretch(
        params: ToolLoopStretchParametersJS,
    ): Promise<ToolLoopOutJS> {
        const { out } = await this.sendSimpleMessage<
            UpdateLoopStretchRequest,
            UpdateLoopStretchResponse
        >('updateLoopStretchRequest', { params });
        return out;
    }

    async finishLoopStretch(isCancel: boolean): Promise<void> {
        await this.sendSimpleMessage<FinishLoopStretchRequest>(
            'finishLoopStretchRequest',
            { isCancel },
        );
    }

    async startLoopScale(): Promise<void> {
        await this.sendSimpleMessage<StartLoopScaleRequest>(
            'startLoopScaleRequest',
            {},
        );
    }

    async updateLoopScale(
        params: ToolLoopScaleParametersJS,
    ): Promise<ToolLoopOutJS> {
        const { out } = await this.sendSimpleMessage<
            UpdateLoopScaleRequest,
            UpdateLoopScaleResponse
        >('updateLoopScaleRequest', { params });
        return out;
    }

    async finishLoopScale(isCancel: boolean): Promise<void> {
        await this.sendSimpleMessage<FinishLoopScaleRequest>(
            'finishLoopScaleRequest',
            { isCancel },
        );
    }
}
