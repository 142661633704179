import { ChangeDetectorRef, Component } from '@angular/core';

import { LoopToolBaseComponent } from '../loop-tool-base.component';
import { BendLoopToolService } from './bend-loop-tool.service';

enum Region {
    TOP = 0,
    BOTTOM = 1,
    ALL = 2,
}

@Component({
    selector: 'leo-bend-loop-tool',
    templateUrl: './bend-loop-tool.component.html',
    styleUrl: './bend-loop-tool.component.scss',
})
export class BendLoopToolComponent extends LoopToolBaseComponent<BendLoopToolService> {
    Region = Region;

    public regions = [
        'MESH_EDIT.LOOP_TOOLS.TOP',
        'MESH_EDIT.LOOP_TOOLS.BOTTOM',
        'MESH_EDIT.LOOP_TOOLS.ALL',
    ];

    public selectedRegionIndex = 0;

    constructor(change: ChangeDetectorRef, service: BendLoopToolService) {
        super(change, service);
    }

    onRegionChanged(index: number) {
        this.selectedRegionIndex = index;
        this.service.params.isBendTop = [Region.ALL, Region.TOP].includes(
            index,
        );
        this.service.params.isBendBottom = [Region.ALL, Region.BOTTOM].includes(
            index,
        );
        this.params.isBendModeMirror = true;
        this.service.update();
    }
}
