<leo-styled-toggle
    [label]="'Enable component'"
    [checked]="isEnabled"
    (checkedChange)="onChange('isEnabled', $event, true)"></leo-styled-toggle>

<leo-styled-slider-with-input
    [title]="'DESIGN.SIZE_X' | translate"
    [min]="1"
    [max]="50"
    [value]="4"
    [step]="0.1"
    [value]="width"
    (valueChange)="onChange('sizeX', $event, true)"
    [unit]="mmUnit"
    [multiplier]="mm2cm"></leo-styled-slider-with-input>
<leo-styled-slider-with-input
    [title]="'DESIGN.SIZE_Y' | translate"
    [min]="1"
    [max]="50"
    [value]="30"
    [step]="0.1"
    [value]="height"
    (valueChange)="onChange('sizeY', $event, true)"
    [unit]="mmUnit"
    [multiplier]="mm2cm"></leo-styled-slider-with-input>
<leo-styled-slider-with-input
    [title]="'DESIGN.ROTATION' | translate"
    [min]="-180"
    [max]="180"
    [step]="0.1"
    [value]="rotation"
    (valueChange)="onChange('rotation', $event, true)"
    [unit]="degreeUnit"
    [multiplier]="deg2rad"></leo-styled-slider-with-input>
<leo-styled-slider-with-input
    [title]="'DESIGN.ROUNDING' | translate"
    [min]="0"
    [max]="50"
    [value]="2.0"
    [step]="0.1"
    [value]="rounding"
    (valueChange)="onChange('rounding', $event, true)"
    [unit]="mmUnit"
    [multiplier]="mm2cm"></leo-styled-slider-with-input>
