import {
    deg2rad,
    degreeUnit,
    mm2cm,
    mmUnit,
} from '@orthocore-web-mono/shared/utils';
import {
    LocalSplineContourData,
    SpinalDesignService,
} from '@orthocore-web-mono/spinal-feature-design';

export abstract class ComponentSettingsBaseComponent {
    public mmUnit = mmUnit;
    public degreeUnit = degreeUnit;
    public mm2cm = mm2cm;
    public deg2rad = deg2rad;

    public abstract readonly service: SpinalDesignService;

    onChange<
        TKey extends keyof LocalSplineContourData,
        TValue extends LocalSplineContourData[TKey],
    >(key: TKey, value: TValue, updateVisuals = false) {
        this.service.updateSplineParameterToEngine(key, value, updateVisuals);
    }
}
