import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

export type StyledButtonStyles = 'default' | 'gray';

@Component({
    selector: 'leo-styled-button',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './styled-button.component.html',
    styleUrl: './styled-button.component.scss',
})
export class StyledButtonComponent {
    @Input() leoStyle: StyledButtonStyles = 'default';
    @Input() disabled: boolean | null = false;
}
