<leo-styled-dropdown
    *ngIf="activeProcess === PressureReliefStep.Creating"
    [title]="'MESH_EDIT.PRESSURE_RELIEF.SHAPE' | translate"
    [options]="pressureReliefShapeNames"
    [(selected)]="selected"></leo-styled-dropdown>

<leo-styled-button
    *ngIf="activeProcess === PressureReliefStep.Creating"
    (click)="onAddShapeClick()"
    >{{ 'MESH_EDIT.PRESSURE_RELIEF.ADD_SHAPE' | translate }}</leo-styled-button
>

<leo-styled-slider-with-input
    *ngIf="activeProcess === PressureReliefStep.Editing"
    [title]="'MESH_EDIT.PRESSURE_RELIEF.DEPTH' | translate"
    [min]="-20"
    [max]="20"
    [value]="depth"
    (valueChange)="onDepthChange($event)"
    [step]="0.1"
    [multiplier]="mm2cm"
    [unit]="mmUnit"></leo-styled-slider-with-input>
<leo-styled-slider-with-input
    *ngIf="
        activeProcess === PressureReliefStep.Editing &&
        selectedInnerPointIndex !== undefined
    "
    [title]="'MESH_EDIT.PRESSURE_RELIEF.POINT_HEIGHT' | translate"
    [min]="0"
    [max]="150"
    [value]="height"
    (valueChange)="onHeightChange($event)"
    [step]="0.1"
    [multiplier]="value2percent"
    [unit]="percentUnit"></leo-styled-slider-with-input>

<!-- <div
    *ngIf="activeProcess === PressureReliefStep.Editing"
    class="pressure-info-box">
    <img alt="info" src="assets/icons/info.svg" />
    <p>{{ 'MESH_EDIT.PRESSURE_RELIEF.CTRL_INFO' | translate }}</p>
</div> -->

<leo-styled-button
    *ngIf="activeProcess === PressureReliefStep.Editing"
    (click)="onFinish()">
    {{ 'APPLY' | translate }}
</leo-styled-button>
<leo-styled-button
    *ngIf="activeProcess === PressureReliefStep.Editing"
    (click)="onCancel()">
    {{ 'CANCEL' | translate }}
</leo-styled-button>
