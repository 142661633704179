import { Inject, Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    NavigationService,
} from '@orthocore-web-mono/feature-core-services';
import { LandmarksService } from '@orthocore-web-mono/feature-landmarks';
import { UndoRedoService } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    SCAN_SERVICE_TOKEN,
    ToolLoopStretchModeJS,
    ToolLoopStretchParametersJS,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';

import { LoopTool } from '../loop-tool';
import { LoopToolBaseService } from '../loop-tool-base.service';
import {
    LoopToolsMaterialService,
    LoopTransformPlanes,
} from '../loop-tools-material.service';
import { MeasurementBO } from '../measurements/measurement.bo';

const depaultParamsThatChangeOnReset = {
    stretchAmount: 0,
};
const depaultParamsThatStayOnReset = {
    stretchMode: ToolLoopStretchModeJS.Double,
    stretchPos: 0.5,
    stretchDistTop: 0.15,
    stretchDistBottom: 0.15,
    stretchMiddleSize: 0,
    isStretchModeBottom: false,
};

@Injectable()
export class StretchLoopToolService extends LoopToolBaseService<ToolLoopStretchParametersJS> {
    protected sectionName = LoopTool.Stretch;

    constructor(
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        @Inject(SCAN_SERVICE_TOKEN)
        scanService: IScanService,
        sceneService: SceneService,
        undoRedoService: UndoRedoService,
        nav: NavigationService,
        loopToolsMaterialService: LoopToolsMaterialService,
        status: EditorStageStatusService,
        landmarks: LandmarksService,
    ) {
        super(
            engine,
            scanService,
            sceneService,
            undoRedoService,
            nav,
            loopToolsMaterialService,
            status,
            landmarks,
            depaultParamsThatChangeOnReset,
            depaultParamsThatStayOnReset,
        );
    }

    protected sendStartToEngine = async () =>
        await this.engine.startLoopStretch();
    protected sendUpdateToEngine = async (
        params: ToolLoopStretchParametersJS,
    ) => await this.engine.updateLoopStretch(params);
    protected sendFinishToEngine = async (isCancel: boolean) =>
        await this.engine.finishLoopStretch(isCancel);

    protected setPlanesOnMaterial(measurements: MeasurementBO): void {
        this.loopToolsMaterialService.updatePlaneFromVector(
            LoopTransformPlanes.Top,
            measurements.topPlane,
        );

        this.loopToolsMaterialService.updatePlaneFromVector(
            LoopTransformPlanes.Bottom,
            measurements.bottomPlane,
        );

        if (this.params.stretchMode === ToolLoopStretchModeJS.Triple) {
            this.loopToolsMaterialService.updatePlaneFromVector(
                LoopTransformPlanes.Middle,
                measurements.middleTopPlane,
            );

            this.loopToolsMaterialService.updatePlaneFromVector(
                LoopTransformPlanes.Middle2,
                measurements.middleBottomPlane,
            );
        }
    }
}
