import { ChangeDetectorRef, Component } from '@angular/core';
import { ToolLoopTwistModeJS } from '@orthocore-web-mono/shared-types';

import { LoopToolBaseComponent } from '../loop-tool-base.component';
import { TwistLoopToolService } from './twist-loop-tool.service';

@Component({
    selector: 'leo-twist-loop-tool',
    templateUrl: './twist-loop-tool.component.html',
    styleUrl: './twist-loop-tool.component.scss',
})
export class TwistLoopToolComponent extends LoopToolBaseComponent<TwistLoopToolService> {
    Mode = ToolLoopTwistModeJS;

    public modes = [
        'MESH_EDIT.LOOP_TOOLS.TOP',
        'MESH_EDIT.LOOP_TOOLS.MIDDLE',
        'MESH_EDIT.LOOP_TOOLS.BOTTOM',
    ];

    constructor(change: ChangeDetectorRef, service: TwistLoopToolService) {
        super(change, service);
    }

    public onModeChanged(event: number) {
        this.params.twistMode = this.mapMode(event);
        this.service.update();
    }

    private mapMode(index: number) {
        switch (index) {
            case 0:
                return ToolLoopTwistModeJS.Top;
            case 1:
                return ToolLoopTwistModeJS.Middle;
            case 2:
                return ToolLoopTwistModeJS.Bottom;
        }
        return ToolLoopTwistModeJS.Top;
    }
}
