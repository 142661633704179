import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureUndoRedoResetModule } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    AxisSlidersComponent,
    CollapsingGroupComponent,
    CollapsingSectionComponent,
    HorizontalSeparatorComponent,
    StyledButtonComponent,
    StyledDropdownComponent,
    StyledToggleComponent,
} from '@orthocore-web-mono/shared-ui';

import { ScanCleanService } from './scan-clean.service';
import { ScanSettingsComponent } from './scan-settings.component';
import { ScanSettingsService } from './scan-settings.service';
import { ScreenSelectionLayerComponent } from './screen-selection-layer/screen-selection-layer.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FeatureUndoRedoResetModule,

        HorizontalSeparatorComponent,
        CollapsingGroupComponent,
        CollapsingSectionComponent,
        AxisSlidersComponent,
        StyledToggleComponent,
        StyledDropdownComponent,
        StyledButtonComponent,
    ],
    declarations: [ScanSettingsComponent, ScreenSelectionLayerComponent],
    providers: [ScanSettingsService, ScanCleanService],
    exports: [ScanSettingsComponent, ScreenSelectionLayerComponent],
})
export class FeatureScanEditModule {}
