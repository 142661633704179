<div class="container">
    <slot name="title">{{ title }}</slot>
    <div class="dropdown-container">
        <select class="value-selector" (change)="onSelectionChanged($event)">
            <ng-template [ngIf]="localise">
                <option
                    *ngFor="let option of options; let i = index"
                    [selected]="i === selected"
                    [value]="i">
                    {{ option | translate }}
                </option>
            </ng-template>

            <ng-template [ngIf]="!localise">
                <option
                    *ngFor="let option of options; let i = index"
                    [selected]="i === selected"
                    [value]="i">
                    {{ option }}
                </option>
            </ng-template>
        </select>
        <div class="dropdown-arrow"></div>
    </div>
</div>
