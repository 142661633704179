import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { NavigationService } from '@orthocore-web-mono/feature-core-services';
import {
    ScanCleanSelectionMode,
    ScreenPoint,
    defaultScanPreparationParams,
    unreachable,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import { EditorFacade } from '@orthocore-web-mono/state';
import { cloneDeep } from 'lodash';
import {
    Subject,
    distinctUntilChanged,
    filter,
    map,
    race,
    takeUntil,
} from 'rxjs';

import { ScanSettingSections } from '../constants';
import { ScanCleanService } from '../scan-clean.service';

const brushSize = defaultScanPreparationParams.brushSize / 2;

@Component({
    selector: 'leo-screen-selection-layer',
    templateUrl: './screen-selection-layer.component.html',
    styleUrl: './screen-selection-layer.component.scss',
})
export class ScreenSelectionLayerComponent implements OnDestroy {
    @ViewChild('svg')
    svg!: ElementRef;

    @ViewChild('path')
    path!: ElementRef;

    @ViewChild('group')
    brushGroup!: ElementRef;

    public selectionFillColor = '#0086ff40';
    public selectionStrokeColor = '#0086ff';

    public readonly selectionOutline: ScreenPoint[] = [];
    public selectionMode: ScanCleanSelectionMode = ScanCleanSelectionMode.Rect;

    private lassoPathSegmentsCache: string[] = [];
    private destroyed$ = new Subject<void>();

    private selecting = false;

    private isOnSection$ = this.nav.selectedSection$.pipe(
        map(section => section === ScanSettingSections.ClearSectionName),
        distinctUntilChanged(),
    );

    private createSubs$ = this.isOnSection$.pipe(filter(v => v));
    private destroySubs$ = race([
        this.destroyed$,
        this.isOnSection$.pipe(filter(v => !v)),
    ]);

    constructor(
        private readonly sceneService: SceneService,
        private readonly facade: EditorFacade,
        private readonly nav: NavigationService,
        private readonly service: ScanCleanService,
    ) {
        this.createSubs$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.subscribeToMouseEvents();
        });

        this.facade.scanClearSelectionMode$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(val => {
                if (val === this.selectionMode) return;
                this.selectionMode = val;
            });
    }

    private subscribeToMouseEvents() {
        this.sceneService.mouseDown$
            .pipe(takeUntil(this.destroySubs$))
            .subscribe(event => this.startSelection(event));

        this.sceneService.mouseUp$
            .pipe(takeUntil(this.destroySubs$))
            .subscribe(event => this.endSelection(event));

        this.sceneService.mouseMove$
            .pipe(takeUntil(this.destroySubs$))
            .subscribe(event => this.updateSelection(event));
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private visualizeSelection() {
        if (this.selectionOutline.length === 0) {
            return;
        }

        switch (this.selectionMode) {
            case ScanCleanSelectionMode.Lasso: {
                const pathString = this.lassoPathSegmentsCache.join(' ');
                this.path.nativeElement.setAttribute('d', pathString);
                break;
            }
            case ScanCleanSelectionMode.Rect: {
                const [p0, p1] = this.selectionOutline;
                const minX = Math.min(p0.pixelPosition.x, p1.pixelPosition.x);
                const minY = Math.min(p0.pixelPosition.y, p1.pixelPosition.y);
                const maxX = Math.max(p0.pixelPosition.x, p1.pixelPosition.x);
                const maxY = Math.max(p0.pixelPosition.y, p1.pixelPosition.y);

                this.path.nativeElement.setAttribute(
                    'd',
                    `M ${minX} ${minY} H ${maxX} V ${maxY} H ${minX} Z`,
                );
                break;
            }
            case ScanCleanSelectionMode.Brush:
                // Already visualized
                break;
        }
    }

    private tryAddMousePointToSelection(ev: MouseEvent, isFirstPoint = false) {
        if (!this.selecting) return;
        // Transform from [0, 1] to [-1, 1]
        const screenX = ev.clientX;
        const screenY = ev.clientY;
        const x = (screenX / window.innerWidth) * 2 - 1;
        const y = -(screenY / window.innerHeight) * 2 + 1; // Flip y

        const point: ScreenPoint = {
            pixelPosition: { x: screenX, y: screenY },
            normalizedPosition: { x, y },
        };

        const threshold = 0.005; // 0.5% of screen height
        const thresholdSqr = threshold * threshold;
        const canAddCurrentPoint = () => {
            if (this.selectionOutline.length === 0) {
                return true;
            }

            const prevPoint =
                this.selectionOutline[this.selectionOutline.length - 1];
            const dx =
                (point.pixelPosition.x - prevPoint.pixelPosition.x) /
                window.innerHeight; // innerHeight is not a typo
            const dy =
                (point.pixelPosition.y - prevPoint.pixelPosition.y) /
                window.innerHeight;

            return dx * dx + dy * dy > thresholdSqr;
        };

        switch (this.selectionMode) {
            case ScanCleanSelectionMode.Lasso: {
                if (isFirstPoint) {
                    this.lassoPathSegmentsCache.push(`M ${screenX} ${screenY}`);
                } else if (!canAddCurrentPoint()) {
                    return;
                }

                this.lassoPathSegmentsCache.push(`L ${screenX} ${screenY}`);
                this.selectionOutline.push(point);
                break;
            }
            case ScanCleanSelectionMode.Rect: {
                if (isFirstPoint) {
                    // Add two points, since the box won't have more than that
                    this.selectionOutline.push(point);
                    this.selectionOutline.push(cloneDeep(point)); // Ensure different object
                }
                if (!this.selectionOutline.length) break;

                const movedPoint =
                    this.selectionOutline[this.selectionOutline.length - 1];

                movedPoint.pixelPosition.x = screenX;
                movedPoint.pixelPosition.y = screenY;
                movedPoint.normalizedPosition.x = x;
                movedPoint.normalizedPosition.y = y;
                break;
            }
            case ScanCleanSelectionMode.Brush:
                {
                    if (!canAddCurrentPoint()) {
                        return;
                    }

                    const circle = document.createElementNS(
                        'http://www.w3.org/2000/svg',
                        'circle',
                    );
                    circle.setAttribute('cx', point.pixelPosition.x.toString());
                    circle.setAttribute('cy', point.pixelPosition.y.toString());

                    const radius = (window.innerHeight * brushSize) / 2;
                    circle.setAttribute('r', radius.toString());

                    circle.setAttribute('fill', this.selectionStrokeColor);

                    this.brushGroup.nativeElement.appendChild(circle);

                    this.selectionOutline.push(point);
                }
                break;
            default:
                unreachable(this.selectionMode);
        }

        this.visualizeSelection();
    }

    public startSelection = (ev: MouseEvent) => {
        if (ev.button !== 0 || this.selecting) {
            return;
        }

        this.selecting = true;

        this.svg.nativeElement.setAttribute(
            'width',
            window.innerWidth.toString(),
        );
        this.svg.nativeElement.setAttribute(
            'height',
            window.innerHeight.toString(),
        );

        const isBrushSelection =
            this.selectionMode === ScanCleanSelectionMode.Brush;
        this.path.nativeElement.style.display = isBrushSelection ? 'none' : '';
        this.brushGroup.nativeElement.style.display = isBrushSelection
            ? ''
            : 'none';

        this.svg.nativeElement.style.display = '';

        this.selectionOutline.splice(0, this.selectionOutline.length);
        this.tryAddMousePointToSelection(ev, true);
    };

    private updateSelection = (ev: MouseEvent) => {
        this.tryAddMousePointToSelection(ev);
    };

    private endSelection = async (ev: MouseEvent) => {
        if (ev.button !== 0 || !this.selecting) {
            return;
        }

        this.selecting = false;

        this.svg.nativeElement.style.display = 'none';
        this.path.nativeElement.setAttribute('d', '');
        this.brushGroup.nativeElement.replaceChildren();

        this.lassoPathSegmentsCache.splice(
            0,
            this.lassoPathSegmentsCache.length,
        );

        await this.service.selectionFinish({
            selectionMode: this.selectionMode,
            outline: this.selectionOutline,
            shiftPressed: ev.getModifierState('Shift'),
            ctrlPressed: ev.getModifierState('Control'),
        });
    };
}
