/* eslint-disable max-params-no-constructor/max-params-no-constructor */
import { Injectable } from '@angular/core';
import {
    GenericResponse,
    WorkerRequest,
    WorkerResponse,
} from '@orthocore-web-mono/shared-types';
import { Subject } from 'rxjs';

import { EditorStageStatusService } from './editor-stage-status.service';

@Injectable()
export class EngineWorkerConnectorService {
    private msgId = 0;

    private waitingMessages = new Map<
        number,
        (response: WorkerResponse) => void
    >();

    constructor(private readonly status: EditorStageStatusService) {}

    private sendMessageSubject = new Subject<{
        msg: { id: number };
        transfer: Transferable[];
    }>();
    public sendMessage$ = this.sendMessageSubject.pipe();

    public onMessage(msg: any) {
        const callback = this.waitingMessages.get(msg.id);
        if (callback) {
            this.waitingMessages.delete(msg.id);
            callback(msg);
        }
    }

    public async sendMessage<T extends WorkerResponse>(
        msg: { id: number },
        transfer: Transferable[] = [],
    ): Promise<T> {
        await this.status.engineReady;
        const response = await new Promise<WorkerResponse>(resolve => {
            this.waitingMessages.set(msg.id, resolve);
            this.sendMessageSubject.next({ msg, transfer });
        });
        return response as T;
    }

    public async sendSimpleMessage<
        TMsg extends WorkerRequest,
        TResp extends WorkerResponse = GenericResponse,
    >(
        type: TMsg['type'],
        msg: Omit<TMsg, 'id' | 'type'>,
        transfer: Transferable[] = [],
    ) {
        const id = this.msgId++;
        return await this.sendMessage<TResp>({ ...msg, type, id }, transfer);
    }
}
