import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@ngrx/store';
import { Stage } from '@orthocore-web-mono/feature-core-services';
import {
    Float3JS,
    selectionModeArray,
    selectionModeLocales,
} from '@orthocore-web-mono/shared-types';
import {
    CollapsingGroupChangedEvent,
    CollapsingSectionComponent,
} from '@orthocore-web-mono/shared-ui';
import {
    deg2rad,
    degreeUnit,
    scanSettingsScaleUnits,
} from '@orthocore-web-mono/shared/utils';
import { EditorFacade, EditorState } from '@orthocore-web-mono/state';
import { Subject, map, takeUntil } from 'rxjs';

import { ScanSettingSections, ScanTransformMode } from './constants';
import { ScanCleanService } from './scan-clean.service';
import { ScanSettingsService } from './scan-settings.service';

@Component({
    selector: 'leo-scan-settings',
    templateUrl: './scan-settings.component.html',
    styleUrl: './scan-settings.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanSettingsComponent implements OnInit, OnDestroy {
    public ScanSettingSections = ScanSettingSections;
    public ScanTransformMode = ScanTransformMode;

    public selectionModeLocales = selectionModeLocales;

    @ViewChild('scaleSection')
    scaleSection!: CollapsingSectionComponent;

    public readonly units = scanSettingsScaleUnits;
    public readonly degreeUnit = degreeUnit;
    public readonly deg2rad = deg2rad;

    public selectedScaleIndex = scanSettingsScaleUnits.indexOf(
        this.service.scale,
    );
    public mode?: ScanTransformMode;

    public selectedClearIndex$ = this.facade.scanClearSelectionMode$.pipe(
        map(v => selectionModeArray.indexOf(v)),
    );

    public rotation = this.service.rotation;

    private destroyed$ = new Subject<void>();

    constructor(
        private readonly state: State<EditorState>,
        private readonly facade: EditorFacade,
        private readonly service: ScanSettingsService,
        private readonly cleanService: ScanCleanService,
        private readonly change: ChangeDetectorRef,
        private readonly router: Router,
    ) {}

    ngOnInit(): void {
        const state = <EditorState>this.state.getValue().editor;

        this.mode = <ScanTransformMode>state.scanTransformSettings.mode;

        this.service.rotationChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(v => {
                this.rotation = v;
                this.change.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public async apply() {
        await this.service.apply();
        await this.router.navigate([Stage.Landmarks]);
    }

    public async onScanScaleChanged(index: number) {
        await this.service.setScale(scanSettingsScaleUnits[index]);
    }

    public onCollapsingGroupChanged(event: CollapsingGroupChangedEvent) {
        if (event.target === this.scaleSection) {
            this.facade.changeShowGroundPlane(!event.collapsed);
        }
    }

    public onRotateSliderChanged(event: Float3JS) {
        this.service.updateRotationFromSliders(event);
    }

    public async onRotateSliderFinished() {
        await this.service.sliderFinished();
    }

    public onRotateEnabledChanged(checked: boolean) {
        const newMode = checked ? ScanTransformMode.Rotate : undefined;
        this.mode = newMode;
        this.facade.changeScanTransformMode(newMode);
    }

    public onMoveEnabledChanged(checked: boolean) {
        const newMode = checked ? ScanTransformMode.Translate : undefined;
        this.mode = newMode;
        this.facade.changeScanTransformMode(newMode);
    }

    public onScanClearSelectionModeChanged(index: number) {
        this.facade.changeScanClearSelectionMode(selectionModeArray[index]);
    }

    public async onInvertClick() {
        await this.cleanService.invert();
    }

    public async onEraseClick() {
        await this.cleanService.erase();
    }

    public async onSmoothClick() {
        await this.cleanService.globalSmooth();
    }

    public async onFillHolesClick() {
        await this.cleanService.fillHoles();
    }

    public async onRemeshClick() {
        await this.cleanService.remesh();
    }
}
