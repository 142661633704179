import { Inject, Injectable } from '@angular/core';
import { LandmarkContainer } from '@orthocore-web-mono/feature-landmarks';
import {
    GeometryUpdateFlags,
    IScanService,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import { AsyncSubject, Subject, concat, merge, of, startWith } from 'rxjs';

import { HistoryOperationData } from './operations/operation-base';

interface PendingOperation {
    cancel: () => Promise<void> | void;
}

@Injectable()
export class UndoRedoService {
    private availabilityChangedSubject = new Subject<{
        canUndo: boolean;
        canRedo: boolean;
    }>();

    public availability$ = this.availabilityChangedSubject.pipe(
        startWith({
            canUndo: false,
            canRedo: false,
        }),
    );

    private waitingOperationResolvers: (() => void)[] = [];
    private operationExecutionInProgress = false;
    private undoStack: HistoryOperationData[] = [];
    private redoStack: HistoryOperationData[] = [];

    private undoInitialState: number | null = null;
    private pendingOperation: PendingOperation | null = null;

    private undoStartSubject = new Subject<void>();
    public undoStart$ = this.undoStartSubject.pipe();

    private redoStartSubject = new Subject<void>();
    public redoStart$ = this.redoStartSubject.pipe();

    private resetStartSubject = new Subject<void>();
    public resetStart$ = this.resetStartSubject.pipe();

    public start$ = merge(this.undoStart$, this.redoStart$, this.resetStart$);

    private undoFinishSubject = new Subject<void>();
    public undoFinish$ = this.undoFinishSubject.pipe();

    private redoFinishSubject = new Subject<void>();
    public redoFinish$ = this.redoFinishSubject.pipe();

    private resetFinishSubject = new Subject<void>();
    public resetFinish$ = this.resetFinishSubject.pipe();

    public finish$ = merge(
        this.undoFinish$,
        this.redoFinish$,
        this.resetFinish$,
    );

    constructor(
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: IScanService,
        private readonly sceneService: SceneService,
        public readonly landmarkContainer: LandmarkContainer,
    ) {}

    public async doOperation(op: HistoryOperationData) {
        await this.waitForPreviousOperationCompletion();

        await op.do();

        if (op.isValid()) {
            await this.scanService.syncScanMeshFromEngine(
                op.scanMeshChangeFlags(),
            );
            await this.syncLandmarksIfNeeded(op.needsLandmarkSync());

            this.redoStack.splice(0, this.redoStack.length);
            this.undoStack.push(op);
        }

        this.setPendingOperation(null); // Finish pending operation if needed
        this.operationCompleted();
    }

    public async GetCurrentHistoryState() {
        await this.waitForPreviousOperationCompletion();

        const id = this.undoStack.length;

        this.operationCompleted();

        return id;
    }

    private async waitForPreviousOperationCompletion() {
        if (!this.operationExecutionInProgress) {
            this.operationExecutionInProgress = true;
            return;
        }

        await new Promise<void>(res =>
            this.waitingOperationResolvers.push(res),
        );
    }

    private operationCompleted() {
        this.operationExecutionInProgress = false;
        this.waitingOperationResolvers.pop()?.(); // Continue next operation if needed
    }

    private async syncLandmarksIfNeeded(sync: boolean) {
        if (sync) {
            await this.landmarkContainer.syncLandmarkPositionsFromEngine(true);
        }
    }

    public updateUndoRedoAvailability() {
        const availability = ((): { canUndo: boolean; canRedo: boolean } => {
            if (this.pendingOperation !== null) {
                // Undo cancels the pending operation
                return { canUndo: true, canRedo: false };
            }

            let canUndo = this.undoStack.length !== 0;
            const canRedo = this.redoStack.length !== 0;

            if (
                this.undoInitialState !== null &&
                this.undoStack.length === this.undoInitialState
            ) {
                canUndo = false;
            }

            return { canUndo, canRedo };
        })();

        this.availabilityChangedSubject.next(availability);
    }

    public setUndoInitialState(initialState: number | null) {
        // If not null, then undo/reset operations won't go back more than the initial state
        this.undoInitialState = initialState;
    }

    public setPendingOperation(op: PendingOperation | null) {
        this.pendingOperation = op;
        this.updateUndoRedoAvailability();
    }

    async cancelPendingOperation() {
        if (this.pendingOperation === null) {
            return false;
        }

        const op = this.pendingOperation;
        this.pendingOperation = null;
        await op.cancel();
        return true;
    }

    async clearUndoRedo() {
        await this.waitForPreviousOperationCompletion();

        this.undoStack.length = 0;
        this.redoStack.length = 0;
        this.operationCompleted();
    }

    public async undo() {
        await this.waitForPreviousOperationCompletion();

        this.undoStartSubject.next();

        let scanUpdateFlags = GeometryUpdateFlags.None;
        let needsLandmarkSync = false;

        try {
            const hadPendingOperation = await this.cancelPendingOperation();
            if (hadPendingOperation) {
                console.log('HAVE PENDING');
                return;
            }

            // eslint-disable-next-line no-constant-condition, @typescript-eslint/no-unnecessary-condition
            while (true) {
                if (
                    this.undoInitialState !== null &&
                    this.undoStack.length === this.undoInitialState
                ) {
                    // Can't undo beyond the initial state
                    break;
                }

                const operationToUndo = this.undoStack.pop();
                if (operationToUndo === undefined) {
                    // Nothing to undo
                    console.warn('No operation to undo');
                    return;
                }

                scanUpdateFlags |= operationToUndo.scanMeshChangeFlags();
                needsLandmarkSync ||= operationToUndo.needsLandmarkSync();

                await operationToUndo.undo();
                this.redoStack.push(operationToUndo);

                // If this operation is internal, then automatically undo the next one too
                if (!operationToUndo.isInternal()) {
                    break;
                }
            }
        } finally {
            this.updateUndoRedoAvailability();
            await this.scanService.syncScanMeshFromEngine(scanUpdateFlags);
            await this.syncLandmarksIfNeeded(needsLandmarkSync);

            this.operationCompleted();
            this.undoFinishSubject.next();
            this.sceneService.requestSceneRender();
        }
    }

    public async redo() {
        await this.waitForPreviousOperationCompletion();

        this.redoStartSubject.next();

        let footScanUpdateFlags = GeometryUpdateFlags.None;
        let needsLandmarkSync = false;

        try {
            // eslint-disable-next-line no-constant-condition, @typescript-eslint/no-unnecessary-condition
            while (true) {
                const operationToRedo = this.redoStack.pop();
                if (operationToRedo === undefined) {
                    // Nothing to redo
                    return;
                }

                footScanUpdateFlags |= operationToRedo.scanMeshChangeFlags();
                needsLandmarkSync ||= operationToRedo.needsLandmarkSync();

                await operationToRedo.redo();
                this.undoStack.push(operationToRedo);

                // If this operation is internal, then automatically redo the next one too
                if (!operationToRedo.isInternal()) {
                    break;
                }
            }
        } finally {
            this.updateUndoRedoAvailability();
            await this.scanService.syncScanMeshFromEngine(footScanUpdateFlags);
            await this.syncLandmarksIfNeeded(needsLandmarkSync);

            this.operationCompleted();
            this.redoFinishSubject.next();
            this.sceneService.requestSceneRender();
        }
    }

    public async reset() {
        await this.waitForPreviousOperationCompletion();

        this.resetStartSubject.next();

        let scanUpdateFlags = GeometryUpdateFlags.None;
        let needsLandmarkSync = false;

        try {
            await this.cancelPendingOperation();

            // Undo everything while possible
            // eslint-disable-next-line no-constant-condition, @typescript-eslint/no-unnecessary-condition
            while (true) {
                if (
                    this.undoInitialState !== null &&
                    this.undoStack.length === this.undoInitialState
                ) {
                    // Can't undo beyond the initial state
                    break;
                }

                const operationToUndo = this.undoStack.pop();
                if (operationToUndo === undefined) {
                    // Nothing to undo
                    return;
                }

                scanUpdateFlags |= operationToUndo.scanMeshChangeFlags();
                needsLandmarkSync ||= operationToUndo.needsLandmarkSync();

                await operationToUndo.undo();
                this.redoStack.push(operationToUndo);
            }
        } finally {
            this.updateUndoRedoAvailability();
            await this.scanService.syncScanMeshFromEngine(scanUpdateFlags);
            await this.syncLandmarksIfNeeded(needsLandmarkSync);

            this.operationCompleted();
            this.resetFinishSubject.next();
            this.sceneService.requestSceneRender();
        }
    }
}
