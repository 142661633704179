<div class="design-edit-components-option">
    <leo-styled-toggle
        [label]="'DESIGN.ENABLE_COMPONENT' | translate"></leo-styled-toggle>
    <leo-styled-slider-with-input
        [title]="'DESIGN.EXTRA_THICKNESS' | translate"
        [min]="0"
        [max]="5"
        [step]="0.1"
        [unit]="mmUnit"></leo-styled-slider-with-input>
    <leo-styled-slider-with-input
        [title]="'DESIGN.EXTRA_OFFSET' | translate"
        [min]="0"
        [max]="5"
        [step]="0.1"
        [unit]="mmUnit"></leo-styled-slider-with-input>
</div>
