<label>{{ label }}</label>
<input
    type="range"
    class="form-control"
    [step]="step"
    [style]="
        '--track-fill-percent: ' +
        sliderPercent +
        '%' +
        '; --fill-color:' +
        color +
        '; --hover-color:' +
        hoverColor +
        ';'
    "
    [value]="sliderPercent"
    [attr.placeholder]="label"
    [disabled]="disabled"
    (input)="onSliderValueChange($event)"
    (change)="onSliderValueChangeFinished($event)" />
