import { HttpClient, provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FeatureEnablerModule } from '@orthocore-web-mono/feature-enabler';
import {
    ENGINE_DATA_FILE_URL,
    ENGINE_MODULE_URL,
    ENVIRONMENT,
} from '@orthocore-web-mono/shared-types';
import { SpinalEditorModule } from '@orthocore-web-mono/spinal-feature-editor';
import { StateModule } from '@orthocore-web-mono/state';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { WorkerConnectionService } from './worker-connection.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        RouterModule.forRoot(appRoutes),
        StateModule,
        SpinalEditorModule,
        FeatureEnablerModule,
    ],
    providers: [
        provideHttpClient(),
        provideAnimationsAsync(),
        WorkerConnectionService,
        {
            provide: ENVIRONMENT,
            useValue: environment,
        },
        {
            provide: ENGINE_MODULE_URL,
            useValue: 'assets/wasm',
        },
        {
            provide: ENGINE_DATA_FILE_URL,
            useValue: 'assets/wasm/spb-data.asar',
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
