<leo-header>
    <button
        *ngFor="let step of editorSteps"
        [routerLink]="step.key"
        [routerLinkActive]="['selected']"
        [disabled]="step.disabled | async">
        {{ step.name | translate }}
    </button>
</leo-header>

<router-outlet></router-outlet>

<leo-powered-by></leo-powered-by>
<leo-view-options-container></leo-view-options-container>

<leo-canvas></leo-canvas>
<leo-screen-selection-layer></leo-screen-selection-layer>
<leo-image-import-layer></leo-image-import-layer>
