<leo-styled-slider-with-input
    [title]="'DESIGN.GENERAL_THICKNESS' | translate"
    [min]="1.5"
    [max]="5"
    [value]="3"
    [step]="0.1"
    [(value)]="service.globalThickness"
    (valueChangeFinished)="update()"
    [unit]="mmUnit"
    [multiplier]="mm2cm"></leo-styled-slider-with-input>
<leo-styled-slider-with-input
    [title]="'DESIGN.GENERAL_OFFSET' | translate"
    [min]="0"
    [max]="5"
    [value]="2"
    [step]="0.1"
    [(value)]="service.globalOffset"
    (valueChangeFinished)="update()"
    [unit]="mmUnit"
    [multiplier]="mm2cm"></leo-styled-slider-with-input>
