import { Inject, Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    EngineWorkerConnectorService,
} from '@orthocore-web-mono/feature-core-services';
import {
    ENGINE_DATA_FILE_URL,
    ENGINE_MODULE_URL,
} from '@orthocore-web-mono/shared-types';
import { EditorFacade } from '@orthocore-web-mono/state';

@Injectable()
export class WorkerConnectionService {
    worker: Worker;
    constructor(
        @Inject(ENGINE_MODULE_URL) engineModuleUrl: string,
        @Inject(ENGINE_DATA_FILE_URL) engineDataFileUrl: string,
        private readonly engine: EngineWorkerConnectorService,
        private readonly facade: EditorFacade,
        private readonly status: EditorStageStatusService,
    ) {
        if (typeof Worker === 'undefined')
            throw new Error(
                'Web Workers are not supported in this environment.',
            );

        this.worker = new Worker(new URL('./test.worker', import.meta.url));
        this.worker.onmessage = ev => {
            const msg: any = ev.data;

            switch (msg.type) {
                case 'engineInitialized':
                    this.status.engineReady$.next(true);
                    this.facade.engineLoaded();
                    return;
                default:
                    break;
            }

            this.engine.onMessage(msg);
        };

        this.worker.postMessage([engineModuleUrl, engineDataFileUrl]);

        this.engine.sendMessage$.subscribe(({ msg, transfer }) => {
            this.worker.postMessage(msg, transfer);
        });
    }
}
