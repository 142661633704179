<leo-styled-button
    *ngIf="(stage$ | async) === Stage.Nothing"
    (click)="importInput.click()">
    {{ 'MESH_EDIT.IMPORT_IMAGE.IMPORT' | translate }}
</leo-styled-button>
<leo-styled-dropdown
    *ngIf="(stage$ | async) === Stage.ImageImported"
    [title]="'MESH_EDIT.IMPORT_IMAGE.FITTING_PLANE' | translate"
    [options]="fittingPlaneLocales"
    [localise]="true"
    [(selected)]="selectedPlaneIndex"
    (selectedChange)="onSelectedFittingPlaneChange($event)">
</leo-styled-dropdown>

<leo-styled-slider-with-input
    *ngIf="canSetOpacity$ | async"
    [title]="'MESH_EDIT.IMPORT_IMAGE.OPACITY' | translate"
    [min]="0"
    [max]="100"
    [value]="1"
    [step]="1"
    [unit]="degreeUnit"
    [multiplier]="value2percent"
    (valueChange)="onOpacityChange($event)">
</leo-styled-slider-with-input>

<leo-styled-button
    *ngIf="(stage$ | async) === Stage.ImageImported"
    (click)="applyImageToMesh()">
    Apply to Scan
</leo-styled-button>

<leo-styled-button
    *ngIf="(stage$ | async) === Stage.ImageImported"
    (click)="addImageToScene()">
    Place In 3d Space
</leo-styled-button>

<leo-styled-button *ngIf="finished$ | async" (click)="repositionImage()">
    Reposition image
</leo-styled-button>

<leo-styled-button
    *ngIf="(stage$ | async) === Stage.ImageImported"
    (click)="cancel()">
    Cancel
</leo-styled-button>

<input
    #importInput
    type="file"
    accept=".png,.jpg"
    (input)="onInputChanged($event)" />
