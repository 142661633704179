import { Inject, Injectable } from '@angular/core';
import { NavigationService } from '@orthocore-web-mono/feature-core-services';
import {
    GlobalSmoothOperation,
    MeshEraseOperation,
    ScanCleanOperation,
    UndoRedoService,
    VisualizeSelectionOnScan,
} from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    GeometryUpdateFlags,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    Matrix4x4JS,
    SCAN_SERVICE_TOKEN,
    ScanCleanSelectionMode,
    ScanPreparationParamsJS,
    ScreenPoint,
    defaultScanPreparationParams,
    unreachable,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import { UpdateGeometryFromMeshData } from '@orthocore-web-mono/shared/utils';
import { distinctUntilChanged, map } from 'rxjs';
import * as THREE from 'three';

import { ScanSettingSections } from './constants';
import { selectionMaterial } from './selection.material';

@Injectable()
export class ScanCleanService {
    private isOnClearSection$ = this.nav.selectedSection$.pipe(
        map(section => section === ScanSettingSections.ClearSectionName),
        distinctUntilChanged(),
    );

    constructor(
        @Inject(SCAN_SERVICE_TOKEN) private readonly scanService: IScanService,
        private readonly sceneService: SceneService,
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        private readonly undoRedoService: UndoRedoService,
        private readonly nav: NavigationService,
    ) {
        this.isOnClearSection$.subscribe(onSection => {
            this.scanService.setMaterial(
                onSection ? selectionMaterial : undefined,
            );
        });
    }

    public async invert() {
        await this.DoScanPreparation(
            new MeshEraseOperation(
                this.engine,
                this.scanService,
                this.sceneService,
                {
                    ...defaultScanPreparationParams,
                    applyManualDel: true,
                    manualDelMode: 'SelectionInvert',
                },
                null,
            ),
        );
    }

    public async erase() {
        await this.DoScanPreparation(
            new MeshEraseOperation(
                this.engine,
                this.scanService,
                this.sceneService,
                {
                    ...defaultScanPreparationParams,
                    applyManualDel: true,
                    manualDelMode: 'Delete',
                },
                null,
            ),
        );
    }

    public async globalSmooth() {
        await this.DoScanPreparation(
            new GlobalSmoothOperation(
                this.engine,
                this.scanService,
                this.sceneService,
                {
                    ...defaultScanPreparationParams,
                    applyGlobalSmooth: true,
                },
                null,
            ),
        );
    }

    public async fillHoles() {
        await this.DoScanPreparation(
            new GlobalSmoothOperation(
                this.engine,
                this.scanService,
                this.sceneService,
                {
                    ...defaultScanPreparationParams,
                    applyHoleFill: true,
                },
                null,
            ),
        );
    }

    public async remesh() {
        await this.DoScanPreparation(
            new GlobalSmoothOperation(
                this.engine,
                this.scanService,
                this.sceneService,
                {
                    ...defaultScanPreparationParams,
                    applyVoxelRemesh: true,
                },
                null,
            ),
        );
    }

    public async selectionFinish({
        selectionMode,
        outline,
        shiftPressed = false,
        ctrlPressed = false,
    }: {
        selectionMode: ScanCleanSelectionMode;
        outline: ScreenPoint[];
        shiftPressed?: boolean;
        ctrlPressed?: boolean;
    }) {
        const tmpMat4 = new THREE.Matrix4();

        const mvp = tmpMat4
            .copy(this.sceneService.activeCamera.matrixWorldInverse)
            .multiply(this.scanService.scan.matrixWorld)
            .premultiply(this.sceneService.activeCamera.projectionMatrix);

        const [
            m11,
            m12,
            m13,
            m14,
            m21,
            m22,
            m23,
            m24,
            m31,
            m32,
            m33,
            m34,
            m41,
            m42,
            m43,
            m44,
        ] = mvp.elements;

        const matrix: Matrix4x4JS = {
            c0: { x: m11, y: m12, z: m13, w: m14 },
            c1: { x: m21, y: m22, z: m23, w: m24 },
            c2: { x: m31, y: m32, z: m33, w: m34 },
            c3: { x: m41, y: m42, z: m43, w: m44 },
        };

        const getScreenAspect = () => {
            const camera = this.sceneService.activeCamera;
            if (camera instanceof THREE.PerspectiveCamera) {
                return camera.aspect;
            }

            if (camera instanceof THREE.OrthographicCamera) {
                const width = camera.right - camera.left;
                const height = camera.top - camera.bottom;
                return width / height;
            }

            // Shouldn't happen
            return 1;
        };

        const params: ScanPreparationParamsJS = {
            ...defaultScanPreparationParams,
            applyManualDel: true,
            manualDelIsSelClearOnSel: !shiftPressed && !ctrlPressed,
            manualDelMode: selectionMode,
            screenAspect: getScreenAspect(),
            cameraViewProjMat: matrix,
            brushSize: defaultScanPreparationParams.brushSize / 2,
        };

        if (selectionMode === ScanCleanSelectionMode.Rect) {
            const [rect0, rect1] = outline;

            params.vertexSelInfoGuideRect = {
                x: rect0.normalizedPosition.x,
                y: rect0.normalizedPosition.y,
                z: rect1.normalizedPosition.x,
                w: rect1.normalizedPosition.y,
            };
        }

        await this.DoScanPreparation(
            new MeshEraseOperation(
                this.engine,
                this.scanService,
                this.sceneService,
                params,
                outline.map(point => point.normalizedPosition),
            ),
        );
    }

    private async DoScanPreparation(operation: ScanCleanOperation) {
        await this.undoRedoService.doOperation(operation);
        const { result } = operation;

        if (result !== null) {
            if (result.updatedMesh !== null) {
                UpdateGeometryFromMeshData(
                    this.scanService.scan.geometry,
                    result.updatedMesh,
                    GeometryUpdateFlags.All,
                );
            }

            switch (result.selection.type) {
                case 'no-change':
                    break;
                case 'clear':
                    VisualizeSelectionOnScan(
                        null,
                        this.scanService,
                        this.sceneService,
                    );
                    break;
                case 'update':
                    VisualizeSelectionOnScan(
                        result.selection.vertices,
                        this.scanService,
                        this.sceneService,
                    );
                    break;
                default:
                    unreachable(result.selection);
            }
        }

        this.sceneService.requestSceneRender();
    }
}
