import { Component, Input } from '@angular/core';

import { MeasurementBO } from './measurement.bo';

@Component({
    selector: 'leo-measurements-loop-tool',
    templateUrl: './measurements-loop-tool.component.html',
    styleUrl: './measurements-loop-tool.component.scss',
})
export class MeasurementsLoopToolComponent {
    @Input()
    public measurements?: MeasurementBO;

    @Input()
    public showMiddle: boolean = true; //TODO: do we need a middle or a distance or both
}
