<div class="measurements-panel">
    <div *ngIf="showMeasurementsPanel">
        <div class="measurement-modes-container">
            <leo-toggle-group
                [label]="'MEASUREMENTS.TITLE' | translate"
                [options]="measurementModes"
                [selected]="selectedMeasurementMode"
                (selectionChanged)="
                    onSelectedMeasurementModeChanged($event)
                "></leo-toggle-group>
        </div>
        <ng-template [ngIf]="selectedMeasurementMode === 0">
            <div>{{ 'MEASUREMENTS.DISTANCE_DESCRIPTION' | translate }}</div>
            <div>
                {{ 'MEASUREMENTS.DISTANCE' | translate }}:
                <span *ngIf="distance$ | async as distance; else notApplicable">
                    {{ distance }}
                </span>
                <ng-template #notApplicable>
                    {{ 'MEASUREMENTS.NOT_APPLICABLE' | translate }}
                </ng-template>
            </div>
        </ng-template>
        <ng-template [ngIf]="selectedMeasurementMode === 1">
            <div>{{ 'MEASUREMENTS.GIRTH_DESCRIPTION' | translate }}</div>
            <div>
                {{ 'MEASUREMENTS.GIRTH' | translate }}:
                <span *ngIf="girth$ | async as girth; else notApplicable">
                    {{ girth }}
                </span>
                <ng-template #notApplicable>
                    {{ 'MEASUREMENTS.NOT_APPLICABLE' | translate }}
                </ng-template>
            </div>
        </ng-template>
        <leo-styled-button (click)="onClearPointsClicked()">{{
            'MEASUREMENTS.CLEAR_POINTS' | translate
        }}</leo-styled-button>
    </div>
    <img
        *ngIf="showGridSizeIndicator$ | async"
        id="grid-size-indicator"
        src="/assets/icons/1cm.svg" />
</div>
<div class="view-options-panel">
    <div class="opacity-input">
        <img
            [src]="scanOpacityIconUrl"
            width="40px"
            style="filter: var(--white-colorize-filter); padding: 4px" />
        <leo-styled-slider
            [min]="0"
            [max]="1"
            [default]="1"
            [disabled]="isScanOpacityInputDisabled$ | async"
            (valueChange)="onScanOpacityChange($event)"></leo-styled-slider>
    </div>
    <div class="opacity-input">
        <img
            [src]="generatedOpacityIconUrl"
            width="40px"
            style="filter: var(--white-colorize-filter); padding: 4px" />
        <leo-styled-slider
            [min]="0"
            [max]="1"
            [default]="1"
            [disabled]="isGeneratedOpacityInputDisabled$ | async"
            (valueChange)="
                onGeneratedOpacityChange($event)
            "></leo-styled-slider>
    </div>
    <div class="view-options-toggles">
        <leo-styled-toggle
            [label]="'VISUALS.GROUND_PLANE' | translate"
            [checked]="(showGroundPlane$ | async) ?? false"
            (checkedChange)="onGroundPlaneVisibilityChanged($event)" />
        <leo-styled-toggle
            [label]="'VISUALS.LANDMARKS' | translate"
            [checked]="(showLandmarks$ | async) ?? false"
            [disabled]="showLandmarks$ | async"
            (checkedChange)="onShowLandmarksChanged($event)" />
        <leo-styled-toggle
            [label]="'VISUALS.ORTHOGRAPHIC' | translate"
            [disabled]="lockCamera$ | async"
            [checked]="(isOrthographicsCamera$ | async) ?? false"
            (checkedChange)="onIsOrthographicsViewChanged($event)" />
        <leo-styled-button
            leoStyle="gray"
            (click)="toggleMeasurementsPanel()"
            [disabled]="disableMeasurements$ | async"
            >{{ 'MEASUREMENTS.TITLE' | translate }}</leo-styled-button
        >
    </div>
</div>
