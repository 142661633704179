import { Inject, Injectable } from '@angular/core';
import { NavigationService } from '@orthocore-web-mono/feature-core-services';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    IScanService,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import {
    EmbosserParameters,
    ISpinalEngineWorkerService,
} from '@orthocore-web-mono/spinal-types';
import {
    Subject,
    distinctUntilChanged,
    exhaustMap,
    filter,
    map,
    takeUntil,
} from 'rxjs';
import * as THREE from 'three';

import { SpinalDesignService } from './design.service';

const embosserColor = 0x7ceb36;

const mainMouseButton = 0; // Left click

@Injectable()
export class EmbosserService {
    public updateSingleContourSplineVisual = new Subject<number>();

    public parameters: EmbosserParameters = {
        text: '',
        rotation: 0,
        fontSize: 1.2,
        lineHeight: 100.0,
        depth: 0.5,
        // logoEnabled: true,
        // logoScale: 2.1,
    };

    private _hitPointSphere = new THREE.Mesh(
        new THREE.SphereGeometry(0.1),
        new THREE.MeshBasicMaterial({ color: embosserColor }),
    );
    private _isEmplacingActive = false;

    private isOnSection$ = this.nav.selectedSection$.pipe(
        map(section => section === 'embossed-text'),
        distinctUntilChanged(),
    );

    private activate$ = this.isOnSection$.pipe(filter(v => v));
    private deactivate$ = this.isOnSection$.pipe(filter(v => !v));

    constructor(
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: IScanService,
        private readonly sceneService: SceneService,
        private readonly nav: NavigationService,
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: ISpinalEngineWorkerService,
        private readonly design: SpinalDesignService,
    ) {
        sceneService.scene.add(this._hitPointSphere);
        this._hitPointSphere.visible = true;

        this.activate$.subscribe(() => this.activate());

        // this.isOnSection$.subscribe(onSection => {
        //     this._hitPointSphere.visible = onSection;
        //     this.sceneService.requestSceneRender();
        // });
    }

    public isPointEmplaced = () => this._hitPointSphere.visible;

    private activate() {
        this.sceneService.mouseDown$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.embosserMouseDown(event));

        this.sceneService.mouseUp$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.embosserMouseUp(event));

        this.sceneService.mouseMove$
            .pipe(
                takeUntil(this.deactivate$),
                exhaustMap(event => this.embosserMouseMove(event)),
            )
            .subscribe();
    }

    private embosserMouseDown = async (ev: MouseEvent) => {
        if (ev.button !== mainMouseButton) return;
        const { origin, direction } = this.scanService.getMouseRay(ev);
        const result = await this.embosserRaycaster(origin, direction);
        this._isEmplacingActive = true;
    };

    private embosserMouseUp = async (ev: MouseEvent) => {
        if (ev.button !== mainMouseButton) return;
        const { origin, direction } = this.scanService.getMouseRay(ev);
        const result = await this.embosserRaycaster(origin, direction);
        this._isEmplacingActive = false;
    };

    private embosserMouseMove = async (ev: MouseEvent) => {
        if (ev.button !== mainMouseButton) return;
        if (!this._isEmplacingActive) return;
        const { origin, direction } = this.scanService.getMouseRay(ev);
        const result = await this.embosserRaycaster(origin, direction);
    };

    private embosserRaycaster = async (
        origin: THREE.Vector3,
        direction: THREE.Vector3,
    ) => {
        const result = await this.engine.setEmbossPlane(origin, direction);
        await this.engine.setEmbosserParameters(this.parameters);
        const splineIndex = (await this.engine.getEmbossSplineIndex()).index;

        if (result.hitPoint == null || splineIndex == -1) {
            console.log('TODO!!!!');

            return;
        }

        await this.engine.getContourSplineInfo(
            {
                type: 'byIndices',
                request: new Map([
                    [
                        splineIndex,
                        {
                            needControlPoints: false,
                            needSamplePoints: true,
                            needSampleNormals: false,
                        },
                    ],
                ]),
            },
            false,
        );

        this.design.UpdateAllContourSplineVisuals();
        this._hitPointSphere.position.set(
            result.hitPoint.x,
            result.hitPoint.y,
            result.hitPoint.z,
        );
        this._hitPointSphere.visible = true;
    };
}
