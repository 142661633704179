import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { StyledButtonComponent } from '../styled-button/styled-button.component';

export interface ToggleGroupselectedChangeEvent {
    index: number;
    value: string;
}

@Component({
    selector: 'leo-toggle-group',
    standalone: true,
    imports: [CommonModule, StyledButtonComponent, TranslateModule],
    templateUrl: './toggle-group.component.html',
    styleUrl: './toggle-group.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleGroupComponent implements OnInit {
    @Input() label = '';
    @Input() options: string[] = [];
    @Input() colums?: number;
    @Input() selected?: number;
    @Output() selectionChanged =
        new EventEmitter<ToggleGroupselectedChangeEvent>();

    public gridColumnsSyle = '';
    public gridRowsSyle = '';

    ngOnInit(): void {
        if (!this.colums) {
            this.gridColumnsSyle =
                'repeat(' + this.options.length + ', minmax(0px, 1fr))';
            return;
        }

        const rows = Math.ceil(this.options.length / this.colums);
        this.gridColumnsSyle = 'repeat(' + this.colums + ', minmax(0px, 1fr))';
        this.gridRowsSyle = 'repeat(' + rows + ', minmax(0px, 1fr))';
    }

    public onSelectionChanged(index: number) {
        this.selected = index;
        this.selectionChanged.emit({
            index: index,
            value: this.options[index],
        });
    }
}
