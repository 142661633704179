import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
    HorizontalSeparatorComponent,
    RawUndoRedoResetComponent,
    StyledButtonComponent,
} from '@orthocore-web-mono/shared-ui';

import { LandmarksComponent } from './controls/landmarks.component';
import { LandmarkContainer } from './landmark-container';
import { LandmarksService } from './landmarks.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        HorizontalSeparatorComponent,
        StyledButtonComponent,
        RawUndoRedoResetComponent,
    ],
    declarations: [LandmarksComponent],
    providers: [LandmarksService, LandmarkContainer],
    exports: [LandmarksComponent],
})
export class FeatureLandmarksModule {}
