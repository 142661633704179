export const enum ScanSettingsScaleUnit {
    MM = 'mm',
    CM = 'cm',
    M = 'm',
}

export const scanSettingsScaleUnits = [
    ScanSettingsScaleUnit.MM,
    ScanSettingsScaleUnit.CM,
    ScanSettingsScaleUnit.M,
];

export const scaleUnitToValue: { [key in ScanSettingsScaleUnit]: number } = {
    [ScanSettingsScaleUnit.MM]: 0.1,
    [ScanSettingsScaleUnit.CM]: 1,
    [ScanSettingsScaleUnit.M]: 100,
};
