import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ImageImportSectionName } from '@orthocore-web-mono/feature-image-import';
import { LoopTool } from '@orthocore-web-mono/feature-loop-tools';
import { SharedMeshEditSecion } from '@orthocore-web-mono/feature-mesh-edit';
import { CollapsingSectionComponent } from '@orthocore-web-mono/shared-ui';

@Component({
    selector: 'leo-mesh-edit',
    templateUrl: './mesh-edit.component.html',
    styleUrl: './mesh-edit.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinalMeshEditComponent {
    LoopTool = LoopTool;
    SharedMeshEditSecion = SharedMeshEditSecion;
    ImageImportSectionName = ImageImportSectionName;

    @ViewChild('imageImportSection')
    imageImportSection!: CollapsingSectionComponent;

    constructor() {}

    onImageWasAdded() {
        this.imageImportSection.collapsed = true;
    }
}
