/**
 * Anatomical planes.
 *
 * After the landmarks are given the engine rotates the mesh in these directions.
 */
export enum FittingPlane {
    /** Plane fits on Y,X axis. */
    Coronal = 'Coronal',
    /** Plane fits on Y,Z axis. */
    Saggital = 'Saggital',
    /** Plane fits on X,Z axis. */
    Transverse = 'Transverse',
}
