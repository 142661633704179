import * as THREE from 'three';

export type LandmarkObject = {
    [key: number]: THREE.Mesh;
};

export interface LandMarkInOrderData {
    /** Engine index. */
    type: number;
    /** Front-end translation name. */
    shortName: string;
    /** Engine returns their name like this. */
    fullName: string;
    /**  Icon on the front-end. */
    icon: string;
    /** Is the landmark added to the engine. */
    done?: boolean;
}

export const LANDMARKS_TOKEN = 'landmarks-token';
