import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
    StyledButtonComponent,
    StyledDropdownComponent,
    StyledSliderWithInputComponent,
    StyledToggleComponent,
} from '@orthocore-web-mono/shared-ui';

import { BendLoopToolComponent } from './bend/bend-loop-tool.component';
import { BendLoopToolService } from './bend/bend-loop-tool.service';
import { LoopToolsMaterialService } from './loop-tools-material.service';
import { MeasurementsLoopToolComponent } from './measurements/measurements-loop-tool.component';
import { ScaleLoopToolComponent } from './scale/scale-loop-tool.component';
import { ScaleLoopToolService } from './scale/scale-loop-tool.service';
import { StretchLoopToolComponent } from './stretch/stretch-loop-tool.component';
import { StretchLoopToolService } from './stretch/stretch-loop-tool.service';
import { TwistLoopToolComponent } from './twist/twist-loop-tool.component';
import { TwistLoopToolService } from './twist/twist-loop-tool.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        StyledDropdownComponent,
        StyledSliderWithInputComponent,
        StyledButtonComponent,
        StyledToggleComponent,
    ],
    declarations: [
        BendLoopToolComponent,
        MeasurementsLoopToolComponent,
        ScaleLoopToolComponent,
        StretchLoopToolComponent,
        TwistLoopToolComponent,
    ],
    providers: [
        BendLoopToolService,
        ScaleLoopToolService,
        StretchLoopToolService,
        TwistLoopToolService,
        LoopToolsMaterialService,
    ],
    exports: [
        BendLoopToolComponent,
        MeasurementsLoopToolComponent,
        ScaleLoopToolComponent,
        StretchLoopToolComponent,
        TwistLoopToolComponent,
    ],
})
export class FeatureLoopToolsModule {}
