<div class="title">
    {{ 'MAIN_MENU.SCAN_SETTINGS' | translate }}
</div>
<leo-horizontal-separator></leo-horizontal-separator>

<leo-collapsing-group (changed)="onCollapsingGroupChanged($event)">
    <!-- Scale -->
    <leo-collapsing-section
        #scaleSection
        [title]="'SCAN_SETTINGS.SCALE' | translate"
        [openOnFragment]="ScanSettingSections.ScaleSectionName">
        <leo-styled-dropdown
            [title]="'SCAN_SETTINGS.UNITS' | translate"
            [options]="units"
            [selected]="selectedScaleIndex"
            (selectedChange)="onScanScaleChanged($event)"></leo-styled-dropdown>
    </leo-collapsing-section>

    <!-- Transform -->
    <leo-collapsing-section
        [title]="'SCAN_SETTINGS.TRANSFORM' | translate"
        [openOnFragment]="ScanSettingSections.TransformSectionName">
        <leo-styled-toggle
            [label]="'SCAN_SETTINGS.ROTATE' | translate"
            [checked]="mode === ScanTransformMode.Rotate"
            (checkedChange)="onRotateEnabledChanged($event)">
        </leo-styled-toggle>
        <leo-axis-sliders
            [min]="-180"
            [max]="180"
            [step]="0.1"
            [value]="rotation"
            [unit]="degreeUnit"
            [multiplier]="deg2rad"
            [disabled]="mode !== 'rotate'"
            (valueChange)="onRotateSliderChanged($event)"
            (movingFinished)="onRotateSliderFinished()"></leo-axis-sliders>
        <leo-styled-toggle
            [label]="'SCAN_SETTINGS.MOVE' | translate"
            [checked]="mode === ScanTransformMode.Translate"
            (checkedChange)="onMoveEnabledChanged($event)">
        </leo-styled-toggle>
    </leo-collapsing-section>

    <!-- Clean -->
    <leo-collapsing-section
        [title]="'SCAN_SETTINGS.CLEAN.TITLE' | translate"
        [openOnFragment]="ScanSettingSections.ClearSectionName">
        <leo-styled-dropdown
            [title]="'SCAN_SETTINGS.CLEAN.SELECTION_MODE' | translate"
            [options]="selectionModeLocales"
            [localise]="true"
            [selected]="(selectedClearIndex$ | async) ?? 0"
            (selectedChange)="
                onScanClearSelectionModeChanged($event)
            "></leo-styled-dropdown>

        <div class="scan-settings-clean-buttons">
            <leo-styled-button (click)="onInvertClick()">{{
                'SCAN_SETTINGS.CLEAN.INVERT' | translate
            }}</leo-styled-button>
            <leo-styled-button (click)="onEraseClick()">{{
                'SCAN_SETTINGS.CLEAN.ERASE' | translate
            }}</leo-styled-button>
        </div>

        <leo-styled-button (click)="onSmoothClick()">{{
            'SCAN_SETTINGS.CLEAN.SMOOTH' | translate
        }}</leo-styled-button>
        <leo-styled-button (click)="onFillHolesClick()">{{
            'SCAN_SETTINGS.CLEAN.FILL_HOLES' | translate
        }}</leo-styled-button>
        <leo-styled-button (click)="onRemeshClick()">{{
            'SCAN_SETTINGS.CLEAN.REMESH' | translate
        }}</leo-styled-button>
    </leo-collapsing-section>
</leo-collapsing-group>

<leo-undo-redo-reset></leo-undo-redo-reset>

<leo-styled-button (click)="apply()">{{
    'APPLY' | translate
}}</leo-styled-button>
