import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EnablerService } from './enabler.service';

@NgModule({
    imports: [CommonModule],
    providers: [EnablerService],
})
export class FeatureEnablerModule {}
