import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'leo-styled-toggle',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './styled-toggle.component.html',
    styleUrl: './styled-toggle.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyledToggleComponent {
    @Input() public label?: string;
    @Input() public checked = false;
    @Input() public disabled: boolean | null = false;

    @Output() public checkedChange = new EventEmitter<boolean>();

    public onValueChange(event: Event) {
        this.checked = (event.target as HTMLInputElement).checked;
        this.checkedChange.emit(this.checked);
    }
}
