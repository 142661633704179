export const redColor = '#db0707';
export const redHoverColor = '#e34949';

export const greenColor = '#00c90b';
export const greenHoverColor = '#72db78';

export const blueColor = '#3285d1';
export const blueHoverColor = '#6f9ae6';

export const axisColors = {
    xColor: redColor,
    xHover: redHoverColor,

    yColor: greenColor,
    yHovel: greenHoverColor,

    zColor: blueColor,
    zHover: blueHoverColor,
};
