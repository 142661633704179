import { Component } from '@angular/core';
import { SpinalDesignService } from '@orthocore-web-mono/spinal-feature-design';

import { ComponentSettingsBaseComponent } from '../component-settings-base.component';

@Component({
    selector: 'leo-reinforcement',
    templateUrl: './reinforcement.component.html',
    styleUrl: './reinforcement.component.scss',
})
export class ReinforcementComponent extends ComponentSettingsBaseComponent {
    public isEnabled: boolean;
    public thickness: number;
    public offset: number;
    public transitionLength: number;

    constructor(public readonly service: SpinalDesignService) {
        super();
        const spline = this.service.currentSplineInfo;
        this.isEnabled = spline.isEnabled;
        this.thickness = spline.thickness;
        this.offset = spline.offset;
        this.transitionLength = spline.transitionLength;
    }
}
