<leo-styled-dropdown
    [title]="'MESH_EDIT.LOOP_TOOLS.MODE' | translate"
    [options]="modes"
    [localise]="true"
    (selectedChange)="onModeChanged($event)"></leo-styled-dropdown>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.TOP_POSITION' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.stretchDistTop"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.BOTTOM_POSITION' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.stretchDistBottom"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.AMOUNT' | translate"
    [min]="-15"
    [max]="15"
    [(value)]="params.stretchAmount"
    (valueChange)="onChange()"
    [step]="0.1"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.POSITION' | translate"
    [min]="0"
    [max]="100"
    [(value)]="params.stretchPos"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-toggle
    *ngIf="params.stretchMode === Mode.Double"
    [label]="'MESH_EDIT.LOOP_TOOLS.STRETCH_BOTTOM_PART' | translate"
    [(checked)]="params.isStretchModeBottom"
    (checkedChange)="onChange()"></leo-styled-toggle>

<leo-styled-slider-with-input
    *ngIf="params.stretchMode === Mode.Triple"
    [title]="'MESH_EDIT.LOOP_TOOLS.EFFECT_RANGE' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.stretchMiddleSize"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-measurements-loop-tool
    *ngIf="measurements$ | async as measurements"
    [measurements]="measurements"></leo-measurements-loop-tool>

<leo-styled-button
    (click)="onApplyLoopTool()"
    [disabled]="params.stretchAmount === 0">
    {{ 'APPLY' | translate }}
</leo-styled-button>
