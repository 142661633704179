import { Inject, Injectable } from '@angular/core';
import { EditorStageStatusService } from '@orthocore-web-mono/feature-core-services';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    ENVIRONMENT,
    Environment,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    MeshExportFileFormat,
} from '@orthocore-web-mono/shared-types';

// import { ShowFullScreenDialog } from "./ui/fullscreen-dialog.js";

const activationApiUrl = 'api/Service';
const exportApiUrl = 'api/Export';

// Try to activate every 2 minutes
const activateIntervalSeconds = 120.0;

let engineActivatedResolver = (_: boolean) => {};
const engineActivated = new Promise<boolean>(
    resolve => (engineActivatedResolver = resolve),
);

@Injectable()
export class EnablerService {
    constructor(
        @Inject(ENVIRONMENT) private readonly environment: Environment,
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        private readonly status: EditorStageStatusService,
    ) {}

    async PostToEnablerService() {
        const randomValue = await this.engine.enablerServiceGetRandomValue();

        let jsonString = '';
        if (this.environment.skipEngineActivation) return jsonString;

        try {
            const req = await fetch(activationApiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Domain: window.location.hostname,
                    version: 0,
                    timestamp: new Date().toISOString(),
                    randomValue: randomValue,
                }),
            });

            if (req.redirected) {
                // await ShowFullScreenDialog("generic/activation-error", "generic/ok");
                window.location.href = req.url;
            } else if (req.ok) {
                jsonString = await req.text();
            } else {
                // ShowFullScreenDialog("generic/activation-error", "generic/ok");
                return false;
            }
        } catch (ex) {
            console.warn('Engine activation skipped due to network error.');
            return false;
        }

        return jsonString;
    }

    async ActivateSingleEngine() {
        await this.status.engineReady;

        const jsonString = await this.PostToEnablerService();
        if (!jsonString) return true;

        const success = await this.engine.enablerServiceVerifyMessage(
            jsonString,
        );

        return success;
    }

    async ActivateEngine() {
        const Activate = async () => {
            setTimeout(Activate, activateIntervalSeconds * 1000);

            const success = await this.ActivateSingleEngine();
            if (!success) {
                // ShowFullScreenDialog("generic/engine-activation-error", "generic/ok");
            }

            return success;
        };

        const success = await Activate();
        engineActivatedResolver(success);
    }

    async SetActivationError(isError: boolean, message: string) {
        const errorNotification = document.querySelector(
            '#error-notification',
        )!;
        const errorNotificationText = errorNotification.querySelector('div')!;
        errorNotification.classList[isError ? 'remove' : 'add']('hidden');
        errorNotificationText.textContent = message;
    }

    async ExportCounted(exportFormat: MeshExportFileFormat): Promise<{
        meshBytes: Uint8Array | null;
        generationSuccess: boolean;
        countSuccess: boolean;
    }> {
        // const randomValue = await this.engine.enablerServiceBeginExport();
        // const afoGenerationSuccess = await this.engine.enablerServiceExport();

        // if (!afoGenerationSuccess) {
        //     return {
        //         meshBytes: null,
        //         generationSuccess: false,
        //         countSuccess: false,
        //     };
        // }

        // let jsonString = '';
        // try {
        //     const req = await fetch(exportApiUrl, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({
        //             'export-type': 'SINGLE',
        //             timestamp: new Date().toISOString(),
        //             randomValue: randomValue,
        //         }),
        //     });

        //     if (req.redirected) {
        //         // await ShowFullScreenDialog(
        //         //     'generic/export-error',
        //         //     'generic/ok',
        //         // );
        //         // window.location.href = req.url;
        //     } else if (req.ok) {
        //         jsonString = await req.text();
        //     } else {
        //         // ShowFullScreenDialog('generic/export-error', 'generic/ok');
        //     }
        // } catch (ex) {
        //     console.warn('Export skipped due to network error.');
        //     return {
        //         meshBytes: null,
        //         generationSuccess: true,
        //         countSuccess: false,
        //     };
        // }

        const meshDataBytes = await this.engine.exportMesh(exportFormat);
        return {
            meshBytes: meshDataBytes,
            generationSuccess: true,
            countSuccess: meshDataBytes !== null,
        };
    }
}
