import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'leo-powered-by',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './powered-by.component.html',
    styleUrl: './powered-by.component.scss',
})
export class PoweredByComponent {}
