import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';

export interface SectionChangedEvent {
    target: CollapsingSectionComponent;
    collapsed: boolean;
}

@Component({
    selector: 'leo-collapsing-section',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './collapsing-section.component.html',
    styleUrl: './collapsing-section.component.scss',
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsingSectionComponent implements OnInit, OnDestroy {
    @Input() public title = '';
    @Input() public collapsed = true;

    @Output() public sectionCollapseChanged =
        new EventEmitter<SectionChangedEvent>();

    @Input() public openOnFragment?: string;

    @Input()
    public disabled = false;

    private destroyed$ = new Subject<void>();

    constructor(
        public readonly change: ChangeDetectorRef,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.subscribeToFragmentChange();
        this.openIfFragmentMatches();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public async onTitleClicked() {
        if (this.collapsed) {
            this.collapsed = false;
            if (this.openOnFragment) {
                await this.router.navigate([], {
                    fragment: this.openOnFragment,
                });
            }
        } else {
            this.collapsed = true;
            await this.router.navigate([]);
        }

        this.sectionCollapseChanged.emit({
            collapsed: this.collapsed,
            target: this,
        });
    }

    public openSection() {
        this.collapsed = false;
        this.sectionCollapseChanged.emit({
            collapsed: this.collapsed,
            target: this,
        });
    }

    public closeSection() {
        this.collapsed = true;
        this.sectionCollapseChanged.emit({
            collapsed: this.collapsed,
            target: this,
        });
    }

    private subscribeToFragmentChange() {
        if (!this.openOnFragment) return;

        this.router.events
            .pipe(
                takeUntil(this.destroyed$),
                filter(event => event instanceof NavigationEnd),
                distinctUntilChanged(),
            )
            .subscribe(() => {
                this.openIfFragmentMatches();
            });
    }

    private openIfFragmentMatches() {
        const fragment = this.router.routerState.snapshot.root.fragment;
        if (fragment === null && !this.collapsed) {
            this.closeSection();
            this.change.markForCheck();
        } else if (fragment === this.openOnFragment && this.collapsed) {
            this.openSection();
        }
    }
}
