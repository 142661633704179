import { Component } from '@angular/core';
import { SpinalDesignService } from '@orthocore-web-mono/spinal-feature-design';

import { ComponentSettingsBaseComponent } from '../component-settings-base.component';

@Component({
    selector: 'leo-cutout',
    templateUrl: './cutout.component.html',
    styleUrl: './cutout.component.scss',
})
export class CutoutComponent extends ComponentSettingsBaseComponent {
    public isEnabled: boolean;
    public width: number;
    public height: number;
    public rotation: number;
    public rounding: number;

    constructor(public readonly service: SpinalDesignService) {
        super();
        const spline = this.service.currentSplineInfo;
        this.isEnabled = spline.isEnabled;
        this.width = spline.sizeX;
        this.height = spline.sizeY;
        this.rotation = spline.rotation;
        this.rounding = spline.rounding;
    }
}
