import { HistoryOperationData } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    GeometryUpdateFlags,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
} from '@orthocore-web-mono/shared-types';
import {
    QuaternionToFloat4,
    Vector3ToFloat3,
} from '@orthocore-web-mono/shared/utils';
import * as THREE from 'three';

import { ScanSettingsService } from './scan-settings.service';

export const enum ImportScaleUnit {
    MM = 'mm',
    CM = 'cm',
    M = 'm',
}

export const importUnits = [
    ImportScaleUnit.MM,
    ImportScaleUnit.CM,
    ImportScaleUnit.M,
] as const;

const scaleUnitToValue: { [key in ImportScaleUnit]: number } = {
    [ImportScaleUnit.MM]: 0.1,
    [ImportScaleUnit.CM]: 1,
    [ImportScaleUnit.M]: 100,
};

let currentScaleUnit = ImportScaleUnit.MM;

export class ScaleUnitChangeOperation extends HistoryOperationData {
    private prevScale: ImportScaleUnit;
    private newScale: ImportScaleUnit;

    constructor(
        private readonly scanService: IScanService,
        newScaleDropdownValue: string,
    ) {
        super();

        this.prevScale = currentScaleUnit;
        this.newScale = newScaleDropdownValue as ImportScaleUnit;
    }

    public do() {
        this.setScaleUnit(this.scanService, this.newScale);
    }

    public undo() {
        this.setScaleUnit(this.scanService, this.prevScale);
    }

    public redo() {
        this.do();
    }

    public static setInitialUnit(unit: ImportScaleUnit) {
        currentScaleUnit = unit;
    }

    private setScaleUnit(
        scanService: IScanService,
        scaleUnit: ImportScaleUnit,
    ) {
        currentScaleUnit = scaleUnit;
        scanService.scan.scale.setScalar(scaleUnitToValue[scaleUnit]);
    }
}

const currentPosition = new THREE.Vector3();
export class TransformMoveOperation extends HistoryOperationData {
    private prevPosition: THREE.Vector3;
    private newPosition: THREE.Vector3;

    constructor(
        private readonly scanService: IScanService,
        newPosition: THREE.Vector3,
    ) {
        super();

        this.prevPosition = currentPosition.clone();
        this.newPosition = newPosition.clone();
    }

    public do() {
        this.setPosition(this.scanService, this.newPosition);
    }

    public undo() {
        this.setPosition(this.scanService, this.prevPosition);
    }

    public redo() {
        this.do();
    }

    private setPosition(scanService: IScanService, position: THREE.Vector3) {
        scanService.scan.position.copy(position);
        currentPosition.copy(position);
    }
}

const currentRotation = new THREE.Quaternion().identity();

export class TransformRotateOperation extends HistoryOperationData {
    private prevRotation: THREE.Quaternion;
    private newRotation: THREE.Quaternion;

    constructor(
        private readonly service: ScanSettingsService,
        private readonly scanService: IScanService,
        newRotation: THREE.Quaternion,
    ) {
        super();

        this.prevRotation = currentRotation.clone();
        this.newRotation = newRotation.clone();
    }

    public do() {
        this.setRotation(this.scanService, this.newRotation);
    }

    public undo() {
        this.setRotation(this.scanService, this.prevRotation);
        this.service.updateRotationFromFootscan();
    }

    public redo() {
        this.do();
        this.service.updateRotationFromFootscan();
    }

    private setRotation(scanService: IScanService, rotation: THREE.Quaternion) {
        scanService.scan.quaternion.copy(rotation);
        currentRotation.copy(rotation);
    }
}

export class ApplyTransformOperation extends HistoryOperationData {
    private obj: THREE.Object3D;
    private position: THREE.Vector3;
    private rotation: THREE.Quaternion;
    private scale: number;

    constructor(
        obj: THREE.Object3D,
        position: THREE.Vector3,
        rotation: THREE.Quaternion,
        scale: number,
        private engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
    ) {
        super();

        this.obj = obj;
        this.position = position.clone();
        this.rotation = rotation.clone();
        this.scale = scale;
    }

    public async do() {
        await this.engine.scanTransform(
            Vector3ToFloat3(this.position),
            QuaternionToFloat4(this.rotation),
            this.scale,
        );
        this.resetObjectTransform();
    }

    public async undo() {
        await this.engine.scanUndoRedo(true);

        this.obj.position.copy(this.position);
        this.obj.quaternion.copy(this.rotation);
        this.obj.scale.setScalar(this.scale);
    }

    public async redo() {
        await this.engine.scanUndoRedo(false);
        this.resetObjectTransform();
    }

    public override isInternal(): boolean {
        return true;
    }

    public override scanMeshChangeFlags() {
        return GeometryUpdateFlags.Vertices | GeometryUpdateFlags.Normals;
    }

    private resetObjectTransform() {
        this.obj.position.setScalar(0);
        this.obj.quaternion.identity();
        this.obj.scale.setScalar(1);
    }
}
