import { Injectable } from '@angular/core';
import {
    EnsureStringOrNullish,
    IsIframe,
    IsObject,
} from '@orthocore-web-mono/shared/utils';
import { downloadZip } from 'client-zip';

import {
    IframeInitializer,
    IframeUploadData,
    defaultExportFormat,
    orthosisName,
} from './iframe-types.js';
import { ZipInput } from './zip.js';

let exportUploadUrl = '';

let exportApprovalResolver: (result: true | string | null) => void = () => {};

const initData: IframeInitializer = {
    scanData: null,
    exportFormat: null,
};

let initMessageReceived = false;

let patientName = 'patientName';
let version = 0;
let orderId: string | number = 'orderId';
let editorClosed = false;

let initializePromiseResolver = () => {};
const initializePromise = new Promise<void>(
    res => (initializePromiseResolver = res),
);

function getFileName(customText: string, extension: string) {
    return `${orderId}_${customText}_${patientName}_v${version}.${extension}`;
}

@Injectable()
export class IframeCommunicationsService {
    private lastMessageId = 0;

    constructor() {
        if (!IsIframe()) return;
        this.initializeIframeCommunication();
        this.createListener();
    }

    async initialize() {
        await initializePromise;
        return initData;
    }

    // Returns true if the export is successful
    // null if the approval failed without a message
    // A string if the approval failed, and a message was provided
    async approveExport(): Promise<true | string | null> {
        this.sendMessage('model_export_start');

        const approved = await new Promise<true | string | null>(
            resolve => (exportApprovalResolver = resolve),
        );
        exportApprovalResolver = () => {};
        return approved;
    }

    async uploadExportResult(uploadData: IframeUploadData) {
        // Increment version with each upload
        // When not set by the init message, then the first uploaded version will be v1
        ++version;

        const zipInput: ZipInput[] = [];
        const fileFormat = initData.exportFormat ?? defaultExportFormat;

        // Exported meshes
        for (const file of uploadData.meshFiles) {
            zipInput.push({
                input: file.bytes,
                name: getFileName(
                    `design_${orthosisName}${file.name}`,
                    fileFormat,
                ),
            });
        }

        const zipResponse = downloadZip(zipInput);
        const zipBytes = new Uint8Array(await zipResponse.arrayBuffer());

        // Set this to true to download the zip file instead of uploading it
        const debugDownloadZip = true;

        if (debugDownloadZip) {
            const a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);

            const url = URL.createObjectURL(new Blob([zipBytes]));
            a.href = url;
            a.download = `${orthosisName}_export.zip`;
            a.click();

            setTimeout(() => {
                a.remove();
                URL.revokeObjectURL(url);
            }, 2000);

            return true;
        } else {
            //TODO: Upload
            try {
                const uploadResult = await fetch(exportUploadUrl, {
                    method: 'PUT',
                    body: zipBytes,
                    headers: {
                        'Content-Type': 'application/zip',
                    },
                });

                return uploadResult.ok;
            } catch {
                return false;
            }
        }
    }

    public closeEditor() {
        if (editorClosed) {
            return;
        }

        document.cookie =
            'session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        this.sendMessage('closed_IFrame');
        editorClosed = true;
    }

    public initializeIframeCommunication() {
        this.sendMessage('loaded_IFrame');
    }

    private sendMessage(messageType: string, data?: any) {
        if (!IsIframe()) {
            console.error('The editor is not running from an iframe');
            return;
        }

        const msg: any = {
            EVENT_NAME: messageType,
            timestamp: new Date().getTime(),
            messageId: this.lastMessageId++,
        };

        if (typeof data === 'object') {
            msg.data = data;
        }

        window.parent.postMessage(msg, '*');
    }

    private createListener() {
        window.addEventListener('message', ev => {
            const { data } = ev;
            const eventName = data.EVENT_NAME;

            if (typeof eventName !== 'string') return;

            switch (eventName) {
                case 'init_data': {
                    if (initMessageReceived) {
                        console.error('Already initialized');
                        return;
                    }

                    const messageStructureValid = (() => {
                        if (!IsObject(data.data)) {
                            console.error('`data` is missing or not an object');
                            return false;
                        }

                        if (typeof data.data.export_URL !== 'string') {
                            console.error(
                                '`data.export_URL` is missing or not a string',
                            );
                            return false;
                        }

                        if (!IsObject(data.data.file_name_data)) {
                            console.error(
                                '`data.file_name_data` is missing or not an object',
                            );
                            return false;
                        }

                        if (
                            typeof data.data.file_name_data.patient_name !==
                            'string'
                        ) {
                            console.error(
                                '`data.file_name_data.patient_name` is missing or not a string',
                            );
                            return false;
                        }

                        if (
                            typeof data.data.file_name_data.version !== 'number'
                        ) {
                            console.error(
                                '`data.file_name_data.version` is missing or not a number',
                            );
                            return false;
                        }

                        if (
                            typeof data.data.file_name_data.order_id !==
                                'string' &&
                            typeof data.data.file_name_data.order_id !==
                                'number'
                        ) {
                            console.error(
                                '`data.file_name_data.order_id` must be a string or a number',
                            );
                            return false;
                        }

                        return true;
                    })();

                    if (!messageStructureValid) {
                        console.error('Invalid message structure');
                        return;
                    }

                    initMessageReceived = true;
                    exportUploadUrl = data.data.export_URL;

                    function EnsureValidFileFormat(format: unknown) {
                        if (typeof format === 'string') {
                            format = format.toLowerCase();
                        }

                        switch (format) {
                            case 'stl':
                            case 'obj':
                            case null:
                                return format;
                            default:
                                return null;
                        }
                    }

                    initData.scanData = {
                        url:
                            EnsureStringOrNullish(
                                data.data.scan_import_data?.url,
                            ) ?? null,
                        importFormat:
                            EnsureValidFileFormat(
                                data.data.scan_import_data?.format,
                            ) ?? null,
                    };

                    initData.exportFormat = EnsureValidFileFormat(
                        data.data.export_format,
                    );

                    patientName = data.data.file_name_data.patient_name;
                    version = data.data.file_name_data.version;
                    orderId = String(data.data.file_name_data.order_id);

                    const msgOrderVersion = data.data.version;
                    const msgOrderId = data.data.orderId;

                    if (typeof msgOrderVersion === 'number') {
                        version = msgOrderVersion;
                    }

                    if (
                        typeof msgOrderId === 'string' ||
                        typeof msgOrderId === 'number'
                    ) {
                        orderId = String(msgOrderId);
                    }

                    initializePromiseResolver();
                    break;
                }

                case 'model_export_approved': {
                    exportApprovalResolver(true);
                    break;
                }

                case 'model_export_cancelled': {
                    const errorMessage =
                        typeof data.data?.message === 'string'
                            ? data.data.message
                            : null;
                    exportApprovalResolver(errorMessage);
                    break;
                }

                default:
                    console.error(`Unhandled event: "${eventName}"`);
                    return;
            }
        });
    }
}
