import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
    StyledButtonComponent,
    StyledDropdownComponent,
    StyledSliderWithInputComponent,
    StyledToggleComponent,
} from '@orthocore-web-mono/shared-ui';

import { ImageImportComponent } from './controls/image-import.component';
import { ImageImportService } from './image-import.service';
import { ImageImportLayerComponent } from './layer/image-import-layer.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,

        StyledButtonComponent,
        StyledDropdownComponent,
        StyledToggleComponent,
        StyledSliderWithInputComponent,
    ],
    declarations: [ImageImportComponent, ImageImportLayerComponent],
    providers: [ImageImportService],
    exports: [ImageImportComponent, ImageImportLayerComponent],
})
export class FeatureImageImportModule {}
