import {
    GeometryUpdateFlags,
    HistoryOperation,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
} from '@orthocore-web-mono/shared-types';

import { HistoryOperationData } from './operation-base';

export abstract class MeshOperationBase<
    T extends IEngineWorkerServiceDataBase,
> extends HistoryOperationData {
    constructor(protected readonly engine: IEngineWorkerService<T>) {
        super();
    }

    protected engineHistoryStateBeforeOperation: number | null = null;
    private engineHistoryState: number | null = null;

    private valid = true;

    public async do() {
        this.engineHistoryStateBeforeOperation =
            await this.engine.getScanHistoryState();

        await this.doEngineOperation();
        this.engineHistoryState = await this.engine.getScanHistoryState();

        if (
            this.engineHistoryStateBeforeOperation === this.engineHistoryState
        ) {
            // The operation did not change anything
            this.valid = false;
        } else {
            // footScanMeshChanged.dispatchEvent();
        }
    }

    public async undo() {
        if (this.engineHistoryStateBeforeOperation === null) {
            return;
        }

        await this.engine.setScanHistoryState(
            HistoryOperation.Undo,
            this.engineHistoryStateBeforeOperation,
        );
        // footScanMeshChanged.dispatchEvent();
    }

    public async redo() {
        if (this.engineHistoryState === null) {
            return;
        }

        await this.engine.setScanHistoryState(
            HistoryOperation.Redo,
            this.engineHistoryState,
        );
        // footScanMeshChanged.dispatchEvent();
    }

    public abstract override scanMeshChangeFlags(): GeometryUpdateFlags;

    public abstract override needsLandmarkSync(): boolean;

    public override isValid() {
        return this.valid;
    }

    protected abstract doEngineOperation(): void | Promise<void>;
}
