import { Inject, Injectable } from '@angular/core';
import { NavigationService } from '@orthocore-web-mono/feature-core-services';
import { UndoRedoService } from '@orthocore-web-mono/feature-undo-redo-reset';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    GeometryUpdateFlags,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    SCAN_SERVICE_TOKEN,
    SculptParametersJS,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import * as THREE from 'three';

import { sculptMaterial } from '../materials/sculpt.material';
import { SculptOperation } from '../mesh-edit-operation';

const sculptMouseButton = 0; // Left click
const defaultRadius = 2;
const defaultStrength = 0.5;

const sculptCursorObject = new THREE.Mesh(
    new THREE.IcosahedronGeometry(1, 6),
    new THREE.MeshBasicMaterial({
        color: 0x0086ff,
        transparent: true,
        opacity: 0.3,
        depthWrite: false,
    }),
);
sculptCursorObject.visible = false;
sculptCursorObject.renderOrder = 1;
sculptCursorObject.scale.setScalar(defaultRadius);

let engineParametersNeedUpdate = true;
let sculptingMouseDown = false;

const modes: SculptParametersJS['mode'][] = ['Smooth', 'Flatten'];

@Injectable()
export class SculptingService {
    public selectedModeIndex = 0;
    public _radius = defaultRadius;
    public _strength = defaultStrength;

    private isOnSection$ = this.nav.selectedSection$.pipe(
        map(section => section === 'sculpting'),
        distinctUntilChanged(),
    );

    private activate$ = this.isOnSection$.pipe(filter(v => v));
    private deactivate$ = this.isOnSection$.pipe(filter(v => !v));

    constructor(
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: IScanService,
        private readonly sceneService: SceneService,
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        private readonly undoRedoService: UndoRedoService,
        private readonly nav: NavigationService,
    ) {
        this.sceneService.scene.add(sculptCursorObject);

        this.activate$.subscribe(() => this.activate());
        this.deactivate$.subscribe(() => this.deactivate());
    }

    public changeSelectedModeIndex(index: number) {
        engineParametersNeedUpdate = true;
        this.selectedModeIndex = index;
    }

    public set radius(value: number) {
        engineParametersNeedUpdate = true;
        this._radius = value;
        sculptCursorObject.scale.setScalar(value);
    }

    public get radius(): number {
        return this._radius;
    }

    public set strength(value: number) {
        engineParametersNeedUpdate = true;
        this._strength = value;
    }

    public get strength(): number {
        return this._strength;
    }

    private activate() {
        this.scanService.setMaterial(sculptMaterial);

        this.sceneService.mouseDown$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.mouseDown(event));

        this.sceneService.mouseUp$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.mouseUp(event));

        this.sceneService.mouseMove$
            .pipe(takeUntil(this.deactivate$))
            .subscribe(event => this.mouseMove(event));
    }

    private deactivate() {
        this.scanService.setMaterial();
    }

    private mouseDown(ev: MouseEvent) {
        if (ev.button !== sculptMouseButton) {
            return;
        }

        if (engineParametersNeedUpdate) {
            engineParametersNeedUpdate = false;
            this.engine.setSculptParameters(this.CreateSculptParameters());
        }

        this.engine.sculptStart();
        sculptingMouseDown = true;
    }

    private mouseUp(ev: MouseEvent) {
        if (ev.button !== sculptMouseButton) {
            return;
        }

        if (!sculptingMouseDown) {
            return;
        }

        sculptingMouseDown = false;

        this.undoRedoService.doOperation(new SculptOperation(this.engine));
    }

    private async mouseMove(ev: MouseEvent) {
        const { origin, direction } = this.scanService.getMouseRay(ev);

        const raycastOnly = !sculptingMouseDown;

        const hitPoint = await this.engine.sculptUpdate(
            { x: origin.x, y: origin.y, z: origin.z },
            { x: direction.x, y: direction.y, z: direction.z },
            raycastOnly,
        );

        if (hitPoint !== null) {
            sculptCursorObject.visible = true;
            sculptCursorObject.position.set(hitPoint.x, hitPoint.y, hitPoint.z);
            this.sceneService.requestSceneRender();

            if (!raycastOnly) {
                // Update foot scan mesh - triangles don't change on sculpt
                this.scanService.syncScanMeshFromEngine(
                    GeometryUpdateFlags.Vertices | GeometryUpdateFlags.Normals,
                );
            }
        } else {
            if (sculptCursorObject.visible) {
                sculptCursorObject.visible = false;
                this.sceneService.requestSceneRender();
            }
        }
    }

    private CreateSculptParameters(): SculptParametersJS {
        // Note: some of the values are fixed, maybe can be changed later

        const currentMode = modes[this.selectedModeIndex];
        const isFlatten = currentMode === 'Flatten';
        const strengthMultiplier = isFlatten ? 0.1 : 1; // Use less strength for flatten

        return {
            cursorRadius: this.radius,
            cursorInnerRadius: 0.5,
            strength: this.strength * strengthMultiplier,

            falloffMode: 'SmoothStep',
            falloffParam: 1,

            mode: currentMode,
            smoothIterations: 1,
            flattenAngleThreshold: Math.PI / 2,
        };
    }
}
