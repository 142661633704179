/* eslint-disable max-params-no-constructor/max-params-no-constructor */
import { Injectable } from '@angular/core';
import { EngineWorkerServiceBase } from '@orthocore-web-mono/shared-feature-engine';
import {
    EmbossRaycastResult,
    EmbossSplineIndex,
    Float3JS,
    Float4JS,
    GeometryUpdateFlags,
    HistoryOperation,
    MeshExportFileFormat,
    MeshExportResult,
    Ptr,
} from '@orthocore-web-mono/shared-types';
import {
    ApplySnapshottedLandmarks,
    EmbossParametersMessage,
    EmbossRaycastMessage,
    EmbossSplineIndexMessage,
    EmbosserParameters,
    ExportSPBMeshMessage,
    ExportScanMeshMessage,
    ExportScanMeshResult,
    GenerateSPBMessage,
    GenerateSPBResult,
    GetLandmarksMessage,
    GetLandmarksResponse,
    GetSPBBuiltinDesignsMessage,
    GetSPBBuiltinDesignsResponse,
    GetSPBContourSplineInfoMessage,
    GetSPBContourSplineResponse,
    GetSPBDataMessage,
    GetSPBDataResponse,
    GetScanHistoryStateMessage,
    GetScanHistoryStateResponse,
    ISpinalEngineWorkerService,
    LoadReferenceScan,
    ScanRaycastMessage,
    ScanRaycastResult,
    ScanTransformMessage,
    ScanUndoRedoMessage,
    ScanUndoRedoResponse,
    SelectSPBBuiltinDesignMessage,
    SetLandmarkMessage,
    SetSPBContourSplineDataMessage,
    SetSPBDataMessage,
    SetScanHistoryStateMessage,
    SetScanHistoryStateResponse,
    SetScanMessage,
    SnapshotLandmarks,
    SpinalEngineWorkerData,
    TransformScanToMainAxisMessage,
    WeldScanMessage,
} from '@orthocore-web-mono/spinal-types';

@Injectable()
export class SpinalEngineWorkerService
    extends EngineWorkerServiceBase<SpinalEngineWorkerData>
    implements ISpinalEngineWorkerService
{
    override enablerServiceBeginExport(): Promise<string> {
        throw new Error('Method not implemented.');
    }
    override enablerServiceExport(): Promise<boolean> {
        throw new Error('Method not implemented.');
    }
    override enablerServiceEndExport(
        response: string,
        format: MeshExportFileFormat,
    ): Promise<Uint8Array> {
        throw new Error('Method not implemented.');
    }
    override getParameters(): Promise<unknown> {
        throw new Error('Method not implemented.');
    }
    override async snapshotScanLandmarks(): Promise<void> {
        await this.sendSimpleMessage<SnapshotLandmarks>(
            'snapshotLandmarks',
            {},
        );
    }
    async applySnapshottedScanLandmarks(): Promise<void> {
        await this.sendSimpleMessage<ApplySnapshottedLandmarks>(
            'applySnapshottedLandmarks',
            {},
        );
    }

    async generateMesh() {
        return (
            await this.sendSimpleMessage<GenerateSPBMessage, GenerateSPBResult>(
                'generateSpbMessage',
                {},
            )
        ).mesh;
    }

    override async exportMesh(format: MeshExportFileFormat) {
        return (
            await this.sendSimpleMessage<
                ExportSPBMeshMessage,
                MeshExportResult
            >('exportSpbMesh', { format })
        ).fileBytes;
    }

    async getContourSplineInfo(info: GetSPBContourSplineInfoMessage['info']) {
        return (
            await this.sendSimpleMessage<
                GetSPBContourSplineInfoMessage,
                GetSPBContourSplineResponse
            >('getSpbContourSplineInfoMessage', { info })
        ).data as any;
    }

    async setContourSplineData(data: SetSPBContourSplineDataMessage['data']) {
        await this.sendSimpleMessage<SetSPBContourSplineDataMessage>(
            'setSpbContourSplineDataMessage',
            { data },
        );
    }

    async transformToMainAxis(): Promise<void> {
        await this.sendSimpleMessage<TransformScanToMainAxisMessage>(
            'transformScanToMainAxis',
            {},
        );
    }

    async getGeneralOffsetAndThickness() {
        const { globalOffset, globalThickness } = await this.sendSimpleMessage<
            GetSPBDataMessage,
            GetSPBDataResponse
        >('getSpbDataMessage', {});
        return { globalOffset, globalThickness };
    }

    async setGeneralOffsetAndThickness(
        globalOffset: number,
        globalThickness: number,
    ) {
        await this.sendSimpleMessage<SetSPBDataMessage>('setSpbDataMessage', {
            globalOffset,
            globalThickness,
        });
    }

    async getBuiltinDesigns() {
        return (
            await this.sendSimpleMessage<
                GetSPBBuiltinDesignsMessage,
                GetSPBBuiltinDesignsResponse
            >('getSpbBuiltinDesignsMessage', {})
        ).designNames;
    }

    async selectBuiltinDesign(designIndex: number) {
        await this.sendSimpleMessage<SelectSPBBuiltinDesignMessage>(
            'selectASpbBuiltinDesignMessage',
            { designIndex },
        );
    }

    async loadReferenceScan() {
        //WHAAAAA
        await this.sendSimpleMessage<LoadReferenceScan>(
            'loadReferenceScan',
            {},
        );
    }

    async setScan(meshPtr: Ptr) {
        await this.sendSimpleMessage<SetScanMessage>('setScan', { meshPtr });
    }

    async weldScan(meshPtr: Ptr) {
        //WHAAAAA
        await this.sendSimpleMessage<WeldScanMessage>('weldScan', { meshPtr });
    }

    async setScanLandmark(name: number, position: Float3JS) {
        //WHAAAAA
        await this.sendSimpleMessage<SetLandmarkMessage>('setLandmark', {
            name,
            position,
        });
    }

    async getLandmarks() {
        const {
            PubicBone,
            GreaterTrochanterFemurLeft,
            GreaterTrochanterFemurRight,
            AnteriorSuperiorIliacSpineLeft,
            AnteriorSuperiorIliacSpineRight,
            XiphoidProcessOfSternum,
            ManubriumOfSternum,
            AcromioclavicularLeft,
            AcromioclavicularRight,
            C7,
            ScapulaLeft,
            ScapulaRight,
        } = await this.sendSimpleMessage<
            GetLandmarksMessage,
            GetLandmarksResponse
        >('getLandmarks', {});

        return {
            PubicBone,
            GreaterTrochanterFemurLeft,
            GreaterTrochanterFemurRight,
            AnteriorSuperiorIliacSpineLeft,
            AnteriorSuperiorIliacSpineRight,
            XiphoidProcessOfSternum,
            ManubriumOfSternum,
            AcromioclavicularLeft,
            AcromioclavicularRight,
            C7,
            ScapulaLeft,
            ScapulaRight,
        };
    }

    async scanUndoRedo(isUndo: boolean) {
        const { success, stillCanUndo, stillCanRedo } =
            await this.sendSimpleMessage<
                ScanUndoRedoMessage,
                ScanUndoRedoResponse
            >('scanUndoRedo', { isUndo });

        return { success, stillCanUndo, stillCanRedo };
    }

    async getScanHistoryState() {
        return (
            await this.sendSimpleMessage<
                GetScanHistoryStateMessage,
                GetScanHistoryStateResponse
            >('getScanHistoryState', {})
        ).state;
    }

    async setScanHistoryState(
        operation: HistoryOperation,
        targetState: number,
    ) {
        return (
            await this.sendSimpleMessage<
                SetScanHistoryStateMessage,
                SetScanHistoryStateResponse
            >('setScanHistoryState', { operation, targetState })
        ).success;
    }

    async scanTransform(
        translation?: Float3JS,
        rotation?: Float4JS,
        scale?: number,
    ) {
        await this.sendSimpleMessage<ScanTransformMessage>('scanTransform', {
            translation,
            rotation,
            scale,
        });
    }

    async scanRaycast(
        rayOrigin: Float3JS,
        rayDirection: Float3JS,
        includeForefootFull = false,
    ) {
        return (
            await this.sendSimpleMessage<ScanRaycastMessage, ScanRaycastResult>(
                'scanRaycast',
                { rayOrigin, rayDirection, includeForefootFull },
            )
        ).hitPoint;
    }

    async scanExport(exportFlags: GeometryUpdateFlags) {
        return (
            await this.sendSimpleMessage<
                ExportScanMeshMessage,
                ExportScanMeshResult
            >('exportScanMeshMessage', { exportFlags })
        ).mesh;
    }

    async setEmbosserParameters(parameters: EmbosserParameters) {
        await this.sendSimpleMessage<EmbossParametersMessage>(
            'embossTextAndLogoParameters',
            { parameters },
        );
    }

    async setEmbossPlane(rayOrigin: Float3JS, rayDirection: Float3JS) {
        return await this.sendSimpleMessage<
            EmbossRaycastMessage,
            EmbossRaycastResult
        >('embossRaycastParameters', {
            rayOrigin,
            rayDirection,
        });
    }

    async getEmbossSplineIndex() {
        return await this.sendSimpleMessage<
            EmbossSplineIndexMessage,
            EmbossSplineIndex
        >('embossSplineIndexMessage', {});
    }
}
