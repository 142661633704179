import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
    FittingPlane,
    MeasurementMode,
    ScanCleanSelectionMode,
} from '@orthocore-web-mono/shared-types';
import { cloneDeep } from 'lodash';

import * as EditorActions from './editor.actions';
import { EditorState } from './editor.models';

export const EDITOR_FEATURE_KEY = 'editor';

export interface EditorPartialState {
    readonly [EDITOR_FEATURE_KEY]: EditorState;
}

export const editorAdapter: EntityAdapter<EditorState> =
    createEntityAdapter<EditorState>();

export const initialEditorState: EditorState = editorAdapter.getInitialState({
    // set initial required properties
    loadedEngine: false,
    error: null,
    selectedStep: 'file',

    loadedModel: false,

    showGroundPlane: false,
    showLandMarks: false,
    isOrthographicsView: false,
    showMeasurementsPanel: false,

    // scanSettingsScaleUnit: ScanSettingsScaleUnit.MM,
    opacity: 1,

    scanTransformSettings: {
        mode: 'rotate',
    },

    scanCrearSettings: {
        selectionMode: ScanCleanSelectionMode.Rect,
    },

    importImage: {
        imageLoaded: false,
        fittingPlane: FittingPlane.Coronal,
        showImage: true,
        mirrorImage: false,
        opacity: 100,
    },
});

function onloadEngineSuccess(state: EditorState): EditorState {
    return { ...state, loadedEngine: true };
}

function onLoadEditorFailure(
    state: EditorState,
    { error }: { error: string | null },
): EditorState {
    return {
        ...state,
        error,
    };
}

function onLoadedModel(state: EditorState): EditorState {
    return { ...state, loadedModel: true };
}

function onChangeShowGroundPlane(
    state: EditorState,
    { value }: { value: boolean },
): EditorState {
    return {
        ...state,
        showGroundPlane: value,
    };
}

function onChangeShowLandmarks(
    state: EditorState,
    { value }: { value: boolean },
): EditorState {
    return {
        ...state,
        showLandMarks: value,
    };
}

function onChangeIsOrthographicsView(
    state: EditorState,
    { value }: { value: boolean },
): EditorState {
    return {
        ...state,
        isOrthographicsView: value,
    };
}

function onChangeShowMeasurementsPanel(
    oldState: EditorState,
    { value }: { value: boolean },
): EditorState {
    const state = cloneDeep(oldState);
    state.showMeasurementsPanel = value;
    state.measurementMode = value
        ? state.measurementMode ?? 'distance'
        : undefined; //Default

    //If Measurement panel is to be closed remove the event handler but only if the handler stayed one of them
    if (
        state.activeHandler &&
        !value &&
        ['distance', 'girth'].indexOf(state.activeHandler)
    ) {
        state.activeHandler = undefined;
    } else {
        state.activeHandler = state.measurementMode;
    }
    return state;
}

function onSetMeasurementMode(
    state: EditorState,
    { mode }: { mode: MeasurementMode },
): EditorState {
    return {
        ...state,
        measurementMode: mode,
        activeHandler: mode,
    };
}

function onSetOpacity(
    state: EditorState,
    { value }: { value: number },
): EditorState {
    return {
        ...state,
        opacity: value,
    };
}

function onSetMeasuredDistance(
    state: EditorState,
    { value }: { value?: number },
): EditorState {
    return {
        ...state,
        measuredDistance: value,
    };
}

function onSetMeasuredGirth(
    state: EditorState,
    { value }: { value?: number },
): EditorState {
    return {
        ...state,
        measuredGirth: value,
    };
}

function onclearMeasurementPoints(state: EditorState): EditorState {
    return {
        ...state,
        measuredDistance: undefined,
    };
}

function onSetScanTransformMode(
    state: EditorState,
    { mode }: { mode?: 'translate' | 'rotate' },
): EditorState {
    return {
        ...state,
        scanTransformSettings: {
            ...state.scanTransformSettings,
            mode,
        },
    };
}

function onChangeScanClearSelectionMode(
    state: EditorState,
    { mode }: { mode: ScanCleanSelectionMode },
): EditorState {
    return {
        ...state,
        scanCrearSettings: {
            ...state.scanCrearSettings,
            selectionMode: mode,
        },
    };
}

// Mesh edit / Import image
function onMeshEditImageLoaded(
    state: EditorState,
    { loaded }: { loaded: boolean },
): EditorState {
    return {
        ...state,
        importImage: {
            ...state.importImage,
            imageLoaded: loaded,
        },
    };
}

function onChangeMeshEditFittingPlane(
    state: EditorState,
    { plane }: { plane: FittingPlane },
): EditorState {
    return {
        ...state,
        importImage: {
            ...state.importImage,
            fittingPlane: plane,
        },
    };
}

function onChangeMeshEditImageImportShow(
    state: EditorState,
    { show }: { show: boolean },
): EditorState {
    return {
        ...state,
        importImage: {
            ...state.importImage,
            showImage: show,
        },
    };
}

function onchangeMeshEditImageImportOpacity(
    state: EditorState,
    { opacity }: { opacity: number },
): EditorState {
    return {
        ...state,
        importImage: {
            ...state.importImage,
            opacity,
        },
    };
}

const reducer = createReducer(
    initialEditorState,
    on(EditorActions.loadEngineSuccess, onloadEngineSuccess),
    on(EditorActions.loadEditorFailure, onLoadEditorFailure),
    on(EditorActions.loadedModel, onLoadedModel),

    on(EditorActions.changeShowGroundPlane, onChangeShowGroundPlane),
    on(EditorActions.changeShowLandmarks, onChangeShowLandmarks),
    on(EditorActions.changeIsOrthographicsView, onChangeIsOrthographicsView),
    on(
        EditorActions.changeShowMeasurementsPanel,
        onChangeShowMeasurementsPanel,
    ),
    on(EditorActions.setMeasurementMode, onSetMeasurementMode),
    on(EditorActions.setMeasuredDistance, onSetMeasuredDistance),
    on(EditorActions.setMeasuredGirth, onSetMeasuredGirth),
    on(EditorActions.setOpacity, onSetOpacity),
    on(EditorActions.clearMeasurementPoints, onclearMeasurementPoints),

    on(EditorActions.setScanTransformMode, onSetScanTransformMode),

    // Mesh edit / Import image
    on(EditorActions.meshEditImageLoaded, onMeshEditImageLoaded),
    on(
        EditorActions.changeMeshEditImageFittingPlane,
        onChangeMeshEditFittingPlane,
    ),
    on(
        EditorActions.changeMeshEditImageImportShow,
        onChangeMeshEditImageImportShow,
    ),
    on(
        EditorActions.changeMeshEditImageImportOpacity,
        onchangeMeshEditImageImportOpacity,
    ),
);

export function editorReducer(state: EditorState | undefined, action: Action) {
    return reducer(state, action);
}
