import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
} from '@angular/core';
import {
    ToolLoopScaleDirectionJS,
    ToolLoopScaleModeJS,
} from '@orthocore-web-mono/shared-types';

import { LoopToolBaseComponent } from '../loop-tool-base.component';
import { ScaleLoopToolService } from './scale-loop-tool.service';

@Component({
    selector: 'leo-scale-loop-tool',
    templateUrl: './scale-loop-tool.component.html',
    styleUrl: './scale-loop-tool.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleLoopToolComponent extends LoopToolBaseComponent<ScaleLoopToolService> {
    Mode = ToolLoopScaleModeJS;
    Direction = ToolLoopScaleDirectionJS;

    public showTopPositionInput = true;
    public showBottomPositionInput = true;
    public showDirectionInput = false;

    public modes = [
        'MESH_EDIT.LOOP_TOOLS.TOP',
        'MESH_EDIT.LOOP_TOOLS.BOTTOM',
        'MESH_EDIT.LOOP_TOOLS.MIDDLE',
        'MESH_EDIT.LOOP_TOOLS.ALL',
    ];

    public directions = [
        'MESH_EDIT.LOOP_TOOLS.DIRECTION1',
        'MESH_EDIT.LOOP_TOOLS.DIRECTION2',
        'MESH_EDIT.LOOP_TOOLS.ALL_DIRECTIONS',
    ];

    public selectedModeIndex = 2;
    public selectedDirectionIndex = 2;

    constructor(change: ChangeDetectorRef, service: ScaleLoopToolService) {
        super(change, service);
    }

    public onModeChanged(index: number) {
        this.params.scaleMode = this.mapMode(index);
        if (this.params.scaleMode !== ToolLoopScaleModeJS.Middle) {
            this.params.scaleMiddleSize = 0;
        }

        this.showTopPositionInput =
            [this.Mode.Top, this.Mode.Middle].findIndex(
                x => x === this.params.scaleMode,
            ) !== -1;
        this.showBottomPositionInput =
            [this.Mode.Bottom, this.Mode.Middle].findIndex(
                x => x === this.params.scaleMode,
            ) !== -1;
        this.service.update();
    }

    public onDirectionChange(index: number) {
        this.params.scaleDir = this.mapDirection(index);
        this.showDirectionInput =
            this.params.scaleDir !== ToolLoopScaleDirectionJS.Full;
        this.service.update();
    }

    private mapMode(index: number) {
        switch (index) {
            case 0:
                return ToolLoopScaleModeJS.Top;
            case 1:
                return ToolLoopScaleModeJS.Bottom;
            case 2:
                return ToolLoopScaleModeJS.Middle;
            case 3:
                return ToolLoopScaleModeJS.All;
        }
        return ToolLoopScaleModeJS.Bottom;
    }

    private mapDirection(index: number) {
        switch (index) {
            case 0:
                return ToolLoopScaleDirectionJS.Single;
            case 1:
                return ToolLoopScaleDirectionJS.Double;
            case 2:
                return ToolLoopScaleDirectionJS.Full;
        }
        return ToolLoopScaleDirectionJS.Single;
    }
}
