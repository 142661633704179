import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    StyledSliderComponent,
    StyledSliderValueChange,
} from '../styled-slider/styled-slider.component';

export interface AxisSliderSingleAxisDescriptor {
    minValue: number;
    maxValue: number;
    defaultValue?: number;
}

export interface AxisSliderDescriptor {
    x: AxisSliderSingleAxisDescriptor;
    y: AxisSliderSingleAxisDescriptor;
    z: AxisSliderSingleAxisDescriptor;
    step?: number;
    multiplier?: number;
    unit?: string;
}

@Component({
    selector: 'leo-styled-slider-with-input',
    standalone: true,
    imports: [CommonModule, StyledSliderComponent],
    templateUrl: './styled-slider-with-input.component.html',
    styleUrl: './styled-slider-with-input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyledSliderWithInputComponent implements OnInit {
    @Input() title = '';
    @Input() min = 0;
    @Input() max = 1;
    @Input() step?: number | 'any';
    @Input() unit = '';
    @Input() multiplier = 1;
    @Input() color?: string;
    @Input() hoverColor?: string;

    @Input() disabled = false;

    @Output() valueChange = new EventEmitter<number>();
    @Output() valueChangeFinished = new EventEmitter<void>();

    private displayedDecimals = 3;

    public _value = 0;
    public get value(): number {
        return this._value;
    }

    @Input()
    public set value(value: number) {
        this._value = value;
        this._inputValue = this.roundValue(value / this.multiplier);
    }

    public _inputValue = 0;
    public get inputValue(): number {
        return this._inputValue;
    }

    ngOnInit(): void {
        this.setDecimalSteps();
        this._inputValue = this.roundValue(this._value / this.multiplier);
    }

    onSliderValueChange(event: StyledSliderValueChange) {
        this._value = event.value;
        this._inputValue = this.roundValue(this._value / this.multiplier);
        this.valueChange.emit(this._value);
    }

    onSliderValueChangeFinished(event: StyledSliderValueChange) {
        this._value = event.value;
        this._inputValue = this.roundValue(this.value / this.multiplier);
        this.valueChangeFinished.emit();
    }

    onNumberInputChange(event: Event) {
        let value = +(event.target as HTMLInputElement).value;

        if (value < this.min) value = this.min;
        if (value > this.max) value = this.max;

        value = this.roundValue(value);
        this._inputValue = this.roundValue(value);
        this._value = value * this.multiplier;

        this.valueChange.emit(this._value);
        this.valueChangeFinished.emit();
    }

    private roundValue(value: number) {
        return parseFloat(value.toFixed(this.displayedDecimals));
    }

    private setDecimalSteps() {
        if (this.step === 'any') {
            return;
        }

        let numberStep = Number(this.step);
        if (!isNaN(numberStep) && numberStep >= 0) {
            const maxDecimals = 10;
            let currentDecimals = 0;

            for (let i = 0; i < maxDecimals; ++i) {
                if (1 / numberStep <= 1) {
                    break;
                }

                ++currentDecimals;
                numberStep *= 10;
            }

            this.displayedDecimals = currentDecimals;
        }
    }
}
