import { GeometryUpdateFlags } from '@orthocore-web-mono/shared-types';

export abstract class HistoryOperationData {
    public abstract do(): void | Promise<void>;
    public abstract undo(): void | Promise<void>;
    public abstract redo(): void | Promise<void>;

    public isInternal(): boolean {
        return false;
    }

    public isValid() {
        // If an overridden class returns false, then the operation is not recorded
        // This can happen for example, if a mesh operation doesn't change the mesh (because no sliders were changed)
        return true;
    }

    public scanMeshChangeFlags(): GeometryUpdateFlags {
        // If an overridden class returns something other than None, then the foot scan mesh needs to be synced from the engine
        return GeometryUpdateFlags.None;
    }

    public needsLandmarkSync() {
        // If true, then the landmarks will be synced from the engine after the operation
        return false;
    }
}
