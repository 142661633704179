/* eslint-disable max-params-no-constructor/max-params-no-constructor */
import { unreachable } from './utils';

/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable complexity */

/* eslint-disable @typescript-eslint/no-explicit-any */

export const pointerSize = 4;
export const enumSize = 4;

export const enum NativeType {
    Pointer,
    Byte,
    Char,
    /** 4byte int. 0 or 1. */
    LeoBool,
    Int,
    Float,
    CString,
}

export function GetSize(type: NativeType): number {
    switch (type) {
        case NativeType.Pointer:
        case NativeType.CString:
            return pointerSize;
        case NativeType.Byte:
        case NativeType.Char:
            return 1;
        case NativeType.LeoBool:
            return 4;
        case NativeType.Int:
            return 4;
        case NativeType.Float:
            return 4;
    }
}

// need to use a class here for instanceof
export class NativeArrayDescriptor<
    T extends NativeStructDescriptor | NativeType,
> {
    public type: T;
    public count: number;

    constructor(type: T, count: number) {
        this.type = type;
        this.count = count;
    }
}

export class NativeEnumDescriptor<T extends { [key: string]: number }> {
    public descriptor: T;
    private reverseMap = new Map<number, keyof T>();

    public readonly keyTypeHolder!: keyof T;
    public readonly valueTypeHolder!: T[keyof T];

    constructor(descriptor: T) {
        this.descriptor = descriptor;

        for (const key in descriptor) {
            const typedKey = key as keyof T;
            this.reverseMap.set(descriptor[typedKey], typedKey);
        }
    }

    public valueToName(value: number) {
        return this.reverseMap.get(value) ?? null;
    }

    public valueToNameTyped<TValue extends T[keyof T]>(value: TValue) {
        const name = this.reverseMap.get(value);
        console.assert(name !== undefined);
        return name!;
    }
}

export function createJSEnumFromNativeEnum<T extends Record<string, unknown>>(
    obj: T,
): { [K in keyof T]: K } {
    return Object.keys(obj).reduce((acc, key) => {
        acc[key as keyof T] = key;
        return acc;
    }, {} as { [K in keyof T]: K });
}

export const createJSEnumFromNumberedDesc = (obj: object) =>
    Object.fromEntries(Object.entries(obj).map(a => a.reverse()));

export type NativeStructDescriptor =
    | { readonly [key: string]: NativeStructDescriptor }
    | NativeType
    | NativeArrayDescriptor<NativeStructDescriptor>
    | NativeEnumDescriptor<any>;

export type DeepWriteable<T> = {
    -readonly [P in keyof T]: DeepWriteable<T[P]>;
};

export type ToJsType<T> = T extends NativeArrayDescriptor<infer Ty>
    ? Ty extends NativeType.Char
        ? string
        : ToJsType<Ty>[]
    : T extends NativeEnumDescriptor<infer Ty>
    ? keyof Ty
    : T extends NativeType.Pointer
    ? number
    : T extends NativeType.LeoBool
    ? boolean
    : T extends NativeType.Int
    ? number
    : T extends NativeType.Byte
    ? number
    : T extends NativeType.Float
    ? number
    : T extends NativeType.CString
    ? string | null
    : T extends NativeStructDescriptor
    ? { [p in keyof DeepWriteable<T>]: ToJsType<T[p]> }
    : never;

export function GetAlignedIndex(currentIndex: number, size: number) {
    // size must be a power of 2

    console.assert(size > 0);

    // reads/writes should be at multiples of the size
    const shift = 31 - Math.clz32(size);
    const shifted = currentIndex >>> shift;

    const roundedDown = shifted << shift;
    if (currentIndex === roundedDown) {
        // already aligned
        return currentIndex;
    }

    console.trace('Unaligned data access, skipping forwards');

    // round up
    return (shifted + 1) << shift;
}

export function CreateNativeStruct<T extends NativeStructDescriptor>(
    descriptor: T,
    debugNativeStructName?: string,
) {
    type InputType = ToJsType<T>;

    const generateDebugReport = debugNativeStructName !== undefined;
    const debugReport: string[] = [];

    function CalculateSize(desc: T, depth: number) {
        let totalSize = 0;

        if (desc instanceof NativeEnumDescriptor) {
            totalSize += enumSize;
        } else if (typeof desc === 'number') {
            // primitive type
            const size = GetSize(desc);
            totalSize = GetAlignedIndex(totalSize, size) + size;
        } else if (desc instanceof NativeArrayDescriptor) {
            // array
            totalSize +=
                CalculateSize(desc.type as any, depth + 1) * desc.count;
        } else {
            // other struct

            for (const k in desc) {
                if (generateDebugReport && depth === 0) {
                    debugReport.push(
                        `static_assert(offsetof(${debugNativeStructName}, ${k}) == ${totalSize});`,
                    );
                }

                totalSize += CalculateSize(desc[k] as any, depth + 1);
            }
        }

        return totalSize;
    }

    const size = CalculateSize(descriptor, 0);

    if (generateDebugReport) {
        console.log(debugReport.join('\n'));
    }

    class NativeStruct {
        public static readonly nativeSize = size;

        public static readonly typeHolder: InputType; // no actual data, just using this for the type

        public input: InputType;

        constructor(input: InputType) {
            this.input = input;
        }

        public static copyToNativeStatic(
            input: InputType,
            HEAPU8: Uint8Array,
            targetByteOffset: number,
            mallocFunction: (size: number) => number,
            freeFunction: (ptr: number) => void,
        ) {
            const dataView = new DataView(
                HEAPU8.buffer,
                targetByteOffset,
                size,
            );

            const allocations: number[] = [];

            let currentByteOffset = 0;

            function ProcessStruct(desc: T, jsValue: any) {
                if (desc instanceof NativeEnumDescriptor) {
                    // enum
                    const key: string = jsValue;
                    const value: number = desc.descriptor[key];

                    currentByteOffset = GetAlignedIndex(
                        currentByteOffset,
                        enumSize,
                    );
                    dataView.setUint32(currentByteOffset, value, true);
                    currentByteOffset += enumSize;
                } else if (typeof desc === 'number') {
                    // primitive type

                    const size = GetSize(desc);
                    currentByteOffset = GetAlignedIndex(
                        currentByteOffset,
                        size,
                    );

                    switch (desc) {
                        case NativeType.Pointer:
                        case NativeType.Int:
                            dataView.setUint32(
                                currentByteOffset,
                                jsValue,
                                true,
                            );
                            break;
                        case NativeType.Byte:
                        case NativeType.Char:
                            dataView.setUint8(currentByteOffset, jsValue);
                            break;
                        case NativeType.LeoBool:
                            dataView.setUint32(
                                currentByteOffset,
                                jsValue ? 1 : 0,
                                true,
                            );
                            break;
                        case NativeType.Float:
                            dataView.setFloat32(
                                currentByteOffset,
                                jsValue,
                                true,
                            );
                            break;
                        case NativeType.CString: {
                            const str = jsValue as string;
                            const bytes = new TextEncoder().encode(str);
                            const ptr = mallocFunction(bytes.length + 1);
                            dataView.setUint32(currentByteOffset, ptr, true);

                            HEAPU8.set(bytes, ptr);
                            HEAPU8[ptr + bytes.length] = 0; // null terminated

                            allocations.push(ptr);
                            break;
                        }
                        default:
                            unreachable(desc);
                    }

                    currentByteOffset += size;
                } else if (desc instanceof NativeArrayDescriptor) {
                    // array

                    if (typeof jsValue === 'string') {
                        // string to char array
                        if (desc.type !== NativeType.Char) {
                            throw Error(
                                'Only char arrays can be created from strings',
                            );
                        }

                        const textBytes = new TextEncoder().encode(jsValue);
                        const numBytesToWrite = Math.min(
                            textBytes.length,
                            desc.count - 1,
                        );

                        const startPtr = targetByteOffset + currentByteOffset;

                        HEAPU8.set(
                            textBytes.subarray(0, numBytesToWrite),
                            startPtr,
                        );
                        HEAPU8.fill(
                            0,
                            startPtr + numBytesToWrite,
                            startPtr + desc.count,
                        );

                        currentByteOffset += desc.count;
                    } else {
                        if (desc.count !== jsValue.length) {
                            throw Error(
                                'Array size mismatch, expected: ' +
                                    desc.count +
                                    ', actual: ' +
                                    jsValue.length,
                            );
                        }

                        for (let i = 0; i < desc.count; ++i) {
                            ProcessStruct(desc.type as any, jsValue[i]);
                        }
                    }
                } else {
                    // other struct

                    for (const k in desc) {
                        const fieldName = k as keyof T;
                        const fieldType = desc[fieldName];
                        const val = jsValue[fieldName];

                        ProcessStruct(fieldType as any, val);
                    }
                }
            }

            ProcessStruct(descriptor, input);

            return allocations.length === 0
                ? null
                : {
                      freeAllocations: () => allocations.forEach(freeFunction),
                  };
        }

        public copyToNative(
            HEAPU8: Uint8Array,
            targetByteOffset: number,
            mallocFunction: (size: number) => number,
            freeFunction: (ptr: number) => void,
        ) {
            return NativeStruct.copyToNativeStatic(
                this.input,
                HEAPU8,
                targetByteOffset,
                mallocFunction,
                freeFunction,
            );
        }

        static fromNative(HEAPU8: Uint8Array, ptr: number): InputType {
            const dataView = new DataView(HEAPU8.buffer, ptr, size);

            function DecodeString(ptr: number, maxLength: number | null) {
                if (ptr === 0) {
                    return '';
                }

                maxLength ??= Infinity;
                let length = 0;
                let current = ptr;
                while (HEAPU8[current] !== 0 && length++ < maxLength) {
                    ++current;
                }

                return new TextDecoder().decode(HEAPU8.subarray(ptr, current));
            }

            let currentByteOffset = 0;

            function ProcessStruct(desc: T, resultObj: any, key: any) {
                if (desc instanceof NativeEnumDescriptor) {
                    // enum
                    currentByteOffset = GetAlignedIndex(
                        currentByteOffset,
                        enumSize,
                    );
                    const value = dataView.getUint32(currentByteOffset, true);
                    const mappedValue = desc.valueToName(value);

                    if (mappedValue === null) {
                        throw Error(
                            'No enum value for: ' +
                                value +
                                '\nAvailable values: ' +
                                JSON.stringify(desc.descriptor),
                        );
                    }

                    resultObj[key] = mappedValue;
                    currentByteOffset += enumSize;
                } else if (typeof desc === 'number') {
                    // primitive type

                    const size = GetSize(desc);
                    currentByteOffset = GetAlignedIndex(
                        currentByteOffset,
                        size,
                    );

                    switch (desc) {
                        case NativeType.Pointer:
                        case NativeType.Int:
                            resultObj[key] = dataView.getUint32(
                                currentByteOffset,
                                true,
                            );
                            break;
                        case NativeType.Byte:
                        case NativeType.Char:
                            resultObj[key] =
                                dataView.getUint8(currentByteOffset);
                            break;
                        case NativeType.LeoBool:
                            resultObj[key] =
                                dataView.getUint32(currentByteOffset, true) !==
                                0;
                            break;
                        case NativeType.Float:
                            resultObj[key] = dataView.getFloat32(
                                currentByteOffset,
                                true,
                            );
                            break;
                        case NativeType.CString: {
                            const ptr = dataView.getUint32(
                                currentByteOffset,
                                true,
                            );
                            resultObj[key] = DecodeString(ptr, null);
                            break;
                        }
                        default:
                            unreachable(desc);
                    }

                    currentByteOffset += size;
                } else if (desc instanceof NativeArrayDescriptor) {
                    // array or string

                    if (desc.type === NativeType.Char) {
                        // string
                        resultObj[key] = DecodeString(
                            ptr + currentByteOffset,
                            desc.count,
                        );
                        currentByteOffset += desc.count;
                    } else {
                        const array: any[] = [];
                        resultObj[key] = array;

                        for (let i = 0; i < desc.count; ++i) {
                            ProcessStruct(desc.type as any, array, i);
                        }
                    }
                } else {
                    // other struct

                    const newObj = {};
                    resultObj[key] = newObj;

                    for (const k in desc) {
                        const fieldName = k as keyof T;
                        const fieldType = desc[fieldName];
                        ProcessStruct(fieldType as any, newObj, fieldName);
                    }
                }
            }

            const result = {} as any; // create an object so we can pass by reference
            ProcessStruct(descriptor, result, '');
            return result[''];
        }
    }

    return NativeStruct;
}
