import * as THREE from 'three';

export const landmarkGeometry = new THREE.SphereGeometry(0.3, 24, 16);

export const landmarkMaterial = new THREE.MeshBasicMaterial({
    color: 0x88b8d1,
});
export const landmarkMaterialHighlighted = new THREE.MeshBasicMaterial({
    color: 0x0086ff,
});
