<button
    [class.hidden]="hideReset()"
    [disabled]="disableReset()"
    (click)="onResetClick.emit()">
    <img
        src="assets/icons/reset.svg"
        style="height: 30px; transform: rotate(40deg)" />
</button>
<div class="undo-redo">
    <button
        [class.hidden]="hideUndo()"
        [disabled]="disableUndo()"
        (click)="onUndoClick.emit()">
        <img src="assets/icons/arrow.svg" />
    </button>
    <button
        [class.hidden]="hideRedo()"
        [disabled]="disableRedo()"
        (click)="onRedoClick.emit()">
        <img src="assets/icons/arrow.svg" style="transform: rotate(180deg)" />
    </button>
</div>
