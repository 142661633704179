<leo-styled-toggle
    [label]="'Enable component'"
    [checked]="isEnabled"
    (checkedChange)="onChange('isEnabled', $event, true)"></leo-styled-toggle>

<leo-styled-slider-with-input
    [title]="'Extra thickness'"
    [min]="-5"
    [max]="5"
    [step]="0.1"
    [value]="thickness"
    (valueChange)="onChange('sizeX', $event, true)"
    [unit]="mmUnit"
    [multiplier]="mm2cm"></leo-styled-slider-with-input>
<leo-styled-slider-with-input
    [title]="'Extra offset'"
    [min]="0"
    [max]="5"
    [step]="0.1"
    [value]="offset"
    (valueChange)="onChange('sizeY', $event, true)"
    [unit]="mmUnit"
    [multiplier]="mm2cm"></leo-styled-slider-with-input>
<leo-styled-slider-with-input
    [title]="'Transition length'"
    [min]="2"
    [max]="20"
    [step]="0.1"
    [value]="transitionLength"
    (valueChange)="onChange('transitionLength', $event, true)"
    [unit]="mmUnit"
    [multiplier]="mm2cm"></leo-styled-slider-with-input>
