import { Inject, Injectable } from '@angular/core';
import { EditorStageStatusService } from '@orthocore-web-mono/feature-core-services';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    FittingPlane,
    GeometryUpdateFlags,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
} from '@orthocore-web-mono/shared-types';
import {
    UpdateGeometryFromMeshData,
    UpdateOpacity,
} from '@orthocore-web-mono/shared/utils';
import { EditorFacade } from '@orthocore-web-mono/state';
import { BehaviorSubject, Subject, takeUntil, timer, toArray } from 'rxjs';
import * as THREE from 'three';

import { defaultGeneratedMeshMaterial } from '../materials/default-generated-mesh.material';
import { defaultScanMaterial } from '../materials/default-scan.material';
import { SceneService } from './scene-data.service';

@Injectable()
export class ScanService implements IScanService {
    public materials = new Map<string, THREE.Material>();

    public scan = new THREE.Mesh(new THREE.BufferGeometry());
    public generatedParts: THREE.Mesh[] = [];
    public generatedPartsGroup: THREE.Group = new THREE.Group();
    public generatedPartsMaterial = defaultGeneratedMeshMaterial;

    public generationStateChangeSubject = new BehaviorSubject<boolean>(false); //TODO: move to own service
    public generationStateChange$ = this.generationStateChangeSubject.pipe();

    private scanSyncSubject = new Subject<void>();
    public scanSync$ = this.scanSyncSubject.pipe();

    private scanOpacity = 1;

    private changeMaterialRequestSubject?: Subject<THREE.Material | undefined>;
    private baseMaterial?: THREE.Material;

    constructor(
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        public readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        public readonly sceneService: SceneService,
        public readonly facade: EditorFacade,
        private readonly status: EditorStageStatusService,
    ) {
        this.scan.frustumCulled = false;
        this.sceneService.scene.add(this.scan);

        this.setMaterial();

        this.facade.modelLoaded$.subscribe(loaded => {
            if (!loaded) return;
            this.sceneService.requestSceneRender();
        });

        //After loading set the default scale
        this.facade.modelLoaded$.subscribe(async loaded => {
            if (!loaded) return;
            await this.syncScanMeshFromEngine();
            this.status.modelLoaded$.next(true);
        });

        this.generationStateChangeSubject.subscribe(available => {
            this.generatedParts.forEach(part => (part.visible = available));
        });
    }

    public setScanOpacity(val: number): void {
        UpdateOpacity(this.scan, this.scan.material, val);

        this.scanOpacity = val;
        this.sceneService.requestSceneRender();
    }

    public setGeneratedOpacity(val: number): void {
        UpdateOpacity(
            this.generatedPartsGroup,
            this.generatedPartsMaterial,
            val,
        );

        this.sceneService.requestSceneRender();
    }

    public async syncScanMeshFromEngine(
        flags: GeometryUpdateFlags = GeometryUpdateFlags.All,
    ): Promise<void> {
        if (flags === GeometryUpdateFlags.None) {
            return;
        }

        const result = await this.engine.scanExport(flags);

        UpdateGeometryFromMeshData(this.scan.geometry, result, flags);
        this.sceneService.requestSceneRender();
        this.scanSyncSubject.next();
    }

    public setBaseMaterial(material: THREE.Material | undefined) {
        this.baseMaterial = material;
        this.setMaterial(material);
    }

    public setMaterial(material?: THREE.Material): void {
        if (!this.changeMaterialRequestSubject) {
            this.changeMaterialRequestSubject = new Subject();
            this.changeMaterialRequestSubject
                .pipe(takeUntil(timer(1)), toArray())
                .subscribe(res => this.changeMaterial(res));
        }

        this.changeMaterialRequestSubject.next(material);
    }

    private changeMaterial(materials: (THREE.Material | undefined)[]) {
        let material = materials.findLast(x => !!x);
        material ??= this.baseMaterial ?? defaultScanMaterial;

        this.scan.material = material;
        UpdateOpacity(this.scan, this.scan.material, this.scanOpacity);
        this.sceneService.requestSceneRender();
        this.changeMaterialRequestSubject = undefined;
    }

    public getMouseRay(ev: MouseEvent) {
        const mousePos = new THREE.Vector2();
        const raycaster = new THREE.Raycaster();

        mousePos.x =
            (ev.offsetX / this.sceneService.renderer.domElement.clientWidth) *
                2 -
            1;
        mousePos.y =
            (ev.offsetY / this.sceneService.renderer.domElement.clientHeight) *
                -2 +
            1;

        raycaster.setFromCamera(mousePos, this.sceneService.activeCamera);
        return raycaster.ray;
    }

    public async transformToMainAxis(): Promise<void> {
        // await this.engine.snapshotScanLandmarks();
        // await this.engine.transformToMainAxis();
        // await this.engine.applySnapshottedScanLandmarks();
        // await this.engine.snapshotScanLandmarks();
        await this.syncScanMeshFromEngine();
    }

    public async moveOrtoCameraToSeeFittingPlane(
        plane: FittingPlane,
    ): Promise<void> {
        await this.status.modelLoaded;
        const box = new THREE.Box3().setFromObject(this.scan);
        const size = box.getSize(new THREE.Vector3());
        const center = box.getCenter(new THREE.Vector3());

        const camera = this.sceneService.orthographicCamera;

        const padding = 0.7;

        // Calculate the required zoom level based on the largest dimension
        const maxDim = Math.max(size.x, size.y, size.z);
        const viewSize = maxDim * padding;

        // Adjust the camera's zoom level
        const aspectRatio = camera.right / camera.top;
        camera.zoom = Math.min(
            camera.right / (viewSize * aspectRatio),
            camera.top / viewSize,
        );

        // Set the camera's position based on the selected fitting plane
        switch (plane) {
            case FittingPlane.Coronal:
                camera.position.set(center.x, center.y, viewSize);
                break;
            case FittingPlane.Saggital:
                camera.position.set(-viewSize, center.y, center.z);
                break;
            case FittingPlane.Transverse:
                camera.position.set(center.x, viewSize, center.z);
                break;
        }

        // Ensure the camera looks at the center of the bounding box
        camera.lookAt(center);

        // Update the camera's projection matrix to apply the new zoom level
        camera.updateProjectionMatrix();

        // Request the scene to render again
        this.sceneService.requestSceneRender();
    }
}
