import { CommonModule } from '@angular/common';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    OnDestroy,
    Output,
    QueryList,
} from '@angular/core';
import { Subject, merge, takeUntil } from 'rxjs';

import { CollapsingSectionComponent } from '../collapsing-section/collapsing-section.component';

export interface CollapsingGroupChangedEvent {
    target: CollapsingSectionComponent;
    collapsed: boolean;
}

@Component({
    selector: 'leo-collapsing-group',
    standalone: true,
    imports: [CommonModule, CollapsingSectionComponent],
    templateUrl: './collapsing-group.component.html',
    styleUrl: './collapsing-group.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsingGroupComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(CollapsingSectionComponent)
    private sectionsRef!: QueryList<CollapsingSectionComponent>;

    private destroyed$ = new Subject<void>();

    @Output() changed = new EventEmitter<CollapsingGroupChangedEvent>();

    ngAfterContentInit(): void {
        const sections = this.sectionsRef.toArray();

        const obs = sections.map(s => s.sectionCollapseChanged);
        const merged = merge(...obs);
        merged.pipe(takeUntil(this.destroyed$)).subscribe(x => {
            (() => {
                if (x.collapsed) return;
                sections
                    .filter(s => s !== x.target)
                    .forEach(s => {
                        s.collapsed = true;
                        s.change.markForCheck();
                    });
            })();

            this.changed.emit({
                target: x.target,
                collapsed: x.collapsed,
            });
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
