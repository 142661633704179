import { Component, Input } from '@angular/core';

@Component({
    selector: 'leo-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrl: './loading-indicator.component.scss',
})
export class LoadingIndicatorComponent {
    @Input()
    loadingTextTranslateKey?: string;
}
