<div class="container">
    <div class="top-row">
        <slot [style.color]="color ? color : null">{{ title }}</slot>
        <input
            class="text-input"
            type="number"
            [value]="inputValue"
            [min]="min"
            [max]="max"
            [disabled]="disabled"
            (change)="onNumberInputChange($event)" />
    </div>
    <div class="bottom-row">
        <div class="min-value-label">{{ min }}{{ unit }}</div>
        <leo-styled-slider
            class="slider-input"
            [min]="min"
            [max]="max"
            [step]="step"
            [multiplier]="multiplier"
            [color]="color"
            [value]="value"
            [hoverColor]="hoverColor"
            [disabled]="disabled"
            (valueChange)="onSliderValueChange($event)"
            (valueChangeFinished)="onSliderValueChangeFinished($event)">
        </leo-styled-slider>
        <div class="max-value-label">{{ max }}{{ unit }}</div>
    </div>
</div>
