<label>
    <div class="container">
        <slot name="text">{{ label }}</slot>
        <div class="toggle-background">
            <input
                type="checkbox"
                [checked]="checked"
                [disabled]="disabled"
                (change)="onValueChange($event)" />
            <div class="toggle-indicator-transform">
                <div class="toggle-indicator"></div>
            </div>
            <div class="toggle-indicator-background"></div>
        </div>
    </div>
</label>
