<div class="title">
    {{ 'MAIN_MENU.LANDMARKS' | translate }}
</div>
<leo-horizontal-separator></leo-horizontal-separator>

<div class="inner-panel">
    <div class="upper-section">
        <img
            src="/assets/icons/info.svg"
            style="
                filter: var(--white-colorize-filter);
                height: 16px;
                position: absolute;
                top: 32px;
                left: 8px;
            " />
        <div
            data-locale-key="landmarks/title"
            style="font-size: 18px; text-align: center; margin: 0px 20px">
            {{ 'LANDMARKS.TITLE' | translate }}
        </div>

        <img
            *ngFor="let landmark of landmarks"
            [class.active]="landmark === selected"
            class="landmark-current-image"
            src="assets/icons/landmarks/{{ selected.icon }}" />
    </div>
    <div class="landmarks-name-container">
        <div
            *ngFor="let landmark of landmarks"
            [class.done]="landmark.done"
            [class.active]="landmark === selected"
            (click)="onLandmarkSelected(landmark)">
            {{ 'LANDMARKS.NAMES.' + landmark.shortName | translate }}
        </div>
    </div>
</div>

<leo-raw-undo-redo-reset
    [hideUndo]="true"
    [hideRedo]="true"
    (onResetClick)="onReset()">
</leo-raw-undo-redo-reset>

<leo-styled-button (click)="onApplyClick()" [disabled]="applyDisabled$ | async">
    {{ 'APPLY' | translate }}
</leo-styled-button>
