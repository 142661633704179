import { Inject, Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    NavigationService,
} from '@orthocore-web-mono/feature-core-services';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    ENVIRONMENT,
    Environment,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
    IScanService,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import { EditorFacade } from '@orthocore-web-mono/state';
import {
    Subject,
    distinctUntilChanged,
    filter,
    map,
    takeUntil,
    tap,
} from 'rxjs';
import * as THREE from 'three';

import { LANDMARKS_TOKEN, LandMarkInOrderData } from './base-landmark-objects';
import { LandmarkContainer } from './landmark-container';
import { ILandmarkService } from './landmark.service.interface';

const raycastClickButton = 0; // Left click

@Injectable()
export class LandmarksService implements ILandmarkService {
    public atTheLandmarksStep$ = this.nav.selectedStep$.pipe(
        map(step => step === 'landmarks'),
        tap(v => this.facade.changeShowLandmarksChanged(v)),
    );

    public landmarksChange$ = new Subject<void>();
    public allLandmarksSet$ = this.landmarksChange$.pipe(
        map(
            () =>
                this.landmarks.filter(l => l.done).length ===
                this.landmarks.length,
        ),
    );

    public selectedLandmarktType?: number;

    private isOnLandmarksStep$ = this.nav.selectedStep$.pipe(
        map(step => step === 'landmarks'),
        distinctUntilChanged(),
    );

    private createSubs$ = this.isOnLandmarksStep$.pipe(filter(v => v));
    private destroySubs$ = this.isOnLandmarksStep$.pipe(filter(v => !v));

    private pointerDown = false;

    constructor(
        @Inject(ENVIRONMENT) private readonly environment: Environment,
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: IScanService,
        private readonly sceneService: SceneService,
        private readonly container: LandmarkContainer,
        private readonly facade: EditorFacade,
        @Inject(LANDMARKS_TOKEN)
        public readonly landmarks: LandMarkInOrderData[],
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        private readonly nav: NavigationService,
        private readonly status: EditorStageStatusService,
    ) {
        if (!this.environment.production) {
            this.autoPlaceDevLandmarks().catch(e => console.error(e));
        }

        this.createSubs$.subscribe(() => this.subscribeToMouseEvents());

        this.sceneService.scene.add(this.container);

        this.facade.showLandmarks$.subscribe(val => {
            this.container.visible = val;
            this.sceneService.requestSceneRender();
        });
    }

    public setLandmarkPosition(hitPoint: THREE.Vector3) {
        const landmark = this.landmarks.find(
            l => l.type === this.selectedLandmarktType,
        );
        if (!landmark) return;

        landmark.done = true;
        this.container.setLandmarkPosition(landmark.type, hitPoint);
        this.landmarksChange$.next();
    }

    public async syncLandmarkPositionsFromEngine(
        applySnapshotBeforeSync: boolean,
    ) {
        await this.container.syncLandmarkPositionsFromEngine(
            applySnapshotBeforeSync,
        );
    }

    public resetLandmarks() {
        this.landmarks.forEach(l => (l.done = false));
        this.container.resetLandmarks();
        this.landmarksChange$.next();
    }

    public async applyLandmarks() {
        await this.status.transformInitialised;
        const doneLandmarks = this.landmarks.filter(l => l.done);
        if (doneLandmarks.length !== this.landmarks.length) return;

        for (const landmark of this.landmarks) {
            const position = this.container.getLandmarkPosition(landmark.type);
            await this.engine.setScanLandmark(landmark.type, position);
        }
        this.scanService.transformToMainAxis();
        await this.engine.snapshotScanLandmarks();
        this.status.landmarksApplied$.next(true);
    }

    private subscribeToMouseEvents() {
        this.sceneService.mouseDown$
            .pipe(takeUntil(this.destroySubs$))
            .subscribe(event => this.UpdateRaycast(event, false));

        this.sceneService.mouseUp$
            .pipe(takeUntil(this.destroySubs$))
            .subscribe(event => {
                if (event.button !== raycastClickButton) return;
                this.pointerDown = false;
            });

        this.sceneService.mouseMove$
            .pipe(takeUntil(this.destroySubs$))
            .subscribe(event => this.UpdateRaycast(event, true));
    }

    private async autoPlaceDevLandmarks() {
        if (!this.environment.devLandmarks) return;

        for (const l of this.environment.devLandmarks) {
            const landmark = this.landmarks.find(x => x.type === l.type);
            if (!landmark)
                throw new Error(
                    'Dev landmark from environment type does not exist',
                );
            landmark.done = true;
            this.container.setLandmarkPosition(landmark.type, l.vector);
        }

        this.landmarksChange$.next();

        if (this.environment.autoApplyLandmarks) {
            this.applyLandmarks();
        }
    }

    private async UpdateRaycast(ev: MouseEvent, isMove: boolean) {
        if (!isMove) {
            if (ev.button !== raycastClickButton) return;
            this.pointerDown = true;
        } else if (!this.pointerDown) {
            return;
        }

        if (!this.scanService) return;
        const ray = this.scanService.getMouseRay(ev);

        const raycastResult = await this.engine.scanRaycast(
            ray.origin,
            ray.direction,
        );

        if (raycastResult === null) return;

        let vector = new THREE.Vector3();
        vector.set(raycastResult.x, raycastResult.y, raycastResult.z);
        this.setLandmarkPosition(vector);
    }
}
