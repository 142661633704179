import * as THREE from 'three';

export function shaderMaterialBuilder(
    textureSourceUrl: string,
    camera: THREE.Camera,
) {
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load(textureSourceUrl);
    const projectorCamera = camera.clone(true);

    return new THREE.ShaderMaterial({
        uniforms: {
            map: { value: texture },
            projectorMatrix: { value: projectorCamera.projectionMatrix },
            projectorViewMatrix: { value: projectorCamera.matrixWorldInverse },
            baseColor: { value: new THREE.Color(0xcccccc) }, // Base color for non-projected areas or transparent regions
            opacity: { value: 1.0 }, // Additional opacity control
        },
        vertexShader: `
        uniform mat4 projectorMatrix;
        uniform mat4 projectorViewMatrix;
        varying vec4 vProjCoord;
        varying vec3 vWorldPosition;
    
        void main() {
          vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
          gl_Position = projectionMatrix * mvPosition;
    
          vec4 worldPosition = modelMatrix * vec4(position, 1.0);
          vProjCoord = projectorMatrix * projectorViewMatrix * worldPosition;
          vWorldPosition = worldPosition.xyz;
        }
      `,
        fragmentShader: `
        uniform sampler2D map;
        uniform vec3 baseColor;
        uniform float opacity; // Additional opacity control
        varying vec4 vProjCoord;
        varying vec3 vWorldPosition;
    
        void main() {
          vec3 projCoord = vProjCoord.xyz / vProjCoord.w;
          projCoord = projCoord * 0.5 + 0.5; // Normalize to [0, 1]
    
          // Only apply texture if within the projector's view frustum
          if (projCoord.x >= 0.0 && projCoord.x <= 1.0 && projCoord.y >= 0.0 && projCoord.y <= 1.0 && projCoord.z >= 0.0) {
            vec4 projectedColor = texture2D(map, projCoord.xy);
            if (projectedColor.a < 0.1) {
              gl_FragColor = vec4(baseColor, 1.0); // Use base color for transparency
            } else {
              projectedColor.a *= opacity; // Apply additional opacity
              gl_FragColor = mix(vec4(baseColor, 1.0), projectedColor, projectedColor.a); // Blend with base color
            }
          } else {
            gl_FragColor = vec4(baseColor, 1.0); // Outside the projection area
          }
        }
      `,
        side: THREE.DoubleSide, // Render both sides of the geometry
        transparent: true, // Enable transparency in the material
    });
}
