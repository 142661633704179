import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'leo-styled-text-input-multiline',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './styled-text-input-multiline.component.html',
    styleUrl: './styled-text-input-multiline.component.scss',
})
export class StyledTextInputMultilineComponent {
    @Input() label = '';
}
