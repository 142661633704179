<leo-styled-dropdown
    [title]="'MESH_EDIT.LOOP_TOOLS.REGION' | translate"
    [options]="modes"
    [localise]="true"
    (selectedChange)="onModeChanged($event)"></leo-styled-dropdown>

<leo-styled-slider-with-input
    *ngIf="params.twistMode === Mode.Middle || params.twistMode === Mode.Top"
    [title]="'MESH_EDIT.LOOP_TOOLS.TOP_POSITION' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.twistDistTop"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    *ngIf="params.twistMode === Mode.Middle || params.twistMode === Mode.Bottom"
    [title]="'MESH_EDIT.LOOP_TOOLS.BOTTOM_POSITION' | translate"
    [min]="0"
    [max]="50"
    [(value)]="params.twistDistBottom"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<span>{{ 'MESH_EDIT.LOOP_TOOLS.TWIST' | translate }}</span>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.AMOUNT' | translate"
    [min]="-180"
    [max]="180"
    [(value)]="params.twistAmount"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="degreeUnit"
    [multiplier]="deg2rad"></leo-styled-slider-with-input>

<leo-styled-slider-with-input
    [title]="'MESH_EDIT.LOOP_TOOLS.POSITION' | translate"
    [min]="0"
    [max]="100"
    [(value)]="params.twistPos"
    (valueChange)="onChange()"
    [step]="0.1"
    [unit]="percentUnit"
    [multiplier]="value2percent"></leo-styled-slider-with-input>

<leo-measurements-loop-tool
    *ngIf="measurements$ | async as measurements"
    [measurements]="measurements"></leo-measurements-loop-tool>

<leo-styled-button
    (click)="onApplyLoopTool()"
    [disabled]="params.twistAmount === 0">
    {{ 'APPLY' | translate }}
</leo-styled-button>
