export enum LoopTool {
    Bend = 'bend-tool',
    Scale = 'scale-tool',
    Stretch = 'stretch-tool',
    Twist = 'twist-tool',
}

export const loopTools = [
    LoopTool.Bend,
    LoopTool.Scale,
    LoopTool.Stretch,
    LoopTool.Twist,
];
