export const scanSettingsStageName = 'scan-settings';

export const scaleSectionName = 'scale';
export const transformSectionName = 'transform';
export const cleanSectionName = 'clean';

export enum ScanSettingSections {
    ScaleSectionName = scaleSectionName,
    TransformSectionName = transformSectionName,
    ClearSectionName = cleanSectionName,
}

export enum ScanTransformMode {
    Translate = 'translate',
    Rotate = 'rotate',
}
