import { Inject, Injectable } from '@angular/core';
import {
    EditorStageStatusService,
    NavigationService,
} from '@orthocore-web-mono/feature-core-services';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    Float3JS,
    SCAN_SERVICE_TOKEN,
} from '@orthocore-web-mono/shared-types';
import { DialogService } from '@orthocore-web-mono/shared/feature-dialogs';
import {
    ScanService,
    SceneService,
    SplineObject,
    SplineObjectParams,
} from '@orthocore-web-mono/shared/feature-scene';
import {
    IntersectRaySphere,
    UpdateGeometryFromMeshData,
} from '@orthocore-web-mono/shared/utils';
import {
    ISpinalEngineWorkerService,
    SplineContourData,
} from '@orthocore-web-mono/spinal-types';
import {
    Subject,
    distinctUntilChanged,
    exhaustMap,
    filter,
    map,
    takeUntil,
} from 'rxjs';
import * as THREE from 'three';

import {
    contrastSplineObjectParams,
    controlPointRadius,
    deafultSplineObjectParams,
    embosserColor,
    splineDisplayOffset,
    tangentColor,
} from './constants';

const generateTimeout = 15_000;
/** Timeout for a simple get request to the web worker to see if it is still working. */
const checkTimeout = 5_000;

// Double shell layers
export enum Layer {
    outer,
    inner,
}

let selectedLayer: number = Layer.outer;

const outerSplines: SplineObject[][] = [];
const innerSplines: SplineObject[][] = [];
const splines = () =>
    selectedLayer === Layer.outer ? outerSplines : innerSplines;
const allSplines = () => [...innerSplines, ...outerSplines];
const splinesObj = { [Layer.inner]: innerSplines, [Layer.outer]: outerSplines };
const selectedLayerSplines = () => splinesObj[selectedLayer as Layer];
const isInnerLayerSelected = () => selectedLayer === Layer.inner;

const outerSplineContainer = new THREE.Group();
outerSplineContainer.frustumCulled = false;
outerSplineContainer.visible = true;

const innerSplineContainer = new THREE.Group();
innerSplineContainer.frustumCulled = false;
innerSplineContainer.visible = false;
const splineContainers = [outerSplineContainer, innerSplineContainer];

const selectedSplinesContainer = () =>
    selectedLayer === Layer.outer ? outerSplineContainer : innerSplineContainer;
const splineContainerObj = {
    [Layer.inner]: innerSplineContainer,
    [Layer.outer]: outerSplineContainer,
};

const tangentLines: SplineObject[] = [];
const tangentLineParams: SplineObjectParams = { ...deafultSplineObjectParams };
tangentLineParams.lineColor = tangentColor;

export type LocalSplineContourData = SplineContourData & {
    needsUpdateToEngine: boolean;
    isEnabled: boolean;
};

function mapContourSplineInfoResponesToLocalSplineContourData(spline: any) {
    const infos: LocalSplineContourData[] = [];
    if (!spline.controlPoints?.length) return [];

    const controlPoints: Float3JS[] = [];
    for (let i = 0; i < spline.controlPoints.length; i += 3) {
        controlPoints.push({
            x: spline.controlPoints[i],
            y: spline.controlPoints[i + 1],
            z: spline.controlPoints[i + 2],
        });
    }

    infos.push({
        type: spline.contourType,
        closed: spline.closed,
        offset: spline.offset,
        thickness: spline.thickness,
        rotation: spline.rotation,
        sizeX: spline.size.x,
        sizeY: spline.size.y,
        rounding: spline.rounding,
        controlPoints,
        isEnabled: true,
        needsUpdateToEngine: false,

        transitionLength: spline.transitionLength,
    });
    return infos;
}

let contourSplineVisualsNeedUpdate = true;

interface DraggedControlPointInfo {
    splineIndex: number;
    subSpineIndex: number;
    controlPointIndex: number;
    typeSpecificData:
        | {
              type: 'controlPoint';
              linkedControlPointIndex: number | null; // When the spline is closed, the first and last points are linked
              prevTangentIndex: number | null;
              nextTangentIndex: number | null;
          }
        | {
              type: 'tangent';
              middleControlPointIndex: number;
              oppositeControlPointIndex: number | null;
              oppositeTangentDistanceFromMiddlePoint: number;
              initialDistanceFromCamera: number;
          };
}

const editMouseButton = 0; // Left
let draggedControlPointInfo: DraggedControlPointInfo | null = null;
const tmpVec3 = new THREE.Vector3();
const tmpVec3_2 = new THREE.Vector3();
const tmpVec3_3 = new THREE.Vector3();

const tmpPlane = new THREE.Plane();
const tmpLine = new THREE.Line3();

let selectedComponentIndex = 0;
let designEditActive = false;

@Injectable()
export class SpinalDesignService {
    public designs: string[] = [];
    public designs$: Subject<string[]> = new Subject();
    public selectedDesignIndex = 0;

    public designChanged$ = new Subject<void>();
    public splineInfosChanged$ = new Subject<LocalSplineContourData[][]>();
    public selectedComponentIndexChanged$ = new Subject<number>();

    public currentSplineInfos: LocalSplineContourData[][] = [];

    private isOnDesignStep$ = this.nav.selectedStep$.pipe(
        map(step => step === 'design'),
        distinctUntilChanged(),
    );

    private isEditSection$ = this.nav.selectedSection$.pipe(
        map(section => section === 'edit-desing'),
        distinctUntilChanged(),
    );

    private activateEdit$ = this.isEditSection$.pipe(filter(v => v));
    private deactivateEdit$ = this.isEditSection$.pipe(filter(v => !v));

    private syncChangedSplinesToEngine$ = new Subject<void>();

    public globalOffset: number = 0;
    public globalThickness: number = 0;

    public get currentSpline() {
        return selectedLayerSplines()[selectedComponentIndex][0];
    }

    public get currentSplineInfo() {
        return this.currentSplineInfos[selectedComponentIndex][0];
    }

    constructor(
        @Inject(SCAN_SERVICE_TOKEN)
        private readonly scanService: ScanService,
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: ISpinalEngineWorkerService,
        private readonly sceneService: SceneService,
        // private readonly enabler: EnablerService,
        private readonly nav: NavigationService,
        private readonly status: EditorStageStatusService,
        private readonly dialog: DialogService,
    ) {
        sceneService.scene.add(outerSplineContainer);
        sceneService.scene.add(innerSplineContainer);
        this.init().catch(e => console.error(e));

        this.isEditSection$.subscribe(active => {
            this.OnDesignEditActivated(active);
        });

        this.activateEdit$.subscribe(active => {
            this.subscribeToMouseEvents();
        });

        this.syncChangedSplinesToEngine$
            .pipe(exhaustMap(() => this.syncSplinesToEngine()))
            .subscribe();

        this.isOnDesignStep$.subscribe(active => {
            splineContainers.forEach(c => {
                c.visible = active;
            });

            if (active) {
                this.updateDataOnStageSelect();
            }

            this.sceneService.requestSceneRender();
        });
    }

    public changeSelectedComponent(index: number) {
        selectedComponentIndex = index;
        this.UpdateControlPointVisibility();
        this.sceneService.requestSceneRender();
    }

    public SyncChangedSplinesToEngine() {
        this.syncChangedSplinesToEngine$.next();
    }

    public async generate() {
        const timeOutPromise = (timeout: number) =>
            new Promise<null>(r =>
                setTimeout(() => {
                    r(null);
                }, timeout),
            );

        const result = await this.dialog.doWhileLoading(async () => {
            await this.syncSplinesToEngine();
            return await Promise.race([
                timeOutPromise(generateTimeout),
                this.engine.generateMesh(),
            ]);
        });

        // Timeout!
        if (result === null) {
            const check = await Promise.race([
                timeOutPromise(checkTimeout),
                this.engine.getBuiltinDesigns(),
            ]);
            if (check) {
                // The worker still works
                this.dialog.prompt('DESIGN.GENERATE_FAILED', 'GENERIC.OK');
            } else {
                // The worker is stuck
                this.dialog.prompt('DESIGN.GENERATE_TIMEOUT', 'GENERIC.OK');
            }
            return;
        }

        if (result.length > 0) {
            while (result.length > this.scanService.generatedParts.length) {
                const afoPartObject = new THREE.Mesh(
                    new THREE.BufferGeometry(),
                    this.scanService.generatedPartsMaterial,
                );
                this.sceneService.addObjectToScene(afoPartObject);
                this.scanService.generatedParts.push(afoPartObject);
            }

            for (let i = 0; i < result.length; ++i) {
                const afoPartObject = this.scanService.generatedParts[i];
                afoPartObject.visible = true;
                UpdateGeometryFromMeshData(afoPartObject.geometry, result[i]);
            }
            for (
                let i = result.length;
                i < this.scanService.generatedParts.length;
                ++i
            ) {
                this.scanService.generatedParts[i].geometry.setDrawRange(0, 0);
            }

            this.scanService.generationStateChangeSubject.next(true);
            this.sceneService.requestSceneRender();
        } else {
            this.dialog.prompt('DESIGN.GENERATE_FAILED', 'GENERIC.OK');
        }
    }

    public changeDesign(index: number): void {
        if (this.selectedDesignIndex === index) return;
        this.selectedDesignIndex = index;
        contourSplineVisualsNeedUpdate = true;
        this.updateContourSplineVisualsIfNeeded();

        selectedLayer = Layer.outer;
        this.scanService.generationStateChangeSubject.next(false);
        this.designChanged$.next();
    }

    private async init() {
        await this.status.landmarksApplied;
        // await this.enabler.ActivateEngine(); //TODO: add activation

        let { globalOffset, globalThickness } =
            await this.engine.getGeneralOffsetAndThickness();
        this.globalOffset = globalOffset;
        this.globalThickness = globalThickness;

        this.designs = await this.engine.getBuiltinDesigns();
        this.designs$.next(this.designs);
        await this.updateContourSplineVisualsIfNeeded();
        this.designChanged$.next();
    }

    private UpdateTangentLine(controlPointIndex: number) {
        const subSplines = splines()[selectedComponentIndex];
        for (const spline of subSplines) {
            this.UpdateSplineTangentLine(spline, controlPointIndex);
        }
    }

    private UpdateSplineTangentLine(
        spline: SplineObject,
        controlPointIndex: number,
    ) {
        const numControlPoints = spline.getNumControlPoints();
        if (numControlPoints < 3) {
            return;
        }

        const closed =
            this.currentSplineInfos[selectedComponentIndex][0].closed;

        let prevIndex: number;
        let nextIndex: number;

        if (controlPointIndex === 0) {
            prevIndex = closed ? numControlPoints - 2 : 0;
        } else {
            prevIndex = controlPointIndex - 1;
        }

        if (controlPointIndex === numControlPoints - 1) {
            nextIndex = closed ? 1 : numControlPoints - 1;
        } else {
            nextIndex = controlPointIndex + 1;
        }

        const tangentLineIndex = controlPointIndex / 3;

        const prev = spline.getControlPointLocalPosition(prevIndex);
        const next = spline.getControlPointLocalPosition(nextIndex);

        function UpdateSingleTangentLine(index: number) {
            // This doesn't work with 2 points because of a bug, see https://github.com/pmndrs/meshline/issues/14
            // So just add a third point after the last point (with a very small offset)
            tangentLines[index].updateGeometry(null, [
                prev,
                next,
                tmpVec3.lerpVectors(prev, next, 1.0001),
            ]);
        }

        UpdateSingleTangentLine(tangentLineIndex);

        if (closed) {
            if (tangentLineIndex === tangentLines.length - 1) {
                UpdateSingleTangentLine(0);
            } else if (tangentLineIndex === 0) {
                UpdateSingleTangentLine(tangentLines.length - 1);
            }
        }
    }

    private updateTangentLines(subSplines: SplineObject[] | null) {
        let numTangentLines = 0;
        if (subSplines !== null) {
            const EnsureNewTangentLine = () => {
                if (numTangentLines < tangentLines.length) {
                    const line = tangentLines[numTangentLines++];
                    line.visible = true;
                } else {
                    const line = new SplineObject(
                        this.sceneService,
                        tangentLineParams,
                    );
                    selectedSplinesContainer().add(line);
                    tangentLines.push(line);
                    ++numTangentLines;
                }
            };

            for (const spline of subSplines) {
                const numControlPoints = spline.getNumControlPoints();

                for (let i = 0; i < numControlPoints; i += 3) {
                    EnsureNewTangentLine();
                    this.UpdateTangentLine(i);
                }
            }
        }

        // Hide unused lines
        for (let i = numTangentLines; i < tangentLines.length; ++i) {
            tangentLines[i].visible = false;
        }
    }

    async UpdateAllContourSplineVisuals() {
        // First clear, then resize to the required size
        // Since we are retrieving all splines here, every element will be set

        for (const subSplines of allSplines()) {
            subSplines.forEach(s => s.dispose());
        }

        outerSplines.splice(0, outerSplines.length);
        innerSplines.splice(0, innerSplines.length);

        await this.UpdateAllContourSplineVisualsForLayer();

        this.UpdateControlPointVisibility();
        if (this.currentSplineInfos.length) {
            this.splineInfosChanged$.next(this.currentSplineInfos);
        }
        this.sceneService.requestSceneRender();
        // afoGenerationStateChanged.dispatchEvent(false);
    }

    async UpdateAllContourSplineVisualsForLayer(layer: Layer = Layer.outer) {
        const infos = await this.engine.getContourSplineInfo(
            {
                type: 'all',
                request: {
                    needControlPoints: true,
                    needSamplePoints: true,
                    needSampleNormals: true,
                },
            },
            isInnerLayerSelected(),
        );

        this.currentSplineInfos = [...infos.values()].map(subSplines =>
            mapContourSplineInfoResponesToLocalSplineContourData(subSplines),
        );

        for (let [_, tmpInfos] of infos) {
            tmpInfos = [tmpInfos];

            deafultSplineObjectParams.lineColor =
                tmpInfos[0].type == 'Embosser' ? embosserColor : 0xff00000;

            const tmpSplines = [];
            for (const info of tmpInfos) {
                if (
                    info.controlPoints === null ||
                    info.samplePoints === null ||
                    info.sampleNormals === null
                ) {
                    continue;
                }

                for (let i = 0; i < info.samplePoints.length; ++i) {
                    info.samplePoints[i] +=
                        info.sampleNormals[i] * splineDisplayOffset;
                }

                const splineObjectParams =
                    selectedLayer === layer
                        ? deafultSplineObjectParams
                        : contrastSplineObjectParams;
                const spline = new SplineObject(
                    this.sceneService,
                    splineObjectParams,
                );
                spline.updateGeometry(info.controlPoints, info.samplePoints);
                splineContainerObj[layer].add(spline);

                for (let i = 0; i < info.controlPoints.length; i += 3) {
                    const controlPointIndex = (i / 3) | 0;
                    const isTangent = controlPointIndex % 3 !== 0;
                    spline.setControlPointColorIndex(
                        controlPointIndex,
                        isTangent ? 1 : 0,
                    );
                }

                tmpSplines.push(spline);
            }

            splinesObj[layer].push(tmpSplines);
        }
    }

    async UpdateSingleContourSplineVisual(index: number) {
        const infos = await this.engine.getContourSplineInfo(
            {
                type: 'byIndices',
                request: new Map([
                    [
                        index,
                        {
                            needControlPoints: false,
                            needSamplePoints: true,
                            needSampleNormals: true,
                        },
                    ],
                ]),
            },
            isInnerLayerSelected(),
        );

        for (let [splineIndex, tmpInfos] of infos) {
            tmpInfos = [tmpInfos];
            const spline = splines()[splineIndex];
            spline.forEach(s => s.updateGeometry(null, []));

            for (const subsplineIndex in tmpInfos) {
                const info = tmpInfos[subsplineIndex];

                if (info.samplePoints === null || info.sampleNormals === null) {
                    continue;
                }

                for (let i = 0; i < info.samplePoints.length; ++i) {
                    info.samplePoints[i] +=
                        info.sampleNormals[i] * splineDisplayOffset;
                }

                spline[subsplineIndex].updateGeometry(null, info.samplePoints);
            }
        }

        this.sceneService.requestSceneRender();
        this.scanService.generationStateChangeSubject.next(false);
    }

    async updateContourSplineVisualsIfNeeded() {
        if (!contourSplineVisualsNeedUpdate) return;
        contourSplineVisualsNeedUpdate = false;

        await this.engine.selectBuiltinDesign(this.selectedDesignIndex);
        await this.UpdateAllContourSplineVisuals();
    }

    private subscribeToMouseEvents() {
        this.sceneService.mouseDown$
            .pipe(takeUntil(this.deactivateEdit$))
            .subscribe(event => this.handleMouseDown(event));

        this.sceneService.mouseUp$
            .pipe(takeUntil(this.deactivateEdit$))
            .subscribe(event => this.handleMouseUp(event));

        this.sceneService.mouseMove$
            .pipe(
                takeUntil(this.deactivateEdit$),
                exhaustMap((event: MouseEvent) => this.handleMouseMove(event)),
            )
            .subscribe();
    }

    private async handleMouseDown(ev: MouseEvent) {
        if (ev.button !== editMouseButton) {
            return;
        }

        const ray = this.scanService.getMouseRay(ev);
        const hit = await this.engine.scanRaycast(
            ray.origin,
            ray.direction,
            true,
        );

        // Tangent points are not required to be on the foot scan, so only use the foot scan raycast to filter out points that would be behind the foot scan
        // So intersect all spheres with the mouse ray and find the closest, but only if it's in front of the foot scan

        const raycastHitDistance =
            hit === null
                ? Infinity
                : tmpVec3.set(hit.x, hit.y, hit.z).distanceTo(ray.origin);
        let closestHitDistance = Infinity;

        let draggedControlPoint: {
            splineIndex: number;
            subSpineIndex: number;
            controlPointIndex: number;
        } | null = null;

        // Use a larger radius for clicking
        const clickRadius = controlPointRadius * 2;

        // Find closest control point to raycast hit point
        for (let i = 0; i < selectedLayerSplines().length; ++i) {
            const subSplines = selectedLayerSplines()[i];
            for (let k = 0; k < subSplines.length; ++k) {
                const spline = subSplines[k];
                if (!spline.visible || !spline.getControlPointVisibility()) {
                    continue;
                }

                const count = spline.getNumControlPoints();
                for (let j = 0; j < count; ++j) {
                    const controlPointPosition =
                        spline.getControlPointLocalPosition(j);

                    const hitDistance = IntersectRaySphere(
                        controlPointPosition,
                        clickRadius,
                        ray,
                    );
                    if (
                        hitDistance !== null &&
                        hitDistance < closestHitDistance
                    ) {
                        closestHitDistance = hitDistance;
                        draggedControlPoint = {
                            splineIndex: i,
                            subSpineIndex: k,
                            controlPointIndex: j,
                        };
                    }
                }
            }
        }

        if (draggedControlPoint !== null) {
            // Allow tangents to be clicked inside the foot scan
            // So if an interpolation point is clicked, then make sure that the hit distance is less than the raycast distance
            if (draggedControlPoint.controlPointIndex % 3 === 0) {
                if (raycastHitDistance + clickRadius < closestHitDistance) {
                    // Point is inside the foot scan
                    draggedControlPoint = null;
                }
            }
        }

        if (draggedControlPoint !== null) {
            const spline =
                splines()[draggedControlPoint.splineIndex][
                    draggedControlPoint.subSpineIndex
                ];
            const closedSpline =
                this.currentSplineInfos[draggedControlPoint.splineIndex][
                    draggedControlPoint.subSpineIndex
                ].closed;
            const draggedPointIndex = draggedControlPoint.controlPointIndex;
            const numControlPoints = spline.getNumControlPoints();

            if (draggedPointIndex % 3 === 0) {
                let linkedControlPointIndex: number | null;
                let prevTangentIndex: number | null;
                let nextTangentIndex: number | null;

                if (closedSpline) {
                    if (draggedPointIndex === 0) {
                        linkedControlPointIndex = numControlPoints - 1;
                        prevTangentIndex = numControlPoints - 2;
                        nextTangentIndex = 1;
                    } else if (draggedPointIndex === numControlPoints - 1) {
                        linkedControlPointIndex = 0;
                        prevTangentIndex = numControlPoints - 2;
                        nextTangentIndex = 1;
                    } else {
                        linkedControlPointIndex = null;
                        prevTangentIndex = draggedPointIndex - 1;
                        nextTangentIndex = draggedPointIndex + 1;
                    }
                } else {
                    linkedControlPointIndex = null;
                    prevTangentIndex = null;
                    nextTangentIndex = null;

                    if (draggedPointIndex !== 0) {
                        prevTangentIndex = draggedPointIndex - 1;
                    }
                    if (draggedPointIndex !== numControlPoints - 1) {
                        nextTangentIndex = draggedPointIndex + 1;
                    }
                }

                draggedControlPointInfo = {
                    splineIndex: draggedControlPoint.splineIndex,
                    subSpineIndex: draggedControlPoint.subSpineIndex,
                    controlPointIndex: draggedPointIndex,
                    typeSpecificData: {
                        type: 'controlPoint',
                        linkedControlPointIndex,
                        prevTangentIndex,
                        nextTangentIndex,
                    },
                };
            } else {
                let oppositeIndex: number | null;
                let middleControlPointIndex: number;
                if (closedSpline) {
                    if (draggedPointIndex === 1) {
                        // If the first tangent point is clicked, then the opposite is the point before the last
                        oppositeIndex = numControlPoints - 2;
                        middleControlPointIndex = numControlPoints - 1;
                    } else if (draggedPointIndex === numControlPoints - 2) {
                        // Opposite direction
                        oppositeIndex = 1;
                        middleControlPointIndex = 0;
                    } else {
                        // Generic case
                        if (draggedPointIndex % 3 === 1) {
                            oppositeIndex = draggedPointIndex - 2;
                            middleControlPointIndex = draggedPointIndex - 1;
                        } else {
                            oppositeIndex = draggedPointIndex + 2;
                            middleControlPointIndex = draggedPointIndex + 1;
                        }
                    }
                } else {
                    if (draggedPointIndex === 1) {
                        // First point
                        oppositeIndex = null;
                        middleControlPointIndex = 0;
                    } else if (draggedPointIndex === numControlPoints - 2) {
                        // Last point
                        oppositeIndex = null;
                        middleControlPointIndex = numControlPoints - 1;
                    } else {
                        // Generic case
                        if (draggedPointIndex % 3 === 1) {
                            oppositeIndex = draggedPointIndex - 2;
                            middleControlPointIndex = draggedPointIndex - 1;
                        } else {
                            oppositeIndex = draggedPointIndex + 2;
                            middleControlPointIndex = draggedPointIndex + 1;
                        }
                    }
                }

                draggedControlPointInfo = {
                    splineIndex: draggedControlPoint.splineIndex,
                    subSpineIndex: draggedControlPoint.subSpineIndex,
                    controlPointIndex: draggedPointIndex,
                    typeSpecificData: {
                        type: 'tangent',
                        middleControlPointIndex,
                        oppositeControlPointIndex: oppositeIndex,
                        oppositeTangentDistanceFromMiddlePoint:
                            oppositeIndex === null
                                ? 0
                                : spline
                                      .getControlPointLocalPosition(
                                          middleControlPointIndex,
                                      )
                                      .distanceTo(
                                          spline.getControlPointLocalPosition(
                                              oppositeIndex,
                                          ),
                                      ),
                        initialDistanceFromCamera: (() => {
                            const cameraForward =
                                this.sceneService.activeCamera.getWorldDirection(
                                    tmpVec3,
                                );
                            const directionToDraggedPoint = tmpVec3_2
                                .copy(
                                    spline.getControlPointLocalPosition(
                                        draggedPointIndex,
                                    ),
                                )
                                .sub(this.sceneService.activeCamera.position);
                            return cameraForward.dot(directionToDraggedPoint);
                        })(),
                    },
                };
            }
        } else {
            draggedControlPointInfo = null;
        }
    }

    private async handleMouseMove(ev: MouseEvent): Promise<void> {
        const controlPointInfo = draggedControlPointInfo; // Store this in another variable to make sure that it doesn't become null when the mouse is released
        if (controlPointInfo === null) {
            return;
        }

        const designSplines = this.currentSplineInfos;
        const currentDesignSpline =
            designSplines[controlPointInfo.splineIndex][
                controlPointInfo.subSpineIndex
            ];
        const spline =
            splines()[controlPointInfo.splineIndex][
                controlPointInfo.subSpineIndex
            ];

        function SetControlPointPosition(
            index: number | null,
            position: Float3JS,
        ) {
            if (index === null) {
                return;
            }

            const controlPointPosition =
                currentDesignSpline.controlPoints[index];
            controlPointPosition.x = position.x;
            controlPointPosition.y = position.y;
            controlPointPosition.z = position.z;

            const controlPoint = spline.getControlPointLocalPosition(index);
            controlPoint.set(position.x, position.y, position.z);
        }

        const ray = this.scanService.getMouseRay(ev);

        if (controlPointInfo.typeSpecificData.type === 'controlPoint') {
            // Regular control point, raycast to foot scan
            const hit = await this.engine.scanRaycast(
                ray.origin,
                ray.direction,
                true,
            );

            if (hit === null) {
                return;
            }

            const newPosition = tmpVec3.set(hit.x, hit.y, hit.z);

            const pos = spline.getControlPointLocalPosition(
                controlPointInfo.controlPointIndex,
            );

            function UpdateNeighborTangent(index: number | null) {
                if (index === null) {
                    return;
                }

                const tangentPosition =
                    spline.getControlPointLocalPosition(index);
                const diff = tmpVec3_2.copy(tangentPosition).sub(pos);
                const movedPosition = diff.add(newPosition);
                SetControlPointPosition(index, movedPosition);
            }

            UpdateNeighborTangent(
                controlPointInfo.typeSpecificData.prevTangentIndex,
            );
            UpdateNeighborTangent(
                controlPointInfo.typeSpecificData.nextTangentIndex,
            );

            SetControlPointPosition(
                controlPointInfo.controlPointIndex,
                newPosition,
            );
            SetControlPointPosition(
                controlPointInfo.typeSpecificData.linkedControlPointIndex,
                newPosition,
            );

            this.UpdateTangentLine(controlPointInfo.controlPointIndex);
        } else {
            // Tangent point, move in the camera plane

            const currentPoint = spline.getControlPointLocalPosition(
                controlPointInfo.controlPointIndex,
            );
            const middlePoint = spline.getControlPointLocalPosition(
                controlPointInfo.typeSpecificData.middleControlPointIndex,
            );
            const oppositePoint =
                controlPointInfo.typeSpecificData.oppositeControlPointIndex ===
                null
                    ? null
                    : spline.getControlPointLocalPosition(
                          controlPointInfo.typeSpecificData
                              .oppositeControlPointIndex,
                      );

            const cameraForward =
                this.sceneService.activeCamera.getWorldDirection(tmpVec3);
            const cameraPosition = this.sceneService.activeCamera.position;
            const referencePoint = tmpVec3_2
                .copy(cameraForward)
                .multiplyScalar(
                    controlPointInfo.typeSpecificData.initialDistanceFromCamera,
                )
                .add(cameraPosition);

            tmpPlane.setFromNormalAndCoplanarPoint(
                cameraForward,
                referencePoint,
            );
            // Would be better to just intersect with a ray, but there is no built-in method for that
            const lineLength = 10000;
            tmpLine.set(
                ray.origin,
                tmpVec3
                    .copy(ray.direction)
                    .multiplyScalar(lineLength)
                    .add(ray.origin),
            );
            const raycastedPoint = tmpPlane.intersectLine(tmpLine, tmpVec3);
            if (raycastedPoint === null) return;

            // TODO?: use keybinds for these
            const keepOnSameLine = false;
            const keepOppositeDistance = false;

            if (keepOnSameLine) {
                const dir = tmpVec3_2.copy(middlePoint).sub(currentPoint);
                const len = dir.length();
                if (len > 1e-7) {
                    dir.divideScalar(len);
                    tmpLine.set(
                        middlePoint,
                        tmpVec3_3
                            .copy(dir)
                            .multiplyScalar(lineLength)
                            .add(middlePoint),
                    );
                    tmpLine.closestPointToPoint(
                        raycastedPoint,
                        false,
                        currentPoint,
                    );
                }
            } else {
                currentPoint.copy(raycastedPoint);
            }

            SetControlPointPosition(
                controlPointInfo.controlPointIndex,
                currentPoint,
            );

            if (oppositePoint !== null) {
                // Also move the opposite point to be on the same line

                const oppositeDirection = tmpVec3_2
                    .copy(middlePoint)
                    .sub(currentPoint);
                if (keepOppositeDistance) {
                    // Move to the same distance
                    oppositePoint.copy(middlePoint).add(oppositeDirection);
                } else {
                    // Keep the original distance
                    oppositeDirection.normalize();
                    oppositePoint
                        .copy(oppositeDirection)
                        .multiplyScalar(
                            controlPointInfo.typeSpecificData
                                .oppositeTangentDistanceFromMiddlePoint,
                        )
                        .add(middlePoint);
                }

                SetControlPointPosition(
                    controlPointInfo.typeSpecificData.oppositeControlPointIndex,
                    oppositePoint,
                );
            }

            this.UpdateTangentLine(
                controlPointInfo.typeSpecificData.middleControlPointIndex,
            );
        }

        this.engine.setContourSplineData(
            [
                {
                    splineIndex: controlPointInfo.splineIndex,
                    subsplineIndex: 0,
                    data: {
                        closed: currentDesignSpline.closed,
                        controlPoints: currentDesignSpline.isEnabled
                            ? currentDesignSpline.controlPoints
                            : [],
                        thickness: currentDesignSpline.thickness,
                        offset: currentDesignSpline.offset,
                        type: currentDesignSpline.type,
                        rotation: currentDesignSpline.rotation,
                        sizeX: currentDesignSpline.sizeX,
                        sizeY: currentDesignSpline.sizeY,
                        rounding: currentDesignSpline.rounding,
                    },
                },
            ],
            false,
        );

        await this.UpdateSingleContourSplineVisual(
            controlPointInfo.splineIndex,
        );

        this.sceneService.requestSceneRender();
    }

    private handleMouseUp(ev: MouseEvent) {
        if (ev.button !== editMouseButton) {
            return;
        }

        draggedControlPointInfo = null;
    }

    UpdateControlPointVisibility() {
        allSplines()
            .flat()
            .forEach(s => s.setControlPointVisibility(false));
        this.updateTangentLines(null);

        if (designEditActive) {
            const selectedComponentSplines =
                selectedLayerSplines()[selectedComponentIndex];
            if (!selectedComponentSplines) return;
            selectedComponentSplines.forEach(s =>
                s.setControlPointVisibility(true),
            );
            this.updateTangentLines(selectedComponentSplines);
        }
    }

    OnDesignEditActivated(active: boolean) {
        designEditActive = active;
        this.UpdateControlPointVisibility();
        this.sceneService.requestSceneRender();
    }

    OnDesignEditLeaving() {
        this.SyncChangedSplinesToEngine();
    }

    private async syncSplinesToEngine() {
        const data: any[] = [];

        for (const [splineIndex, spline] of this.currentSplineInfos.entries()) {
            for (const [subSplineIndex, subspline] of spline.entries()) {
                if (subspline.needsUpdateToEngine) {
                    subspline.needsUpdateToEngine = false;

                    if (subspline.isEnabled) {
                        data.push({
                            splineIndex: splineIndex,
                            subsplineIndex: subSplineIndex,
                            data: subspline,
                        });
                    } else {
                        // Set control points to an empty array if disabled
                        const splineCopy = { ...subspline };
                        splineCopy.controlPoints = [];
                        data.push({
                            splineIndex: splineIndex,
                            subsplineIndex: subSplineIndex,
                            data: splineCopy,
                        });
                    }
                }
            }
        }

        if (data.length !== 0) {
            await this.engine.setContourSplineData(
                data,
                isInnerLayerSelected(),
            );
        }
    }

    public updateGeneralOffsetAndThickness() {
        this.engine.setGeneralOffsetAndThickness(
            this.globalOffset,
            this.globalThickness,
        );
    }

    public updateSplineParameterToEngine<
        TKey extends keyof LocalSplineContourData,
        TValue extends LocalSplineContourData[TKey],
    >(key: TKey, value: TValue, updateVisuals = false) {
        const spline = this.currentSplineInfos[selectedComponentIndex];
        spline[0][key] = value;
        spline[0].needsUpdateToEngine = true;

        if (updateVisuals) {
            this.SyncChangedSplinesToEngine();
            this.UpdateSingleContourSplineVisual(selectedComponentIndex);
        }
    }

    private async updateDataOnStageSelect() {
        // if (footScanNeedsToBeOriented)
        // {
        //     footScanNeedsToBeOriented = false;
        //     await TransformFootScanToMainAxis();
        // }
        this.designChanged$.next();
        await this.UpdateAllContourSplineVisuals();
    }
}
