<div></div>
<div>{{ 'MESH_EDIT.LOOP_TOOLS.HEIGHT' | translate }}</div>
<div>{{ 'MESH_EDIT.LOOP_TOOLS.PERIMETER' | translate }}</div>
<div>{{ 'MESH_EDIT.LOOP_TOOLS.TOP' | translate }}</div>
<div class="measurement">
    {{ measurements?.measureTopHeight | number : '1.0-0' }} mm
</div>
<div class="measurement">
    {{ measurements?.measureTopPerimeter | number : '1.0-0' }} mm
</div>
<div *ngIf="false" class="disabled">
    {{ 'MESH_EDIT.LOOP_TOOLS.MIDDLE' | translate }}
</div>
<div *ngIf="false" class="measurement disabled">0 mm</div>
<div *ngIf="false" class="measurement disabled">0 mm</div>
<div [class.disabled]="!showMiddle">
    {{ 'MESH_EDIT.LOOP_TOOLS.BOTTOM' | translate }}
</div>
<div class="measurement" [class.disabled]="!showMiddle">
    {{ measurements?.measureBottomHeight | number : '1.0-0' }} mm
</div>
<div class="measurement" [class.disabled]="!showMiddle">
    {{ measurements?.measureBottomPerimeter | number : '1.0-0' }} mm
</div>
