import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UndoRedoResetComponent } from './undo-redo-reset/undo-redo-reset.component';
import { UndoRedoService } from './undo-redo.service';

@NgModule({
    imports: [CommonModule],
    providers: [UndoRedoService],
    declarations: [UndoRedoResetComponent],
    exports: [UndoRedoResetComponent],
})
export class FeatureUndoRedoResetModule {}
