import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'leo-styled-dropdown',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './styled-dropdown.component.html',
    styleUrl: './styled-dropdown.component.scss',
})
export class StyledDropdownComponent {
    @Input() title = '';
    @Input() options: string[] = [];
    @Input() selected?: number = 0;
    @Input() localise = false;

    @Output() selectedChange = new EventEmitter<number>();

    onSelectionChanged(event: Event) {
        this.selected = +(event.target as HTMLInputElement).value;
        this.selectedChange.emit(this.selected);
    }
}
