import * as THREE from 'three';

export class CheckerboardPlane extends THREE.Mesh {
    private shaderMaterial: THREE.ShaderMaterial;
    private planeGeometry: THREE.PlaneGeometry;

    constructor(
        colorA: THREE.Color,
        alphaA: number,
        colorB: THREE.Color,
        alphaB: number,
        patternSize: number,
        fadeDistance: number,
    ) {
        const material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            transparent: true,
            blending: THREE.NormalBlending,
            vertexShader: `
                varying vec3 worldPosition;

                void main()
                {
                    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                    gl_Position = projectionMatrix * mvPosition;

                    worldPosition = position;
                }
                    `,
            fragmentShader: `
                varying vec3 worldPosition;

                const vec4 colorA = vec4(${colorA.r}, ${colorA.g}, ${
                colorA.b
            }, ${alphaA});
                const vec4 colorB = vec4(${colorB.r}, ${colorB.g}, ${
                colorB.b
            }, ${alphaB});

                float saturate(float x)
                {
                    return clamp(x, 0.0, 1.0);
                }

                vec4 colorAt(vec3 worldPos, float scale)
                {
                    // https://www.shadertoy.com/view/XlcSz2
                    vec2 q = floor(worldPos.xz * scale);
                    float t = mod(q.x + q.y, 2.0);

                    return mix(colorA, colorB, t);
                }

                void main()
                {
                    const float scale = float(${1.0 / patternSize});
                    const float fadeDistance = float(${fadeDistance});
                    vec4 col = colorAt(worldPosition, scale);

                    col.a *= pow(saturate(fadeDistance * 0.5 / length(worldPosition.xz)), 2.0);
                    gl_FragColor = col;
                }
                    `,
        });

        const geometry = new THREE.PlaneGeometry(500, 500, 1, 1);
        geometry.rotateX(-Math.PI / 2);
        super(geometry, material);
        this.renderOrder = 1;

        this.shaderMaterial = material;
        this.planeGeometry = geometry;
    }

    public dispose() {
        this.shaderMaterial.dispose();
        this.planeGeometry.dispose();
    }
}
