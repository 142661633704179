import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Stage } from '@orthocore-web-mono/feature-core-services';
import { EnablerService } from '@orthocore-web-mono/feature-enabler';
import {
    IframeCommunicationsService,
    IframeUploadData,
    defaultExportFormat,
} from '@orthocore-web-mono/feature-iframe-integraton';
import { MeshExportFileFormat } from '@orthocore-web-mono/shared-types';
import { DialogService } from '@orthocore-web-mono/shared/feature-dialogs';
import { ScanService } from '@orthocore-web-mono/shared/feature-scene';
import { IsIframe } from '@orthocore-web-mono/shared/utils';
import { firstValueFrom, map, startWith } from 'rxjs';

import { FileLoaderService } from '../file-loader.service';

@Component({
    selector: 'leo-file-panel',
    templateUrl: './file-panel.component.html',
    styleUrl: './file-panel.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePanelComponent {
    @ViewChild('importInput')
    inputRef!: ElementRef;

    @ViewChild('exportLink')
    downloadLink!: ElementRef;

    public exportOptions = ['OBJ', 'STL'];
    public selectedExportOption = 0;

    public exportDisabled$ = this.scanService.generationStateChangeSubject.pipe(
        map(v => !v),
        startWith(true),
    );

    private exportInProgress = false;

    constructor(
        private readonly fileLoaderService: FileLoaderService,
        private readonly scanService: ScanService,
        private readonly enablerService: EnablerService,
        private readonly dialog: DialogService,
        private readonly router: Router,
        private readonly iframeIntegration: IframeCommunicationsService,
    ) {}

    public onImportButtonClicked() {
        this.inputRef.nativeElement.click();
    }

    public onInputChanged(event: Event) {
        const fileInput = <HTMLInputElement>event.target;
        if (!fileInput.files?.length) return;

        const [file] = Array.from(fileInput.files);

        const promise = new Promise<boolean>(resolve => {
            const reader = new FileReader();
            reader.addEventListener('loadend', async () => {
                if (!(reader.result instanceof ArrayBuffer)) {
                    resolve(false);
                    return;
                }
                await this.fileLoaderService.LoadFromArrayBuffer(
                    reader.result,
                    file.name,
                );
                resolve(true);
            });
            reader.readAsArrayBuffer(file);
        });

        this.dialog
            .doWhileLoading(async () => {
                const loaded = await promise;
                if (loaded) {
                    await this.router.navigate([Stage.ScanSettings]);
                }
            })
            .catch(e => console.error(e));
    }

    public async export() {
        if (this.exportInProgress) return;
        this.exportInProgress = true;

        if (IsIframe()) {
            await this.exportInIframe();
        } else {
            await this.defaultExport();
        }

        this.exportInProgress = false;
    }

    private async defaultExport() {
        const format = this.mapFileFormat(this.selectedExportOption);
        const exportResult = await this.enablerService.ExportCounted(format);

        if (!exportResult.generationSuccess || !exportResult.meshBytes) {
            console.error('Failed to export!');
            this.exportInProgress = false;
            return;
        }

        const blob = new Blob([exportResult.meshBytes]);
        const url = URL.createObjectURL(blob);

        this.downloadLink.nativeElement.href = url;
        this.downloadLink.nativeElement.download = `spinal.${format}`;
        this.downloadLink.nativeElement.click();

        setTimeout(() => URL.revokeObjectURL(url), 1000);
    }

    private mapFileFormat(index: number): MeshExportFileFormat {
        switch (index) {
            case 0:
                return 'obj' as MeshExportFileFormat;
            case 1:
                return 'stl' as MeshExportFileFormat;
            default:
                throw new Error('Not supported export format');
        }
    }

    private async exportInIframe() {
        const initData = await this.iframeIntegration.initialize();

        let exportCountSuccess = true;

        const exportResult = await this.dialog.doWhileLoading(async () => {
            const exportFormat = initData.exportFormat ?? defaultExportFormat;
            const result = await this.enablerService.ExportCounted(
                exportFormat,
            );

            exportCountSuccess = result.countSuccess;
            if (
                !result.generationSuccess ||
                !result.countSuccess
                // || TODO: !!!!!
                // result. files.length === 0
            ) {
                return null;
            }

            const exportResultData: IframeUploadData = {
                meshFiles: [result.meshBytes].map((f, i) => ({
                    bytes: f as Uint8Array,
                    name: 'file',
                })),
            };

            return exportResultData;
        }, 'payment/exporting');

        if (exportResult === null) {
            // Show export error
            this.dialog.prompt(
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                exportCountSuccess
                    ? 'PAYMENT.EXPORT_ERROR'
                    : 'PAYMENT.EXPORT_COUNTER-ERROR',
                'GENERIC.OK',
            );
            return;
        }

        // Wait for approval
        const approveResult = await this.dialog.doWhileLoading(
            this.iframeIntegration.approveExport(),
            'PAYMENT.WAITING',
        );

        if (approveResult !== true) {
            // Note: since approveResult is true | string | null, !approveResult cannot be used here
            // Export cancelled
            this.dialog.prompt(
                approveResult === null ? 'PAYMENT.CANCELLED' : approveResult, // Show raw localized string with reason
                'GENERIC.OK',
            );
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition
        while (true) {
            const uploadSuccess = await this.dialog.doWhileLoading(
                this.iframeIntegration.uploadExportResult(exportResult),
                'PAYMENT.UPLOADING',
            );
            if (uploadSuccess) {
                this.iframeIntegration.closeEditor();
                break;
            }

            const dialogRef = this.dialog.confirm(
                'PAYMENT.UPLOAD_ERROR',
                'PAYMENT.RETRY',
                true,
            );

            const dialogResult = await firstValueFrom(dialogRef.afterClosed());

            if (!dialogResult) {
                // Cancelled
                break;
            }
        }
    }
}
