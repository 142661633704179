import { ChangeDetectorRef, Component } from '@angular/core';
import { ToolLoopStretchModeJS } from '@orthocore-web-mono/shared-types';

import { LoopToolBaseComponent } from '../loop-tool-base.component';
import { StretchLoopToolService } from './stretch-loop-tool.service';

@Component({
    selector: 'leo-stretch-loop-tool',
    templateUrl: './stretch-loop-tool.component.html',
    styleUrl: './stretch-loop-tool.component.scss',
})
export class StretchLoopToolComponent extends LoopToolBaseComponent<StretchLoopToolService> {
    Mode = ToolLoopStretchModeJS;

    public modes = [
        'MESH_EDIT.LOOP_TOOLS.LOOPS2',
        'MESH_EDIT.LOOP_TOOLS.LOOPS3',
    ];

    constructor(change: ChangeDetectorRef, service: StretchLoopToolService) {
        super(change, service);
    }

    public onModeChanged(index: number) {
        const nemMode =
            index === 0
                ? ToolLoopStretchModeJS.Double
                : ToolLoopStretchModeJS.Triple;
        this.params.stretchMode = nemMode;
        this.service.update();
    }
}
