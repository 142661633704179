import { Inject, Injectable } from '@angular/core';
import {
    ENGINE_WORKER_SERVICE_TOKEN,
    Float3JS,
    IEngineWorkerService,
    IEngineWorkerServiceDataBase,
} from '@orthocore-web-mono/shared-types';
import { SceneService } from '@orthocore-web-mono/shared/feature-scene';
import * as THREE from 'three';

import {
    LANDMARKS_TOKEN,
    LandMarkInOrderData,
    LandmarkObject,
} from './base-landmark-objects';
import {
    landmarkGeometry,
    landmarkMaterial,
    landmarkMaterialHighlighted,
} from './landmark-material';

@Injectable()
export class LandmarkContainer extends THREE.Group {
    private landmarkObjects: LandmarkObject;
    private highlightedLandmark: number | null = null;

    constructor(
        @Inject(LANDMARKS_TOKEN)
        private readonly landmarks: LandMarkInOrderData[],
        @Inject(ENGINE_WORKER_SERVICE_TOKEN)
        private readonly engine: IEngineWorkerService<IEngineWorkerServiceDataBase>,
        private readonly sceneService: SceneService,
    ) {
        super();
        this.frustumCulled = false;

        this.landmarkObjects = this.landmarks
            .map(l => l.type)
            .reduce((p, c) => {
                p[c] = new THREE.Mesh(landmarkGeometry, landmarkMaterial);
                return p;
            }, {} as LandmarkObject);

        Object.values(this.landmarkObjects).forEach(obj => this.add(obj));

        this.resetLandmarks();
    }

    public setHighlightedLandmark(type: number | null) {
        if (this.highlightedLandmark === type) {
            return;
        }

        if (this.highlightedLandmark !== null) {
            this.landmarkObjects[this.highlightedLandmark].material =
                landmarkMaterial;
        }

        if (type !== null) {
            this.landmarkObjects[type].material = landmarkMaterialHighlighted;
        }

        this.highlightedLandmark = type;
        this.sceneService.requestSceneRender();
    }

    public setLandmarkPosition(type: number, position: THREE.Vector3) {
        const obj = this.landmarkObjects[type];
        obj.position.copy(position);
        obj.visible = true;
        this.sceneService.requestSceneRender();
    }

    public getLandmarkPosition(type: number) {
        return this.landmarkObjects[type].position;
    }

    public async syncLandmarkPositionsFromEngine(
        applySnapshotBeforeSync: boolean,
    ) {
        if (applySnapshotBeforeSync) {
            await this.engine.applySnapshottedScanLandmarks();
        }

        const result: { string: Float3JS } = await this.engine.getLandmarks();
        const tmpVec3 = new THREE.Vector3();
        for (const [name, position] of Object.entries(result)) {
            const type = this.landmarks.find(x => x.fullName === name)?.type;
            if (type === undefined)
                throw new Error(`Landmark is not found: ${name}`);

            this.setLandmarkPosition(
                type,
                tmpVec3.set(position.x, position.y, position.z),
            );
        }
    }

    public resetLandmarks() {
        for (const obj of Object.values(this.landmarkObjects)) {
            obj.position.setScalar(0);
            obj.visible = false;
        }

        this.sceneService.requestSceneRender();
    }
}
