import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private loadingCount = 0;
    private loadingFadeOutTimeout: number | undefined = undefined;

    constructor(public dialog: MatDialog) {}

    public openLoadingDialog(
        loadingTextTranslateKey?: string,
        disableClose = true,
    ): MatDialogRef<LoadingIndicatorComponent> {
        const ref = this.dialog.open(LoadingIndicatorComponent, {
            disableClose,
        });

        ref.componentInstance.loadingTextTranslateKey = loadingTextTranslateKey;

        return ref;
    }

    public confirm(text: string, buttonText: string, disableClose = false) {
        const ref = this.dialog.open(ConfirmationDialogComponent, {
            disableClose,
        });
        ref.componentInstance.confirmMessage = text;
        ref.componentInstance.buttonMessage = buttonText;

        return ref;
    }

    public prompt(text: string, buttonText: string, disableClose = false) {
        const ref = this.dialog.open(PromptDialogComponent, {
            disableClose,
        });
        ref.componentInstance.confirmMessage = text;
        ref.componentInstance.buttonMessage = buttonText;

        return ref;
    }

    public async doWhileLoading<T>(
        callback: Promise<T> | (() => Promise<T>),
        loadingTextTranslateKey?: string,
    ) {
        this.loadingCount++;
        window.clearTimeout(this.loadingFadeOutTimeout);

        const ref = this.openLoadingDialog(loadingTextTranslateKey);

        try {
            let result: T;
            if (callback instanceof Promise) {
                result = await callback;
            } else {
                result = await callback();
            }

            return result;
        } finally {
            if (--this.loadingCount === 0) {
                ref.close();
            }
        }
    }
}
