/* eslint-disable max-params-no-constructor/max-params-no-constructor */
import * as THREE from 'three';

const axisGizmoCameraDistanceFromOrigin = 2.5;
export const axisGizmoCamera = new THREE.PerspectiveCamera(45, 1, 0.01, 100);
axisGizmoCamera.position.z = axisGizmoCameraDistanceFromOrigin;

const axisGizmoScene = new THREE.Scene();

function CreateGeometry(vertices: number[], triangles: number[]) {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(new Float32Array(vertices), 3, false),
    );
    geometry.setIndex(
        new THREE.BufferAttribute(new Uint16Array(triangles), 1, false),
    );
    geometry.translate(-0.5, -0.5, 0);
    return geometry;
}

const xGeometry = CreateGeometry(
    [
        0, 0, 0, 0.35, 0.5, 0, 0, 1, 0, 0.3, 1, 0, 0.5, 0.7, 0, 0.7, 1, 0, 1, 1,
        0, 0.65, 0.5, 0, 1, 0, 0, 0.7, 0, 0, 0.5, 0.3, 0, 0.3, 0, 0,
    ],
    [
        0, 1, 11, 11, 1, 10, 1, 2, 3, 1, 3, 4, 1, 4, 7, 1, 7, 10, 4, 5, 7, 7, 5,
        6, 9, 10, 7, 9, 7, 8,
    ],
);

const yGeometry = CreateGeometry(
    [
        0.375, 0, 0, 0.375, 0.35, 0, 0, 1, 0, 0.3, 1, 0, 0.5, 0.6, 0, 0.7, 1, 0,
        1, 1, 0, 0.625, 0.35, 0, 0.625, 0, 0,
    ],
    [0, 1, 7, 0, 7, 8, 1, 2, 3, 1, 3, 4, 1, 4, 7, 7, 4, 5, 7, 5, 6],
);

const zGeometry = CreateGeometry(
    [
        0.1, 0, 0, 0.1, 0.2, 0, 0.65, 0.8, 0, 0.1, 0.8, 0, 0.1, 1, 0, 0.9, 1, 0,
        0.9, 0.8, 0, 0.35, 0.2, 0, 0.9, 0.2, 0, 0.9, 0, 0,
    ],
    [9, 7, 8, 0, 7, 9, 0, 1, 7, 1, 2, 7, 7, 2, 6, 2, 5, 6, 2, 4, 5, 3, 4, 2],
);

export const axisGizmo = new THREE.Group();
axisGizmoScene.add(axisGizmo);

const lineThickness = 0.02;
const lineLength = 0.6;
const letterSize = 0.2;
const letterPadding = 0.05;

const redColor = 0xff6c6c;
const greenColor = 0x73ea97;
const blueColor = 0x73ceff;
const lightRedColor = 0xff9999;
const lightGreenColor = 0x9dffc8;
const lightBlueColor = 0xa3e4ff;
const redMaterial = new THREE.MeshBasicMaterial({
    color: redColor,
    side: THREE.DoubleSide,
});
const greenMaterial = new THREE.MeshBasicMaterial({
    color: greenColor,
    side: THREE.DoubleSide,
});
const blueMaterial = new THREE.MeshBasicMaterial({
    color: blueColor,
    side: THREE.DoubleSide,
});
const lightRedMaterial = new THREE.MeshBasicMaterial({
    color: lightRedColor,
    side: THREE.DoubleSide,
});
const lightGreenMaterial = new THREE.MeshBasicMaterial({
    color: lightGreenColor,
    side: THREE.DoubleSide,
});
const lightBlueMaterial = new THREE.MeshBasicMaterial({
    color: lightBlueColor,
    side: THREE.DoubleSide,
});

const lineGeometry = new THREE.CylinderGeometry(
    lineThickness,
    lineThickness,
    lineLength,
    8,
    1,
    false,
);
lineGeometry.translate(0, lineLength / 2, 0);

const sphereGeometry = new THREE.SphereGeometry(0.1, 32, 32);
const topSphere = new THREE.Mesh(sphereGeometry, greenMaterial);
const bottomSphere = new THREE.Mesh(sphereGeometry, lightGreenMaterial);
const leftSphere = new THREE.Mesh(sphereGeometry, lightRedMaterial);
const rightSphere = new THREE.Mesh(sphereGeometry, redMaterial);
const frontSphere = new THREE.Mesh(sphereGeometry, blueMaterial);
const backSphere = new THREE.Mesh(sphereGeometry, lightBlueMaterial);

topSphere.position.set(0, 0.6, 0);
bottomSphere.position.set(0, -0.6, 0);
leftSphere.position.set(-0.6, 0, 0);
rightSphere.position.set(0.6, 0, 0);
frontSphere.position.set(0, 0, 0.6);
backSphere.position.set(0, 0, -0.6);

axisGizmo.add(
    topSphere,
    bottomSphere,
    leftSphere,
    rightSphere,
    frontSphere,
    backSphere,
);

export const gizmoCircles = [
    topSphere,
    bottomSphere,
    leftSphere,
    rightSphere,
    frontSphere,
    backSphere,
];

const xAxisMesh = new THREE.Mesh(lineGeometry, redMaterial);
xAxisMesh.rotation.set(0, 0, -Math.PI / 2);
axisGizmo.add(xAxisMesh);

const yAxisMesh = new THREE.Mesh(lineGeometry, greenMaterial);
axisGizmo.add(yAxisMesh);

const zAxisMesh = new THREE.Mesh(lineGeometry, blueMaterial);
zAxisMesh.rotation.set(Math.PI / 2, 0, 0);
axisGizmo.add(zAxisMesh);

function CreateLetterObject(
    geometry: THREE.BufferGeometry,
    material: THREE.Material,
    componentIndex: number,
) {
    const obj = new THREE.Mesh(geometry, material);
    obj.position.setComponent(
        componentIndex,
        lineLength + letterPadding + letterSize,
    );
    obj.scale.setScalar(letterSize);
    axisGizmo.add(obj);
    return obj;
}

const xLetterObject = CreateLetterObject(xGeometry, redMaterial, 0);
const yLetterObject = CreateLetterObject(yGeometry, greenMaterial, 1);
const zLetterObject = CreateLetterObject(zGeometry, blueMaterial, 2);

// Render at the top right corner of the canvas
export function RenderAxisGizmo(
    cameraRotation: THREE.Quaternion,
    renderer: THREE.WebGLRenderer,
    canvasWidth: number,
    canvasHeight: number,
    sizeInPixels = 150,
    margin = 20,
) {
    axisGizmoCamera.quaternion.copy(cameraRotation);
    axisGizmoCamera.position
        .set(0, 0, axisGizmoCameraDistanceFromOrigin)
        .applyQuaternion(cameraRotation);

    xLetterObject.quaternion.copy(cameraRotation);
    yLetterObject.quaternion.copy(cameraRotation);
    zLetterObject.quaternion.copy(cameraRotation);

    renderer.autoClearColor = false; // Don't clear color before rendering, so it'll be rendered over the default scene
    renderer.setScissorTest(true);
    renderer.domElement.clientWidth;
    const viewportStartX = canvasWidth - sizeInPixels - margin;
    const viewportStartY = canvasHeight - sizeInPixels - margin;
    renderer.setScissor(
        viewportStartX,
        viewportStartY,
        sizeInPixels,
        sizeInPixels,
    );
    renderer.setViewport(
        viewportStartX,
        viewportStartY,
        sizeInPixels,
        sizeInPixels,
    );

    renderer.render(axisGizmoScene, axisGizmoCamera);
    renderer.autoClearColor = true;

    renderer.setScissorTest(false);
}
