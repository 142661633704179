import { Float2JS } from './native-types';

//TODO: move to scan-edit module

export interface ScreenPoint {
    pixelPosition: Float2JS;
    normalizedPosition: Float2JS;
}

export const enum ScanCleanSelectionMode {
    Rect = 'Rect',
    Lasso = 'Lasso',
    Brush = 'Brush',
}

export const selectionModeArray = [
    ScanCleanSelectionMode.Rect,
    ScanCleanSelectionMode.Lasso,
    ScanCleanSelectionMode.Brush,
];

export const selectionModeLocales = [
    'SCAN_SETTINGS.CLEAN.BOX',
    'SCAN_SETTINGS.CLEAN.LASSO',
    'SCAN_SETTINGS.CLEAN.BRUSH',
];
